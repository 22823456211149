import { Field, FieldProps, FieldConfig } from 'formik';
import { PasswordInput } from 'components/Input';
import { cx } from '@linaria/core';
import React, { useCallback } from 'react';
import { InputWithIcon, InputWithIconProps } from 'components/Input/components/InputWithIcon';
import { FormGroup } from './FormGroup';
import { FormFieldError } from './FormFieldError';
import { LabelWithTooltip, TooltipText } from './LabelWithTooltip';
import { fieldWrapper, formLabel, multiLineInputStyle } from './Forms.styles';

export type InputFieldProps = {
  errorClassName?: string;
  label?: string;
  tooltip?: {
    title: string;
    text: TooltipText;
  };
  rows?: number;
  isMultiLine?: boolean;
  /** @deprecated this prop is going to be removed soon as instances of use are removed */
  customError?: string;
  formGroupClassName?: string;
  fieldWrapperClassName?: string;
  type?: string | undefined;
  truncateError?: boolean;
  inputLabelClassName?: string;
  asteriskClassName?: string;
} & Omit<InputWithIconProps, 'form'>;

function InputField_({
  errorClassName,
  field,
  tooltip,
  label,
  meta,
  formGroupClassName,
  fieldWrapperClassName,
  className,
  type,
  id,
  truncateError,
  inputLabelClassName,
  asteriskClassName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form, // form is unused prop but needs to be picked so as to not be spreaded down
  ...restInputProps
}: InputFieldProps & FieldProps): JSX.Element {
  const error = meta.error;
  const isErrored = meta.touched && error;
  const idForLabel = id || label || field.name; // fallback to other options to always ensure having a valid htmlFor id for the label
  const invalid = isErrored ? 'invalid' : undefined;

  return (
    <FormGroup className={formGroupClassName}>
      <div className={cx(fieldWrapper, invalid, fieldWrapperClassName)}>
        {Boolean(label) && (
          <LabelWithTooltip
            className={cx(formLabel, inputLabelClassName)}
            htmlFor={idForLabel}
            tooltipTitle={tooltip?.title}
            tooltipText={tooltip?.text}
          >
            {label} {restInputProps?.required ? <span className={cx('asterisk', asteriskClassName)}>*</span> : null}
          </LabelWithTooltip>
        )}
        {type === 'password' ? (
          <PasswordInput id={idForLabel} variant={invalid} className={className} {...field} {...restInputProps} />
        ) : (
          <InputWithIcon type={type} id={idForLabel} variant={invalid} className={className} {...field} {...restInputProps} />
        )}
        {isErrored && <FormFieldError className={errorClassName} error={error} truncateError={truncateError} />}
      </div>
    </FormGroup>
  );
}

export function InputField({ isMultiLine, className, ...props }: InputFieldProps & Omit<FieldConfig, 'component' | 'children'>): JSX.Element {
  /* eslint-disable react/no-children-prop */
  // avoid field rerendering on parent rerender
  const children = useCallback(
    (fieldProps: FieldProps) => (
      <InputField_
        as={isMultiLine ? 'textarea' : 'input'}
        className={cx(className, isMultiLine ? multiLineInputStyle : '')}
        {...fieldProps}
        {...props}
        onChange={(event) => {
          // Formik's `onChange`.
          fieldProps.field.onChange(event);
          // User-defined `onChange`.
          props.onChange?.(event);
        }}
      />
    ),
    [props],
  );

  return <Field {...props} children={children} />;
}
