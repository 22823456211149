import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchApi, fetchTE } from 'utils/request';

interface PatchAppRequestBody {
  app: Omit<Partial<CF.API.Apps.App>, 'image'> & { id: string };
  action: 'merge' | 'overwrite' | 'remove';
  reindex: boolean;
}

interface PatchAppCoverImageRequestBody extends PatchAppRequestBody {
  app: Pick<CF.API.Apps.App, 'id' | 'image'>;
}

/**
 * @deprecated
 */
export const updateAppTE = <E = CF.API.ApiError>(
  { userId, body }: { userId: string; body: PatchAppRequestBody },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.FetchAppResponse> =>
  fetchTE<CF.API.Apps.FetchAppResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${body.app.id}`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );

/**
 * @deprecated
 */
export const updateAppCoverImageTE = <E = CF.API.ApiError>(
  { userId, body }: { userId: string; body: PatchAppCoverImageRequestBody },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.FetchAppResponse> =>
  fetchTE<CF.API.Apps.FetchAppResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${body.app.id}`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );

export const apiUpdateApp = ({ userId, body }: { userId: string; body: PatchAppRequestBody }) =>
  fetchApi<CF.API.Apps.FetchAppResponse>({
    path: `/api/_api/v2/users/${userId}/apps/${body.app.id}`,
    method: 'PATCH',
    body,
  });

export const apiUpdateAppCoverImage = ({ userId, body }: { userId: string; body: PatchAppCoverImageRequestBody }) =>
  fetchApi<CF.API.Apps.FetchAppResponse>({
    path: `/api/_api/v2/users/${userId}/apps/${body.app.id}`,
    method: 'PATCH',
    body,
  });
