import React, { useRef } from 'react';
import { cx } from '@linaria/core';
import { FieldProps } from 'formik';
import { PasswordInput, InputProps } from 'components/Input';
import { getCheckedPoliciesLength, getPercentValue, RulesInterface } from 'modules/User/utils/password';
import { PercentLineInner, percentLineContainer, percentLineOuter, rulesElement, wrapper } from './RuledPassword.styles';

export type RuledPasswordProps = {
  rules: RulesInterface;
  variant?: string;
  amountOfPolicies?: number;
  className?: string;
};

const getPercentColorClass = (percentInt: number): 'green' | 'red' => {
  if (percentInt === 100) {
    return 'green';
  }

  return 'red';
};

const getPercentLine = (percentInt: number): JSX.Element => {
  return (
    <div className={percentLineContainer}>
      <div className={cx(percentLineOuter)} data-testid="percent-line">
        <PercentLineInner percent={percentInt} className={getPercentColorClass(percentInt)} />
      </div>
    </div>
  );
};

export const RuledPasswordInput = ({
  field,
  rules,
  variant,
  className,
  ...props
}: RuledPasswordProps & Omit<FieldProps, 'meta' | 'form'> & Omit<InputProps, 'form'>): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const amountOfPolicies = Object.keys(rules).length;
  const checkedPoliciesLength = getCheckedPoliciesLength(rules);
  const percent = inputRef?.current?.value ? getPercentValue(amountOfPolicies, checkedPoliciesLength) : 0;

  return (
    <div className={wrapper}>
      <PasswordInput
        ref={inputRef}
        data-testid={amountOfPolicies ? '' : 'regular-password'}
        className={className}
        variant={variant}
        placeholder="Password"
        {...field}
        {...props}
      />
      {Boolean(percent) && getPercentLine(percent)}

      {Boolean(amountOfPolicies) && (
        <ul className="failed-rules">
          {Object.keys(rules)
            .filter((ruleKey: keyof typeof rules) => rules[ruleKey]?.isChecked)
            .map((ruleKey: keyof typeof rules) => (
              <li className={rulesElement} key={ruleKey}>
                {rules[ruleKey]?.text}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
