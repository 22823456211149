import { cx } from '@linaria/core';
import { useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import { tagItem, tagWrapper, themeBlue } from './TagList.styles';
import { EntityCardLayoutProps } from './types';

export const TAG_THEME = {
  BLUE: themeBlue,
};

const overflowScreenSizeList = [420, 365, 290, 210];
const overflowSize = 420;
const maxTags = 20;
const minTagsMap = {
  420: 4,
  365: 3,
  290: 2,
  210: 1,
};

export const TagList: React.FC<{ tags?: EntityCardLayoutProps['tags'] }> = ({ tags }) => {
  const [measureRef, { width }] = useMeasure();
  const mRefTyped = measureRef as React.LegacyRef<HTMLDivElement>;
  const [isExpanded, setIsExpanded] = useState(false);
  const isOverflow = width <= overflowSize;
  const hideAllTags = isOverflow && !isExpanded;

  const minScreenSize = overflowScreenSizeList.reduce((firstElement, secondElement) => {
    const difference = Math.abs(secondElement - width) - Math.abs(firstElement - width);
    return difference && width <= secondElement ? secondElement : firstElement;
  }) as keyof typeof minTagsMap;
  const minTags = minTagsMap[minScreenSize];
  const maxItem = hideAllTags ? minTags : maxTags;

  const tagList = useMemo(() => {
    return tags?.slice(0, maxItem);
  }, [tags, maxItem]);

  if (!tags?.length) {
    return null;
  }

  const onExpandClick = (event: React.MouseEvent) => {
    setIsExpanded(true);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={tagWrapper} ref={mRefTyped}>
      {tagList?.map(({ uniqueKey, item, icon, theme }) => (
        <div className={cx(tagItem, theme)} key={uniqueKey}>
          {icon}
          <span key={uniqueKey}> {item}</span>
        </div>
      ))}
      {hideAllTags && tags?.length > minTags && (
        <button type="button" className={tagItem} onClick={(event) => onExpandClick(event)}>
          ...
        </button>
      )}
    </div>
  );
};
