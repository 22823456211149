import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const LayerThreeIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 17" fill="none">
    <path
      d="M1.33334 8.39002L7.7615 11.6041C7.84895 11.6478 7.89268 11.6697 7.93854 11.6783C7.97917 11.6859 8.02085 11.6859 8.06148 11.6783C8.10734 11.6697 8.15107 11.6478 8.23852 11.6041L14.6667 8.39002M1.33334 11.7233L7.7615 14.9374C7.84895 14.9812 7.89268 15.003 7.93854 15.0116C7.97917 15.0192 8.02085 15.0192 8.06148 15.0116C8.10734 15.003 8.15107 14.9812 8.23852 14.9374L14.6667 11.7233M1.33334 5.05668L7.7615 1.84261C7.84895 1.79888 7.89268 1.77702 7.93854 1.76841C7.97917 1.76079 8.02085 1.76079 8.06148 1.76841C8.10734 1.77702 8.15107 1.79888 8.23852 1.84261L14.6667 5.05668L8.23852 8.27076C8.15107 8.31449 8.10734 8.33635 8.06148 8.34496C8.02085 8.35258 7.97917 8.35258 7.93854 8.34496C7.89268 8.33635 7.84895 8.31449 7.7615 8.27076L1.33334 5.05668Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
