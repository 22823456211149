import { Button } from 'reakit';
import { useCallback, useEffect, useState } from 'react';
import { EmailShareButton, FacebookShareButton, RedditShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { IconCopy, IconEmailOutline, IconFacebookOutline, IconRedditOutline, IconTwitterOutline, IconLinkedInOutline } from 'components/Icons';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useIsSSR } from 'react-aria';
import { item } from './SocialShareList_styles';

export const SocialShareList_: React.FC = () => {
  const [url, setUrl] = useState('');

  const windowUrl = window ? window.location.href : '';
  useEffect(() => {
    // runs only in browser
    setUrl(windowUrl);
  }, [windowUrl]);

  const onCopyClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      copyToClipboard(url)();
    },
    [url],
  );

  return (
    <ul>
      <ListItem>
        <IconCopy size={16} />
        <Button color="" onClick={onCopyClick}>
          Copy URL
        </Button>
      </ListItem>
      <ListItem>
        <IconTwitterOutline size={16} strokeWidth={1} />
        <TwitterShareButton url={url}>Twitter</TwitterShareButton>
      </ListItem>
      <ListItem>
        <IconFacebookOutline size={16} strokeWidth={1} />
        <FacebookShareButton url={url}>Facebook</FacebookShareButton>
      </ListItem>
      <ListItem>
        <IconRedditOutline size={16} strokeWidth={1} />
        <RedditShareButton url={url}>Reddit</RedditShareButton>
      </ListItem>
      <ListItem>
        <IconLinkedInOutline size={16} strokeWidth={1} />
        <LinkedinShareButton url={url}>LinkedIn</LinkedinShareButton>
      </ListItem>
      <ListItem>
        <IconEmailOutline size={16} strokeWidth={1} />
        <EmailShareButton url={url}>Email</EmailShareButton>
      </ListItem>
    </ul>
  );
};

export const SocialShareList: React.FC = () => {
  const isSSR = useIsSSR();

  if (isSSR) return <ul />;
  return <SocialShareList_ />;
};

const ListItem: React.FC = ({ children }) => <li className={item}>{children}</li>;
