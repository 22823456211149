import { genGradient } from './utils';

export type EntityCardGradientProps = { gradientText: string; children: React.ReactNode };

export const EntityCardGradient: React.FC<EntityCardGradientProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { gradientText, children, ...rest } = props;
  return (
    <div
      style={{
        background: genGradient(gradientText, 'diagonal'),
        minWidth: 0,
      }}
      data-testid="cfcomp-EntityCard-fallback"
      {...rest}
    >
      {children}
    </div>
  );
};
