import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const AudioToTextIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.56586 6.05814V15.8645" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M8.4032 9.04266V12.8799" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M12.2405 4.56587V17.3568" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M16.0777 7.5504V14.3722" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M19.915 9.3741V12.5481" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path
      d="M17.901 24.5001H15.8824L18.958 15.591H21.3854L24.4566 24.5001H22.4382L20.2064 17.6268H20.1369L17.901 24.5001ZM17.7748 20.9981H22.5425V22.4685H17.7748V20.9981Z"
      fill={color}
    />
  </svg>
);
