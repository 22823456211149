import React, { FC, useCallback } from 'react';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { listAppInstalledModuleVersionsTE } from 'api/modules/listAppInstalledModuleVersions';
import { errorToReactLeft, pipe } from 'utils/fp';
import { either, taskEither } from 'fp-ts';
import { MenuGroup, MenuItem } from 'modules/Sidebar/components/Menu/Menu';
import { useIsLoggedIn } from 'modules/Auth/hooks';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { useHasScopes } from 'modules/Auth/hooks/scopes';
import { Button } from 'components/Button';
import { useRouter } from 'next/router';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { IconEvaluations } from 'components/Icons';
import { isIMVForDemo } from 'modules/InstalledModuleVersions/utils/imv_utils';
import { AuthModalState } from 'modules/Auth/components/AuthModal';
import { manageIMVsBtn } from './styles';
import { ModuleVersionsList } from './ModuleVersionsList';

interface ModuleVersionsSidebarMenuProps {
  userOrOrgId: string;
  appId: string;
  installedModuleVersionId: string | undefined;
  appHref: (path?: string) => string;
  isCollapsed?: boolean;
}

export const listInstalledModuleVersions = (userId: string, appId: string) =>
  pipe(
    listAppInstalledModuleVersionsTE({ userId, appId }, errorToReactLeft),
    taskEither.map((x) => x.installed_module_versions),
  );

export const ModuleVersionsSidebarMenu: FC<ModuleVersionsSidebarMenuProps> = ({
  isCollapsed,
  userOrOrgId,
  appId,
  installedModuleVersionId,
  appHref,
}) => {
  const { openLoginModal } = useAuthActions();
  let hasAccessToModuleVersions = useHasScopes([permissionMatchers.imvs.list]);
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const { track } = useDataCollection();

  const { data: modulesData } = useQueryTE(
    [
      queryKeys.Modules,
      {
        userOrOrgId,
        appId,
      },
    ],
    listInstalledModuleVersions(userOrOrgId, appId),
  );

  const handleUninstall = useCallback(
    (moduleId: string) => {
      const clbck = () => {
        track('Uninstall Module Clicked', { param1: 'sidebar', userOrOrgId, appId, moduleId });
        router.push(`/${userOrOrgId}/${appId}/installed_module_versions/module_manager_install/uninstall?delete_imv_id=${moduleId}`);
      };
      if (!isLoggedIn) {
        openLoginModal(AuthModalState.SHOW_SIGN_IN, clbck);
        return;
      }
      clbck();
    },
    [isLoggedIn, track],
  );

  const handleManageIMVs = useCallback(() => {
    const clbck = () => {
      track('Manage Installed Modules Clicked', { param1: 'sidebar', userOrOrgId, appId, moduleId: installedModuleVersionId });
      router.push(`/${userOrOrgId}/${appId}/installed_module_versions/module_manager_install/manage_imvs`);
    };
    if (!isLoggedIn) {
      openLoginModal(AuthModalState.SHOW_SIGN_IN, clbck);
      return;
    }
    clbck();
  }, [isLoggedIn, track]);

  const renderMenuText = useCallback((text) => (!isCollapsed ? text : ''), [isCollapsed]);
  return (
    <>
      {pipe(
        modulesData,
        either.fold(
          () => null,
          (imvs) => {
            // We check if internal user OR below we see if there are IMVs being returned by the API
            // this allows early access users who we have installed IMVs into their apps to see them.
            hasAccessToModuleVersions = hasAccessToModuleVersions || imvs.length > 0 || isIMVForDemo(userOrOrgId);

            return (
              <>
                {hasAccessToModuleVersions && (
                  <MenuGroup title={renderMenuText('Installed Modules')}>
                    <ModuleVersionsList
                      appHref={appHref}
                      imvList={imvs}
                      installedModuleVersionId={installedModuleVersionId}
                      handleUninstall={isLoggedIn && handleUninstall}
                      isCollapsed={isCollapsed}
                    />

                    {!isCollapsed && isLoggedIn && (
                      <div className={manageIMVsBtn}>
                        <Button size="md" variant="link" className="manage-imvs" onClick={handleManageIMVs} data-testid="manage-imvs-btn">
                          Manage Installed Modules
                        </Button>
                      </div>
                    )}
                    {isCollapsed && (
                      <MenuItem
                        isCollapsed={isCollapsed}
                        className="sidebar-menu-item"
                        href={appHref('/installed_module_versions/module_manager_install/manage_imvs')}
                        icon={IconEvaluations}
                        title=" Manage Installed Modules"
                      >
                        {renderMenuText('Manage Installed Modules')}
                      </MenuItem>
                    )}
                  </MenuGroup>
                )}
              </>
            );
          },
        ),
      )}
    </>
  );
};
