import { either, errorToReactLeft } from 'utils/fp';
import { queryKeys, useMutationTE } from 'utils/react-query';
import { acceptInvitationTE, declineInvitationTE } from 'api/organizations';
import { ReactLeft } from 'utils/uiStates/uiStates';
import cogoToast from 'cogo-toast';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { QueryClient, useQueryClient } from 'react-query';

type TCachedOrgInvitationQuery = either.Either<ReactLeft, CF.API.Organizations.OrgMemberInvite[]> | undefined;

// No need to refetch the query, simply remove it from the react-query cache.
// When the query is refetched, the accepted/declined invite won't be there anyway.
function removeInviteFromCachedQuery(queryClient: QueryClient, invitation: CF.API.Organizations.OrgMemberInvite, userId: string) {
  const queryKey = [queryKeys.UserOrganizationInvitations, { userId }];

  if (!queryClient.getQueryData(queryKey)) {
    return;
  }

  queryClient.setQueryData<TCachedOrgInvitationQuery>(queryKey, (invitations) => {
    if (invitations && either.isRight(invitations)) {
      const removedInviteList = either.right(invitations.right.filter((keepInvite) => keepInvite.id !== invitation.id));
      return removedInviteList;
    }

    return invitations;
  });
}

export function useAcceptOrgInvitation(invitation: CF.API.Organizations.OrgMemberInvite, onSuccess?: () => void) {
  const { authData } = useAuthState();
  const { changeUserAccountById } = useAuthActions();
  const queryClient = useQueryClient();

  return useMutationTE(() => acceptInvitationTE({ invitationId: invitation.id, userId: authData?.user_id || '' }, errorToReactLeft)(), {
    onSuccess: () => {
      cogoToast.success('Invitation accepted.');
      queryClient.invalidateQueries([queryKeys.Organizations]);
      changeUserAccountById(invitation?.organization.id);
      removeInviteFromCachedQuery(queryClient, invitation, authData?.user_id || '');
      onSuccess?.();
    },
    onError: () => {
      cogoToast.error('Failed to accept the invitation.');
    },
  });
}

export function useDeclineOrgInvitation(invitation: CF.API.Organizations.OrgMemberInvite, onSuccess?: () => void) {
  const { authData } = useAuthState();
  const queryClient = useQueryClient();

  return useMutationTE(() => declineInvitationTE({ invitationId: invitation.id, userId: authData?.user_id || '' }, errorToReactLeft)(), {
    onSuccess: () => {
      cogoToast.success('Invitation declined.');
      removeInviteFromCachedQuery(queryClient, invitation, authData?.user_id || '');
      onSuccess?.();
    },
    onError: () => {
      cogoToast.error('Failed to decline the invitation.');
    },
  });
}
