import { useQueryTE } from 'utils/react-query';
import { fetchPasswordPoliciesTE } from 'api/users/fetchPasswordPolicies';
import { errorToReactLeft, pipe } from 'utils/fp';
import { either } from 'fp-ts';
import { PolicyInterface } from 'modules/User/utils/password';
import { useMemo } from 'react';

const policyIdForNonLoggedInUser = 'globalPolicyID';

export const usePasswordRules = (): { policies?: PolicyInterface } => {
  const defaultPolicies: { policies?: PolicyInterface } = {};

  const { data: passwordPoliciesE } = useQueryTE(['PasswordPolicies'], fetchPasswordPoliciesTE({ userId: '' }, errorToReactLeft));

  return useMemo(
    () =>
      pipe(
        passwordPoliciesE,
        either.match(
          () => {
            return defaultPolicies;
          },
          (response) => {
            const passwordPolicies = response.password_policies.find((policy) => policy.id === policyIdForNonLoggedInUser);
            if (!passwordPolicies || !passwordPolicies.no_simple_passwords) {
              return defaultPolicies;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, no_simple_passwords, password_life_span_days, ...globalPolicy }: CF.API.Users.PasswordPoliciesEntity = passwordPolicies;

            return {
              policies: globalPolicy as PolicyInterface,
            };
          },
        ),
      ),
    [passwordPoliciesE],
  );
};
