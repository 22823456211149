import { MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { usePopoverState } from 'components/Popover';
import { capitalize } from '@mui/material';
import { SupportedListingEntities } from 'modules/Listings/types';
import { getAppId, getEntityId, getUserId, getEntityName } from 'modules/Listings/helpers/entityCardUtils';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { fetchImageUrl } from 'utils/inputs';
import { constructThumbnailURL, getAllCoverImages } from 'utils/coverImage';
import { useProgressiveImg } from 'utils/hooks/useProgressiveImage';
import { useAppResourceCountsConditionally } from 'modules/Listings/hooks/useAppResourceCountsConditionally';
import { getEntityTags, isDatasetEntity } from 'modules/Listings/helpers/EntityTagHelper';
import { EntityCardFooterItem } from './types';
import { EntityCardGrid } from './EntityCardGrid';
import { EntityCardList } from './EntityCardList';
import { EntityPopover } from './EntityPopover';

export interface EntityCardProps extends React.HTMLAttributes<HTMLDivElement> {
  href?: string;
  title: string;
  description: string;
  pageName: string;
  footerItems: EntityCardFooterItem[];
  star?: ReactNode;
  share?: ReactNode;
  lock?: ReactNode;
  owner: boolean;
  entity: SupportedListingEntities;
  visibilityHidden?: boolean;
  parentWidth?: number;
  isGridLayout?: boolean;
  viewMode?: string;
  showTags?: boolean;
}

export const WrapLink: React.FC<{ href?: string }> = ({ href, children }) => (href ? <a href={href}>{children}</a> : <>{children}</>);

export const EntityCard: React.FC<EntityCardProps> = ({
  href,
  title,
  description,
  footerItems,
  pageName,
  onClick,
  share,
  star,
  owner,
  entity,
  visibilityHidden = true,
  viewMode = 'list',
  lock,
  showTags = false,
}) => {
  const [cardThumbnail, setCardThumbnail] = useState<string | undefined>();
  const popover = usePopoverState();
  const { smallImage, largeImage, isCoverImageAvailable } = getAllCoverImages(entity as CF.API.Apps.App);
  const { src } = useProgressiveImg(smallImage, largeImage, 3000);
  const entityId = getEntityId(entity);
  const appResourceCounts = useAppResourceCountsConditionally(entity, showTags && !isDatasetEntity(href as string));
  const tags = getEntityTags({ entity, href: href as string, appResourceCounts });

  const { track } = useDataCollection();
  const onCardClick = (e: MouseEvent<HTMLDivElement>): void => {
    track(`${capitalize(getEntityName(entity) || 'Unknown')} Card Clicked`, {
      pageName,
      userOrOrgId: getUserId(entity),
      appId: getAppId(entity),
      id: entityId,
    });

    if (onClick) {
      onClick(e);
    }
  };

  const fetchUrl = async () => {
    const featuredUrl = isCoverImageAvailable ? src : constructThumbnailURL(entity);
    const rawUrl = (featuredUrl || '').split('?')[0];

    const image = await fetchImageUrl({
      url: rawUrl,
      fallbackUrl: featuredUrl,
    });
    setCardThumbnail(image);
  };

  const EntityCardLayout = useMemo(() => {
    switch (viewMode) {
      case 'grid':
        return EntityCardGrid;
      case 'list':
        return EntityCardList;
      default:
        return () => <></>;
    }
  }, [viewMode, onCardClick]);

  useEffect(() => {
    fetchUrl();
  }, [entity, src]);

  return (
    <>
      <WrapLink href={href}>
        <EntityCardLayout
          title={title}
          onCardClick={onCardClick}
          popover={popover}
          cardThumbnail={cardThumbnail}
          entity={entity}
          owner={owner}
          share={share}
          star={star}
          lock={lock}
          tags={tags}
          footerItems={footerItems}
          visibilityHidden={visibilityHidden}
          description={description}
        />
      </WrapLink>
      <EntityPopover entity={entity} popover={popover} />
    </>
  );
};
