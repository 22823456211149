import { useCallback } from 'react';
import { noop } from 'utils/fp';
import { cx } from '@linaria/core';
import { PopoverAction } from 'components/Popover';
import { IconDotsMenu } from 'components/Icons';
import { Visibility } from 'components/Visibility';
import { getEntityId, getAppDefaultWorkflow, getEntityVisibility, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { ModelTypeIcon } from 'components/ModelTypeIcon/ModelTypeIcon';
import { TemplateBadge } from 'components/Badge/TemplateBadge';
import { flex } from 'styles/utils';
import { EntityCardGradient } from './EntityCardGradient';
import { EntityCardFooter, WorkflowOnlyTag } from './Footer';
import { EntityCardModelTypeIcon } from './EntityCardModelTypeIcon';
import { useShortDescription } from './utils';
import { moreActionsWrapper, gridIconFallback, actionWrapper, titleStyles, iconGridContainer, wrapperVertical, detailsContainer2, modelTypeClass, modelTypeItemCrontainerClass, modelTypeItemIconClass, defaultWorkflowIdClass, blockDiv, flex5wrap, flexSpaceBetween, flex10gap } from './EntityCardGrid.styled';
import { FooterBreadcrumbs } from './FooterBreadcrumbs';
import { EntityCardLayoutProps } from './types';
import { TagList } from './TagList';
export const EntityCardGrid: React.FC<EntityCardLayoutProps> = ({
  title,
  description,
  footerItems,
  cardThumbnail,
  share,
  star,
  lock,
  tags,
  owner,
  entity,
  popover,
  visibilityHidden,
  onCardClick
}) => {
  const visibility = getEntityVisibility(entity);
  const preventPropogation = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const entityId = getEntityId(entity);
  const defaultAppWorkflowId = getAppDefaultWorkflow(entity);
  const shortDescription = useShortDescription(description, 13);
  const userId = getUserId(entity);
  const isAppTemplate = (entity as CF.API.Apps.App).is_template;
  const workflowNodes = 'nodes' in entity && entity.nodes ? entity.nodes.slice(0, 6) : [];
  return <>
      <div className={wrapperVertical} onClick={onCardClick} role="button" onKeyDown={noop} tabIndex={0} title={title}>
        <div className={iconGridContainer}>
          {isAppTemplate && <TemplateBadge />}
          {cardThumbnail ? <div className="thumbnail">
              <img src={cardThumbnail} alt={title} />
            </div> : <EntityCardGradient className={gridIconFallback} gradientText={'id' in entity ? entity.id : 'default_id'}>
              {defaultAppWorkflowId !== '' && <div className={defaultWorkflowIdClass}>
                  <span style={{
              fontSize: 8
            }}>default workflow</span>
                  {defaultAppWorkflowId}
                </div>}
              {'model_type_id' in entity && <div className="iconBack">
                  <EntityCardModelTypeIcon modelTypeId={entity.model_type_id} size="70%" />
                </div>}
              {workflowNodes.length > 0 && <div className={cx('hideOnHover', modelTypeItemCrontainerClass)}>
                  {workflowNodes.map(node => <div className={modelTypeItemIconClass}>
                      <EntityCardModelTypeIcon modelTypeId={node.model.model_type_id} size="70%" />
                    </div>)}
                </div>}
            </EntityCardGradient>}
          {workflowNodes.length > 0 && <div className="showOnHover">
              {workflowNodes.map(node => <div className={blockDiv}>
                  <div className={modelTypeClass}>
                    <ModelTypeIcon modelTypeId={node.model.model_type_id} />
                  </div>
                </div>)}
            </div>}
        </div>
        <div className="flex-left-container">
          <div>
            <div className={cx('modelcard-header', 'elli')}>
              <span>{entityId}</span>
            </div>
          </div>
          <div>
            {star}
            {lock}
          </div>
        </div>
        <div className={detailsContainer2}>
          <div className="textsContainer">
            <div>
              {'model_version' in entity && <div style={{
              display: 'inline-block'
            }}>
                  <ModelTypeIcon modelTypeId={entity.model_type_id} />
                </div>}
              <div className={flex5wrap}>
                {workflowNodes.map(node => <div className={modelTypeClass}>
                    <ModelTypeIcon modelTypeId={node.model.model_type_id} />
                  </div>)}
              </div>
            </div>
            <div className={titleStyles}>{!visibilityHidden && <Visibility visibility={visibility} text={visibility} />}</div>
            <div className="modelcard-description" style={{
            marginBottom: shortDescription.trim() ? '20px' : '5px'
          }}>
              {shortDescription}
            </div>
            <TagList tags={tags} />
          </div>
          <div className={flexSpaceBetween}>
            <div className={flex10gap}>{'model_version' in entity && entity.workflow_recommended && <WorkflowOnlyTag />}</div>
          </div>
          <div className={cx('flex-left-container', flex.gap.half)}>
            <div className="modelcard-footer">
              {userId && <EntityCardFooter items={footerItems.concat({
              uniqueKey: 'FooterBreadcrumb',
              item: <FooterBreadcrumbs entity={entity} />
            }).reverse()} workflowOnly={'model_version' in entity && entity.workflow_recommended} />}
            </div>
            <div className={actionWrapper}>
              {share}
              <div className={moreActionsWrapper} tabIndex={0} onClick={preventPropogation} onKeyDown={noop} role="button">
                {owner && <PopoverAction popover={popover}>
                    <IconDotsMenu size={18} color="#808080" />
                  </PopoverAction>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
};