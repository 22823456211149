import { useCallback } from 'react';
import { either, taskEither } from 'fp-ts';
import { apiGetDuplicateApp, duplicateApp, listAppsTE } from 'api/apps';
import { useCurrentUserId } from 'modules/Auth/hooks';
import type { SelectOption } from 'components/Select';
import { errorToReactLeft, pipe } from 'utils/fp';
import { useQueryTE } from 'utils/react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { queryKeys } from 'utils/react-query/queryKeys';
import { useAuthState } from 'modules/Auth/AuthContext';
import { makeUserFullName } from 'utils/users';
import { useQuery } from 'react-query';
import { appStorage } from 'utils/appStorage';
import { StatusCode } from 'api/grpc/resources';
import cogoToast from 'cogo-toast';
import { DUPLICATE_APP_ID } from '../CreateApplication/DuplicateApplicationForm';

export function useDuplicateApplicationTE({ userId, appId }: { userId: string; appId: string }): (values: {
  selectedUser: SelectOption;
  selectedDestination?: SelectOption;
  existing_app_id?: string;
  new_app_id?: string;
  new_app_name?: string;
  destination_app?: {
    user_id?: string;
    description?: string;
    id?: string;
  };
  filters?: {
    [key: string]: boolean;
  };
}) => taskEither.TaskEither<ReactLeft, CF.API.Apps.duplicateMockApp> {
  return useCallback(
    (values) => {
      const constructDestinationApp: {
        user_id?: string;
        description?: string;
        id?: string;
      } = {
        user_id: values.selectedUser.value,
        id: values.selectedDestination?.value === 'new_app' ? values.new_app_id : values.selectedDestination?.value,
      };

      return pipe(
        duplicateApp(
          {
            userId,
            appId,
            body: {
              app_duplications: [
                {
                  destination_app: values.destination_app || constructDestinationApp,
                  filter: values.filters,
                },
              ],
            },
          },
          errorToReactLeft,
        ),
      );
    },
    [], // Add an empty dependency list here
  );
}

export function useAppOptions(userId: string, excludeCurrentAppId: string) {
  const { data } = useQueryTE([queryKeys.Apps, { userOrOrgId: userId }], listAppsTE({ userId }, errorToReactLeft), {
    enabled: Boolean(userId),
  });

  const transformForList = (app: CF.API.Apps.App): SelectOption => ({ value: app.id, name: app.id });

  return pipe(
    data,
    /* istanbul ignore next */
    either.map(({ apps }) => apps.map(transformForList)),
    either.map((apps) => apps.filter((app) => app.value !== excludeCurrentAppId)),
  );
}

export function useUserAndOrgsOptions(): [SelectOption, SelectOption[]] {
  const { authData } = useAuthState();
  const currentUserId = useCurrentUserId();

  const loggedInUser = { value: authData?.user_id || '', name: authData ? `${makeUserFullName(authData)} (you)` : '' };
  const orgsList =
    authData?.organizations?.map(({ organization }) => ({
      value: organization.id,
      name: organization.name,
    })) || [];

  const userAndOrgsList = (loggedInUser ? [loggedInUser].concat(orgsList) : []) as SelectOption[];
  const defaultSelectedUserOption = userAndOrgsList.find((option) => option.value === currentUserId) as SelectOption;

  return [defaultSelectedUserOption, userAndOrgsList];
}

export const useGetDuplicateAppStatus = ({ userId, appId, onCompletion }: { userId: string; appId: string; onCompletion: () => void }) => {
  const duplicateAppId = appStorage.get(`${DUPLICATE_APP_ID}-${appId}`) as string;
  return useQuery([queryKeys.GetDuplicateApp, { userId }], () => apiGetDuplicateApp({ userId, duplicateAppId }), {
    enabled: Boolean(duplicateAppId),
    refetchInterval: (tasks) => {
      if (tasks?.app_duplication?.status?.code === StatusCode.APP_DUPLICATION_SUCCESS) {
        return false;
      }
      return 5000;
    },
    onSuccess: (data) => {
      if (data?.app_duplication?.status?.code === StatusCode.APP_DUPLICATION_SUCCESS) {
        appStorage.rm(`${DUPLICATE_APP_ID}-${appId}`);
        cogoToast.success(`Copying ${data.app_duplication.new_app_name} has been completed successfully.`);
        onCompletion?.();
      }
    },
  });
};
