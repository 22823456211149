import { Chip, ThemeProvider } from '@mui/material';
import { TextTooltip } from 'components/Tooltip';
import { IconCloseRound } from 'components/Icons';
import { baseTheme } from 'styles/utils';
import { useTheme } from '@mui/material/styles';
import { MobileFilterContentProps } from '../type';
import { chip, chipContainer } from './MobileFilterAndSortLayout.styles';
import { tooltipCls } from '../Filters/SearchFilter.styles';

export const FilterChips = ({
  selectedFilters,
  setAllFilters,
}: Pick<MobileFilterContentProps<Record<never, never>>, 'selectedFilters' | 'setAllFilters'>): JSX.Element | null => {
  const theme = useTheme();
  if (selectedFilters.length === 0) return null;

  return (
    <ThemeProvider theme={theme}>
      <div className={chipContainer}>
        <span className="filterLabels">Selected Filters</span>
        <div className="chip-value-container">
          {selectedFilters.map(({ id, value, headerName }) => {
            return (
              <Chip
                key={id}
                label={
                  <>
                    {headerName}:&nbsp;
                    <TextTooltip arrowProps={{ className: 'darkArrow' }} showArrow text={value} className={tooltipCls}>
                      {value}
                    </TextTooltip>
                  </>
                }
                onDelete={() => setAllFilters(selectedFilters.filter((selectedFilter) => selectedFilter.id !== id))}
                className={chip}
                deleteIcon={<IconCloseRound size={16} color={baseTheme.light.colors.gray700} />}
              />
            );
          })}
        </div>
      </div>
    </ThemeProvider>
  );
};
