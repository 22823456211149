import { cx } from '@linaria/core';
import type { Row } from 'react-table';

export const TableCells = ({ row, style }: { row: Row; style?: Record<never, string> }) => {
  return (
    <div className={cx('tr')} {...row.getRowProps({ style })}>
      {row.cells.map((cell) => {
        return (
          <div {...cell.getCellProps()} data-testid="test">
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};
