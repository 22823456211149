import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualDetectorEmbedderIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.36435 9.61179L1.75 14.4309L5.36435 19.25" stroke={color} strokeWidth="1.60637" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.3572 19.25H9.86064C9.42477 19.25 9.07141 18.8967 9.07141 18.4607V16.0373" stroke={color} strokeWidth="0.899083" />
    <path d="M9.07141 12.8246V10.401C9.07141 9.96518 9.42477 9.61182 9.86064 9.61182H12.3572" stroke={color} strokeWidth="0.899083" />
    <path d="M15.6429 19.25H18.1394C18.5754 19.25 18.9287 18.8967 18.9287 18.4607V16.0373" stroke={color} strokeWidth="0.899083" />
    <path d="M15.6429 9.61182H18.1394C18.5754 9.61182 18.9287 9.96518 18.9287 10.401V12.8246" stroke={color} strokeWidth="0.899083" />
    <path
      d="M14.0244 12.0213C15.3552 12.0213 16.434 13.1001 16.434 14.4309C16.434 15.7617 15.3552 16.8404 14.0244 16.8404C12.6936 16.8404 11.6148 15.7617 11.6148 14.4309C11.6148 13.1001 12.6936 12.0213 14.0244 12.0213Z"
      fill={color}
    />
    <path d="M22.6357 9.61179L26.25 14.4309L22.6357 19.25" stroke={color} strokeWidth="1.60637" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
