import { cx } from '@linaria/core';
import { Button } from 'components/Button';
import { IconCloseSquare } from 'components/Icons';
import { DeleteAlert } from 'components/Alert';
import { titles } from 'styles/typography';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { dialogWrapper } from './Modals.styles';
type Props = {
  title: string;
  message: string | JSX.Element;
  onClose: () => void;
  onDelete: () => void;
  hideAlert?: boolean;
  btnText?: string;
};
export const DeleteModal = ({
  title,
  message,
  onClose,
  onDelete,
  hideAlert,
  btnText = 'Delete'
}: Props): JSX.Element => {
  return <div className={dialogWrapper}>
      <div className={cx(flex.init, flex.justifyContent.between, flex.alignItems.center)}>
        <h4 className={titles}>{title}</h4>
        <IconCloseSquare size={20} onClick={onClose} />
      </div>

      <p className={cssHelpers.margin.y[1]}>{message}</p>
      {!hideAlert && <DeleteAlert />}

      <div className={cx('buttons', flex.init, flex.alignItems.center, flex.justifyContent.end)}>
        <Button size="md" variant="tertiary" onClick={onClose} className={cssHelpers.margin.x[1]}>
          Cancel
        </Button>
        <Button size="md" variant="dangerSecondary" onClick={() => {
        onDelete();
        onClose();
      }}>
          {btnText}
        </Button>
      </div>
    </div>;
};