import React from 'react';
import { destroy, IAnyStateTreeNode } from 'mobx-state-tree';

export function useAutoDestroyMst(instance: IAnyStateTreeNode): void {
  React.useEffect(
    () => () => {
      /* istanbul ignore next: just a null check */
      if (instance) destroy(instance);
    },
    [instance],
  );
}
