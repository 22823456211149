import React, { useCallback, useMemo } from 'react';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { observer } from 'mobx-react-lite';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { Popover, PopoverGroup, PopoverAction, SegmentTitle, usePopoverState } from 'components/Popover';
import { IconSortDown } from 'components/Icons/IconSortDown';
import { sorterWrapper } from './styles';
import { SorterItems } from './SorterItems';

const sortOrderOptions = [
  { name: 'Ascending', value: true },
  { name: 'Descending', value: false },
];

type Option<T> = {
  name?: string;
  value: T;
};

function Sorter_(): JSX.Element | null {
  const store = useCurrentStore();
  const popover = usePopoverState({ placement: 'bottom-end' });

  const sortByHandler = useCallback(
    (x: Option<string>) => {
      store.setSortBy(x.value);
      popover.hide();
    },
    [store.setSortBy],
  );

  const sortByLabel = useMemo(() => store.sortCriteria.find(({ value }) => value === store.sortBy.value)?.name, [store.sortBy.value]);

  const ascHandler = useCallback(
    (x: Option<boolean>) => {
      store.setAscending(x.value);
      popover.hide();
    },
    [store.setAscending],
  );

  if (!store.sortCriteria?.length) {
    return null;
  }

  return (
    <div className={cx(sorterWrapper, flex.init, flex.alignItems.center)}>
      <PopoverAction
        popover={popover}
        style={{
          flex: 1,
          display: 'flex',
        }}
      >
        <div className={cx('title', 'container')}>
          <span className="sortByLabel">
            <span style={{ fontWeight: 'bold', color: '#344054' }}>Sort by: </span> {sortByLabel}
          </span>
          <IconSortDown size={8} color="#808080" />
        </div>
      </PopoverAction>
      <Popover popover={popover} aria-label="Sort By">
        <PopoverGroup style={{ borderTop: 0, paddingTop: 0 }}>
          <SegmentTitle>SORT BY</SegmentTitle>
          <SorterItems
            items={store.sortCriteria}
            selectedValue={store.sortBy.value}
            // https://clarifai.atlassian.net/browse/MRK-1140
            // assigning to Prasanna due to lack of typing using generics in SorterItems
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={sortByHandler as any}
          />
        </PopoverGroup>
        <PopoverGroup>
          <SegmentTitle>Order</SegmentTitle>
          <SorterItems
            items={sortOrderOptions}
            selectedValue={store.sortBy.asc}
            // https://clarifai.atlassian.net/browse/MRK-1140
            // assigning to Prasanna due to lack of typing using generics in SorterItems
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={ascHandler as any}
          />
        </PopoverGroup>
      </Popover>
    </div>
  );
}

Sorter_.defaultProps = {
  style: undefined,
};

export const Sorter = observer(Sorter_);
