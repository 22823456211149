import { css } from '@linaria/core';
import { baseTheme, theming, breakpoints } from 'styles/utils';
export const contentWrapper = "odules__Models__components__ModelViewer__Header__Header_styles_ts-contentWrapper-c1v05wyv";
export const headerWrapper = "odules__Models__components__ModelViewer__Header__Header_styles_ts-headerWrapper-hdxmr0";
export const intersectionFillerDiv = "odules__Models__components__ModelViewer__Header__Header_styles_ts-intersectionFillerDiv-ioyzqsv";
export const intersectionDetecterDiv = "odules__Models__components__ModelViewer__Header__Header_styles_ts-intersectionDetecterDiv-i1cj5o13";
export const starsContainer = "odules__Models__components__ModelViewer__Header__Header_styles_ts-starsContainer-s1y2q1ls";
export const starIconWrapper = "odules__Models__components__ModelViewer__Header__Header_styles_ts-starIconWrapper-slfans9";
export const starCounter = "odules__Models__components__ModelViewer__Header__Header_styles_ts-starCounter-s10ujw0g";
export const actionsWrapper = "odules__Models__components__ModelViewer__Header__Header_styles_ts-actionsWrapper-arw6j2f";
export const actionsIcon = "odules__Models__components__ModelViewer__Header__Header_styles_ts-actionsIcon-a9qsfc2";
export const breadCrumbs = "odules__Models__components__ModelViewer__Header__Header_styles_ts-breadCrumbs-bacqd0";
export const bottomBorderOverride = "odules__Models__components__ModelViewer__Header__Header_styles_ts-bottomBorderOverride-bt1y0o0";
export const tooltipCls = "odules__Models__components__ModelViewer__Header__Header_styles_ts-tooltipCls-tk0dw3y";

require("./Header.styles.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Header.styles.ts");