import { editableDetailsFormButton } from './styles';

export const withDetailEditButton = ({
  onClick,
  isEditable,
  content,
  isEditing,
  isDisabled,
  testId,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isEditable: boolean;
  isEditing: boolean;
  content: React.ReactNode;
  isDisabled?: boolean;
  testId?: string;
}): JSX.Element => {
  return (
    <div>
      {content}
      {isEditable && (
        <button disabled={isDisabled} type="button" onClick={onClick} className={editableDetailsFormButton} data-testid={testId}>
          {isEditing ? 'Save' : 'Edit'}
        </button>
      )}
    </div>
  );
};
