import { cx } from '@linaria/core';
import { actionsWrapper } from './Cells.styles';

export interface CellActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  actionList: React.ReactNode[];
}

export const Actions = ({ actionList, ...rest }: CellActionsProps): JSX.Element => (
  <div className={cx(actionsWrapper, rest.className)} {...rest}>
    {actionList.map((action) => action)}
  </div>
);
