import { TextTooltip } from 'components/Tooltip';
import { useMeasure } from 'react-use';
import { cx } from '@linaria/core';
import { errorMsg, truncatedErrorFieldWrapper, truncatedErrorTooltipStyles } from './Forms.styles';

type FormFieldErrorProps = {
  className?: string;
  error?: string;
  truncateError?: boolean;
};

/**
 * Default form field error, where the entire error message will be shown always.
 */
export function UntrucatedFieldError({ className, error }: { className?: string; error: string }): JSX.Element {
  return <p className={cx(errorMsg, className)}>{error}</p>;
}

/**
 * Shows a tooltip when hovered (in desktop) / clicked (in mobile) on a form field's error.
 * Useful for showing long error message on a tooltip and only a portion of message under
 * the form field : https://clarifai.atlassian.net/browse/MRK-2733
 */
export function TruncatedFieldError({ className, error }: { className?: string; error: string }): JSX.Element {
  const [wrapperRef, { width: maxWidth }] = useMeasure<HTMLDivElement>();

  return (
    <div ref={wrapperRef} className={truncatedErrorFieldWrapper}>
      <TextTooltip
        className={className}
        showArrow
        text={error}
        initialState={{ gutter: 4 }}
        contentProps={{ className: truncatedErrorTooltipStyles }}
        style={{ wordBreak: 'break-word', maxWidth }}
      >
        {error}
      </TextTooltip>
    </div>
  );
}

export function FormFieldError({ className, error, truncateError = false }: FormFieldErrorProps): JSX.Element | null {
  if (!error) return null;

  return truncateError ? <TruncatedFieldError className={className} error={error} /> : <UntrucatedFieldError className={className} error={error} />;
}
