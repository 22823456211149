import { fetchAppLevelScopesTE } from 'api/users/appLevelScopes';
import { either } from 'fp-ts';
import { useRouter } from 'next/router';
import { allPass } from 'rambda';
import { pipe, errorToReactLeft } from 'utils/fp';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { useIsLoggedIn } from '../hooks';

// TODO: make useQueryTE return generic output type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAppLevelScopes(userAndAppIds?: { appId: string; userOrOrgId: string }) {
  const isLoggedIn = useIsLoggedIn();
  const { appId, userOrOrgId } = userAndAppIds || (useRouter().query as { appId: string; userOrOrgId: string });

  return useQueryTE(
    [
      queryKeys.AppLevelScopes,
      {
        appId,
        userOrOrgId,
        isLoggedIn,
      },
    ],
    fetchAppLevelScopesTE(
      {
        appId,
        userId: userOrOrgId,
      },
      errorToReactLeft,
    ),
    { enabled: Boolean(isLoggedIn && appId && userOrOrgId) },
  );
}

export function useHasScopes(
  scopeMatchers: ((res: CF.API.Users.FetchScopesResponse) => boolean)[],
  userAndAppIds?: { appId: string; userOrOrgId: string },
): boolean {
  const { data: userScopesE } = useAppLevelScopes(userAndAppIds);
  if (!scopeMatchers.length) {
    return false;
  }

  const testAllScopes = allPass(scopeMatchers);

  const hasAccess = pipe(
    userScopesE,
    either.map(testAllScopes),
    either.getOrElse(() => {
      return false;
    }),
  );

  return hasAccess;
}
