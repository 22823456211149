import { cx } from '@linaria/core';
import { Button } from 'components/Button';
import { hideOnMobile } from 'modules/Apps/components/CreateApplication/CreateApplicationModal.styles';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { FormValues, ONBOARDING_STEP_ID } from '../utils';

type FooterProps = {
  step: string;
  handleContinue: () => void;
  handleSkip: () => void;
  handleCreateOrg: () => void;
  isSubmitting?: boolean;
  userIdError?: string;
};

export const Footer: React.FC<FooterProps> = ({ isSubmitting, userIdError, step, handleCreateOrg, handleContinue, handleSkip }) => {
  const { handleSubmit, validateForm, isValid, isValidating, errors } = useFormikContext<FormValues>();

  const isFormValid = useMemo(() => isValid && !userIdError && !Object.keys(errors).length, [isValid, errors, userIdError]);

  const validateFormAndContinue = useCallback(async () => {
    await validateForm();
    handleContinue();
  }, [validateForm, handleContinue]);

  return (
    <div className="footer">
      <span className={cx(hideOnMobile, 'step-count')} />
      {step === ONBOARDING_STEP_ID.Profile && (
        <Button size="md" variant="primary" onClick={validateFormAndContinue} disabled={!isFormValid} loading={isValidating}>
          Continue
        </Button>
      )}
      {step === ONBOARDING_STEP_ID.Goal && (
        <Button size="md" variant="primary" type="submit" onClick={handleSubmit} disabled={!isFormValid || isSubmitting} loading={isSubmitting}>
          Create profile
        </Button>
      )}
      {step === ONBOARDING_STEP_ID.MaybeCreateOrg && (
        <div className="button-group">
          <Button size="md" variant="tertiary" onClick={handleSkip}>
            Skip
          </Button>
          <Button size="md" variant="tertiary" onClick={handleCreateOrg}>
            Yes, create an organization
          </Button>
        </div>
      )}
    </div>
  );
};
