/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconBack, IconColumnSortAsc, IconColumnSortDesc, IconLines, IconSearch } from 'components/Icons';
import { cx } from '@linaria/core';
import { baseTheme } from 'styles/utils';
import { noop } from 'utils/fp';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select } from 'components/Select';
import { SortingState } from '@tanstack/react-table';
import { blueText, fiterContentContainer, selectClass, slideInUp, header, divider } from './MobileFilterAndSortLayout.styles';
import { MobileViewSort } from '../SortIndicators/MobileViewSort';
import { getFiltersWithHeaderName } from '../utils';
import { FilterChips } from './FilterChips';
import { MobileFilterContentProps, MobileLayoutProps } from '../type';
import { TableFilters } from '../Filters';

const MobileFilterContent = ({
  onClose,
  column,
  columnsForFilter,
  setSortBy,
  sortBy,
  setSelectedColumnId,
  selectedFilters,
  setAllFilters,
  clearAll,
  isSortAndFilterApplied,
  applyFilter,
}: MobileFilterContentProps<Record<never, never>>): JSX.Element => {
  const Header = () => (
    <div className="filterContentHeader">
      <span className="leftHeader">
        <IconBack size={14} color={baseTheme.light.colors.gray700} onClick={onClose} />
        Filters & Sort
      </span>

      {isSortAndFilterApplied && (
        <>
          <button type="button" className={blueText} onClick={clearAll}>
            Clean
          </button>
          <span className={divider}>|</span>
        </>
      )}
      <button type="button" className={blueText} onClick={applyFilter}>
        Apply
      </button>
    </div>
  );

  return (
    <div className={cx(fiterContentContainer, slideInUp)}>
      <Header />

      <div className="content">
        <Select
          className="select"
          selectElementClassName={cx(selectClass)}
          title={(column.columnDef as any)?.headerName ?? (column.columnDef.header as string) ?? 'Select Column'}
          placeholder="All"
          onChange={(selectedColumn) => {
            setSelectedColumnId(selectedColumn?.value as string);
          }}
          options={columnsForFilter}
        />
        {column.getCanFilter() && <TableFilters column={column} isMobileView />}
        {column.getCanSort() && (
          <>
            <MobileViewSort
              setSortBy={(nativeSortBy) => {
                setSortBy(nativeSortBy);
              }}
              columnId={column.id as string}
              sortMetaData={column.columnDef.meta?.sortMetaData}
              isSortedDesc={sortBy[0]?.desc}
              isSorted={sortBy.length > 0 && sortBy[0]?.id === column.id}
            />
          </>
        )}
        <FilterChips selectedFilters={selectedFilters} setAllFilters={setAllFilters} />
      </div>
    </div>
  );
};

export const MobileFilterLayout = <D extends unknown>({ columns, state, setSortBy, setAllFilters }: MobileLayoutProps<D>): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const columnsHeaderMapping = useRef({});

  const isSortingEnabled = state?.sorting.length > 0;
  const isFilterEnabled = state?.columnFilters;

  const columnsForFilter = useMemo(
    () =>
      columns
        .filter((column) => column.id !== 'actions' && (column.getCanFilter() || column.getCanSort()))
        .map((column) => {
          const headerName = (column.columnDef as any)?.headerName ?? (column.columnDef.header as string);
          columnsHeaderMapping.current = { ...columnsHeaderMapping.current, [column.id]: headerName };
          return {
            value: column.id as string,
            name: headerName,
            filterValue: column.getFilterValue?.(),
          };
        }),
    [columns, state],
  );

  const [externalFilters, setExternalFilters] = useState<{ id: string; value: string; headerName: string }[]>([]);

  const [externalSortBy, setExternalSortBy] = useState<SortingState>([]);

  const [selctedColumnId, setSelectedColumnId] = useState<string>(columnsForFilter?.[0]?.value as string);

  const onFilterChange = (value: string): void => {
    setExternalFilters((prev) => {
      const index = prev.findIndex((filter) => filter.id === selctedColumnId);
      const updatedFilters = prev.filter((filter) => filter.id !== selctedColumnId);
      const newFilter = index !== -1 ? { ...prev[index], value } : { id: selctedColumnId, value };
      return getFiltersWithHeaderName([...updatedFilters, newFilter], columnsHeaderMapping.current);
    });
  };

  const columnFilterValue = externalFilters.find((filter) => filter.id === selctedColumnId)?.value;

  const selectedColumn = {
    ...columns.find((column) => column.id === selctedColumnId),
    getFilterValue: () => columnFilterValue,
    setFilterValue: onFilterChange,
    setSortBy: setExternalSortBy,
    isSortedDesc: externalSortBy?.[0]?.desc,
  } as any;
  const Icon = selectedColumn?.isSortedDesc ? IconColumnSortDesc : IconColumnSortAsc;
  const isSortAndFilterApplied = externalSortBy.length > 0 || externalFilters?.length > 0;

  const onClose = (): void => {
    setShowModal((prev) => !prev);
  };

  const openFilterModal = (): void => {
    setShowModal(true);
  };

  const clearAll = useCallback(() => {
    setSortBy?.([]);
    setAllFilters?.([]);
    setSearchText('');
    setExternalFilters([]);
    setExternalSortBy([]);
    selectedColumn.columnDef.meta?.onFilterChange?.('');
  }, []);

  const applyFilter = useCallback((): void => {
    setAllFilters?.(externalFilters);
    setSortBy?.(externalSortBy);
    onClose();
  }, [externalFilters, externalSortBy]);

  useEffect(() => {
    setExternalFilters(getFiltersWithHeaderName(state.columnFilters ?? [], columnsHeaderMapping.current));
    setExternalSortBy(state.sorting ?? []);
  }, [state.sorting, state.columnFilters]);

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    <>
      <div className={header}>
        {(state?.sorting?.length > 0 || state?.columnFilters?.length > 0) && (
          <button className={cx(blueText, 'clearAll')} type="button" onClick={clearAll}>
            Clear All
          </button>
        )}
        <div className="action-element" tabIndex={0} role="button" onKeyDown={noop} onClick={openFilterModal}>
          {isSortingEnabled && (
            <div className="sort-indicator-container">
              <span>{columnsHeaderMapping.current[state.sorting?.[0]?.id as keyof typeof columnsHeaderMapping.current]}</span>
              <Icon id="sort-indicator" size={12} color={baseTheme.light.colors.gray400} />
            </div>
          )}
          {isFilterEnabled && <IconSearch size={16} color={baseTheme.light.colors.gray400} />}
          {state.sorting && <IconLines size={16} color={baseTheme.light.colors.gray400} />}
        </div>
      </div>
      {showModal && (
        <MobileFilterContent
          column={selectedColumn}
          columnsForFilter={columnsForFilter as Array<{ value: string; name: string; filterValue: string }>}
          setSortBy={setExternalSortBy}
          sortBy={externalSortBy}
          onClose={onClose}
          searchText={searchText}
          setSearchText={setSearchText}
          setSelectedColumnId={setSelectedColumnId}
          selectedFilters={externalFilters ?? []}
          setAllFilters={setExternalFilters as (filters: Array<{ id: string; value: string }>) => void}
          clearAll={clearAll}
          isSortAndFilterApplied={isSortAndFilterApplied}
          applyFilter={applyFilter}
        />
      )}
    </>
  );
};
