// Here we hard code some IMVs that we want every application to have.
// This will eventually be moved back to the backend as application templates that inject

import getConfig from 'next/config';

const EVAL_MODULE_IMV_ID = 'model_eval_install';

// IMVs into an app based on the use case.
export const hardCodedIMVs = (userId: string, appId: string): CF.API.Modules.ListInstalledModuleVersionsResponse => {
  const { publicRuntimeConfig } = getConfig();

  return {
    status: {
      code: 10000,
      description: 'Ok',
      req_id: 'com-prox-pri-9b0fa71c-c7ade075369a455488b1297a0fba4523',
    },
    installed_module_versions: [
      {
        id: 'module_manager_install',
        module_version: {
          id: 'v1.0',
          module_id: 'module_manager',
          app_id: 'clarifai',
          user_id: 'modules',
          description: 'This is a helper module for you to manage creating and viewing your other modules.',
          module_nav: {
            title: 'Module Manager',
            module_sub_navs: [
              {
                title: 'Manage Installs',
                query_key: 'page',
                query_value: 'manage_imvs',
              },
              {
                title: 'Manage Modules (OLD)',
                query_key: 'page',
                query_value: 'manage',
              },
            ],
          },
        },
        app_id: `"${appId}"`,
        user_id: `"${userId}"`,
        deploy_url: publicRuntimeConfig.NEXT_PUBLIC_MODULE_MANAGER_HOST,
      },
      {
        id: 'auto_annotation_install',
        module_version: {
          id: 'v1.0',
          module_id: 'auto_annotation',
          app_id: 'clarifai',
          user_id: 'modules',
          description: 'This is a helper module for you to manage create auto annotation workflows.',
          module_nav: {
            title: 'Auto Annotation',
            module_sub_navs: [
              {
                title: 'Create',
                query_key: 'page',
                query_value: '',
              },
            ],
          },
        },
        app_id: `"${appId}"`,
        user_id: `"${userId}"`,
        deploy_url: publicRuntimeConfig.NEXT_PUBLIC_AUTO_ANNOTATION_HOST,
      },
      {
        id: 'model_import_install',
        module_version: {
          id: 'v1.0',
          module_id: 'model_import',
          app_id: 'clarifai',
          user_id: 'modules',
          description: 'This is a helper module for you to easily import models to the platform.',
          module_nav: {
            title: 'Model Import',
            module_sub_navs: [
              {
                title: 'Import',
                query_key: 'page',
                query_value: '',
              },
            ],
          },
        },
        app_id: `"${appId}"`,
        user_id: `"${userId}"`,
        deploy_url: publicRuntimeConfig.NEXT_PUBLIC_MODEL_IMPORT_HOST,
      },
      {
        id: EVAL_MODULE_IMV_ID,
        module_version: {
          id: 'v1.0',
          module_id: 'model_eval',
          app_id: 'clarifai',
          user_id: 'modules',
          description: 'This is a helper module for you to easily evaluate models.',
          module_nav: {
            title: 'Model Eval',
            module_sub_navs: [
              {
                title: 'Metrics',
                query_key: 'page',
                query_value: 'metrics',
              },
            ],
          },
        },
        app_id: `"${appId}"`,
        user_id: `"${userId}"`,
        deploy_url: publicRuntimeConfig.NEXT_PUBLIC_MODEL_EVAL_HOST,
      },
    ],
  };
};

// Before the modules return the required scopes we just hardcoded a bunch of them here so that
// we can fake it in the UI ScopeList. This is completely ignored by the API endpoint when
// creating the PAT for the IMV, but that endpoint will be updated to automatically respect the
// scopes on the backend. The user can either way edit the PAT after creation to change the scopes
// as they want (though that may break the usage of the module they are accessing).
export const imvDefaultScopeDeps = [
  { scope: 'All' },
  { scope: 'Predict' },
  { scope: 'Inputs:Add', depending_scopes: ['Inputs:Get'] },
  { scope: 'Inputs:Get' },
  { scope: 'Inputs:Delete', depending_scopes: ['Inputs:Add', 'Inputs:Get'] },
  { scope: 'Concepts:Add', depending_scopes: ['Concepts:Get'] },
  { scope: 'Concepts:Get' },
  { scope: 'Concepts:Delete', depending_scopes: ['Concepts:Add', 'Concepts:Get'] },
  { scope: 'Models:Add', depending_scopes: ['Models:Get'] },
  { scope: 'Models:Get' },
  { scope: 'Models:Delete', depending_scopes: ['Models:Add', 'Models:Get'] },
  { scope: 'Models:Train', depending_scopes: ['Models:Get'] },
  { scope: 'Workflows:Add', depending_scopes: ['Workflows:Get'] },
  { scope: 'Workflows:Get' },
  { scope: 'Workflows:Delete', depending_scopes: ['Workflows:Add', 'Workflows:Get'] },
  { scope: 'WorkflowMetrics:Get' },
  { scope: 'WorkflowMetrics:Add', depending_scopes: ['WorkflowMetrics:Get'] },
  { scope: 'WorkflowMetrics:Delete', depending_scopes: ['WorkflowMetrics:Add', 'WorkflowMetrics:Get'] },
  { scope: 'Annotations:Add', depending_scopes: ['Annotations:Get'] },
  { scope: 'Annotations:Get' },
  { scope: 'Annotations:Delete', depending_scopes: ['Annotations:Add', 'Annotations:Get'] },
  { scope: 'Collectors:Add', depending_scopes: ['Collectors:Get'] },
  { scope: 'Collectors:Get' },
  { scope: 'Collectors:Delete', depending_scopes: ['Collectors:Add', 'Collectors:Get'] },
  { scope: 'Collaborators:Add', depending_scopes: ['Collaborators:Get'] },
  { scope: 'Collaborators:Get' },
  { scope: 'Collaborators:Delete', depending_scopes: ['Collaborators:Add', 'Collaborators:Get'] },
  { scope: 'Metrics:Add', depending_scopes: ['Metrics:Get'] },
  { scope: 'Metrics:Get' },
  { scope: 'Metrics:Delete', depending_scopes: ['Metrics:Add', 'Metrics:Get'] },
  { scope: 'Tasks:Add', depending_scopes: ['Tasks:Get'] },
  { scope: 'Tasks:Get' },
  { scope: 'Tasks:Delete', depending_scopes: ['Tasks:Add', 'Tasks:Get'] },
  { scope: 'LabelOrders:Get' },
  { scope: 'LabelOrders:Add', depending_scopes: ['LabelOrders:Get'] },
  { scope: 'LabelOrders:Delete', depending_scopes: ['LabelOrders:Add', 'LabelOrders:Get'] },
  { scope: 'FindDuplicateAnnotationsJobs:Add', depending_scopes: ['FindDuplicateAnnotationsJobs:Get'] },
  { scope: 'FindDuplicateAnnotationsJobs:Get' },
  { scope: 'FindDuplicateAnnotationsJobs:Delete', depending_scopes: ['FindDuplicateAnnotationsJobs:Add', 'FindDuplicateAnnotationsJobs:Get'] },
  { scope: 'Datasets:Get' },
  { scope: 'Datasets:Add', depending_scopes: ['Datasets:Get'] },
  { scope: 'Datasets:Delete', depending_scopes: ['Datasets:Get', 'Datasets:Add'] },
  { scope: 'Modules:Add', depending_scopes: ['Modules:Get'] },
  { scope: 'Modules:Get' },
  { scope: 'Modules:Delete', depending_scopes: ['Modules:Add', 'Modules:Get'] },
  { scope: 'Search' },
  { scope: 'SavedSearch:Get' },
  { scope: 'SavedSearch:Add', depending_scopes: ['SavedSearch:Get'] },
  { scope: 'SavedSearch:Delete', depending_scopes: ['SavedSearch:Get', 'SavedSearch:Add'] },
  { scope: 'ModelVersionPublications:Add' },
  { scope: 'ModelVersionPublications:Delete' },
  { scope: 'WorkflowPublications:Add' },
  { scope: 'WorkflowPublications:Delete' },
  { scope: 'BulkOperation:Add', depending_scopes: ['BulkOperation:Get'] },
  { scope: 'BulkOperation:Get' },
  { scope: 'BulkOperation:Delete', depending_scopes: ['BulkOperation:Add', 'BulkOperation:Get'] },
  { scope: 'InputsAddJobs:Add', depending_scopes: ['InputsAddJobs:Get'] },
  { scope: 'InputsAddJobs:Get' },
  { scope: 'Uploads:Get' },
  { scope: 'Uploads:Add', depending_scopes: ['Uploads:Get'] },
  { scope: 'Uploads:Delete', depending_scopes: ['Uploads:Get', 'Uploads:Add'] },
];

export function findInstalledModuleVersion({
  userId,
  appId,
  installedModuleVersionId,
  data,
  isEvalModuleEnabled = true,
}: {
  userId: string;
  appId: string;
  installedModuleVersionId: string;
  data?: CF.API.Modules.ListInstalledModuleVersionsResponse;
  isEvalModuleEnabled?: boolean;
}): CF.API.Modules.InstalledModuleVersion | undefined {
  const imvList = appendHardcodedIMVs({ userId, appId, data, isEvalModuleEnabled });

  return imvList && imvList.find((m) => m.id === installedModuleVersionId);
}

export function isHardcodedIMV({
  userId,
  appId,
  installedModuleVersionId,
}: {
  userId: string;
  appId: string;
  installedModuleVersionId: string;
}): boolean {
  const hardcoded = hardCodedIMVs(userId, appId);
  return hardcoded.installed_module_versions.some((m) => m.id === installedModuleVersionId);
}

/*
  appendHardcodeModules is used to add the hard coded IMVs to the response of
  listAppInstalledModuleVersions.
  for each API return value if it's not already in the hard coded results then it returns it.

  Right now we know only module_manager_install is hard coded in the API so we filter that out
  before using the list.

  isEvalModuleEnabled controls whether eval module is included in the response with other hard coded modules or not.
  Ex: In the app sidebar, we don't want eval module in the response so it isn't displayed in the sidebar.
*/
export function appendHardcodedIMVs({
  userId,
  appId,
  data,
  isEvalModuleEnabled = true,
}: {
  userId: string;
  appId: string;
  data?: CF.API.Modules.ListInstalledModuleVersionsResponse;
  isEvalModuleEnabled?: boolean;
}): CF.API.Modules.InstalledModuleVersion[] {
  const hardcoded = hardCodedIMVs(userId, appId);
  const harcodedImvs = hardcoded.installed_module_versions;
  const filteredImvs = isEvalModuleEnabled ? harcodedImvs : harcodedImvs.filter((dm) => dm.id !== EVAL_MODULE_IMV_ID);
  if (data && data.installed_module_versions) {
    return [...data.installed_module_versions.filter((dm) => dm.id !== 'module_manager_install'), ...filteredImvs];
  }
  return filteredImvs;
}

// For demo purposes, the IMVs belonging to these user-ids can be used without logging in:
// https://clarifai.atlassian.net/browse/MRK-2983
export function isIMVForDemo(userId: string) {
  return (
    userId === 'facebook' || userId === 'anthropic' || userId === 'meta' || userId === 'cohere' || userId === 'ai21' || userId === 'clarifaipublic'
  );
}
