/* eslint-disable @typescript-eslint/no-explicit-any */
import { cx } from '@linaria/core';
import { TableRow, TableHead, TableCell } from '@mui/material';
import { Table, flexRender } from '@tanstack/react-table';
import { SortIndicator } from './SortIndicators/SortIndicator';
import { TableFilters } from './Filters';
import { getCellStyles } from './utils';
import { MobileFilterLayout } from './MobileView/MobileFilterAndSortLayout';

interface Props<D extends unknown> {
  table: Table<D>;
  isMobileView: boolean;
}

const MobileHeader = <D extends unknown>({ table }: Pick<Props<D>, 'table'>) => {
  return (
    <TableHead className="table-header">
      <div className="mob-header">
        <MobileFilterLayout<D>
          columns={table.getAllLeafColumns()}
          setSortBy={table.setSorting}
          setAllFilters={table.setColumnFilters}
          state={table.getState()}
        />
      </div>
    </TableHead>
  );
};

export const TableHeader = <D extends unknown>({ table, isMobileView }: Props<D>) => {
  if (isMobileView) {
    return <MobileHeader<D> table={table} />;
  }

  return (
    <TableHead className="table-header">
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className="table-header-row">
          {headerGroup.headers.map((header) => {
            const column = header?.column;
            return (
              <TableCell
                className={cx('table-cell', 'table-header-cell', column.columnDef.meta?.sticky && 'sticky')}
                style={getCellStyles<D>(column)}
                key={header.id}
                colSpan={header.colSpan}
                role="columnheader"
              >
                {header.isPlaceholder ? null : (
                  <div className="header-cell-container">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <div className="sort-filter-container">
                      {header.column.getCanFilter() ? (
                        <div>
                          <TableFilters column={column} />
                        </div>
                      ) : null}
                      {header.column.getCanSort() ? (
                        <SortIndicator
                          sortMetaData={column.columnDef.meta?.sortMetaData}
                          hasSorter={column.getCanSort()}
                          sortDirection={column.getIsSorted()}
                          columnId={column.id}
                          setSortBy={table.setSorting}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};
