/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchModelVersionOutputTE = <E = CF.API.ApiError>(
  { userId, appId, modelId, versionId }: { modelId: string; appId: string; userId: string; versionId?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Models.FetchModelResponse> =>
  fetchTE<CF.API.Models.FetchModelResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/models/${encodeURIComponent(modelId)}/versions/${versionId}`,
      method: 'GET',
    },
    onRejected,
  );
