import React, { useEffect, useCallback, useState } from 'react';
import { addJiraScript } from './helpers';

export interface JiraButtonProps {
  collectorId: string;
  clickTargetSelector: string;
  children: React.ReactElement;
}

export function JiraButton(props: JiraButtonProps): JSX.Element | null {
  const { children, collectorId, clickTargetSelector, ...restProps } = props;
  const scriptSrc = constructScriptSrc(collectorId);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    async function loader(): Promise<void> {
      const jiraButton = document.querySelector(clickTargetSelector);
      if (!jiraButton) {
        await addJiraScript(scriptSrc);
        setScriptLoaded(true);
        return;
      }
      setScriptLoaded(true);
    }
    loader();
  }, [scriptSrc]);

  const handleButtonClick = useCallback(() => {
    const jiraButton = document.querySelector<HTMLElement>(props.clickTargetSelector);
    if (jiraButton) {
      jiraButton.click();
    }
    if (typeof children.props.onClick === 'function') {
      children.props.onClick();
    }
  }, []);

  const newProps = {
    ...restProps,
    onClick: handleButtonClick,
  };
  return scriptLoaded ? React.cloneElement(children, newProps) : null;
}

const host = 'https://clarifai.atlassian.net';
const scriptName1 = 'com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/';
const scriptName2 = 'com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js';
const basepath = 's/d41d8cd98f00b204e9800998ecf8427e-T/-2rg805/b/26/a44af77267a987a660377e5c46e0fb64/_/download/batch';
const path = `${basepath}/${scriptName1}${scriptName2}`;

function constructScriptSrc(collector: string): string {
  const query = `locale=en-US&collectorId=${collector}`;
  return `${host}/${path}?${query}`;
}
