import { Field, FieldProps, FieldConfig } from 'formik';
import { InputProps, RuledPasswordInput } from 'components/Input';
import { cx } from '@linaria/core';
import React, { useCallback } from 'react';
import { RulesInterface } from 'modules/User/utils/password';
import { FormGroup } from './FormGroup';
import { LabelWithTooltip, TooltipText } from './LabelWithTooltip';
import { errorMsg, fieldWrapper, formLabel } from './Forms.styles';

type RuledPasswordFieldProps = {
  name: string;
  label?: string;
  tooltip?: {
    title: string;
    text: TooltipText;
  };
  rules: RulesInterface;
  id?: string;
  className?: string;
} & Omit<InputProps, 'form'>;

function InputField_({
  name,
  tooltip,
  label,
  meta,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form, // form is unused prop but needs to be picked so as to not be spreaded down
  className,
  id,
  rules,
  ...restInputProps
}: RuledPasswordFieldProps & FieldProps): JSX.Element {
  const error = meta.error;
  const isErrored = meta.touched && error;
  const idForLabel = id || label || name; // fallback to other options to always ensure having a valid htmlFor id for the label
  const invalid = isErrored ? 'invalid' : undefined;
  const labelText = `${label}${restInputProps?.required ? ' *' : ''}`;

  return (
    <FormGroup>
      <div className={cx(fieldWrapper, isErrored && 'invalid')}>
        {label && (
          <LabelWithTooltip className={formLabel} htmlFor={idForLabel} tooltipTitle={tooltip?.title} tooltipText={tooltip?.text}>
            {labelText}
          </LabelWithTooltip>
        )}
        <RuledPasswordInput
          id={idForLabel}
          name={name}
          rules={rules}
          variant={invalid}
          className={cx(className, 'formField', isErrored && 'invalid')}
          {...restInputProps}
        />
        {isErrored && <p className={errorMsg}>{error}</p>}
      </div>
    </FormGroup>
  );
}

export function RuledPasswordField(props: RuledPasswordFieldProps & Omit<FieldConfig, 'component' | 'children'>): JSX.Element {
  /* eslint-disable react/no-children-prop */
  // avoid field rerendering on parent rerender
  const children = useCallback((fieldProps: FieldProps) => <InputField_ {...fieldProps} {...props} />, [props]);

  return <Field {...props} children={children} />;
}
