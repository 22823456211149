import { css, cx } from '@linaria/core';
import { regularText, fontMedium } from 'styles/typography';
import { theming, breakpoints } from 'styles/utils';
export const quickStartButton = "omponents__NoAppState__NoAppState_styles_ts-quickStartButton-qydoopl";
export const noAppTitle = cx("omponents__NoAppState__NoAppState_styles_ts-noAppTitle-niagod0", fontMedium);
export const iconContainer = "omponents__NoAppState__NoAppState_styles_ts-iconContainer-ijbpnhr";
export const noAppContent = "omponents__NoAppState__NoAppState_styles_ts-noAppContent-n1nl8ae0";
export const createAppButton = "omponents__NoAppState__NoAppState_styles_ts-createAppButton-c18hve4s";
export const mainContainer = "omponents__NoAppState__NoAppState_styles_ts-mainContainer-mxzze06";
export const buttonIcon = "omponents__NoAppState__NoAppState_styles_ts-buttonIcon-b5olqqs";
export const noAppSubText = "omponents__NoAppState__NoAppState_styles_ts-noAppSubText-n1x4swoi";
export const modelsStartButton = cx("omponents__NoAppState__NoAppState_styles_ts-modelsStartButton-m1si962c", regularText, fontMedium);
export const createTaskLink = "omponents__NoAppState__NoAppState_styles_ts-createTaskLink-coqj7ss";

require("./NoAppState.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./NoAppState.styles.ts");