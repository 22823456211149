import React, { ChangeEvent, useEffect, forwardRef, useCallback, KeyboardEvent } from 'react';
import { cx } from '@linaria/core';
import { useLanguages } from 'utils/languages';
import { Alert } from 'components/Alert';
import { IconInfoFill, IconExpandUpRounded, IconExpandDownRounded, IconVisualSearchModal, IconFileText } from 'components/Icons';
import { SelectOption } from 'components/Select/SelectControlled';
import { noop } from 'utils/fp';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import { errorMsg } from 'components/Formik/Forms.styles';
import { useMutationTE } from 'utils/react-query';
import cogoToast from 'cogo-toast';
import { useCreateApplicationTE } from 'modules/Apps/hooks/useCreateApplicationTE';
import { object, string } from 'yup';
import { Spinner } from 'components/Spinner/Spinner';
import { isWorkflowCheckConsents } from 'utils/face';
import { appStorage } from 'utils/appStorage';
import { HUBSPOT_CUSTOM_EVENTS_NAME, sendEvent } from 'utils/hubspotApi/hubspotApi';
import { appIdTooltipText, selectedBaseWorkflowTooltipText } from 'modules/Apps/constants';
import { ConsentCheckboxField } from 'modules/Apps/components/ConsentCheckboxField/ConsentCheckboxField';
import { RadioGroup, useRadioState } from 'components/RadioGroup';
import { baseTheme } from 'styles/utils';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import {
  dropdownWrapper,
  alertText,
  overrideInputField,
  createAppGormGroup,
  createAppGormGroupDisabled,
  advancedSwitch,
  advancedSettings,
  height100,
  height0,
  inputField,
  fieldDesc,
  fieldTopMargin,
  fieldBottomMargin,
  inputTypeRadioContainer,
  radioLabel,
  recommendWorkflow,
  activeRadioButton,
  defaultRadioButton,
  disableRadioButton,
  selectMenuWrapper,
} from './styles';
import { usePublicWorkflows } from './CreateApplication.hooks';

type FormValues = {
  appId: string;
  description: string;
  selectedLanguage: undefined | SelectOption;
  selectedBaseWorkflow: undefined | SelectOption;
  legalConsent: boolean;
};

const createAppValidationSchema = object().shape({
  appId: string().required('Required'),
  // eslint-disable-next-line react/forbid-prop-types
  selectedLanguage: object({ value: string().required('Required') }),
  // eslint-disable-next-line react/forbid-prop-types
  selectedBaseWorkflow: object({ value: string().required('Required') }),
  description: string().min(2, 'Should be greater than 2'),
});

export interface CreateApplicationFormProps {
  onAppCreated: (createdApp: CF.API.Apps.App) => void;
  setIsLoaded?: (flag: boolean) => void;
  setSubmitDisabled: (flag: boolean) => void;
  enableAdvanced?: boolean;
}

export const DEFAULT_WORKFLOW_FALLBACK_FOR_IMAGE = 'Universal';
export const DEFAULT_WORKFLOW_FALLBACK_FOR_TEXT = 'Empty';

export const CreateApplicationForm = forwardRef<HTMLFormElement, CreateApplicationFormProps>(
  ({ onAppCreated, setSubmitDisabled, setIsLoaded, enableAdvanced = true }, forwardedRef) => {
    const publicWorkflows = usePublicWorkflows();
    const validLanguages = useLanguages();
    const user = useCurrentUser();
    const isOrgAccount = useIsOrgAccount();
    const [showAdvanced, setShowAdvanced] = React.useState(false);

    const {
      'primary-input-type-app-creation': primaryInputTypeAppCreation,
      'default-base-workflow-option-for-text': defaultBaseWorkflowOptionForText,
      'default-base-workflow-option-for-image': defaultBaseWorkflowOptionForImage,
    } = useFeatureFlagsLD();
    const inputTypeRadio = useRadioState({ baseId: 'input-type', state: 'image/video' });
    const isImageVideoType = inputTypeRadio.state === 'image/video';
    const isTextInputType = inputTypeRadio.state === 'text';

    const inputTypeOptions = (values: FormValues) => {
      return [
        {
          value: 'image/video',
          label: (
            <div className="radio-container">
              <IconVisualSearchModal
                size={20}
                color={isImageVideoType && !hasWorkflowChanged(values) ? baseTheme.light.colors.primary600 : baseTheme.light.colors.black}
              />
              <span className={cx('radio-label', isImageVideoType && 'checkedState')}>Image/Video</span>
            </div>
          ),
        },
        {
          value: 'text',
          label: (
            <div className="radio-container">
              <IconFileText
                size={20}
                color={isTextInputType && !hasWorkflowChanged(values) ? baseTheme.light.colors.primary600 : baseTheme.light.colors.black}
              />
              <span className={cx('radio-label', isTextInputType && 'checkedState')}>Text/Document</span>
            </div>
          ),
        },
      ];
    };

    useEffect(() => {
      if (!enableAdvanced) {
        setShowAdvanced(false);
      }
    }, [enableAdvanced]);

    const makeCreateAppTE = useCreateApplicationTE({ showApiKeyToasts: true });

    const { mutate, isLoading } = useMutationTE(
      (data: { userId: string; appId: string; description: string; language: string; baseWorkflow: string; legalConsent: boolean }) =>
        makeCreateAppTE(data)(),
      {
        onSuccess: ({ apps: [createdApp] }) => {
          if (appStorage.get('first_app_not_created')) {
            sendEvent({ email: user?.email, eventName: HUBSPOT_CUSTOM_EVENTS_NAME.firstAppCreated, properties: {} });
            appStorage.rm('first_app_not_created');
          }
          cogoToast.success(`Application ${createdApp.id} created!`);
          onAppCreated(createdApp);
        },
        onError: ({ props }) => {
          cogoToast.error(props?.reason || 'Could not Create Application', { heading: 'Error' });
        },
      },
    );

    const [onIdChange, { errorString }] = useIdValidation$({
      userId: user?.user_id || '',
      entityName: 'app',
    });

    const handleSubmit = (values: FormValues): void => {
      const data = {
        userId: user?.user_id || '',
        appId: values.appId,
        description: values.description,
        language: values.selectedLanguage ? values.selectedLanguage.value : '',
        baseWorkflow: values.selectedBaseWorkflow ? values.selectedBaseWorkflow.value : '',
        legalConsent: values.legalConsent,
      };
      mutate(data);
    };

    const handleAppIdChange = (
      event: ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
    ): void => {
      const value = event.target.value.replaceAll(/[^\w-]/g, '-');
      onIdChange(value);
      setFieldValue('appId', value);
    };

    const validate = (values: FormValues): { legalConsent?: string } => {
      const errors: { legalConsent?: string } = {};
      if (isCheckConsents(values) && !values.legalConsent) {
        errors.legalConsent = 'Required';
      }
      return errors;
    };

    const isCheckConsents = (values: FormValues): boolean => {
      const selectedWorkflow = publicWorkflows.find(
        ({ workflow }) => values?.selectedBaseWorkflow?.value && workflow.id === values?.selectedBaseWorkflow.value,
      );
      return selectedWorkflow ? isWorkflowCheckConsents(selectedWorkflow.workflow) : false;
    };

    const Icon = showAdvanced ? IconExpandUpRounded : IconExpandDownRounded;

    const hasWorkflowChanged = (values: FormValues): boolean => {
      const selectedWorkflow = publicWorkflows.find(({ workflow }) => workflow.id === values?.selectedBaseWorkflow?.value);

      return selectedWorkflow
        ? ![
            defaultBaseWorkflowOptionForImage,
            defaultBaseWorkflowOptionForText,
            DEFAULT_WORKFLOW_FALLBACK_FOR_IMAGE,
            DEFAULT_WORKFLOW_FALLBACK_FOR_TEXT,
          ].includes(selectedWorkflow.workflow.id)
        : true;
    };

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
      event.stopPropagation();
    }, []);

    return (
      <>
        {publicWorkflows?.length ? (
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              appId: '',
              description: '',
              selectedLanguage: validLanguages.find((x) => x.value === 'en'),
              selectedBaseWorkflow: publicWorkflows.find(
                (x) => x.value === (defaultBaseWorkflowOptionForImage || DEFAULT_WORKFLOW_FALLBACK_FOR_IMAGE),
              ),
              legalConsent: false,
            }}
            validationSchema={createAppValidationSchema}
            validate={validate}
          >
            {({ isValid, dirty, values, setFieldValue, errors }) => {
              useEffect(() => {
                setSubmitDisabled(!isValid || !dirty || isLoading || !!errorString);
              }, [isValid, dirty, isLoading, errorString]);

              useEffect(() => {
                if (publicWorkflows?.length > 0 && setIsLoaded) {
                  setIsLoaded(true);
                }
              }, [publicWorkflows, setIsLoaded]);

              return (
                <Form className={overrideInputField} ref={forwardedRef}>
                  <div className={createAppGormGroup}>
                    <InputField
                      name="appId"
                      label="App ID"
                      placeholder="Enter Unique Identifier"
                      customError={errorString}
                      tooltip={{ title: 'App ID', text: appIdTooltipText }}
                      onChange={(event) => handleAppIdChange(event, setFieldValue)}
                      className={inputField}
                      onKeyDown={handleKeyDown}
                      truncateError
                      required
                    />
                    {values.appId?.length > 0 && <p className={errorMsg}>{errorString}</p>}

                    <div className={cx(fieldDesc, (errorString.length > 0 || errors.appId) && fieldTopMargin)}>
                      Choose a clear and descriptive name for your app, such as face-detection, logo-detection, moderation, etc.
                    </div>
                  </div>
                  <div className={errors.description ? fieldBottomMargin : createAppGormGroup}>
                    <InputField name="description" label="Short Description" placeholder="Enter Short Description" onKeyDown={handleKeyDown} />
                  </div>
                  {(primaryInputTypeAppCreation || true) && (
                    <div>
                      <span className={radioLabel}>Primary Input Type*</span>
                      <RadioGroup
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const { target } = e;
                          if (target.value === 'image/video') {
                            let foundImageWorkflow = publicWorkflows.find((x) => x.value === defaultBaseWorkflowOptionForImage);

                            if (!foundImageWorkflow) {
                              foundImageWorkflow = publicWorkflows.find((x) => x.value === DEFAULT_WORKFLOW_FALLBACK_FOR_IMAGE);
                            }

                            setFieldValue('selectedBaseWorkflow', foundImageWorkflow);
                          }
                          if (target.value === 'text') {
                            let foundTextWorkflow = publicWorkflows.find((x) => x.value === defaultBaseWorkflowOptionForText);

                            if (!foundTextWorkflow) {
                              foundTextWorkflow = publicWorkflows.find((x) => x.value === DEFAULT_WORKFLOW_FALLBACK_FOR_TEXT);
                            }

                            setFieldValue('selectedBaseWorkflow', foundTextWorkflow);
                          }
                        }}
                        radio={inputTypeRadio}
                        options={inputTypeOptions(values)}
                        title="Primary Input Type"
                        disabled={hasWorkflowChanged(values)}
                        className={cx(inputTypeRadioContainer, hasWorkflowChanged(values) && createAppGormGroupDisabled)}
                        activeClass={activeRadioButton}
                        disabledClassName={disableRadioButton}
                        activeValue={`${inputTypeRadio.state}`}
                        radioListClass={defaultRadioButton}
                      />
                    </div>
                  )}
                  <div
                    onClick={() => setShowAdvanced((v) => !v)}
                    className={cx(advancedSwitch, enableAdvanced ? height100 : height0)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={noop}
                  >
                    <Icon size={10} />
                    Advanced Settings
                  </div>
                  {showAdvanced && (
                    <div className={cx(advancedSettings, height100)}>
                      <div className={createAppGormGroup}>
                        <SelectField
                          className={dropdownWrapper}
                          menuWrapperClass={selectMenuWrapper}
                          name="selectedBaseWorkflow"
                          label="Base Workflow"
                          placeholder="Select Base Workflow"
                          options={publicWorkflows}
                          tooltip={{ title: 'Base Workflow', text: selectedBaseWorkflowTooltipText }}
                          onChange={(selectOption) => {
                            const { value } = selectOption;
                            if (value === defaultBaseWorkflowOptionForImage) {
                              inputTypeRadio.setState('image/video');
                            } else if (value === defaultBaseWorkflowOptionForText) {
                              inputTypeRadio.setState('text');
                            }
                            setFieldValue('selectedBaseWorkflow', selectOption);
                          }}
                        />
                        {hasWorkflowChanged(values) && (
                          <p className={recommendWorkflow}>
                            You’ve changed from the recommended/default workflow. Results may vary depending on your input type.
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {isCheckConsents(values) && <ConsentCheckboxField name="legalConsent" />}

                  {!isOrgAccount && (
                    <Alert id="info-alert" type="info" data-testid="api-key-message" className={alertText}>
                      <IconInfoFill size={18} color="blue" />
                      <span>
                        <b>Note:</b>an API Key will be created automatically for this application
                      </span>
                    </Alert>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Spinner />
        )}
      </>
    );
  },
);
