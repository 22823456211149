import { cx } from '@linaria/core';
import { IconLock, IconGlobe } from 'components/Icons';
import { tagWrapper } from './Visibility.styles';

export enum VISIBILITY_TEXT {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  UNKNOWN_VISIBILITY = 'Unknown',
  ORG = 'Organization',
}

export interface VisibilityTagProps extends React.HTMLAttributes<HTMLDivElement> {
  visibility: CF.API.VisibilityTypes;
  text?: VisibilityTagProps['visibility'] | string;
  size?: 'sm' | 'lg';
}

export const Visibility = ({ visibility, text = visibility, size = 'sm', ...rest }: VisibilityTagProps): JSX.Element => {
  const isPublic = visibility === 'PUBLIC';
  const iconSize = size === 'sm' ? 16 : 20;

  return (
    <div className={cx(tagWrapper, rest.className, size)} {...rest}>
      <div className="content">
        {isPublic ? (
          <IconGlobe size={iconSize} color="#808080" data-testid="public-icon" />
        ) : (
          <IconLock size={iconSize} color="#808080" data-testid="private-icon" />
        )}
        <span>{text}</span>
      </div>
    </div>
  );
};
