import { EditableMarkdown } from 'modules/EntityViewers/components/EditableMarkdown';
import { useState } from 'react';
import { IconEdit } from 'components/Icons';
import { MdxProps } from 'types';
import { useIsOwner } from 'components/NoAppState/NoAppState.hooks';
import { noop } from 'utils/fp';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { container, buttonContainer, descriptionContainer, saveButton, cancelButton } from './AppInfo.styles';

interface AppInfoProps {
  editableEntity: CF.UI.EditableEntity;
  mdxSource?: MdxProps;
}

export const AppInfo: React.FC<AppInfoProps> = ({ editableEntity, mdxSource }): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialNotes, setInitialNotes] = useState(('notes' in editableEntity.data && editableEntity.data.notes) || '');
  const isOwner = useIsOwner();
  const toggleEditing = (): void =>
    setIsEditing((isE) => {
      if (isE) {
        editableEntity.save();
        setInitialNotes(editableEntity.data.notes);
        return false;
      }
      return true;
    });

  return (
    <div className={cx(container, flex.flex.one)}>
      <div className={descriptionContainer}>
        {isEditing && (
          <div className={buttonContainer}>
            <button
              type="button"
              onClick={() => {
                editableEntity.setValue('notes', initialNotes);
                setIsEditing(false);
              }}
              className={cancelButton}
            >
              Cancel
            </button>
            <button type="button" onClick={toggleEditing} className={saveButton}>
              Save
            </button>
          </div>
        )}

        {!isEditing && isOwner && (
          <div className="editButton">
            <button type="button" onClick={toggleEditing} onKeyDown={noop} tabIndex={0}>
              <IconEdit size={15} color="#aaa" />
            </button>
          </div>
        )}
        <div>
          <EditableMarkdown mdxSource={mdxSource} editableEntity={editableEntity} isEditing={isEditing} />
        </div>
      </div>
    </div>
  );
};
