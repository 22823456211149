import React, { useState, forwardRef, useEffect } from 'react';
import { useMeasure } from 'react-use';
import { CreateApplicationForm } from './CreateApplicationForm';
import { AppFormIconArea } from './AppFormIconArea';
import { createAppContainer } from './styles';

export interface CreateApplicationProps {
  closeAction: (isCanceled?: boolean) => void;
  onAppCreated: (createdApp: CF.API.Apps.App) => void;
  setIsLoaded?: (flag: boolean) => void;
  setSubmitDisabled: (flag: boolean) => void;
}

export const CreateApplication = forwardRef<HTMLFormElement, CreateApplicationProps>(
  ({ onAppCreated, setIsLoaded, setSubmitDisabled }, forwardedRef) => {
    const [measureRef, { width }] = useMeasure();
    const mRefTyped = measureRef as React.LegacyRef<HTMLDivElement>;
    const [wasFocused, setWasFocused] = useState(false);

    const mainRefTyped = forwardedRef as React.RefObject<HTMLFormElement>;

    useEffect(() => {
      if (width <= 720 || wasFocused) {
        return;
      }

      if (mainRefTyped.current) {
        const el = (mainRefTyped.current as HTMLFormElement).querySelector('[name="appId"]') as HTMLInputElement;
        if (el) {
          setTimeout(() => {
            el.focus();
          }, 1000);
        }
        setWasFocused(true);
      }
    }, [mainRefTyped?.current, wasFocused, width]);

    return (
      <div className={createAppContainer} ref={mRefTyped}>
        <CreateApplicationForm
          enableAdvanced
          ref={forwardedRef}
          onAppCreated={onAppCreated}
          setSubmitDisabled={setSubmitDisabled}
          setIsLoaded={setIsLoaded}
        />
        <AppFormIconArea />
      </div>
      //
    );
  },
);
