import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { errorStateContainer } from 'utils/uiStates.styles';
import { ReactNode } from 'react';
import { noAppContent, noAppTitle, iconContainer } from './NoAppState.styles';
import { useIsOwner } from './NoAppState.hooks';

interface NoResourceStateProps {
  subtitle: (isOwner?: boolean) => ReactNode;
  title: (isOwner?: boolean) => ReactNode;
  isCtaVisible: boolean;
  icon?: ReactNode;
  cta: ReactNode;
}

export const NoResourceState: React.FC<NoResourceStateProps> = (props: NoResourceStateProps) => {
  const { isCtaVisible, title, subtitle, icon, cta } = props;
  const isOwner = useIsOwner();

  return (
    <div data-testid="default-empty-apps-ui" className={errorStateContainer}>
      <div className={cx(flex.init, flex.direction.column, flex.alignItems.center, noAppContent)}>
        {icon && (
          <div className={iconContainer}>
            <div>{icon}</div>
          </div>
        )}
        <h3 className={cx(noAppTitle, 'title')}>{title(isOwner)}</h3>
        {subtitle(isOwner)}
        {isCtaVisible && <>{cta}</>}
      </div>
    </div>
  );
};
