import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ConceptThresholderIcon: FC<IconProps> = ({ color, bgColor, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 61.908 46.681">
    <path
      id="Electrical_Threshold-595b40b65ba036ed117d1ba0"
      data-name="Electrical Threshold-595b40b65ba036ed117d1ba0"
      d="M6.538,8.53Zm7.614,0Zm7.614,0Zm7.614,0Zm7.614,0Zm7.614,0Zm7.614,0Zm7.614,0Zm-7.375,5.076a5.535,5.535,0,0,0-4.124,2.141,13.177,13.177,0,0,0-2.141,3.886,96.138,96.138,0,0,0-2.7,9.834c-.163.653-.3,1.263-.476,1.9H26.6l1.269,5.076H41.433a19.723,19.723,0,0,1-.952,2.062c-1.184,2.225-2.115,3.014-3.49,3.014A6.252,6.252,0,0,0,32.551,43.5a13.418,13.418,0,0,0-2.062,3.172A17.428,17.428,0,0,1,29.22,48.9c-.153.193-.175.227-.159.238-.125-.013-.24-.093-.634-.555a10.366,10.366,0,0,1-1.665-2.935,83.734,83.734,0,0,1-2.855-11.024,93.814,93.814,0,0,0-2.935-11.658,15.4,15.4,0,0,0-2.379-4.441,5.857,5.857,0,0,0-4.441-2.379A7.5,7.5,0,0,0,7.569,20.03,9.36,9.36,0,0,0,6.538,23.6l5.076.317a5.076,5.076,0,0,1,.476-1.507c.373-.711.542-1.19,2.062-1.19.035,0,.122-.064.555.476a11.634,11.634,0,0,1,1.507,3.014,58.89,58.89,0,0,1,1.824,6.662H4v5.076H19.148a77.073,77.073,0,0,0,2.934,10.944,15.265,15.265,0,0,0,2.459,4.521,6.217,6.217,0,0,0,4.6,2.3,5.493,5.493,0,0,0,4.124-2.141,16.585,16.585,0,0,0,1.745-3.014,10.807,10.807,0,0,1,1.269-2.062c.344-.371.381-.4.714-.4,3.7,0,6.41-2.7,8.01-5.71a45.287,45.287,0,0,0,3.41-10.151c.884-3.535,1.665-7.026,2.538-9.358A8.739,8.739,0,0,1,52.219,19c.329-.4.243-.317.238-.317.775,0,1.173.413,1.665,1.269a7.693,7.693,0,0,1,.714,1.745l4.917-.952a11.71,11.71,0,0,0-1.269-3.331A7.138,7.138,0,0,0,52.457,13.606ZM50.95,31.37a41.321,41.321,0,0,1-1.269,5.076H64.908V31.37Z"
      transform="translate(-3.5 -8.03)"
      fill={color}
      stroke={bgColor}
      strokeWidth="1"
    />
  </svg>
);
