import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { IconList, IconGrid } from 'components/Icons';
import { observer } from 'mobx-react-lite';
import { css, cx } from '@linaria/core';
import { Button } from 'components/Button';
const buttonHeight = "odules__Listings__components__ListGridToggle__ListGridToggle_tsx-buttonHeight-bwssiyd";
const ListGridToggle_ = (): JSX.Element => {
  const store = useCurrentStore();
  return <Button className={cx(buttonHeight, 'listGridToggle')} variant="tertiary" size="md" onClick={() => store.toggleViewModel()}>
      {store.viewMode === 'list' ? <IconGrid size={16} color="#306AFF" /> : <IconList size={16} color="#306AFF" />}
    </Button>;
};
export const ListGridToggle = observer(ListGridToggle_);

require("./ListGridToggle.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./ListGridToggle.tsx");