import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listCollaboratorAppsTE = <E = CF.API.ApiError>(
  { userId, params = '' }: { userId?: string; params?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.ListCollaboratorAppsResponse> =>
  fetchTE<CF.API.Apps.ListCollaboratorAppsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/collaborations${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
