import { cx, css } from '@linaria/core';
import { flex, theming } from 'styles/utils';
import { ellipsis } from 'polished';
export const account = cx("odules__Organizations__components__Account__styles_ts-account-a1tfe35x", flex.init, flex.alignItems.center);
export const selectedAccountRow = "odules__Organizations__components__Account__styles_ts-selectedAccountRow-s1sp2odh";
export const avatar = cx("odules__Organizations__components__Account__styles_ts-avatar-a1tnpo0k", flex.init, flex.alignItems.center, flex.justifyContent.center);
export const _basicTag = "odules__Organizations__components__Account__styles_ts-_basicTag-_1xdznzx";
export const userTag = cx("odules__Organizations__components__Account__styles_ts-userTag-u1ayr6bv", _basicTag);
export const orgTag = cx("odules__Organizations__components__Account__styles_ts-orgTag-o1gbhmib", _basicTag);

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");