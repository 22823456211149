import React, { useState, useMemo } from 'react';
import { cx } from '@linaria/core';
import { IconBox, IconTableOfContents } from 'components/Icons';
import { noop } from 'utils/fp';
import { AppTypeSVG } from './AppTypeSVG';
import {
  container,
  stepColumnContainer,
  stepsContainer200proc,
  selectedItem,
  itemContainer,
  formContainer,
  mobilePadding,
} from './AppTypeSelector.styles';

export interface CreateApplicationProps {
  onTypeSelected: (appType: string) => void;
}

export const AppTypeSelector: React.FC<CreateApplicationProps> = ({ onTypeSelected }) => {
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);
  const appTypeItems = useMemo(() => {
    return [
      {
        className: 'blue-icon',
        icon: <IconBox size={35} color="#fff" />,
        title: 'Start with a Blank App',
        description: 'Create a new app from scratch, add or build the right model for your target use case.',
        action: 'createApp',
      },
      {
        className: 'green-icon',
        icon: <IconTableOfContents size={30} color="#fff" />,
        title: 'Use an App Template',
        description: 'Begin with a fully equipped app that addresses a particular use case. Then, modify the template to suit your preferences.',
        action: 'chooseTemplate',
      },
    ];
  }, []);

  return (
    <div className={container}>
      <div className={stepsContainer200proc}>
        <div className={stepColumnContainer}>
          <div className={mobilePadding}>
            {appTypeItems.map((item, idx) => (
              <div
                key={item.description}
                className={cx(itemContainer, selectedItemIdx === idx && selectedItem, 'cursor-pointer')}
                onClick={() => {
                  setSelectedItemIdx(idx);
                  onTypeSelected(item.action);
                }}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
              >
                <div className={item.className}>{item.icon}</div>
                <div className="text-container">
                  <div className="title">{item.title}</div>
                  <div className="description">{item.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={cx(formContainer, stepColumnContainer)}>
          <div className="svg-container">
            <AppTypeSVG />
          </div>
          <h3>Build an app from scratch or use a template for a quick start</h3>
          <div>
            Apps on the Clarifai platform are like projects. They hold your data, annotations, models, workflows, predictions and more. You can make
            as many apps as you need and change or remove them whenever you want.
          </div>
        </div>
      </div>
    </div>
    //
  );
};
