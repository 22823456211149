import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { Button } from 'components/Button';
import { textRegular } from 'styles/typography';
import { errorToReactLeft, pipe } from 'utils/fp';
import { getUserPrimaryEmail } from 'utils/users';
import { queryKeys, useMutationTE, useQueryTE } from 'utils/react-query';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { getUserMeTE } from 'api/users';
import { PageWrapper } from 'components/PageWrapper';
import { resendVerificationEmailTE, resendVerificationEmailRequestBody } from 'api/users/resendVerificationEmail';
import { useQueryClient } from 'react-query';
import { useAuthState } from 'modules/Auth/AuthContext';
import { IconMailShadow } from 'components/Icons';
import { CONTACT_US_LINK } from 'components/Navbar/HelpPopover';
import { wrapper, content, btnWrapper } from './PendingVerification.styles';

type PendingVerificationProps = {
  user: CF.API.Users.User;
};

export const PendingVerification_: FC<PendingVerificationProps> = ({ user }) => {
  const router = useRouter();
  const primaryEmail = getUserPrimaryEmail(user);
  const qc = useQueryClient();
  const primaryEmailAddress = primaryEmail?.email;
  const resendVerificationEmailMutation = useMutationTE(
    (body: resendVerificationEmailRequestBody) => resendVerificationEmailTE({ body }, errorToReactLeft)(),
    {
      onError: ({ props }) => cogoToast.error(props?.reason || 'Some error occurred. Please try again', { heading: 'Error' }),
      onSuccess: () => cogoToast.success('Email sent!'),
    },
  );

  const openContactSupportPage = (): void => {
    window.open(CONTACT_US_LINK, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (primaryEmail.verified) {
      router.push(`/${user.id}/apps`);
    }
  }, [primaryEmail.verified]);

  useEffect(() => {
    return () => {
      qc.invalidateQueries([queryKeys.LoggedInUser]);
    };
  }, []);

  return (
    <div className={wrapper}>
      <div className={content}>
        <IconMailShadow />
        <h1>Please verify your email</h1>
        <p className={textRegular}>
          We sent a verification email to <span>{primaryEmailAddress}</span>. If you don&apos;t see the email, please check your junk folder.
        </p>

        <div className={btnWrapper}>
          <Button size="md" variant="tertiary" onClick={openContactSupportPage}>
            Contact support
          </Button>
          <Button size="md" variant="tertiary" onClick={() => resendVerificationEmailMutation.mutate({ email: primaryEmailAddress })}>
            Re-send email
          </Button>
        </div>
      </div>
    </div>
  );
};

export const PendingVerification = (): JSX.Element | null => {
  const sessionToken = useAuthState().authData?.session_token;
  if (!sessionToken) {
    return null;
  }

  const { data } = useQueryTE([queryKeys.LoggedInUser, { sessionToken }], getUserMeTE(undefined, errorToReactLeft));
  return pipe(
    data,
    either.fold(reactLeftToJSX(), ({ user }) => <PendingVerification_ user={user} />),
  );
};

export const PendingVerificationPage = () => {
  return (
    <PageWrapper>
      <PendingVerification />
    </PageWrapper>
  );
};
