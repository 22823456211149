import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const createUserInviteTE = <E = CF.API.ApiError>(
  { orgId, memberEmail, memberRoleId }: { orgId: string; memberEmail: string; memberRoleId: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListInvitedOrgMembersResponse> =>
  fetchTE<CF.API.Organizations.ListInvitedOrgMembersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/invitations`,
      method: 'POST',
      body: {
        invitations: [
          {
            invitee_email: memberEmail,
            role: {
              id: memberRoleId,
            },
          },
        ],
      },
    },
    onRejected,
  );

export const createBulkUserInvitesTE = <E = CF.API.ApiError>(
  { orgId, invitations }: { orgId: string; invitations: { memberEmail: string; memberRoleId: string }[] },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListInvitedOrgMembersResponse> =>
  fetchTE<CF.API.Organizations.ListInvitedOrgMembersResponse, E>(
    {
      path: `/api/organizations/${orgId}/bulkInvitations`,
      method: 'POST',
      body: {
        invitations: invitations.map((invitation) => ({
          invitee_email: invitation.memberEmail,
          role: {
            id: invitation.memberRoleId,
          },
        })),
      },
    },
    onRejected,
  );
