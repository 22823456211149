export const PreTrainedModelIcon = ({ width = 80, height = 80 }: { width: number | string; height: number | string }) => (
  <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="4" fill="#039855" />
    <path
      d="M25.1267 32.4714L32.2734 37.5761C32.6453 37.8417 32.8312 37.9745 33.0258 38.0051C33.197 38.0321 33.3724 38.0049 33.5275 37.9275C33.7037 37.8395 33.8408 37.6567 34.115 37.2911L35.6252 35.2775C35.7027 35.1742 35.7415 35.1225 35.7871 35.0779C35.8275 35.0383 35.8719 35.0029 35.9196 34.9723C35.9732 34.9378 36.0323 34.9116 36.1504 34.8591L42.598 31.9935C42.8653 31.8747 42.9989 31.8153 43.0997 31.7226C43.1888 31.6405 43.259 31.54 43.3053 31.4281C43.3577 31.3015 43.3674 31.1556 43.3869 30.8638L43.859 23.7824M42.5 42.5L46.86 44.3686C47.3659 44.5854 47.6188 44.6938 47.7528 44.8738C47.8704 45.0318 47.9282 45.2263 47.916 45.4228C47.9021 45.6468 47.7495 45.8758 47.4442 46.3337L45.3958 49.4063C45.2511 49.6234 45.1788 49.7319 45.0832 49.8105C44.9985 49.8801 44.901 49.9323 44.7962 49.9641C44.6778 50 44.5473 50 44.2864 50H40.961C40.6155 50 40.4427 50 40.2956 49.9422C40.1658 49.8912 40.0509 49.8083 39.9614 49.7013C39.86 49.5801 39.8054 49.4162 39.6961 49.0883L38.508 45.524C38.4435 45.3306 38.4113 45.2339 38.4028 45.1356C38.3953 45.0485 38.4015 44.9608 38.4213 44.8756C38.4436 44.7795 38.4892 44.6883 38.5803 44.506L39.4829 42.701C39.6662 42.3342 39.7579 42.1509 39.8991 42.037C40.0236 41.9367 40.1743 41.8743 40.3332 41.8572C40.5136 41.8379 40.7081 41.9027 41.0971 42.0324L42.5 42.5ZM56.6667 40C56.6667 49.2048 49.2048 56.6667 40 56.6667C30.7953 56.6667 23.3333 49.2048 23.3333 40C23.3333 30.7953 30.7953 23.3333 40 23.3333C49.2048 23.3333 56.6667 30.7953 56.6667 40Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
