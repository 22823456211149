export const getExplorePageNameByPath = (path: string): string | undefined => {
  switch (path) {
    case '/explore':
      return 'explore_page';
    case '/explore/models':
      return 'models_page';
    case '/explore/workflows':
      return 'workflows_page';
    default:
      return undefined;
  }
};
