import { IconColumnSortDesc, IconColumnSortAsc, IconLines, IconCheck } from 'components/Icons';
import { baseTheme } from 'styles/utils';
import { cx } from '@linaria/core';
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { SortingState, Updater } from '@tanstack/react-table';
import { iconPopOverDisclosure, ascDscSorter, popOverContainer, popOverStyle, sorter } from './SortIndicator.styles';

export interface SortIndicatorProps {
  hasSorter?: boolean;
  size?: number;
  sortDirection?: boolean | 'asc' | 'desc';
  columnId: string;
  setSortBy: (updater: Updater<SortingState>) => void;
  sortMetaData?: Record<'sortAscLabel' | 'sortDescLabel', string>;
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

export const SortIndicator: React.FC<SortIndicatorProps> = ({ hasSorter, columnId, sortDirection, size = 15, setSortBy, sortMetaData }) => {
  const isDefaultSort = sortDirection === false;
  const isDescSort = sortDirection === 'desc';
  const isAscSort = sortDirection === 'asc';
  const Icon = isDescSort ? IconColumnSortDesc : IconColumnSortAsc;
  const popover = usePopoverState({ modal: true });

  const onSortAsc = () => {
    const sort = !isAscSort ? [{ id: columnId, desc: false }] : [];
    setSortBy(sort);
  };

  const onSortDesc = () => {
    const sort = !isDescSort ? [{ id: columnId, desc: true }] : [];
    setSortBy(sort);
  };

  const sortListPopOver = () => {
    return (
      <div className={sorter}>
        <div className={ascDscSorter}>
          <button data-testid="popover-asc-btn" type="button" className={cx(isAscSort && 'selected')} onClick={onSortAsc}>
            <p data-testid="asc-label">{sortMetaData?.sortAscLabel ? sortMetaData?.sortAscLabel : 'Sort (A-Z)'}</p>
            {!isDefaultSort && isAscSort && <IconCheck size={20} color={baseTheme.light.colors.primary600} />}
          </button>
          <button data-testid="popover-desc-btn" type="button" className={cx(isDescSort && 'selected')} onClick={onSortDesc}>
            <p data-testid="desc-label">{sortMetaData?.sortDescLabel ? sortMetaData?.sortDescLabel : 'Sort (Z-A)'}</p>
            {!isDefaultSort && isDescSort && <IconCheck size={20} color={baseTheme.light.colors.primary600} />}
          </button>
        </div>
      </div>
    );
  };

  return hasSorter ? (
    <>
      <Popover {...popover} className={popOverStyle}>
        <div data-testid="popover" className={popOverContainer}>
          {sortListPopOver()}
        </div>
      </Popover>
      <PopoverDisclosure {...popover}>
        <button type="button" className={iconPopOverDisclosure} data-testid="sort-indicator-btn">
          {!isDefaultSort && <Icon id="sort-indicator" size={size} color={baseTheme.light.colors.gray400} />}
          <IconLines size={size} color={baseTheme.light.colors.gray400} />
        </button>
      </PopoverDisclosure>
    </>
  ) : null;
};
