import { useAuthActions } from 'modules/Auth/AuthContext';
import { useTwoFactorAuthData } from 'modules/Auth/hooks';
import { useMutationTE } from 'utils/react-query';
import { Link } from 'components/Link';
import { renderLeftError } from 'utils/renderLeftError';
import { SecurityCodeForm } from 'components/SecurityCodeField';
import React, { useCallback } from 'react';
import { IconArrowLeft } from 'components/Icons';
import { textLink } from '../styles';
import { twoFactorLoginContainer, twoFactorAuthLoginHint, twoFactorAuthLoginSubHeader, twoFactorLoginNav } from './TwoFactorAuth.styles';

type FormValues = {
  passCode: string;
};

type TwoFactorLoginFormProps = {
  onBackClick?: () => void;
};

export const TwoFactorLoginForm: React.FC<TwoFactorLoginFormProps> = ({ onBackClick }): JSX.Element | null => {
  const { makeTwoFactorLoginTE, updateTwoFactorAuthData } = useAuthActions();
  const authData = useTwoFactorAuthData();

  if (!authData) {
    return null;
  }

  const recoveryLink = `/two-factor-recovery?state=${authData.state}&user_id=${authData.user_id}`;

  const { dataE, mutate, isLoading, isSuccess } = useMutationTE((body: FormValues) =>
    makeTwoFactorLoginTE({
      passcode: body.passCode,
      userOrgId: authData.user_id,
      state: authData.state,
    })(),
  );

  const onBackPress = useCallback(() => {
    updateTwoFactorAuthData();
    if (onBackClick) {
      onBackClick();
    }
  }, [updateTwoFactorAuthData]);

  const handleSubmitForm = (values: FormValues): void => {
    mutate(values);
  };

  return (
    <div className={twoFactorLoginContainer}>
      <h3 className={twoFactorAuthLoginSubHeader}>Two Factor Authentication</h3>
      <p className={twoFactorAuthLoginHint}>Open the authenticator app to view the authentication code to verify your identity.</p>
      {renderLeftError(dataE)}
      <SecurityCodeForm onSubmit={handleSubmitForm} isLoading={isLoading || isSuccess} />
      <Link className={textLink} href={recoveryLink}>
        Enter recovery code
      </Link>
      <button type="button" className={twoFactorLoginNav} onClick={onBackPress}>
        <IconArrowLeft size={18} />
        Back to log in
      </button>
    </div>
  );
};
