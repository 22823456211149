import { cx } from '@linaria/core';
import { success, error, info } from './Cells.styles';

interface CellStatusProps {
  value: string;
  type?: string | 'success' | 'error' | 'info';
}

const StatusMap: Record<string, string> = {
  success,
  error,
  info,
};

export const Status = ({ value, type = 'info' }: CellStatusProps): JSX.Element => <div className={cx(StatusMap[type])}>{value}</div>;
