import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type LoginPayload = { email: string; password: string; long_lived?: boolean };

export const postLoginTE = <E = CF.API.ApiError>(
  values: LoginPayload,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.LoginResponse> =>
  fetchTE<CF.API.Users.LoginResponse, E>(
    {
      path: '/api/_api/v2/login',
      method: 'POST',
      body: {
        ...values,
        long_lived: values.long_lived === undefined ? true : values.long_lived,
      },
    },
    onRejected,
  );
