import { Button } from 'components/Button';
import { useRouter } from 'next/router';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useCallback } from 'react';
import { welcomeMsgWrapper } from '../OnboardingDialog.styles';

export function WelcomeToClarifai({ userFullname, closeAction }: { userFullname: string; closeAction: () => void }) {
  const userId = useCurrentUserId();
  const router = useRouter();

  const redirectToApps = useCallback(() => {
    router.push(`/${userId}/apps`);
    closeAction();
  }, [userId, router, closeAction]);

  return (
    <div className={welcomeMsgWrapper}>
      <span className="confetti">🎉</span>
      <h1 className="modal-title">Welcome to Clarifai, {userFullname}!</h1>
      <p className="welcome-description">
        We&apos;re excited to have you on board as a new member of our dynamic community. Build AI faster and unlock value instantly using out
        platform!
      </p>
      <Button size="md" variant="primary" onClick={redirectToApps} style={{ marginTop: 'auto' }}>
        Lets start
      </Button>
    </div>
  );
}
