import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { Button } from 'components/deprecated/Button';
import { cx } from '@linaria/core';
import { object, string } from 'yup';
import { restorePasswordBtn, restorePasswordHint, restorePasswordLink, restorePasswordGormGroup } from '../RestorePassword.styles';

type ForgotPasswordFieldTypes = {
  email: string;
};

const defaultValues = {
  email: '',
};

const forgotPasswordValidationSchema = object().shape({
  email: string().required('Required').email('Email must be a valid email'),
});

interface ForgotPasswordFormInterface {
  onSubmit: (obj: ForgotPasswordFieldTypes) => void;
  onResend: (obj: ForgotPasswordFieldTypes) => void;
  showCheckYourEmail: boolean;
  isLoading?: boolean;
  showResend?: boolean;
}

export function ForgotPasswordForm({ onSubmit, showCheckYourEmail, onResend, isLoading, showResend }: ForgotPasswordFormInterface): JSX.Element {
  const handleFormSubmit = (values: ForgotPasswordFieldTypes): void => {
    onSubmit({
      email: values.email.trim(),
    });
  };

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={forgotPasswordValidationSchema}>
      {({ isValid, dirty, values }) => {
        return !showCheckYourEmail ? (
          <Form>
            <div className={restorePasswordGormGroup}>
              <InputField name="email" label="Email" type="email" placeholder="Enter your email" required />
            </div>

            <Button className={cx(restorePasswordBtn, 'fullWidth')} type="submit" disabled={!isValid || !dirty || isLoading}>
              Reset password
            </Button>
            <p className={restorePasswordHint}>
              Having trouble?{' '}
              <a target="_blank" rel="noreferrer" href="https://www.clarifai.com/company/contact">
                Contact us
              </a>
            </p>
          </Form>
        ) : (
          <p className={restorePasswordHint}>
            Didn&apos;t receive the email?{' '}
            <Button kind="link" onClick={() => onResend({ email: values.email })} className={restorePasswordLink} disabled={isLoading || !showResend}>
              Resend
            </Button>
          </p>
        );
      }}
    </Formik>
  );
}
