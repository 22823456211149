import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ImageCropIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 51.559 51.559">
    <path
      id="iconmonstr-crop-6"
      d="M51.559,38.669H42.965V8.593H12.89V0h-4.3V8.593H0v4.3H8.593V42.965H38.669v8.593h4.3V42.965h8.593Zm-38.669,0V12.89H38.669V38.669Zm4.3-18.26a3.222,3.222,0,1,1,3.222,3.222A3.224,3.224,0,0,1,17.186,20.409Zm11.369,1.074-3.893,8.234-3.179-2.733-4.3,7.388H35Z"
      fill={color}
    />
  </svg>
);
