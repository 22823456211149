import { formatDistance, subDays } from 'date-fns';

export interface CellTimeSinceProps {
  value: string;
  testId?: string;
}

export const TimeSince = ({ value, testId = '' }: CellTimeSinceProps): JSX.Element => {
  return <div data-testid={testId}>{value ? formatDistance(subDays(new Date(value), 1), new Date(), { addSuffix: true }) : null}</div>;
};
