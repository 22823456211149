import { createContext } from 'react';
import type { AllListStoreTypes } from './StateContext';

export enum ENTITY_SLUGS {
  collaborations = 'collaborations',
  apps = 'apps',
  workflows = 'workflows',
  models = 'models',
  modules = 'modules',
  datasets = 'datasets',
}

export const CurrentStoreContext = createContext<AllListStoreTypes>(null as unknown as AllListStoreTypes);
export const ListingEntityTypeContext = createContext<ENTITY_SLUGS>('' as ENTITY_SLUGS);
