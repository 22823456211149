import { cx } from '@linaria/core';
import { useMemo } from 'react';
import type { ButtonProps as RBtnProps } from 'reakit';
import { textRegular } from 'styles/typography';
import { cssHelpers, baseTheme } from 'styles/utils/core';
import { IconProps } from 'components/Icons/IconProps';
import { VariantTypes, StyledButton } from './Button.styles';
export type ButtonProps = RBtnProps & {
  size?: 'sm' | 'md';
  iconSize?: number;
  variant?: VariantTypes;
  RightIcon?: React.ElementType<IconProps>;
  LeftIcon?: React.ElementType<IconProps>;
};
export const Button: import('react').FC<ButtonProps> = ({
  RightIcon,
  LeftIcon,
  size = 'sm',
  iconSize,
  variant = 'primary',
  children,
  disabled,
  ...rest
}) => {
  if (variant === 'unstyled') {
    return <button type="button" {...rest}>
        {children}
      </button>;
  }
  const getIconSize = useMemo(() => {
    if (iconSize) return iconSize;
    return size === 'sm' ? 14 : 16;
  }, [iconSize, size]);
  return <StyledButton {...rest} className={cx(RightIcon || LeftIcon ? 'icon-button' : '', `btn`, textRegular, size, disabled && 'disabled')} disabled={disabled} variant={variant}>
      {LeftIcon && <LeftIcon data-testid="left-button-icon" className={cx(cssHelpers.margin.xr.half)} size={getIconSize} color={baseTheme.light.colors.blue} />}
      {children}
      {RightIcon && <RightIcon data-testid="right-button-icon" className={cx(cssHelpers.margin.xl.half)} size={getIconSize} color={baseTheme.light.colors.blue} />}
    </StyledButton>;
};