import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { EmptyObject } from 'types/empty';
import { fetchTE, fetchApi } from 'utils/request';

/**
 * @deprecated use apiGetUserOrgs instead we are moving towards dropping TaskEither
 */
export const fetchUserOrgsTE = <E = CF.API.ApiError>(
  _params?: EmptyObject,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListUserOrgResponse> =>
  fetchTE<CF.API.Organizations.ListUserOrgResponse, E>(
    {
      path: `/api/_api/v2/users/me/organizations`,
    },
    onRejected,
  );

export const apiGetUserOrgs = ({ sessionToken }: { sessionToken?: string }): Promise<CF.API.Organizations.ListUserOrgResponse> =>
  fetchApi({
    path: `/api/_api/v2/users/me/organizations`,
    sessionToken,
    method: 'GET',
  });
