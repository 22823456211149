import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listFiltersTE = <E = CF.API.ApiError>(
  params: { entityType: CF.API.Filters.SupportedFilterEntities },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Filters.ListFiltersResponse> =>
  fetchTE<CF.API.Filters.ListFiltersResponse, E>(
    {
      path: '/api/community/filters/params',
      method: 'POST',
      body: { entityType: params.entityType },
    },
    onRejected,
  );
