import React, { ReactNode, useCallback, useState } from 'react';
import { cx } from '@linaria/core';
import { Button } from 'components/ui_library/Button';
import { IconCloseRound } from 'components/Icons';
import { styles } from './ModelSelectorModalContainer.styles';
import { CustomModelIcon, HuggingFaceLogo, ImportModelIcon, PreTrainedModelIcon, UploadModelIcon } from './icons';

export interface IListOption {
  id: string;
  icon: ReactNode;
  title: ReactNode | string;
  description: string;
  isDisable?: boolean;
  dataTestId?: string;
}

type Props = {
  title: string;
  options: Array<IListOption>;
  onCancelClick: () => void;
  onContinueClick: (option: IListOption) => void;
};

export const MODELS = {
  PRE_TRAINED: 'PRE_TRAINED',
  CUSTOM: 'CUSTOM',
  IMPORT: 'IMPORT',
  UPLOAD: 'UPLOAD',
};

export const MODEL_OPTIONS = [
  {
    id: MODELS.PRE_TRAINED,
    icon: <PreTrainedModelIcon width="100%" height="100%" />,
    title: 'Pre-Trained Model (Community)',
    description: 'Explore and find pre-trained models for your use cases',
  },
  {
    id: MODELS.CUSTOM,
    icon: <CustomModelIcon width="100%" height="100%" />,
    title: 'Build a Custom Model',
    description: 'Custom train models from transfer-learn to fine-tune, or create rule-based operators to chain models into workflows.',
  },
  {
    id: MODELS.IMPORT,
    icon: <ImportModelIcon width="100%" height="100%" />,
    title: (
      <div>
        Import model from
        <HuggingFaceLogo />
      </div>
    ),
    description: 'One-click import of NLP, CV, Audio and Multi-modal tasks from Huggingface.',
  },
  {
    id: MODELS.UPLOAD,
    icon: <UploadModelIcon width="100%" height="100%" />,
    title: <>Upload a Model</>,
    description: 'Upload locally trained models to our platform as triton models supporting common formats such as Tensorflow, Pytorch, ONNX.',
  },
];

export const ModelSelectorModalContainer: React.FC<Props> = ({ title, options, onCancelClick, onContinueClick }) => {
  const [selectedOption, setSelectedOption] = useState<IListOption>(options[0]);

  const onOptionClick = useCallback(
    (option) => {
      if (window.innerWidth <= 720) {
        onContinueClick(option);
      }
      setSelectedOption(option);
    },
    [onContinueClick],
  );

  return (
    <div className={styles}>
      <div className="modal-title">
        {title}
        <Button
          variant="tertiary"
          onClick={() => {
            onCancelClick();
          }}
        >
          <IconCloseRound size={24} />
        </Button>
      </div>
      {options.map((option: IListOption) => {
        return (
          <Button
            disabled={selectedOption.isDisable}
            key={option.id}
            variant="unstyled"
            className={cx('option-card', selectedOption.id === option.id && !option.isDisable && 'is-selected', option.isDisable && 'disabled-item ')}
            onClick={() => onOptionClick(option)}
            data-testid={option.id}
            aria-disabled={option.isDisable}
          >
            <div className="option-image">{option.icon}</div>
            <div className="option-title">{option.title}</div>
            <div className="option-description">{option.description}</div>
          </Button>
        );
      })}
      <div className="action-menu">
        <Button variant="tertiary" onClick={onCancelClick} data-testid="cancel">
          Cancel
        </Button>
        <Button data-testid="continue" disabled={selectedOption.isDisable} variant="primary" onClick={() => onContinueClick(selectedOption)}>
          Continue
        </Button>
      </div>
    </div>
  );
};
