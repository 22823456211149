import { SelectAppTemplate } from 'modules/Apps/components/AppTemplates/SelectAppTemplate';

interface AppTemplatesViewProps extends React.HTMLAttributes<HTMLElement> {
  onAppSelected: ({ appId, userOrOrgId }: { appId: string; userOrOrgId: string }) => void;
}

export const AppTemplatesView: React.FC<AppTemplatesViewProps> = ({ onAppSelected }) => {
  return (
    <>
      <SelectAppTemplate
        onSelectApp={({ appId, userId }: { userId: string; appId: string }) => {
          onAppSelected({ appId, userOrOrgId: userId });
        }}
      />
    </>
  );
};
