import { loader } from './Button.styles';

export const ButtonLoader = (): JSX.Element => (
  <div className={loader} data-testid="loading">
    <div />
    <div />
    <div />
    <div />
  </div>
);
