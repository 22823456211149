import React, { forwardRef, useState } from 'react';
import { cx } from '@linaria/core';
import { Button } from 'components/deprecated/Button';
import { IconEye, IconEyeOff } from 'components/Icons';
import { InputProps as InputPropsReactkit } from 'reakit/Input';
import { Input } from 'components/Input';
import { passwordInputWrapper } from './PasswordInput.styles';

type ExtraInputProps = InputPropsReactkit & {
  variant?: 'invalid';
};

export const PasswordInput = forwardRef<HTMLInputElement, ExtraInputProps>(({ className, variant, ...rest }, ref) => {
  const [passwordState, setPasswordState] = useState<'visible' | 'invisible'>('invisible');

  return (
    <>
      <div className={cx(passwordInputWrapper, className, variant === 'invalid' && 'invalid')}>
        <Input className="password-input" type={passwordState === 'invisible' ? 'password' : 'text'} ref={ref} {...rest} />
        <Button
          type="button"
          kind="translucent"
          onClick={() => setPasswordState(passwordState === 'visible' ? 'invisible' : 'visible')}
          title={passwordState === 'visible' ? 'Hide password' : 'Show password'}
          data-testid="btn-show-hide-password"
        >
          {passwordState === 'visible' ? (
            <IconEye size={16} color="#808080" data-testid="icon-eye" />
          ) : (
            <IconEyeOff size={16} color="#808080" data-testid="icon-eye-off" />
          )}
        </Button>
      </div>
    </>
  );
});
