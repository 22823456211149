import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const TextTokenClassifierIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.20838 19.6465C10.6121 19.6465 13.3714 16.8872 13.3714 13.4835C13.3714 10.0797 10.6121 7.32043 7.20838 7.32043C3.80463 7.32043 1.04535 10.0797 1.04535 13.4835C1.04535 16.8872 3.80463 19.6465 7.20838 19.6465Z"
      fill="none"
      stroke={color}
      strokeWidth="0.469565"
    />
    <path
      d="M11.2803 10.7411C12.3383 10.7411 13.1961 9.88338 13.1961 8.82532C13.1961 7.76727 12.3383 6.90955 11.2803 6.90955C10.2222 6.90955 9.3645 7.76727 9.3645 8.82532C9.3645 9.88338 10.2222 10.7411 11.2803 10.7411Z"
      fill={color}
    />
    <path
      d="M5.71933 15.9093H4.32404L6.45004 9.75079H8.12799L10.251 15.9093H8.85571L7.31309 11.1581H7.26496L5.71933 15.9093ZM5.63213 13.4886H8.92788V14.505H5.63213V13.4886Z"
      fill={color}
    />
    <path
      d="M28 19.3644L23.6175 21.8948V16.8342L28 19.3644ZM20.5357 19.3644C20.5357 20.6554 19.4892 21.7019 18.1982 21.7019C16.9074 21.7019 15.8609 20.6554 15.8609 19.3644C15.8609 18.0736 16.9074 17.0271 18.1982 17.0271C19.4892 17.0271 20.5357 18.0736 20.5357 19.3644ZM24.0557 19.8028H18.1982V18.9262H24.0557V19.8028Z"
      fill={color}
    />
    <path
      d="M28 13.4834L23.6175 16.0138V10.9532L28 13.4834ZM20.5357 13.4834C20.5357 14.7744 19.4892 15.8208 18.1982 15.8208C16.9074 15.8208 15.8609 14.7744 15.8609 13.4834C15.8609 12.1926 16.9074 11.1461 18.1982 11.1461C19.4892 11.1461 20.5357 12.1925 20.5357 13.4834ZM24.0557 13.9217H18.1982V13.0452H24.0557V13.9217Z"
      fill={color}
    />
    <path
      d="M28 7.60242L23.6175 10.1327V5.07211L28 7.60242ZM20.5357 7.60242C20.5357 8.89333 19.4892 9.93981 18.1982 9.93981C16.9074 9.93981 15.8609 8.89333 15.8609 7.60242C15.8609 6.31152 16.9074 5.26503 18.1982 5.26503C19.4892 5.26503 20.5357 6.31152 20.5357 7.60242ZM24.0557 8.04067H18.1982V7.16415H24.0557V8.04067Z"
      fill={color}
    />
  </svg>
);
