import { ColumnFiltersState, Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export const MIN_ROW_HEIGHT_PX = 47;
export const MAX_ROW_HEIGHT_PX = 65;
export const MIN_ROW_HEIGHT_PX_MOBILE = 40;

export const getFiltersWithHeaderName = (
  filters: ColumnFiltersState,
  columnsHeaderMapping: Record<string, string>,
): { id: string; value: string; headerName: string }[] => {
  return filters.map((filter) => {
    return { ...filter, value: filter.value as string, headerName: columnsHeaderMapping[filter.id] ?? filter.id };
  });
};

export const getCellStyles = <D extends unknown>(column: Column<D, unknown>): CSSProperties => {
  const isStickyLeft = column.columnDef.meta?.sticky === 'left' ? { left: 0 } : {};
  const isStickyRight = column.columnDef.meta?.sticky === 'right' ? { right: 0 } : {};
  return { width: column.getSize(), ...isStickyLeft, ...isStickyRight };
};
