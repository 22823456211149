import { cx } from '@linaria/core';
import { noRecord } from './NoRecords.styles';

type Props = {
  noRecordsText?: string;
  layoutType?: string;
};
export const NoRecords = ({ noRecordsText = 'No records found', layoutType }: Props): JSX.Element => {
  return (
    <div className={cx(noRecord)} data-testid="no-record-table" data-layout-type={layoutType}>
      <span>{noRecordsText}</span>
    </div>
  );
};
