/* eslint-disable @typescript-eslint/no-explicit-any */
import { cx } from '@linaria/core';
import { TableBody, TableCell } from '@mui/material';
import { Row, RowData, Table } from '@tanstack/react-table';
import { forwardRef } from 'react';
import { Spinner } from 'components/Spinner';
import { NoRecords } from './NoRecord/NoRecords';
import { VirtualisedTableRow } from './TableRows';
import { LAYOUT_CELLS_TYPE, layoutResolver } from './LayoutCells/LayoutResolver';

interface Props<D extends Record<never, never>> {
  table: Table<D>;
  noRecordsText: string;
  infinite?: { pages: D[][]; hasNextPage?: boolean; isFetching: boolean; fetchNextPage: () => void; height?: number };
  isMobileView: boolean;
  layoutType?: LAYOUT_CELLS_TYPE;
  isLoading?: boolean;
  rowHeight: number;
}

export const TableContent = forwardRef<HTMLDivElement, Props<Record<never, never>>>(
  ({ table, noRecordsText, infinite, isMobileView, layoutType, isLoading, rowHeight }: Props<Record<never, never>>, ref) => {
    const rows = table.getRowModel()?.rows as Row<RowData>[];
    const { Cell } = layoutResolver(layoutType, isMobileView);

    const renderContent = () => {
      return rows.length === 0 ? (
        <TableCell className={cx('norecord-cell')} colSpan={table.getVisibleLeafColumns().length}>
          <NoRecords noRecordsText={noRecordsText} />{' '}
        </TableCell>
      ) : (
        <>
          <VirtualisedTableRow
            rowHeight={rowHeight}
            isMobileView={isMobileView}
            Cell={Cell as any}
            infinite={infinite}
            state={table.getState()}
            rows={rows}
            ref={ref}
          />
        </>
      );
    };

    return (
      <TableBody data-testid="table-body">
        {isLoading ? (
          <TableCell className={cx('loading-cell')} colSpan={table.getVisibleLeafColumns().length}>
            <div className="loading-spinner">
              <Spinner />
            </div>
          </TableCell>
        ) : (
          renderContent()
        )}
      </TableBody>
    );
  },
);
