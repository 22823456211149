import type { Either } from 'fp-ts/lib/Either';
import { either } from 'fp-ts';
import { pick } from 'rambda';
import { LoginPayload, postLoginTE, postSignupTE } from 'api/users';
import { appStorage } from 'utils/appStorage';
import { pipe } from 'fp-ts/lib/function';
import { errorToReactLeft } from 'utils/fp';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { SignupPayload } from 'api/users/signup';
import { postTotpLoginTE, TotpLoginParams } from 'api/users/twoFactorAuthentication';
import { COOKIE_KEYS, cookieClientStorage } from 'utils/cookieStorage';

const pickedLocalUserKeys = [
  'first_name',
  'last_name',
  'session_token',
  'email',
  'user_id',
  'state',
  'two_factor_auth_enabled',
  'visibility',
  'email_addresses',
];

export async function signup(values: SignupPayload): Promise<Either<ReactLeft, CF.LocalUser>> {
  return pipe(await postSignupTE(values, errorToReactLeft)(), either.map(pick(pickedLocalUserKeys)));
}

export async function login(values: LoginPayload): Promise<Either<ReactLeft, CF.LocalUser>> {
  return pipe(await postLoginTE(values, errorToReactLeft)(), either.map(pick(pickedLocalUserKeys)));
}

export async function twoFactorLogin(values: TotpLoginParams): Promise<Either<ReactLeft, CF.LocalUser>> {
  return pipe(await postTotpLoginTE(values, errorToReactLeft)(), either.map(pick(pickedLocalUserKeys)));
}

function removeAuthData(): void {
  // adding a double call because right now it's possible to have two different domain cookies with same name; long story around SSO
  cookieClientStorage.rm('session_token', { domain: '.clarifai.com' });

  // clears any other cookie like localhost, deconv, etc
  cookieClientStorage.rm('session_token');
  appStorage.rm('authData');
}

function setAuthDataAndCookies(localUser: CF.LocalUser): void {
  if (localUser.session_token) {
    cookieClientStorage.set(COOKIE_KEYS.sessionToken, localUser.session_token);
    appStorage.set('authData', localUser);
  }
}

function updateStorage(data: either.Either<unknown, CF.LocalUser>): void {
  pipe(data, either.fold(removeAuthData, setAuthDataAndCookies));
}

export async function signupAndSaveToStorage(values: SignupPayload): Promise<either.Either<ReactLeft, CF.LocalUser>> {
  const dataE = await signup(values);
  updateStorage(dataE);
  return dataE;
}

export async function loginAndSaveToStorage(values: LoginPayload): Promise<either.Either<ReactLeft, CF.LocalUser>> {
  const dataE = await login(values);
  updateStorage(dataE);
  return dataE;
}

export async function loginWithTwoFactorAndSaveToStorage(values: TotpLoginParams): Promise<either.Either<ReactLeft, CF.LocalUser>> {
  const dataE = await twoFactorLogin(values);
  updateStorage(dataE);
  return dataE;
}

export function logoutLocal(): void {
  removeAuthData();
  const authData: CF.LocalUser = appStorage.get('authData');
  // TODO: implement user waiting in a better less colocated way
  appStorage.rm(`${authData?.user_id}_awaiting_v2`);
}

export const testable = {
  logoutLocal,
  login,
  loginAndSaveToStorage,
  twoFactorLogin,
};
