import { badgeWrapper } from './TemplateBadge.style';

type TemplateProps = {
  name?: string;
};

export const TemplateBadge = ({ name = 'Template' }: TemplateProps) => {
  return (
    <div className={badgeWrapper}>
      <span>{name}</span>
    </div>
  );
};
