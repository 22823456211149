import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { listStarredAppsTE } from 'api/apps';
import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import {
  noAppsTitle,
  noAppsTitleOwner,
  noAppsSubtitle,
  noAppsSubtitleOwner,
  noAppsSearchTitle,
  noAppsSearchSubtitle,
} from 'components/NoAppState/NoAppState.constants';
import { types } from 'mst-effect';
import { makeSearchable } from 'modules/Listings/store/models/Searchable';
import { createListable } from 'modules/Listings/store/models/Listable';
import { makeSortable } from 'modules/Listings/store/models/Sortable';
import { makeUISettings } from 'modules/Listings/store/models/UiSettings';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import type { QueryClient } from 'react-query';
import { errorToReactLeft } from 'utils/fp';
import { NoSearchState } from 'components/NoAppState/NoSearchState';
import { NoResourceState } from 'components/NoAppState/NoResourceState';
import { NoAppCTA } from 'components/NoAppState/NoAppCTA';
import { defineNotFilterable } from 'modules/Listings/store/models/Filterable';
import { cx } from '@linaria/core';
import { noAppSubText } from 'components/NoAppState/NoAppState.styles';
import { textRegularLight } from 'styles/typography';
import { baseTheme } from 'styles/utils';
import { IconBox } from 'components/Icons';
import { NoStarredTemplateResource } from 'components/NoAppState/NoStarredResource';
import { FILTERS, FILTER_PARAMS } from 'modules/Listings/components/AllStarTemplateToggle';
import { appSortCriteria } from './sortCriteria';
import { appMST } from './mst-types';

export const makeFetchApps = (userId?: string) =>
  function fetchAppsTE(params: string): TaskEither<ReactLeft, CF.API.Apps.App[]> {
    return pipe(
      listStarredAppsTE({ userId, params }, errorToReactLeft),
      taskEither.map((x) => x.apps),
    );
  };

export const getEmptyState = (searchTermValue?: string, defaultParams?: string): (() => JSX.Element) => {
  return (): JSX.Element => {
    const isStarredOnly = defaultParams?.includes('&starred_only=true');
    const isTemplateOnly = defaultParams?.includes('&template_only=true');
    return isStarredOnly || isTemplateOnly ? (
      <>
        {isStarredOnly && <NoStarredTemplateResource title="No starred apps" subtitle="Star any app to see it here" />}
        {isTemplateOnly && (
          <NoStarredTemplateResource
            title="There are no templates yet."
            subtitle="Mark an App as a template and use it to create new Apps fast."
            isTemplate
          />
        )}
      </>
    ) : searchTermValue ? (
      <NoSearchState title={`${noAppsSearchTitle} "${searchTermValue}"`} subtitle={noAppsSearchSubtitle} />
    ) : (
      <NoResourceState
        isCtaVisible
        title={(isOwner) => (isOwner ? noAppsTitleOwner : noAppsTitle)}
        subtitle={(isOwner) => <h4 className={cx(textRegularLight, noAppSubText)}>{isOwner ? noAppsSubtitleOwner : noAppsSubtitle}</h4>}
        icon={<IconBox size={24} color={baseTheme.light.colors.blue} />}
        cta={<NoAppCTA />}
      />
    );
  };
};

export const makeAppsUrl = ({ entityId, userId }: Record<string, string>): string => `/${userId}/${entityId}/`;

// inference works better than explicit type definition for MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createAppsList = ({
  queryClient,
  userIdFromRouter,
  initialQuery,
  initialList,
  activeToggle,
}: {
  queryClient: QueryClient;
  userIdFromRouter?: string;
  initialQuery?: Record<string, string>;
  initialList?: CF.API.Apps.App[];
  activeToggle?: string;
}) => {
  const AppsList = types
    .compose(
      createListable({
        fetcher: makeFetchApps(userIdFromRouter),
        entityMstModel: appMST,
        listQueryKey: ['apps', { userIdFromRouter }],
        initialQuery,
      }),
      makeSearchable(initialQuery),
      makeSortable(initialQuery),
      makeUISettings(initialQuery),
      defineNotFilterable(),
    )
    .volatile((self) => ({
      sortCriteria: appSortCriteria,
      makeUrl: makeAppsUrl,
      getIsEntityOwner,
      getUiStateMap: () => ({ [UI_STATES.empty]: getEmptyState(self.searchTermValue, self.defaultParams) }),
    }))
    .named('AppsList');

  const defaultParams = !activeToggle
    ? FILTER_PARAMS.all
    : FILTERS.find((filter) => filter.name.toLowerCase() === activeToggle?.toLowerCase())?.filterParams || FILTER_PARAMS.all;

  const instance = AppsList.create(
    {
      _listItems: initialList || [],
      defaultParams,
    },
    { sortCriteria: appSortCriteria, queryClient },
  );
  return instance;
};

export const testable = {
  makeFetchApps,
};
