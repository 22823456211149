import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchApi, fetchTE } from 'utils/request';

export const duplicateApp = <E = CF.API.ApiError>(
  {
    userId,
    appId,
    body,
  }: {
    userId?: string;
    appId?: string;
    params?: string;
    body: CF.API.Apps.DuplicateAppBody;
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.duplicateMockApp> =>
  fetchTE<CF.API.Apps.duplicateMockApp, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/duplications`,
      method: 'POST',
      body,
    },
    onRejected,
  );

export const apiGetDuplicateApp = ({ userId, duplicateAppId }: { userId: string; duplicateAppId: string }): Promise<CF.API.Apps.IGetDuplicateApp> =>
  fetchApi({
    path: `/api/_api/v2/users/${userId}/duplications/${duplicateAppId}`,
    method: 'GET',
  });
