import { Checkbox as ReakitCheckbox, CheckboxHTMLProps } from 'reakit/Checkbox';
import { cx } from '@linaria/core';
import { forwardRef } from 'react';
import { textSmall } from 'styles/typography';
import { checkboxWrapper, checkboxDefault, checkboxLabel } from './Checkbox_styles';

export interface CheckboxProps extends Omit<CheckboxHTMLProps, 'form'> {
  value?: string;
  state?: boolean | 'indeterminate' | (string | number)[];
  labelClassName?: string;
  labelTextClassName?: string;
  checkboxClassName?: string;
}

export const Checkbox: React.FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, value, className, style, labelClassName, labelTextClassName, checkboxClassName, ...rest }, forwardedRef) => {
    return (
      <div className={cx(checkboxWrapper, className, rest.disabled ? 'disabled' : '')} style={style}>
        <label className={cx(checkboxLabel, textSmall, labelClassName)}>
          <ReakitCheckbox {...rest} value={value} className={cx(checkboxDefault, checkboxClassName)} ref={forwardedRef} />
          <CheckMark />
          <span className={labelTextClassName}>{children}</span>
        </label>
      </div>
    );
  },
);

function CheckMark(): JSX.Element {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6663 1.5L4.24967 7.91667L1.33301 5" stroke="#195AFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
