import { Alert } from 'components/Alert';
import { either } from 'fp-ts';
import React from 'react';
import { pipe } from './fp';
import { ReactLeft, UI_STATES } from './uiStates/uiStates';

const defaultMakeContent = (left: ReactLeft): React.ReactNode => (
  <Alert type="error">
    {left.props?.title || 'Error'}
    {': '}
    {left.props?.reason || 'Something went wrong.'}
  </Alert>
);

export function renderLeftError<L extends ReactLeft, R>(
  eth: either.Either<L, R>,
  makeContent: (l: ReactLeft) => React.ReactNode = defaultMakeContent,
): React.ReactNode {
  if (!eth) return null;
  return pipe(
    eth,
    either.fold(
      (left) => (left.type === UI_STATES.error ? makeContent(left) : null),
      () => null,
    ),
  ) as React.ReactNode;
}

export const useAsyncTE_testable = {
  defaultMakeContent,
};
