import { types } from 'mst-effect';
import Router from 'next/router';

const STATES = ['grid', 'list'];

// type inference is more powerful in case of MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeUISettings(initialQuery: Record<string, string> = {}, params?: { disableRouterUpdates: boolean }) {
  const initialViewMode = initialQuery.viewMode && STATES.includes(initialQuery.viewMode) ? (initialQuery.viewMode as 'grid' | 'list') : 'list';

  return types
    .model({
      viewMode: types.optional(types.union(types.literal('grid'), types.literal('list')), initialViewMode),
      showFilter: true,
    })
    .actions((self) => {
      return {
        toggleViewModel() {
          self.viewMode = STATES[(STATES.indexOf(self.viewMode) + 1) % STATES.length] as 'grid' | 'list';
          const { router } = Router;
          if (!params?.disableRouterUpdates && router) {
            router.push(
              {
                query: {
                  ...router.query,
                  viewMode: self.viewMode,
                },
              },
              undefined,
              { shallow: true },
            );
          }
        },
        toggleShowFilter() {
          self.showFilter = !self.showFilter;
        },
        setShowFilter(value: boolean) {
          self.showFilter = value;
        },
        setViewMode(value?: 'list' | 'grid') {
          self.viewMode = value || initialViewMode;
        },
      };
    });
}

export type UISettingsType = ReturnType<typeof makeUISettings>;
