import { observer } from 'mobx-react-lite';
import { container, userContainer, userProfile } from './AppTitle.styles';
import { EditableTitle } from './EditableTitle';

export const AppTitle: React.FC<{ app: CF.API.Apps.App; editableEntity: CF.UI.EditableEntity }> = observer(({ app, editableEntity }): JSX.Element => {
  return (
    <div className={container}>
      <div className="flex-min-width">
        <EditableTitle editableEntity={editableEntity} entityName="app" />
      </div>
      <div className={userContainer}>
        <div className={userProfile}>{app.user_id[0].toUpperCase()}</div>
        {app.user_id}
      </div>
    </div>
  );
});
