import { cx, css } from '@linaria/core';
import { ellipsis } from 'polished';
import { theming, flex } from 'styles/utils';
import { accountPopover } from 'components/Navbar/styles';
export const submenuIcon = "odules__User__components__Sidebar__Sidebar_styles_ts-submenuIcon-s1uir7nn";
export const sidebarPadding = "odules__User__components__Sidebar__Sidebar_styles_ts-sidebarPadding-s1l92f17";
export const removeFlexGap = "odules__User__components__Sidebar__Sidebar_styles_ts-removeFlexGap-r1q0r5og";
export const sidebarBorder = "odules__User__components__Sidebar__Sidebar_styles_ts-sidebarBorder-s1506mvq";
export const userArea = cx("odules__User__components__Sidebar__Sidebar_styles_ts-userArea-uu81hae", flex.init, flex.alignItems.center);
export const accountSwitchPopover = cx("odules__User__components__Sidebar__Sidebar_styles_ts-accountSwitchPopover-a47idxf", accountPopover);
export const accountSwitchItem = cx("odules__User__components__Sidebar__Sidebar_styles_ts-accountSwitchItem-a1gopwtm", flex.init, flex.alignItems.center);
export const accountSwitchAvatar = "odules__User__components__Sidebar__Sidebar_styles_ts-accountSwitchAvatar-a1y2e86i";

require("./Sidebar.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Sidebar.styles.ts");