import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const createIdentityProviderTE = <E = CF.API.ApiError>(
  { orgId, identityProviderBody }: { orgId: string; identityProviderBody: CF.API.Organizations.IdentityProviderBody },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListIdentityProvidersResponse> =>
  fetchTE<CF.API.Organizations.ListIdentityProvidersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/identity_providers`,
      method: 'POST',
      body: {
        identity_providers: [identityProviderBody],
      },
    },
    onRejected,
  );
