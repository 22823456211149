import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualLandmarksIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.25 5.625H5.48715C4.52775 5.625 3.75 6.40275 3.75 7.36215V11.875" stroke={color} strokeWidth="1.875" />
    <path d="M3.75 18.125V22.6378C3.75 23.5972 4.52775 24.375 5.48715 24.375H11.25" stroke={color} strokeWidth="1.875" />
    <path d="M18.75 5.625H24.5128C25.4722 5.625 26.25 6.40275 26.25 7.36215V11.875" stroke={color} strokeWidth="1.875" />
    <path d="M18.75 24.375H24.5128C25.4722 24.375 26.25 23.5972 26.25 22.6378V18.125" stroke={color} strokeWidth="1.875" />
    <path
      d="M15 13.7812C16.6051 13.7812 17.9063 12.4801 17.9063 10.875C17.9063 9.26992 16.6051 7.96875 15 7.96875C13.395 7.96875 12.0938 9.26992 12.0938 10.875C12.0938 12.4801 13.395 13.7812 15 13.7812Z"
      stroke={color}
      strokeWidth="0.9375"
    />
    <path
      d="M19.868 19.0659C16.1226 12.0887 12.2498 15.4513 10.1554 19.086C9.7495 19.7904 10.2888 20.625 11.1018 20.625H18.8981C19.7111 20.625 20.2526 19.7822 19.868 19.0659Z"
      stroke={color}
      strokeWidth="0.9375"
    />
  </svg>
);
