import { Col, Row } from 'components/Grid';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { fetchAppResourceCountsTE } from 'api/apps/fetchAppResourceCounts';
import { either, errorToReactLeft, pipe } from 'utils/fp';
import { useHasScopes } from 'modules/Auth/hooks/scopes';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { AppResourceCard } from './AppResourceCard';
import { container } from './AppResources.styles';
import { useGetDuplicateAppStatus } from '../../DuplicateApplication/DuplicateApplication.hooks';

enum AppResourceType {
  Datasets = 'Datasets',
  Models = 'Models',
  Workflows = 'Workflows',
  Modules = 'Modules',
  Inputs = 'Inputs',
}

type AppResourcesProps = {
  userOrOrgId: string;
  appId: string;
  isAppTemplate?: boolean;
  app: CF.API.Apps.App;
};

export const AppResources: React.FC<AppResourcesProps> = ({ appId, userOrOrgId, isAppTemplate, app }) => {
  const { data, refetch: refetchAppResources } = useQueryTE(
    [queryKeys.AppResources, { appId, userOrOrgId }],
    fetchAppResourceCountsTE({ appId, userId: userOrOrgId }, errorToReactLeft),
  );
  const currentUserId = useCurrentUserId();

  useGetDuplicateAppStatus({
    userId: userOrOrgId,
    appId,
    onCompletion: refetchAppResources,
  });

  const canAddModel = useHasScopes([permissionMatchers.model.add]);
  const canAddWorkflow = useHasScopes([permissionMatchers.workflow.add]);
  const canAddDataset = useHasScopes([permissionMatchers.datasets.add]);
  const canAddModule = useHasScopes([permissionMatchers.modules.add]);
  const canAddInput = useHasScopes([permissionMatchers.inputs.add]);

  const colWidth = currentUserId ? 2.4 : 3;

  return (
    <Row width="100%" spacing={2} mt="1.5rem" mb="2.5rem" className={container}>
      {pipe(
        data,
        either.fold(
          reactLeftToJSX({
            ERROR: () => null,
          }),
          ({ inputs, datasets, models, modules, workflows }) => (
            <>
              {currentUserId && (
                <Col xs={12} sm={6} lg={colWidth}>
                  <AppResourceCard
                    type={AppResourceType.Inputs}
                    count={inputs}
                    isEditable={canAddInput}
                    hideResourcesLink={isAppTemplate}
                    app={app}
                  />
                </Col>
              )}
              <Col xs={12} sm={6} lg={colWidth}>
                <AppResourceCard
                  type={AppResourceType.Datasets}
                  count={datasets}
                  isEditable={canAddDataset}
                  hideResourcesLink={isAppTemplate ? Number(datasets ?? 0) === 0 : false}
                  isAppTemplate={isAppTemplate}
                  app={app}
                />
              </Col>
              <Col xs={12} sm={6} lg={colWidth}>
                <AppResourceCard
                  type={AppResourceType.Models}
                  count={models}
                  isEditable={canAddModel}
                  hideResourcesLink={isAppTemplate ? Number(models ?? 0) === 0 : false}
                  isAppTemplate={isAppTemplate}
                  app={app}
                />
              </Col>
              <Col xs={12} sm={6} lg={colWidth}>
                <AppResourceCard
                  type={AppResourceType.Workflows}
                  count={workflows}
                  isEditable={canAddWorkflow}
                  hideResourcesLink={isAppTemplate ? Number(workflows ?? 0) === 0 : false}
                  isAppTemplate={isAppTemplate}
                  app={app}
                />
              </Col>
              <Col xs={12} sm={6} lg={colWidth}>
                <AppResourceCard
                  type={AppResourceType.Modules}
                  count={modules}
                  isEditable={canAddModule}
                  hideResourcesLink={isAppTemplate ? Number(modules ?? 0) === 0 : false}
                  isAppTemplate={isAppTemplate}
                  app={app}
                />
              </Col>
            </>
          ),
        ),
      )}
    </Row>
  );
};
