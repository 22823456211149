import { capitalize } from '@mui/material';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { applySnapshot, IAnyType, Instance, isStateTreeNode } from 'mobx-state-tree';
import { TEditableAppMST } from 'modules/Apps/editableAppMst';
import { TEditableWorkflowMST } from 'modules/Workflows/editableWorkflowMst';
import { AnyInstance } from 'mst-effect';
import { pipe } from 'utils/fp';
import { updateMST } from 'utils/withRunInAction';

export function applyEdits(
  entityName: 'workflow' | 'app' | 'dataset' | 'module',
  // todo: make this file type safe
  self: Instance<IAnyType>,
  // https://clarifai.atlassian.net/browse/MRK-1375
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastSaved: any,
  // https://clarifai.atlassian.net/browse/MRK-1375
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLastSaved: (a?: any) => any,
): (
  // https://clarifai.atlassian.net/browse/MRK-1375
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityE: either.Either<unknown, any>,
) => TEditableWorkflowMST['data'] | TEditableAppMST['data'] {
  return (entityE) => {
    return pipe(
      entityE,
      either.fold(
        () => {
          updateMST(self, () => {
            applySnapshot(self.data, lastSaved);
          });

          cogoToast.error(`Could not save ${entityName} details.`);
          return self.data;
        },
        /* istanbul ignore next: branch coverage not meeting due to type-narrowing if blocks */
        (response) => {
          /* just adding if blocks for typescript type narrowing */
          if ('app' in response) {
            updateMST(self, () => {
              applySnapshot(self.data, setLastSaved(response.app));
            });
          } else if ('workflows' in response) {
            /* just adding if blocks for typescript type narrowing */

            updateMST(self, () => {
              applySnapshot(self.data, setLastSaved(response.workflows[0]));
            });
          }
          // TODO: enable when editableModelMST is developed
          /* just adding if blocks for typescript type narrowing */
          else if ('models' in response) {
            updateMST(self, () => {
              applySnapshot(self.data, setLastSaved(response.models[0]));
            });
          }
          cogoToast.success(`${capitalize(entityName)} Updated.`);
          return self.data;
        },
      ),
    );
  };
}

export function setEditableEntityValue(self: AnyInstance, key: string, value: unknown): void {
  /* istanbul ignore if: currently no child model */
  if (isStateTreeNode(self[key])) {
    applySnapshot(self.data[key], value);
  } else {
    self.data[key] = value;
  }
}
