import { cx, css } from '@linaria/core';
import { baseUnits, theming, breakpoints } from 'styles/utils';
export const createApplicationWrapper = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-createApplicationWrapper-cd91zu4";
export const dropdownWrapper = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-dropdownWrapper-dunsloa";
export const selectMenuWrapper = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-selectMenuWrapper-s14g41xy";
export const heading = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-heading-hbci407";
export const subheading = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-subheading-s177lsz5";
export const closeIcon = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-closeIcon-c1a26sd2";
export const alertText = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-alertText-a1bz0cnw";
export const consentCheckboxWrapper = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-consentCheckboxWrapper-chpo8hz";
export const consentCheckbox = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-consentCheckbox-c14u0x76";
export const checkboxLabelWrapper = cx("odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-checkboxLabelWrapper-c37775c", alertText);
export const overrideInputField = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-overrideInputField-oettmp8";
export const createAppGormGroup = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-createAppGormGroup-co1eo26";
export const createAppGormGroupDisabled = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-createAppGormGroupDisabled-cydaw6c";
export const advancedSwitch = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-advancedSwitch-a1r8nkfb";
export const advancedSettings = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-advancedSettings-a3kumr7";
export const inputField = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-inputField-ia9ur7n";
export const fieldDesc = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-fieldDesc-f15lqzdf";
export const fieldTopMargin = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-fieldTopMargin-fz3feac";
export const fieldBottomMargin = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-fieldBottomMargin-fvorj7j";
export const height100 = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-height100-hcktg98";
export const height0 = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-height0-h1hgn4o2";
export const container = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-container-c1xl044q";
export const secondHeaderDiv = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-secondHeaderDiv-s1izhb62";
export const mobilePadding = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-mobilePadding-m1o18jww";
export const modalStyle = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-modalStyle-mgraoo0";
export const stepsContainer200proc = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-stepsContainer200proc-s1ghy9fk";
export const stepColumnContainer = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-stepColumnContainer-s1urnme9";
export const displayOnMobile = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-displayOnMobile-dzkyxxo";
export const selectedItem = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-selectedItem-sjsrjpg";
export const itemContainer = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-itemContainer-ikzbfyi";
export const backToFirstStepButton = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-backToFirstStepButton-b1bqufgb";
export const formContainer = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-formContainer-f1idce6y";
export const defaultRadioButton = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-defaultRadioButton-d19dg0x";
export const activeRadioButton = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-activeRadioButton-atyvgpy";
export const inputTypeRadioContainer = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-inputTypeRadioContainer-iyuwr05";
export const radioLabel = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-radioLabel-r15wo052";
export const recommendWorkflow = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-recommendWorkflow-r1e2e205";
export const radioButtonMessageLabel = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-radioButtonMessageLabel-r7neap9";
export const disableRadioButton = "odules__Apps__components__CreateApplication__AppTypeSelector_styles_ts-disableRadioButton-d17b15r4";

require("./AppTypeSelector.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./AppTypeSelector.styles.ts");