import { fetchTaskTE } from 'api/tasks/fetchTask';
import { fetchAppTE } from 'api/apps/fetchApp';
import { fetchAppMembersTE, fetchCollaboratorsTE } from 'api/collaborator/fetchCollaborators';
import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { errorToReactLeft } from 'utils/fp';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { listTaskAssignmentsTE } from 'api/assignments/listTaskAssignments';

export const taskPageApiEffects = {
  fetchTaskTE: (appId: string, userId: string, taskId: string): TaskEither<ReactLeft, CF.API.LabelerTask.Task> =>
    pipe(
      fetchTaskTE({ appId, userId, taskId }, errorToReactLeft),
      taskEither.map((x) => x.task),
    ),
  fetchAppTE: (params: { appId: string; userId?: string; params?: string; sessionToken?: string }): TaskEither<ReactLeft, CF.API.Apps.App> => {
    return pipe(
      fetchAppTE(params, errorToReactLeft),
      taskEither.map((x) => x.app),
    );
  },
  fetchCollaboratorsAndAppOwnerTE: (
    params: CF.API.Collaborators.FetchCollaboratorsRequest,
  ): TaskEither<ReactLeft, CF.API.Collaborators.FetchCollaboratorsResponse> => {
    return pipe(fetchCollaboratorsTE(params, errorToReactLeft));
  },
  listTaskAssignmentsTE: (params: CF.API.ListTaskAssignment.Params): TaskEither<ReactLeft, CF.API.ListTaskAssignment.Response> => {
    return pipe(
      listTaskAssignmentsTE(params, errorToReactLeft),
      taskEither.map((x) => x),
    );
  },
  fetchAppMembersTE: (params: CF.API.Collaborators.FetchAppMembersRequest): TaskEither<ReactLeft, CF.API.Collaborators.FetchAppMembersResponse> => {
    return pipe(fetchAppMembersTE(params, errorToReactLeft));
  },
};
