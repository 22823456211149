import { hasPath } from 'rambda';
import { TSupportedPredictionCategories } from 'modules/Predictions/types';

const supportedRendererTypes = ['text', 'regions', 'concepts', 'colors'];
export function pickModelInfo(
  model: Partial<CF.API.Models.Model> | undefined = {},
  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  typeInfo: { inputField: string; outputField: string } = { inputField: '', outputField: '' },
): {
  modelId: string;
  modelType: TSupportedPredictionCategories;
  appId: string;
  userId: string;
  modelVersionId?: string;
} {
  let type = TSupportedPredictionCategories.Initialising;
  // add more supported model types as featureset expands
  if ((typeInfo.inputField && typeInfo.inputField === 'image') || hasPath('input_info.fields_map.image', model)) {
    type = checkIfOutputTypeCanBeRendered(typeInfo) ? TSupportedPredictionCategories.Image : TSupportedPredictionCategories.ImageJson;
  } else if ((typeInfo.inputField && typeInfo.inputField === 'text') || hasPath('input_info.fields_map.text', model)) {
    type = checkIfOutputTypeCanBeRendered(typeInfo) ? TSupportedPredictionCategories.Text : TSupportedPredictionCategories.TextJson;
  } else if ((typeInfo.inputField && typeInfo.inputField === 'audio') || hasPath('input_info.fields_map.audio', model)) {
    type = checkIfOutputTypeCanBeRendered(typeInfo) ? TSupportedPredictionCategories.Audio : TSupportedPredictionCategories.AudioJson;
  } else if (model.model_type_id === 'embedding-classifier') {
    const base_embed_model_type = model?.model_version?.input_info?.base_embed_model?.model_type_id;
    if (base_embed_model_type === 'text-embedder') {
      type = TSupportedPredictionCategories.Text;
    } else if (base_embed_model_type === 'audio-embedder') {
      type = TSupportedPredictionCategories.Audio;
    } else if (base_embed_model_type === 'visual-embedder') {
      type = TSupportedPredictionCategories.Image;
    } else if (base_embed_model_type === 'multimodal-embedder') {
      type = TSupportedPredictionCategories.Any;
    }
  } else if (model.model_type_id === 'remote-operator') {
    type = TSupportedPredictionCategories.Text;
  } else if (typeInfo.inputField && typeInfo.inputField === 'any') {
    type = TSupportedPredictionCategories.Any;
  } else if (typeInfo.inputField && typeInfo.outputField) {
    type = TSupportedPredictionCategories.Unsupported;
  }

  return {
    userId: model?.model_version?.user_id || model?.user_id || '',
    appId: model?.model_version?.app_id || model?.app_id || '',
    modelId: model?.id || '',
    modelType: type,
    modelVersionId: model?.model_version?.id,
  };
}

function checkIfOutputTypeCanBeRendered(typeInfo: { outputField: string }): boolean {
  return supportedRendererTypes.some((item) => typeInfo.outputField.startsWith(item));
}
