import { cx } from '@linaria/core';
import { Button, IconButton } from 'components/Button';
import { IconPlus, IconQuestion } from 'components/Icons';
import { flex } from 'styles/utils';
import { useRouter } from 'next/router';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { noop } from 'utils/fp';
import { appRelativeHref } from 'modules/Sidebar/SidebarMenuContent';
import { useCallback } from 'react';
import { useModalActions } from 'components/ModalManager/Context';
import { ADD_MODEL_MODAL_ID, AddModelModal } from 'modules/Models/components/ModelsManager/AddModelModal';
import { observer } from 'mobx-react-lite';
import { formatNumber } from 'utils/number';
import { cssHelpers } from 'styles/utils/core';
import { CopyableTooltip } from 'components/Tooltip';
import { contentStyles } from 'components/Tooltip/Tooltip.styles';
import { addButton, appResourceCard, cardTitle, regularFontWeight, resourceCount, displayOnDesktop, displayOnTablet, tooltip } from './AppResources.styles';
import { ResourceModalViewer } from './ResourceModalViewer/ResourceModalViewer';
import { RESOURCE_VIEWER_MODAL_ID, Tabs } from './ResourceModalViewer/constantAndTypes';
export enum AppResourceType {
  Datasets = 'Datasets',
  Models = 'Models',
  Workflows = 'Workflows',
  Modules = 'Modules',
  Inputs = 'Inputs',
}
interface AppResourceCardProps {
  type: AppResourceType;
  count?: number;
  isEditable?: boolean;
  hideResourcesLink?: boolean;
  isAppTemplate?: boolean;
  app: CF.API.Apps.App;
}
interface TabletOrDesktopCardProps extends Partial<AppResourceCardProps> {
  count: number;
  handleAddResource: () => void;
  handleViewOrAddResource: () => void;
}
const viewAppResourceRedirect = {
  [AppResourceType.Datasets]: '/datasets',
  [AppResourceType.Models]: '/models',
  [AppResourceType.Workflows]: '/workflows',
  [AppResourceType.Modules]: '/modules',
  [AppResourceType.Inputs]: '/inputs'
};
const addAppResourceRedirect = {
  [AppResourceType.Datasets]: '/datasets/new',
  [AppResourceType.Workflows]: '/workflows/new',
  [AppResourceType.Modules]: '/installed_module_versions/module_manager_install/create',
  [AppResourceType.Inputs]: '/inputs'
};
export const AppResourceCard: React.FC<AppResourceCardProps> = observer(({
  type,
  count = 0,
  hideResourcesLink,
  isEditable,
  isAppTemplate,
  app
}) => {
  const {
    push,
    query: {
      appId,
      userOrOrgId
    }
  } = useRouter();
  const {
    openLoginModal
  } = useAuthActions();
  const {
    openModal
  } = useModalActions();
  const appHref = appRelativeHref((userOrOrgId as string), (appId as string));
  const {
    authData
  } = useAuthState();
  const isLoggedIn = Boolean(authData);
  const handleAddResource = useCallback(() => {
    if (type === AppResourceType.Models) {
      openModal({
        id: ADD_MODEL_MODAL_ID,
        title: '',
        content: <AddModelModal userOrOrgId={(userOrOrgId as string)} appId={(appId as string)} />,
        makeActions: noop
      });
      return;
    }
    push(appHref(addAppResourceRedirect[type]));
  }, [type, push]);
  const handleViewResource = useCallback(() => {
    push(appHref(viewAppResourceRedirect[type]));
  }, [type, push]);
  const openResourceModal = useCallback(() => {
    openModal({
      id: RESOURCE_VIEWER_MODAL_ID,
      title: '',
      content: <ResourceModalViewer app={app} selectedTab={(type as Tabs)} />,
      makeActions: noop
    });
  }, []);
  const handleViewOrAddResource = useCallback(() => {
    let clbck = handleAddResource;
    if (!isEditable || count > 0) {
      clbck = isAppTemplate ? openResourceModal : handleViewResource;
    }
    clbck();
  }, [count, type, isEditable, openLoginModal, isLoggedIn, handleViewResource, handleAddResource]);
  return <>
      <TabletAppResourceCard isEditable={isEditable && !isAppTemplate} type={type} count={count} handleAddResource={handleAddResource} handleViewOrAddResource={handleViewOrAddResource} hideResourcesLink={hideResourcesLink} />
      <DesktopAppResourceCard isEditable={isEditable && !isAppTemplate} type={type} count={count} handleAddResource={handleAddResource} handleViewOrAddResource={handleViewOrAddResource} hideResourcesLink={hideResourcesLink} />
    </>;
});
const CountTooltip = (tooltipText: string) => {
  return <div data-testid="count-tooltip" className={cx(flex.init, flex.alignItems.center, cssHelpers.margin.yt.half)}>
      <CopyableTooltip data={tooltipText ?? ''} showArrow className={tooltip} muiTooltipProps={{
      placement: 'bottom'
    }}>
        <span className={cx(contentStyles, flex.init, flex.alignItems.center)}>
          <IconQuestion size={16} data-testid="question-icon" />
        </span>
      </CopyableTooltip>
    </div>;
};
const TabletAppResourceCard: React.FC<TabletOrDesktopCardProps> = observer(props => {
  const formattedCount = formatNumber(props?.count ?? 0);
  const isSuffixAdded = formattedCount.includes('K') || formattedCount.includes('M');
  const showInputsAddViewButton = !props.hideResourcesLink && (props.isEditable || props.count > 0);
  return <div className={cx(appResourceCard, displayOnTablet)}>
      <div className={cx(flex.init, flex.alignItems.center, flex.gap.half, flex.flex.one)}>
        <h1 className={resourceCount} data-testid={`resource-count-${props.type}`}>
          {formattedCount}
        </h1>
        {isSuffixAdded && CountTooltip(`${Number(props?.count ?? 0)?.toLocaleString('en-US')} ${props.type}`)}
        <h2 className={cardTitle}>{props.type}</h2>
        {showInputsAddViewButton && <Button variant="link" size="md" style={{
        justifyContent: 'flex-start'
      }} className={cx(regularFontWeight)} onClick={props.handleViewOrAddResource} data-testid={`addOrView-${props.type}-btn`}>
            {!props.isEditable || props.count > 0 ? 'View' : 'Add'}
          </Button>}
      </div>
      {props.isEditable && <IconButton className={addButton} size="sm" iconSize={24} variant="tertiary" Icon={IconPlus} aria-label={`Add ${props.type}`} onClick={props.handleAddResource} data-testid={`add-${props.type}-btn`} />}
    </div>;
});
const DesktopAppResourceCard: React.FC<TabletOrDesktopCardProps> = observer(props => {
  const formattedCount = formatNumber(props?.count ?? 0);
  const isSuffixAdded = formattedCount.includes('K') || formattedCount.includes('M');
  const showInputsAddViewButton = !props.hideResourcesLink && (props.isEditable || props.count > 0);
  return <div className={cx(appResourceCard, flex.direction.column, displayOnDesktop)}>
      <div className={cx(flex.init, flex.alignItems.center, flex.justifyContent.between)}>
        <h2 className={cardTitle}>{props.type}</h2>
        {!props.hideResourcesLink && props.isEditable && <IconButton className={addButton} size="sm" iconSize={24} variant="tertiary" Icon={IconPlus} aria-label={`Add ${props.type}`} onClick={props.handleAddResource} data-testid={`add-${props.type}-btn`} />}
      </div>
      <div className={cx(flex.init, flex.alignItems.start, flex.gap.half, flex.direction.column)}>
        <div className={cx(flex.init, flex.gap.half)}>
          <h1 className={resourceCount} data-testid={`resource-count-${props.type}`}>
            {formattedCount}
          </h1>
          {isSuffixAdded && CountTooltip(`${Number(props?.count ?? 0)?.toLocaleString('en-US')} ${props.type}`)}
        </div>

        {showInputsAddViewButton && <Button variant="link" size="md" className={regularFontWeight} onClick={props.handleViewOrAddResource} data-testid={`addOrView-${props.type}-btn`}>
            {!props.isEditable || props.count > 0 ? 'View' : 'Add'} {props.type?.toLowerCase()}
          </Button>}
      </div>
    </div>;
});