import { cx } from '@linaria/core';
import { FC, ReactNode } from 'react';
import { pillBase, pillActive } from './Pill_styles';

export interface PillProps extends React.HTMLAttributes<HTMLSpanElement> {
  active?: boolean;
  className?: string;
  children: ReactNode;
}

export const Pill: FC<PillProps> = ({ active = false, className, children, ...rest }) => {
  const pillClassName = active ? pillActive : pillBase;
  return (
    <span aria-selected={active} className={cx(pillClassName, className)} {...rest}>
      {children}
    </span>
  );
};
