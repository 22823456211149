import { taskEither } from 'fp-ts';
import { fetchAuthBannerTE } from 'api/hubspot/fetchAuthBanner';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { errorToReactLeft, pipe } from 'utils/fp';
import { fetchUserProfileTE } from 'api/users/profile';
import { listSSOMethodsTE } from 'api/users/sso';
import type { AuthMethod } from 'api/users/sso';

export const authApiEffects = {
  fetchUserProfileTE: (userId: string): TaskEither<ReactLeft, CF.API.Users.User> =>
    pipe(
      fetchUserProfileTE({ userId }, errorToReactLeft),
      taskEither.map((x) => x.user),
    ),

  fetchAuthBannerTE: (pageName: string): TaskEither<ReactLeft, HUBSPOT.API.AuthBannerResult[]> =>
    pipe(
      fetchAuthBannerTE({}, errorToReactLeft),
      taskEither.map((banners) => (banners.results || []).filter((results) => pageName === results.values.page_name && results.values)),
    ),

  listSSOMethodsTE: (params?: string): TaskEither<ReactLeft, AuthMethod[]> =>
    pipe(
      listSSOMethodsTE({ params }, errorToReactLeft),
      taskEither.map((x) => x.auth_methods),
    ),
};
