import { cx, css } from '@linaria/core';
import { flex, theming, breakpoints } from 'styles/utils';
import { textSmall } from 'styles/typography';
import { ellipsis } from 'polished';
export const regularFormGroupEl = "omponents__Formik__Forms_styles_tsx-regularFormGroupEl-r2e2710";
export const formGroupEl = "omponents__Formik__Forms_styles_tsx-formGroupEl-f14fd6hf";
export const fieldWrapper = "omponents__Formik__Forms_styles_tsx-fieldWrapper-fr1qz4j";
export const formLabel = cx("omponents__Formik__Forms_styles_tsx-formLabel-fdoxwcv", textSmall, flex.init, flex.gap.quarter);
export const labelStyle = "omponents__Formik__Forms_styles_tsx-labelStyle-l5ruff6";
export const errorMsg = cx("omponents__Formik__Forms_styles_tsx-errorMsg-e1dc4v9o", textSmall);
export const truncatedErrorFieldWrapper = "omponents__Formik__Forms_styles_tsx-truncatedErrorFieldWrapper-tedmlff";
export const truncatedErrorTooltipStyles = cx("omponents__Formik__Forms_styles_tsx-truncatedErrorTooltipStyles-tbj8eb6", textSmall);
export const multiLineInputStyle = "omponents__Formik__Forms_styles_tsx-multiLineInputStyle-m1fnlor0";

require("./Forms.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Forms.styles.tsx");