export async function addJiraScript(scriptSrc: string): Promise<void> {
  return new Promise((resolve) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = scriptSrc;
    s.nonce = (document.querySelector('#nonce-csp') as HTMLElement)?.nonce;
    s.addEventListener('load', () => {
      resolve();
    });
    document.body.append(s);
  });
}
