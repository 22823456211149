import { css } from '@linaria/core';
import { baseUnits, breakpoints, theming } from 'styles/utils';
export const jobRoleSelectWrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-jobRoleSelectWrapper-j4kkscy";
export const onboardingDialogClassName = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-onboardingDialogClassName-o1qvgk4a";
export const wrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-wrapper-wv9dkgw";
export const animatedViewsDiv = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-animatedViewsDiv-anag2hh";
export const welcomeMsgWrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-welcomeMsgWrapper-w150ngrx";
export const accountVerifiedBadge = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-accountVerifiedBadge-av7bfjv";
export const overrideSelectStyles = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-overrideSelectStyles-o1fnqhng";

require("./OnboardingDialog.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./OnboardingDialog.styles.ts");