import { SignupForm } from 'modules/Auth/components/Signup/SignupForm';
import { flex } from 'styles/utils/core';
import { SSOProviders } from 'modules/Auth/components/Login/SSOProviders';
import { cx } from '@linaria/core';
import { Link } from 'components/Link';
import { useRouter } from 'next/router';
import { contentStyles, signupLinkStyles, signupDivStyles } from './styles';

type Props = {
  siteKey: string;
  defaultCaptchaToken: string;
  onSignInClick: () => void;
  onSignUp: () => void;
};

export const SignUpView = ({ onSignUp, onSignInClick, siteKey, defaultCaptchaToken }: Props) => {
  const router = useRouter();
  const query = router.query as { from?: string; recovery_path?: string };

  return (
    <div className={contentStyles}>
      <SignupForm onSignUp={onSignUp} logoVisible={false} siteKey={siteKey} defaultCaptchaToken={defaultCaptchaToken} />
      <SSOProviders isSignup recoveryPath={query.from} />
      <div className={cx(flex.init, flex.direction.row, flex.justifyContent.center, signupDivStyles)}>
        <span className="title">Already have an account? </span>
        <Link
          href="/login"
          onClick={(e) => {
            e.preventDefault();
            onSignInClick();
          }}
        >
          <span className={signupLinkStyles}>Log in</span>
        </Link>
      </div>
    </div>
  );
};
