import { css } from '@linaria/core';
import { baseUnits, breakpoints } from 'styles/utils';
export const container = "odules__Apps__components__AppViewer__AppViewer_styles_ts-container-c1renaw8";
export const titleContainer = "odules__Apps__components__AppViewer__AppViewer_styles_ts-titleContainer-t1w9phmb";
export const mainContainer = "odules__Apps__components__AppViewer__AppViewer_styles_ts-mainContainer-m1xmg3rc";
export const mobileHeader = "odules__Apps__components__AppViewer__AppViewer_styles_ts-mobileHeader-mz2uayr";
export const detailsContainer = "odules__Apps__components__AppViewer__AppViewer_styles_ts-detailsContainer-d13kgtrp";
export const infoContainer = "odules__Apps__components__AppViewer__AppViewer_styles_ts-infoContainer-i1p08sol";
export const entityDetailsContainer = "odules__Apps__components__AppViewer__AppViewer_styles_ts-entityDetailsContainer-e11b0x0a";
export const appImage = "odules__Apps__components__AppViewer__AppViewer_styles_ts-appImage-ahaispp";
export const appInfo = "odules__Apps__components__AppViewer__AppViewer_styles_ts-appInfo-amfd8jq";
export const paddedSection = "odules__Apps__components__AppViewer__AppViewer_styles_ts-paddedSection-pfjnz53";
export const mobileButtons = "odules__Apps__components__AppViewer__AppViewer_styles_ts-mobileButtons-mcr9z9l";
export const modelViewWrapper = "odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3";

require("./AppViewer.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./AppViewer.styles.ts");