/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from 'react';

export enum AnimatedViewsActionTypes {
  GotoViewAction = 'GOTO_VIEW_ACTION',
  GoBackAction = 'GO_BACK_ACTION',
  AfterAnimateAction = 'AFTER_ANIMATE_ACTION',
}

type AnimatedViewsActionsPayload = {
  [AnimatedViewsActionTypes.GotoViewAction]: string;
  [AnimatedViewsActionTypes.GoBackAction]: void;
  [AnimatedViewsActionTypes.AfterAnimateAction]: string;
};

type AnimatedViewsActionMap = import('types').ActionMap<AnimatedViewsActionsPayload>;
export type ModalActions = AnimatedViewsActionMap[keyof AnimatedViewsActionMap];

type AnimatedViewsState = {
  config: any;
  prevSteps: string[];
  step: string;
  mounted: Record<string, boolean>;
  animation: Record<string, string>;
  classNames: Record<string, any>;
};

export const createInitialState = (viewIds: string[], config: any): AnimatedViewsState => ({
  config,
  prevSteps: [],
  step: viewIds[0],
  mounted: viewIds.reduce((acc, id, idx) => {
    acc[id] = idx === 0;
    return acc;
  }, {} as Record<string, boolean>),
  animation: viewIds.reduce((acc, id) => {
    acc[id] = 'forward';
    return acc;
  }, {} as Record<string, string>),
  classNames: viewIds.reduce((acc, id, idx) => {
    acc[id] = idx === 0 ? config.classNames.mountedStyle : config.classNames.unmountedStyle;
    return acc;
  }, {} as Record<string, any>),
});

const reducer = (state: AnimatedViewsState, action: ModalActions) => {
  let result: AnimatedViewsState;
  switch (action.type) {
    case AnimatedViewsActionTypes.GotoViewAction:
      result = {
        ...state,
        prevSteps: [...state.prevSteps, state.step],
        step: action.payload,
        animation: {
          ...state.animation,
          [action.payload]: 'forward',
        },
        classNames: {
          ...state.classNames,
          [state.step]: state.config.classNames.unmountedStyle,
        },
      };
      if (state.prevSteps.length) {
        result.animation[state.prevSteps[state.prevSteps.length - 1]] = 'forward';
      }
      return result;
      break;
    case AnimatedViewsActionTypes.GoBackAction:
      return {
        ...state,
        prevSteps: state.prevSteps.slice(0, -1),
        step: state.prevSteps[state.prevSteps.length - 1],
        animation: {
          ...state.animation,
          [state.step]: 'backward',
          [state.prevSteps[state.prevSteps.length - 1]]: 'backward',
        },
        classNames: {
          ...state.classNames,
          [state.step]: state.config.classNames.unmountedBackStyle,
        },
      };
      break;
    case AnimatedViewsActionTypes.AfterAnimateAction:
      return {
        ...state,
        mounted: {
          ...state.mounted,
          [action.payload]: false,
          [state.step]: true,
        },
        classNames: {
          ...state.classNames,
          [state.step]: state.animation[state.step] === 'forward' ? state.config.classNames.mountedStyle : state.config.classNames.mountedBackStyle,
        },
      };
      break;
    default:
      throw new Error('Unknown action.');
  }
};

export const useAnimatedViews = (
  viewIds: string[],
  config: any,
): [
  AnimatedViewsState,
  {
    goBack: () => void;
    goToView: (viewId: string) => void;
    afterAnimation: (viewId: string, animationName: string) => void;
  },
] => {
  const [state, dispatch] = useReducer(reducer, createInitialState(viewIds, config));

  const goBack = () => dispatch({ type: AnimatedViewsActionTypes.GoBackAction, payload: undefined });
  const goToView = (viewId: string) => dispatch({ type: AnimatedViewsActionTypes.GotoViewAction, payload: viewId });
  const afterAnimation = (viewId: string, animationName: string) => {
    if (animationName.startsWith('outAnimation') || animationName.startsWith('outBackAnimation')) {
      dispatch({ type: AnimatedViewsActionTypes.AfterAnimateAction, payload: viewId });
    }
  };
  return [state, { goBack, goToView, afterAnimation }];
};
