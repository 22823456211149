import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualClassifierIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.646851 13.5753C0.646851 13.5753 2.96865 10.0282 7.03178 10.0282C11.0949 10.0282 13.4167 13.5753 13.4167 13.5753C13.4167 13.5753 11.0949 17.1225 7.03178 17.1225C2.96865 17.1225 0.646851 13.5753 0.646851 13.5753Z"
      stroke={color}
      strokeWidth="1.10788"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.03182 15.7037C8.20726 15.7037 9.16013 14.7508 9.16013 13.5754C9.16013 12.3999 8.20726 11.4471 7.03182 11.4471C5.85638 11.4471 4.9035 12.3999 4.9035 13.5754C4.9035 14.7508 5.85638 15.7037 7.03182 15.7037Z"
      fill={color}
    />
    <path
      d="M27.7931 19.522L23.3616 22.0806V16.9635L27.7931 19.522ZM20.2456 19.522C20.2456 20.8273 19.1873 21.8855 17.882 21.8855C16.5767 21.8855 15.5186 20.8273 15.5186 19.522C15.5186 18.2168 16.5767 17.1586 17.882 17.1586C19.1873 17.1586 20.2456 18.2168 20.2456 19.522ZM23.8047 19.9652H17.882V19.0789H23.8047V19.9652Z"
      fill={color}
    />
    <path
      d="M27.7931 13.5754L23.3616 16.1339V11.0168L27.7931 13.5754ZM20.2456 13.5754C20.2456 14.8807 19.1873 15.9388 17.882 15.9388C16.5767 15.9388 15.5186 14.8807 15.5186 13.5754C15.5186 12.27 16.5767 11.2119 17.882 11.2119C19.1873 11.2119 20.2456 12.27 20.2456 13.5754ZM23.8047 14.0185H17.882V13.1322H23.8047V14.0185Z"
      fill={color}
    />
    <path
      d="M27.7931 7.62866L23.3616 10.1872V5.07013L27.7931 7.62866ZM20.2456 7.62866C20.2456 8.93397 19.1873 9.99213 17.882 9.99213C16.5767 9.99213 15.5186 8.93397 15.5186 7.62866C15.5186 6.32336 16.5767 5.26518 17.882 5.26518C19.1873 5.26518 20.2456 6.32336 20.2456 7.62866ZM23.8047 8.07182H17.882V7.18551H23.8047V8.07182Z"
      fill={color}
    />
  </svg>
);
