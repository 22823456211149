import { css, cx } from '@linaria/core';
import { theming, breakpoints } from 'styles/utils';
import { textSmall } from 'styles/typography';
import { heading, textGrey } from '../styles';
const commonMargin = '2rem';
export const restorePasswordContainer = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordContainer-r1uaqdj4";
export const restorePasswordBody = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordBody-rovimli";
export const restorePasswordHeading = cx(heading, "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordHeading-rxwsfmn");
export const restorePasswordTextGrey = cx(textGrey, "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordTextGrey-rn2dj7f");
export const restorePasswordGormGroup = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordGormGroup-r1qyckqy";
export const restorePasswordBtn = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordBtn-r1oxt4gb";
export const restorePasswordLink = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordLink-r1h88kql";
export const restorePasswordHint = cx(textSmall, "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordHint-rsjx689");
export const restorePasswordNav = "odules__Auth__components__RestorePassword__RestorePassword_styles_ts-restorePasswordNav-rb8qhm0";

require("./RestorePassword.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./RestorePassword.styles.ts");