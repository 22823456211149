/* eslint-disable no-param-reassign */
import { either } from 'fp-ts';
import { Either, fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { toJS } from 'mobx';
import { action, effect, getEnv, reaction$, types, isAlive, isValidReference, Instance, IAnyType } from 'mst-effect';
import { QueryClient } from 'react-query';
import { from, of, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { applySnapshotAsync } from 'utils/mst/applySnapshotAsync';
import { fetchQueryTE } from 'utils/react-query';
import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { updateMST, withRunInAction } from 'utils/withRunInAction';
import Router from 'next/router';
import { makeParamString } from '../helpers';
import { LISTINGS_DEBOUNCE_FETCH_MS } from './constants';
import { withEndlessPagination, FetchedData } from './withEndlessPagination';

export type Listable = Instance<ReturnType<typeof createListable>>;

const invalidErrorString = `Invalid logic in createListable.
  Please ensure that list is either a Left or a Right.
  An empty array in _listItems should be accompanied by UI_STATES_DEPRECATED.empty/loading in _leftUiState.`;
// MSTs inferred types are better than writing out own boundary type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createListable = <T>({
  // initialList,
  fetcher,
  defaultParams = '',
  entityMstModel,
  listQueryKey,
  initialQuery = {},
  initialPageSize,
  disableRouterUpdates = false,
}: {
  // initialList: T[]
  fetcher: (params: string, self?: unknown) => TaskEither<ReactLeft, FetchedData<T>>;
  defaultParams?: string;
  entityMstModel: IAnyType;
  listQueryKey: unknown[];
  initialQuery?: Record<string, string>;
  initialPageSize?: number;
  disableRouterUpdates?: boolean;
}) => {
  const PaginatedListable = types.compose(
    withRunInAction(),
    withEndlessPagination({ initialQuery, fetcher, initialPageSize, disableRouterUpdates }),
    types
      .model({
        hasHydratedList: types.optional(types.boolean, false),
        _listItems: types.array(entityMstModel),
        _leftUiState: types.maybeNull(types.optional(types.enumeration(Object.values(UI_STATES)), UI_STATES.loading)),
        defaultParams: types.frozen<string>(defaultParams),
        _errorData: types.optional(
          types.model({
            title: types.maybe(types.string),
            reason: types.maybe(types.string),
          }),
          {},
        ),
      })
      .named('ListableModel'),
  );

  return PaginatedListable.views((self) => ({
    get list() {
      const props = toJS(self._errorData);
      if (self._leftUiState && !self._listItems.length) {
        return either.left({ type: UI_STATES[self._leftUiState.toLowerCase() as keyof typeof UI_STATES], props });
      } else if (self._listItems.length && !self._leftUiState) {
        return either.right(self._listItems);
      } else {
        // defensive handling of future entropy, should require debugging if actually returned
        // as the above two cases are "total" and should leave no space for a 3rd case
        console.error(invalidErrorString);
        return either.left({ type: UI_STATES.invalid, props });
      }
    },
    get queryParams() {
      const paramString = makeParamString({
        searchTermValue: this.searchTermValue,
        defaultParams: self.defaultParams,
        filters: this.filters || [],
        sortBy: this.sortBy || {},
      });
      return paramString;
    },
    get combinedParams() {
      return combineQueryData((self as unknown as { queryParams: string }).queryParams, self.pageInfo);
    },
  }))
    .actions((self) => ({
      setDefaultParams(params: string) {
        self.defaultParams = params;
      },
      setList(listE: Either<ReactLeft, FetchedData<unknown>>, reinitialise = true): void {
        // ignoring as its not important to reproduce this edge case in test (no state update if mobx tree dead)
        /* istanbul ignore if */
        if (!isAlive(self) || !isValidReference(() => self._listItems)) {
          return;
        }

        updateMST(self, () => {
          pipe(
            listE,
            fold(
              ({ type, props = {} }) => {
                self._listItems.replace([]);
                self._leftUiState = type;
                applySnapshotAsync(self._errorData, { title: props.title, reason: props.reason });
              },
              (data) => {
                applySnapshotAsync(self._errorData, {});

                const { list, mixedSuccessStatus } = getListFromMixedSuccessData(data);

                if (!list.length) {
                  if (reinitialise) {
                    // list is empty on init, encode in Left
                    self._listItems.replace([]);
                    self._leftUiState = UI_STATES.empty;
                  } else {
                    // last page has 0 results; unfortunately only way to know "last" page
                    self.hasReachedEnd = true;
                  }
                } else {
                  // list is good to go as a Right
                  self._leftUiState = null;

                  let toApply = list;
                  if (!reinitialise) {
                    // add new page's data to list
                    toApply = self._listItems.concat(list);
                  }
                  applySnapshotAsync(self._listItems, toApply);
                }

                // If the list was returned with "Mixed Success" status, then probably
                // there are more items to be fetched
                if (!mixedSuccessStatus && list.length < self.perPage) {
                  self.hasReachedEnd = true;
                }
              },
            ),
          );
        });
      },
      deleteItem(item: Instance<typeof entityMstModel>): boolean {
        if (self._listItems.length) {
          if (self._listItems.length === 1) {
            self._leftUiState = UI_STATES.empty;
          }
          self._listItems.remove(item);
          return true;
        }
        return false;
      },
    }))
    .actions((self) => {
      const queryClient = getEnv<{ queryClient: QueryClient }>(self).queryClient;
      return {
        load(forceReload: boolean = false) {
          let isInputManagerPage: boolean = false;

          const { router } = Router;

          if (router) {
            isInputManagerPage = router.pathname === '/[userOrOrgId]/[appId]/inputs';
          }

          // If there's existing items in list and this function is still called, then
          // return early, due to this issue: https://clarifai.atlassian.net/browse/MRK-2405.
          // But this behaviour can be bypassed if "forceReload" is set to true, which can be
          // useful for reloading the list whenever a new item is added.
          if (self._listItems.length > 0 && !isInputManagerPage && !forceReload) return;

          let fetchItems = (): Promise<either.Either<ReactLeft, FetchedData<T>>> =>
            fetchQueryTE(queryClient, listQueryKey.concat([self.combinedParams]), fetcher(self.combinedParams, self));

          // if user mounts app on url having page > 1; load all previous items as well
          if (!self.hasHydratedList && self.currentPage >= 1) {
            const { currentPage, perPage, queryParams } = self;
            // if at page 3 & perPage = 10; total to fetch on init == page 1 + page 2 + page 3 == 30 items
            const actualPageSizeRequired = currentPage * perPage;
            const parsed = new URLSearchParams(queryParams);
            parsed.set('page', '1');
            parsed.set('per_page', String(actualPageSizeRequired));
            fetchItems = fetcher(parsed.toString());
          }
          if (self.hasHydratedList && self.currentPage >= 1) {
            const { currentPage, perPage, queryParams } = self;
            // if at page 3 & perPage = 10; total to fetch on init == page 1 + page 2 + page 3 == 30 items
            const actualPageSizeRequired = currentPage * perPage;
            const parsed = new URLSearchParams(queryParams);
            parsed.set('page', '1');
            parsed.set('per_page', String(actualPageSizeRequired));
            fetchItems = fetcher(parsed.toString(), self);
          }
          // eslint-disable-next-line promise/catch-or-return
          fetchItems().then((itemsE) => {
            setTimeout(() => {
              updateMST(self, () => {
                self.hasHydratedList = true;
              });
            }, 50);
            return self.setList(itemsE, true);
          });
        },
        loadNextPage() {
          const fetchItems = (): Promise<either.Either<ReactLeft, FetchedData<T>>> =>
            fetchQueryTE(queryClient, listQueryKey.concat([self.combinedParams]), fetcher(self.combinedParams, self));

          // eslint-disable-next-line promise/catch-or-return
          fetchItems().then((itemsE) => {
            setTimeout(() => {
              updateMST(self, () => {
                self.hasHydratedList = true;
              });
            }, 50);
            return self.setList(itemsE, false);
          });
        },
        reset() {
          self._listItems.replace([]);
          self._leftUiState = UI_STATES.loading;
        },
      };
    })
    .actions((self) => {
      // nature of current impl. has no "going back"; so we can assume if pageInfo changed; *it's NEXT page only*
      // this will change eventually when we only fetch first page; and let user scroll up to fetch
      // previous pages; or if we have buttoned pagination.
      // In both those alternatives; the state will change to a map of key = pageNum & val = Entity[]
      effect<string>(self, () =>
        reaction$(() => self.pageInfo, { fireImmediately: false }).pipe(
          map(() =>
            action(() => {
              self.loadNextPage();
            }),
          ),
        ),
      );

      // this effect is registered on each created instance of Listable.
      // this one listens to changes in self.queryParams (search/sort/filter) and calls the API
      // the observable is unsubscribed when store is destroyed.
      effect<string>(self, () => {
        // Using `reaction$` to automatically refresh data when the queryParams changed
        // reaction$ is like MobX `autorun` but with observables
        return reaction$(
          () =>
            combineQueryData(
              self.queryParams,
              // because the queryParams have changed; we start with page 1
              { page: 1, per_page: self.perPage },
            ),
          { fireImmediately: false },
        ).pipe(
          // wait a little while before asking from API
          debounceTime(LISTINGS_DEBOUNCE_FETCH_MS),
          // dont go ahead if the search term is the same as last
          distinctUntilChanged(),
          tap(() => {
            self.reset();
            self.resetPage();
          }),
          // concatMap maps values to inner observable; and emits in order
          switchMap(
            // from converts promise to observable
            (queryParams) => {
              const fetchItems = fetchQueryTE(
                getEnv<{ queryClient: QueryClient }>(self).queryClient,
                listQueryKey.concat([queryParams.current]),
                fetcher(queryParams.current, self),
              );

              return zip(from(fetchItems), of(queryParams));
            },
          ),
          // reaction$ has to return an `action` that updates the MST data.
          map(([data]) =>
            action(() => {
              setTimeout(
                () =>
                  self.runInAction(() => {
                    self.hasHydratedList = true;
                  }),
                50,
              );
              self.setList(data);
            }),
          ),
        );
      });

      return {};
    });
};

export const testable = { invalidErrorString };

function combineQueryData(queryParams: string, pageInfo: CF.API.PageInfo): string {
  const searchParams = new URLSearchParams(queryParams || '');
  searchParams.append('per_page', String(pageInfo.per_page));
  searchParams.append('page', String(pageInfo.page));
  return searchParams.toString();
}

function getListFromMixedSuccessData(data: FetchedData<unknown> = []) {
  if (Array.isArray(data)) {
    return { list: data, mixedSuccessStatus: false };
  }

  return data;
}
