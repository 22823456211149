import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const BarcodeOperatorIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.15625 6.52608V21.8386" stroke={color} strokeWidth="2.1875" strokeLinecap="round" />
    <path d="M8.96875 6.52608V13.0886" stroke={color} strokeWidth="2.1875" strokeLinecap="round" />
    <path d="M13.7812 6.52608V13.0886" stroke={color} strokeWidth="2.1875" strokeLinecap="round" />
    <path d="M18.5938 6.52608V21.8386" stroke={color} strokeWidth="2.1875" strokeLinecap="round" />
    <path d="M23.4062 6.52608V21.8386" stroke={color} strokeWidth="2.1875" strokeLinecap="round" />
    <path
      d="M14.1823 16.7344H9.44271C8.63729 16.7344 7.98438 17.3873 7.98438 18.1927V20.7448C7.98438 21.5502 8.63729 22.2031 9.44271 22.2031H14.1823C14.9877 22.2031 15.6406 21.5502 15.6406 20.7448V18.1927C15.6406 17.3873 14.9877 16.7344 14.1823 16.7344Z"
      stroke={color}
      strokeWidth="1.09375"
    />
  </svg>
);
