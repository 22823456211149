import { cx } from '@linaria/core';
import { stringToColor, makeInitials } from 'utils/strings/strings';
import { noop } from 'utils/fp';
import { TextIcon } from 'components/TextIcon';
import { Tag } from 'components/Tag';
import { Button } from 'components/ui_library/Button';
import { TextSmall } from 'components/Popover';
import { account, selectedAccountRow, avatar, userTag, orgTag } from './styles';

export interface AccountProps extends React.HTMLAttributes<HTMLDivElement> {
  type: 'user' | 'org';
  name: string;
  id?: string;
  selected?: boolean;
  onSelect?: () => void;
}

export const Account: React.FC<AccountProps> = ({ type, name, id, selected, onSelect, ...rest }): JSX.Element => (
  <Button variant="unstyled" size="md" className={cx(account, selected && selectedAccountRow, 'account', rest.className)} onClick={onSelect}>
    <TextIcon text={makeInitials(name)} background={stringToColor(name)} className={cx('avatar', avatar)} />
    <TextSmall className="name">
      {name}
      <TextSmall className="id">{id}</TextSmall>
    </TextSmall>
    <Tag id={type} onChange={noop} className={cx(type === 'user' ? userTag : orgTag, 'tag')}>
      {type}
    </Tag>
  </Button>
);
