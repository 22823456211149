import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const unstarAppTE = <E = CF.API.ApiError>(
  { userId, body }: { userId: string; body: { user_app_id: { user_id: string; app_id: string }; app_ids: string[] } },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Models.ModelUnstarResponse> =>
  fetchTE<CF.API.Models.ModelUnstarResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/app_stars`,
      method: 'DELETE',
      body,
    },
    onRejected,
  );
