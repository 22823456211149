import { PopoverAction, usePopoverState } from 'components/Popover';
import { StarComponent } from 'components/List/components/Star/Star';
import { EntityPopover } from 'components/EntityCard/EntityPopover';
import { Instance } from 'mobx-state-tree';
import { editableAppMST } from 'modules/Apps/editableAppMst';
import { IconDotsMenu } from 'components/Icons';
import { observer } from 'mobx-react-lite';
import { container } from './AppTools.styles';

export const AppTools = observer(({ entity }: { entity: Instance<typeof editableAppMST> }) => {
  const popover = usePopoverState();

  return (
    <div className={container}>
      <StarComponent entity={entity.data} data-testid="app-star-btn" />
      {entity.isEditable && (
        <>
          <PopoverAction popover={popover} data-testid="app-tools-menu">
            <IconDotsMenu size={18} color="#808080" />
          </PopoverAction>
          <EntityPopover entity={entity.data} popover={popover} />
        </>
      )}
    </div>
  );
});
