/* eslint-disable no-param-reassign */
import { types } from 'mst-effect';
import Router from 'next/router';

// type inference is more powerful in case of MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeSearchable(initialQuery: Record<string, string> = {}, params?: { disableRouterUpdates: boolean }) {
  const { disableRouterUpdates } = params || { disableRouterUpdates: false };
  return types
    .model({
      isSearchable: types.frozen<boolean>(true),
      searchTermValue: types.optional(types.string, initialQuery.searchQuery || ''),
    })
    .actions((self) => {
      const updateRoute = (query: string): void => {
        const { router } = Router;
        // if block only for TS strict adherence
        /* istanbul ignore if  */
        if (router && !disableRouterUpdates) {
          router.replace(
            {
              query: { ...router.query, searchQuery: query },
            },
            undefined,
            { shallow: true },
          );
        }
      };
      return {
        setSearchTermValue(query: string) {
          updateRoute(query);
          self.searchTermValue = query;
        },
      };
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function defineNotSearchable() {
  return types
    .model({
      isSearchable: types.frozen<boolean>(false),
      searchTermValue: types.optional(types.string, ''),
    })
    .actions((_) => ({
      setSearchTermValue(__: string) {},
    }));
}
