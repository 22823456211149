import { css } from '@linaria/core';
import { theming, breakpoints } from 'styles/utils';
export const signupDivStyles = "odules__Auth__components__AuthModal__styles_ts-signupDivStyles-s1o1hug5";
export const contentStyles = "odules__Auth__components__AuthModal__styles_ts-contentStyles-cydr1ib";
export const signupLinkStyles = "odules__Auth__components__AuthModal__styles_ts-signupLinkStyles-sgkj3vb";
export const headerStyles = "odules__Auth__components__AuthModal__styles_ts-headerStyles-h14zlcuq";
export const forgotPasswordStyles = "odules__Auth__components__AuthModal__styles_ts-forgotPasswordStyles-f65cv4f";
export const TwoFAStyles = "odules__Auth__components__AuthModal__styles_ts-TwoFAStyles-t1cyfqq9";
export const closeButtonStyles = "odules__Auth__components__AuthModal__styles_ts-closeButtonStyles-cgy6fbs";
export const backdropStyles = "odules__Auth__components__AuthModal__styles_ts-backdropStyles-bj7h08c";
export const dialogStyles = "odules__Auth__components__AuthModal__styles_ts-dialogStyles-d1j27u0e";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");