import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const RandomSampleIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 45 45">
    <path
      id="iconmonstr-media-control-55"
      d="M33.813,16.028V10.393c-1.879,0-6.214-.353-8.465,4.163L17.425,30.449A11.27,11.27,0,0,1,7.337,36.692H0V32.935H7.337a7.514,7.514,0,0,0,6.725-4.163l7.924-15.894c2.547-5.111,6.9-6.242,11.827-6.242V1L45.084,8.514,33.813,16.028ZM16.037,18.515l2.1-4.212c-2.32-4.657-5.446-7.668-10.8-7.668H0v3.757H7.337c5.4,0,6.845,4.4,8.7,8.123Zm29.047,16.3L33.813,27.3v5.636c-7.1,0-7.55-2.326-10.437-8.119l-2.1,4.21c1.918,3.849,3.945,7.666,12.537,7.666v5.636Z"
      transform="translate(0 -1)"
      fill={color}
    />
  </svg>
);
