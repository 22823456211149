import { IconCheck } from 'components/Icons';
import { baseTheme } from 'styles/utils';
import { cx } from '@linaria/core';
import { SortIndicatorProps } from './SortIndicator';
import { ascDscSorter, sorter } from './SortIndicator.styles';

export const MobileViewSort = ({ columnId, sortMetaData, isSortedDesc, setSortBy, isSorted }: SortIndicatorProps) => {
  const isAscSort = isSorted && !isSortedDesc;
  const isDescSort = isSorted && isSortedDesc;
  const onSortAsc = () => {
    // If already sorted in ascending, clicking again removes the sort.
    const sort = isAscSort ? [] : [{ id: columnId, desc: false }];
    setSortBy(sort);
  };
  const onSortDesc = () => {
    // If already sorted in descending, clicking again removes the sort.
    const sort = isDescSort ? [] : [{ id: columnId, desc: true }];
    setSortBy(sort);
  };
  return (
    <div className={cx(sorter, ascDscSorter)}>
      <span className="filterLabels">Sort By</span>
      <button data-testid="popover-asc-btn" type="button" className={cx('mobile-btn', isAscSort && 'selected')} onClick={onSortAsc}>
        <p data-testid="asc-label">{sortMetaData?.sortAscLabel ? sortMetaData?.sortAscLabel : 'Sort (A-Z)'}</p>
        {isAscSort && <IconCheck size={20} color={baseTheme.light.colors.primary600} />}
      </button>
      <button data-testid="popover-asc-btn" type="button" className={cx('mobile-btn', isDescSort && 'selected')} onClick={onSortDesc}>
        <p data-testid="asc-label">{sortMetaData?.sortDescLabel ? sortMetaData?.sortDescLabel : 'Sort (Z-A)'}</p>
        {isDescSort && <IconCheck size={20} color={baseTheme.light.colors.primary600} />}
      </button>
    </div>
  );
};
