import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import type { SupportedEditableModelFields } from 'modules/Models/components/ModelViewer/useEditableModel';
import { withDetailEditButton } from 'modules/Models/components/ModelViewer/withDetailEditButton';

type Props = {
  editableEntity: CF.UI.EditableEntity;
  entityName: CF.UI.EditableEntityNames;
  field: SupportedEditableModelFields;
};

function EditableDetail_({ editableEntity, field }: Props): JSX.Element | null {
  const ref = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = (): void =>
    setIsEditing((isE) => {
      if (isE) {
        editableEntity.save();
        return false;
      }
      return true;
    });

  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = (evt) => {
    if (evt.key === 'Enter') {
      editableEntity.save();
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      ref.current?.focus();
      ref.current?.setSelectionRange(0, ref.current?.value.length);
    }
  }, [isEditing]);

  const fieldValue = editableEntity.data[field] as string;

  return withDetailEditButton({
    onClick: toggleEditing,
    isEditable: editableEntity.isEditable,
    isEditing,
    content: isEditing ? (
      <input
        ref={ref}
        value={fieldValue}
        onChange={(e) => editableEntity.setValue(field, e.target.value)}
        onKeyDown={handleKey}
        className="fullWidth"
      />
    ) : (
      <div className="fullWidth">{fieldValue || '--'}</div>
    ),
    testId: `${field}-edit`,
  });
}

export const EditableDetail = observer(EditableDetail_);
