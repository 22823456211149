import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualDetectorIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 3.05841H2.92648C2.4148 3.05841 2 3.47321 2 3.98489V6.20127" stroke={color} />
    <path d="M2 9.34412V11.5605C2 12.0722 2.4148 12.487 2.92648 12.487H6" stroke={color} />
    <path d="M10 3.05841H13.0735C13.5852 3.05841 14 3.47321 14 3.98489V6.20127" stroke={color} />
    <path d="M10 12.487H13.0735C13.5852 12.487 14 12.0722 14 11.5605V9.34412" stroke={color} />
    <path
      d="M4.22369 7.77269C4.22369 7.77269 5.63039 5.57269 8.09211 5.57269C10.5538 5.57269 11.9605 7.77269 11.9605 7.77269C11.9605 7.77269 10.5538 9.97269 8.09211 9.97269C5.63039 9.97269 4.22369 7.77269 4.22369 7.77269Z"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.09212 9.0927C8.80427 9.0927 9.38159 8.50171 9.38159 7.7727C9.38159 7.04368 8.80427 6.4527 8.09212 6.4527C7.37996 6.4527 6.80264 7.04368 6.80264 7.7727C6.80264 8.50171 7.37996 9.0927 8.09212 9.0927Z"
      fill={color}
    />
  </svg>
);
