import { cx, css } from '@linaria/core';
import { baseUnits, theming, breakpoints } from 'styles/utils';
export const createApplicationWrapper = "odules__Apps__components__CreateApplication__styles_ts-createApplicationWrapper-c1yk3q00";
export const dropdownWrapper = "odules__Apps__components__CreateApplication__styles_ts-dropdownWrapper-d1ik3nc7";
export const selectMenuWrapper = "odules__Apps__components__CreateApplication__styles_ts-selectMenuWrapper-s12n3k04";
export const heading = "odules__Apps__components__CreateApplication__styles_ts-heading-h10km243";
export const subheading = "odules__Apps__components__CreateApplication__styles_ts-subheading-s1j6jdf8";
export const closeIcon = "odules__Apps__components__CreateApplication__styles_ts-closeIcon-c170aj70";
export const alertText = "odules__Apps__components__CreateApplication__styles_ts-alertText-azptydr";
export const consentCheckboxWrapper = "odules__Apps__components__CreateApplication__styles_ts-consentCheckboxWrapper-chin9t4";
export const consentCheckbox = "odules__Apps__components__CreateApplication__styles_ts-consentCheckbox-cgn9w24";
export const checkboxLabelWrapper = cx("odules__Apps__components__CreateApplication__styles_ts-checkboxLabelWrapper-c9rwgxp", alertText);
export const overrideInputField = "odules__Apps__components__CreateApplication__styles_ts-overrideInputField-o8y6d31";
export const createAppGormGroup = "odules__Apps__components__CreateApplication__styles_ts-createAppGormGroup-c1r2xv3n";
export const createAppGormGroupDisabled = "odules__Apps__components__CreateApplication__styles_ts-createAppGormGroupDisabled-c1rulol";
export const advancedSwitch = "odules__Apps__components__CreateApplication__styles_ts-advancedSwitch-a1txivyw";
export const advancedSettings = "odules__Apps__components__CreateApplication__styles_ts-advancedSettings-a1cca39c";
export const inputField = "odules__Apps__components__CreateApplication__styles_ts-inputField-ic1o3od";
export const fieldDesc = "odules__Apps__components__CreateApplication__styles_ts-fieldDesc-fnctjc0";
export const fieldTopMargin = "odules__Apps__components__CreateApplication__styles_ts-fieldTopMargin-f27w6rk";
export const fieldBottomMargin = "odules__Apps__components__CreateApplication__styles_ts-fieldBottomMargin-f18rfhbh";
export const height100 = "odules__Apps__components__CreateApplication__styles_ts-height100-hr2004m";
export const height0 = "odules__Apps__components__CreateApplication__styles_ts-height0-h1tyyj0q";
export const iconContainer = "odules__Apps__components__CreateApplication__styles_ts-iconContainer-i2ecg0z";
export const createAppContainer = "odules__Apps__components__CreateApplication__styles_ts-createAppContainer-cpohm4y";
export const modalStyle = "odules__Apps__components__CreateApplication__styles_ts-modalStyle-m15vw3oe";
export const defaultRadioButton = "odules__Apps__components__CreateApplication__styles_ts-defaultRadioButton-dtmpz29";
export const activeRadioButton = "odules__Apps__components__CreateApplication__styles_ts-activeRadioButton-arddn4x";
export const inputTypeRadioContainer = "odules__Apps__components__CreateApplication__styles_ts-inputTypeRadioContainer-isw96wa";
export const radioLabel = "odules__Apps__components__CreateApplication__styles_ts-radioLabel-r1xfu0xx";
export const recommendWorkflow = "odules__Apps__components__CreateApplication__styles_ts-recommendWorkflow-r119ux36";
export const radioButtonMessageLabel = "odules__Apps__components__CreateApplication__styles_ts-radioButtonMessageLabel-r1qkdy5g";
export const disableRadioButton = "odules__Apps__components__CreateApplication__styles_ts-disableRadioButton-dj0aled";
export const toggleContainer = "odules__Apps__components__CreateApplication__styles_ts-toggleContainer-t1wdewvd";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");