import { Button } from 'reakit/Button';
import { Checkbox } from 'reakit/Checkbox';
import { base, selected as s } from './Tag_styles';

export interface TagProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onChange'> {
  id: string;
  selected?: boolean;
  onChange: (a: string) => void;
  disabled?: boolean;
}

export const Tag: React.FC<TagProps> = ({ id, children, selected, disabled, onChange, ...rest }) => {
  const className = selected ? s : base;
  return (
    <Checkbox as={Button} checked={selected} className={className} onChange={() => onChange(id)} disabled={disabled} {...rest}>
      {children}
    </Checkbox>
  );
};
