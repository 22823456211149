import { KeyboardEvent, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { cx } from '@linaria/core';
import cogoToast from 'cogo-toast';
import { Form, Formik } from 'formik';
import { string, object } from 'yup';
import { createOrgTE } from 'api/organizations';
import { Button } from 'components/Button';
import { InputField } from 'components/Formik/InputField';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useMutationTE } from 'utils/react-query';
import { formStyles, buttonWrapper, orgInfoFormStyles } from './styles';

type CreateOrganizationFields = {
  orgId: string;
  orgName: string;
  billingEmail: string;
};

export type OrgInfoProps = {
  orgCreateCallback?: (org: CF.API.Organizations.Organization) => void;
};

const stringRules = {
  generic: string().min(1, 'Too Short!').max(240, 'Too Long!').required('Required'),
  identifier: string().min(1, 'Too Short!').max(32, 'Too Long!').required('Required'),
  email: string().email().required('Required'),
};

export const OrgInfo: React.FC<OrgInfoProps> = ({ orgCreateCallback }) => {
  const userId = useCurrentUserId();
  const queryClient = useQueryClient();
  const [onIdChange, { errorString }] = useIdValidation$({
    userId,
    entityName: 'user',
  });

  const createOrganizationMutation = useMutationTE(
    (values: CreateOrganizationFields) =>
      pipe(
        createOrgTE(
          {
            organization: {
              id: values.orgId.trim(),
              name: values.orgName.trim(),
              billing_email: values.billingEmail.trim(),
            },
          },
          errorToReactLeft,
        ),
      )(),
    {
      onSuccess: ({ organizations: [createdOrg] }) => {
        cogoToast.success(`Organization "${createdOrg.name}" created.`);
        orgCreateCallback?.(createdOrg);
        queryClient.invalidateQueries([queryKeys.Organizations]);
      },
      onError: ({ props }) => {
        cogoToast.error(props?.reason || 'There was an error creating organization.', { heading: 'Error' });
      },
    },
  );

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        orgId: '',
        orgName: '',
        billingEmail: '',
      }}
      validationSchema={object().shape({
        billingEmail: stringRules.email,
        orgName: stringRules.generic,
        orgId: stringRules.identifier.test('org-ID', errorString, (value) => {
          if (value) {
            onIdChange(value);
            return !errorString;
          }

          return false;
        }),
      })}
      onSubmit={(values: CreateOrganizationFields) => {
        createOrganizationMutation.mutate(values);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className={cx(formStyles, orgInfoFormStyles)}>
          <InputField
            name="orgName"
            label="Organization name"
            placeholder="Write your Organization name"
            truncateError
            required
            onKeyDown={handleKeyDown}
          />
          <InputField name="orgId" label="Organization ID" placeholder="Write an unique ID" truncateError required onKeyDown={handleKeyDown} />
          <InputField
            name="billingEmail"
            label="Billing email"
            placeholder="Write your billing email"
            truncateError
            required
            onKeyDown={handleKeyDown}
          />

          <div className={buttonWrapper}>
            <Button
              variant="primary"
              size="md"
              id="create-organization-submit"
              type="submit"
              data-testid="Confirm"
              disabled={createOrganizationMutation.isLoading || !isValid || !dirty}
              loading={createOrganizationMutation.isLoading}
            >
              Create organization
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
