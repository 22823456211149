import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const createTeamTE = <E = CF.API.ApiError>(
  {
    orgId,
    body,
  }: {
    orgId: string;
    body: {
      teams: Pick<CF.API.Organizations.Team, 'id' | 'name' | 'default_role_id'>[];
    };
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListTeamsResponse> =>
  fetchTE<CF.API.Organizations.ListTeamsResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams`,
      method: 'POST',
      body,
    },
    onRejected,
  );
