import { isEmpty, xor } from 'lodash';

export const insertAt = <T>(array: T[], index: number, item: T): T[] => [...array.slice(0, index), item, ...array.slice(index)];

export const convertToMap = <T extends Record<never, never>>(arr: T[], key: keyof T): Record<string, T> => {
  return arr.reduce((acc, item) => {
    acc[item[key] as unknown as string] = item;
    return acc;
  }, {} as Record<string, T>);
};

export const areArraysEqual = <T>(array1: T[], array2: T[]): boolean => isEmpty(xor(array1, array2));
