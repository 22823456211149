/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Fragment, useMemo } from 'react';
import { FILTERS, FILTER_ID, IFilter, TOGGLE_IDS } from './constant';
import { toggleContainer } from './styles';

type AllStarTemplateToggleProps = {
  activeToggle: string;
  onToggle: (filter: IFilter) => void;
  showTemplate?: boolean;
  showStarred?: boolean;
};

export const AllStarTemplateToggle = ({
  activeToggle = FILTER_ID.ALL,
  onToggle,
  showTemplate = false,
  showStarred = false,
}: AllStarTemplateToggleProps): JSX.Element => {
  const filterTemplate = ({ id }: IFilter) => {
    if (id === FILTER_ID.TEMPLATES) {
      return showTemplate;
    }
    if (id === FILTER_ID.STARRED) {
      return showStarred;
    }
    return true;
  };

  const filters = useMemo(() => FILTERS.filter(filterTemplate), [showTemplate, showStarred]);

  return (
    <div className={toggleContainer}>
      <div className="toggle_radio">
        {filters.map((filter, idx) => {
          return (
            <Fragment key={filter.id}>
              <input
                id={TOGGLE_IDS[idx]}
                name="toggle_option"
                className="toggle_option"
                type="radio"
                checked={activeToggle === filter.id}
                onChange={() => onToggle(filter)}
              />
              <label className="toggle_label" htmlFor={TOGGLE_IDS[idx]}>
                {filter.name}
              </label>
            </Fragment>
          );
        })}
        <div className="toggle" />
      </div>
    </div>
  );
};
