import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { withDetailEditButton } from 'modules/Models/components/ModelViewer/withDetailEditButton';
import { either } from 'fp-ts';
import { noop, pipe } from 'utils/fp';
import { css, cx } from '@linaria/core';
import { Alert } from 'components/Alert';
import cogoToast from 'cogo-toast';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { useRouter } from 'next/router';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useModalActions } from 'components/ModalManager/Context';
import { flex } from 'styles/utils';
import { dialogComponents } from 'components/Dialog/dialogComponents';
import { cssHelpers } from 'styles/utils/core';
import { Button } from 'components/deprecated/Button';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { IconInfoFill } from 'components/Icons';
import nProgress from 'nprogress';
import { capitalize } from '@mui/material';
type EntityName = 'model' | 'workflow' | 'app' | 'module';
type Props = {
  editableEntity: CF.UI.EditableEntity;
  entityName: EntityName;
};
const alertStyles = "odules__EntityViewers__components__EditEntityId__EditEntityId_tsx-alertStyles-a1imfzna";
function EditableDetail_({
  editableEntity,
  entityName
}: Props): JSX.Element | null {
  const router = useRouter();
  const ref = useRef<HTMLInputElement | null>(null);
  const [newId, setNewId] = useState(editableEntity.data.id);
  const [clickCallback, {
    validationResponse,
    errorString
  }] = useIdValidation$({
    userId: editableEntity.data.user_id,
    appId: 'app_id' in editableEntity.data ? editableEntity.data.app_id : undefined,
    entityName
  });
  const failed = (l: ReactLeft): void => {
    cogoToast.error(l.props?.reason, {
      heading: l.props?.title
    });
    setNewId(editableEntity.data.id);
  };
  const succeeded = (): void => {
    router.push({
      query: {
        ...router.query,
        [`${entityName}Id`]: newId
      }
    });
  };

  /** istanbul ignore next: temporary checks to be removed soon as editableWorkflow supports id editing */
  const updateId: () => Promise<void> =
  // temporary check until all enitities have ID editing feature
  'updateId' in editableEntity && newId !== editableEntity.data.id ? (): Promise<void> => editableEntity?.updateId ? editableEntity.updateId(newId).then(value => {
    return pipe(value, either.fold(failed, succeeded));
  }) : Promise.resolve(undefined) : () => Promise.resolve(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const onCancelModal = useCallback(() => {
    setNewId(editableEntity.data.id);
  }, []);
  const openConfirmationModal = useConfirmationModal(updateId, entityName, onCancelModal);
  const toggleEditing = (): void => setIsEditing(isE => {
    if (isE) {
      openConfirmationModal();
      return false;
    }
    return true;
  });
  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = evt => {
    if (evt.key === 'Enter') {
      setIsEditing(false);
      openConfirmationModal();
    }
  };
  useEffect(() => {
    if (isEditing && ref.current) {
      ref.current.focus();
      ref.current.setSelectionRange(0, ref.current.value.length);
    }
  }, [isEditing]);
  return withDetailEditButton({
    onClick: toggleEditing,
    isEditable: editableEntity.isEditable,
    isEditing,
    isDisabled: isEditing && Boolean(errorString),
    testId: `${entityName}-id-edit`,
    content: <>
        <input ref={ref}
      // if not editing and empty field; give empty placeholder
      value={isEditing ? newId : newId || '--'} disabled={!isEditing} className={cx(errorString && 'invalid')} onChange={x => {
        setNewId(x.target.value);
        clickCallback(x.target.value);
      }} onKeyUp={handleKey} data-testid={`${entityName}-id-input`} />
        {isEditing && <>
            {errorString ? <Alert className={alertStyles} type="error">
                {errorString}
              </Alert> : validationResponse && <Alert className={alertStyles} type="success">
                  Valid Id
                </Alert>}
          </>}
      </>
  });
}
export const EditEntityId = observer(EditableDetail_);
export function useConfirmationModal(updateId: () => Promise<void>, entityName: EntityName, onCancelModal?: () => void): () => void {
  const {
    openModal,
    closeModal
  } = useModalActions();
  return () => openModal({
    id: 'edit_entity_id_entityviewer',
    title: '',
    content: <EntityIdEditWarning entityName={entityName} onConfirm={() => {
      nProgress.start();
      closeModal({
        id: 'edit_entity_id_entityviewer'
      });
      updateId().then(nprogressDone).catch(nprogressDone);
    }} onClose={() => {
      if (onCancelModal) {
        onCancelModal();
      }
      closeModal({
        id: 'edit_entity_id_entityviewer'
      });
    }} />,
    makeActions: noop
  });
}
function nprogressDone(): void {
  nProgress.done();
}
export function EntityIdEditWarning({
  entityName,
  onConfirm,
  onClose
}: {
  entityName: string;
  onConfirm: () => void;
  onClose: () => void;
}): JSX.Element {
  return <div className="wrapper">
      <div className={cx(flex.init, flex.alignItems.center)}>
        <dialogComponents.Heading>{`Change ${capitalize(entityName)} ID`}</dialogComponents.Heading>
        <dialogComponents.CloseButton onClick={onClose} />
      </div>
      <div className={cx(cssHelpers.padding.y[1], cssHelpers.padding.x[1])}>
        <Alert type="error">
          <IconInfoFill size={18} color="#CF536C" />
          This operation can have unintended side effects.
        </Alert>
        <ul className={cx('with-bullets', cssHelpers.margin.y[2])}>
          <li>This may break other users&apos; work if this {entityName} is shared with others or public.</li>
          <li>This will be the {entityName}_id used throughout our API, docs and portal.</li>
          <li>We will not set up redirects for your old {entityName} urls.</li>
          <li>We will not attempt to re-route your old {entityName}_id.</li>
        </ul>
        <ButtonGroup className={cx(flex.init, flex.justifyContent.end)}>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button kind="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonGroup>
      </div>
    </div>;
}

require("./EditEntityId.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./EditEntityId.tsx");