import { useState, useCallback } from 'react';
import { useSubmitOutside } from 'utils/formik/helpers';
import { formatAppResourceCounts, useAppResourceCountsConditionally } from 'modules/Listings/hooks/useAppResourceCountsConditionally';
import { Instance } from 'mobx-state-tree';
import { appMST } from 'modules/Apps/listingStore/mst-types';
import { getAllCoverImages } from 'utils/coverImage';
import { IconCloseRound } from 'components/Icons';
import { Button } from 'components/Button';
import { TagList } from 'components/EntityCard/TagList';
import { container } from './UseApplicationTemplateModal.styles';
import { DuplicateApplicationForm } from './DuplicateApplicationForm';

export interface UseApplicationTemplateModalProps {
  closeAction: (isCanceled?: boolean) => void;
  onAppCreated: (createdApp: CF.API.Apps.DuplicatedApp) => void;
  selectedApp: Instance<typeof appMST>;
}

export const UseApplicationTemplateModal: React.FC<UseApplicationTemplateModalProps> = ({ selectedApp, closeAction, onAppCreated }) => {
  const [formRef, handleSubmitThroughRef] = useSubmitOutside();
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { smallImage } = getAllCoverImages(selectedApp);

  const appResourceCounts = useAppResourceCountsConditionally(selectedApp, true);
  const tags = formatAppResourceCounts(appResourceCounts).map((count) => ({ uniqueKey: `${selectedApp.id}-tags`, item: count }));

  const onAppDuplicated = useCallback((createdApp: CF.API.Apps.DuplicatedApp) => {
    onAppCreated(createdApp);
  }, []);

  const handleCancelClick = () => {
    closeAction();
  };

  const handleDuplicateApp = () => {
    handleSubmitThroughRef();
  };

  return (
    <div className={container}>
      <div className="header">
        <div>Create an App from a template</div>
        <Button variant="tertiary" className="close-button" onClick={handleCancelClick}>
          <IconCloseRound size={24} />
        </Button>
      </div>
      <div className="desc">
        {smallImage ? <div className="thumbnail" style={{ backgroundImage: `url(${smallImage})` }} /> : null}
        <div className="tags">
          <div className="title">Template: {selectedApp.name}</div>
          <TagList tags={tags} />
        </div>
      </div>
      <DuplicateApplicationForm
        appId={selectedApp?.id}
        userOrOrgId={selectedApp?.user_id}
        setIsLoaded={setIsLoaded}
        setSubmitDisabled={setSubmitDisabled}
        ref={formRef}
        onAppDuplicated={onAppDuplicated}
      />
      <div className="buttons">
        <Button variant="primary" onClick={handleDuplicateApp} disabled={!isLoaded || submitDisabled}>
          Create App
        </Button>
      </div>
    </div>
  );
};
