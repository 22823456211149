import { cx, css } from '@linaria/core';
import { ellipsis, lighten } from 'polished';
import { baseUnits, flex, theming } from 'styles/utils';
export const _status = "omponents__Table__Cells__Cells_styles_tsx-_status-_v8zwoc";
export const success = `${"omponents__Table__Cells__Cells_styles_tsx-success-s1cakt2w"}
  ${_status}
`;
export const error = `${"omponents__Table__Cells__Cells_styles_tsx-error-e1dycvad"}
  ${_status}
`;
export const info = `${"omponents__Table__Cells__Cells_styles_tsx-info-iwcikri"}
  ${_status}
`;
export const link = cx("omponents__Table__Cells__Cells_styles_tsx-link-l1f21907", flex.init, flex.alignItems.center, flex.justifyContent.between);
export const actionsWrapper = `${"omponents__Table__Cells__Cells_styles_tsx-actionsWrapper-a9phs3g"}
  ${flex.init}
  ${flex.alignItems.center}
`;

require("./Cells.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Cells.styles.tsx");