import React, { FC, useCallback } from 'react';
import { Legacy_MenuItem, MenuGroup } from 'modules/Sidebar/components/Menu/Menu';
import { IconEvaluations } from 'components/Icons';

interface FixedModuleVersionsSidebarMenuProps {
  fixedModules: Array<{ module_url: string; display_name: string }>;
  appHref: (path?: string) => string;
  isCollapsed?: boolean;
}

export const FixedModuleVersionsSidebarMenu: FC<FixedModuleVersionsSidebarMenuProps> = ({ isCollapsed, fixedModules, appHref }) => {
  const renderMenuText = useCallback((text) => (!isCollapsed ? text : ''), [isCollapsed]);

  return (
    <MenuGroup title={renderMenuText('Installed Modules')}>
      {fixedModules.map(({ display_name, module_url }) => (
        <Legacy_MenuItem
          key={module_url}
          icon={<IconEvaluations size={22} />}
          className={!isCollapsed ? 'menu-subnav' : 'sidebar-menu-item'}
          href={appHref(`/installed_module_versions?module_url=${encodeURIComponent(module_url)}&display_name=${encodeURIComponent(display_name)}`)}
          isCollapsed={isCollapsed}
          title={display_name}
        >
          {renderMenuText(display_name)}
        </Legacy_MenuItem>
      ))}
    </MenuGroup>
  );
};
