/* eslint-disable unicorn/no-useless-undefined */
import { createContext, useContext, useEffect, useReducer } from 'react';
import { nanoid } from 'nanoid';
import { noop } from 'utils/fp';
import { useRouter } from 'next/router';
import type { ModalProps } from './Modal/Modal';

export enum ModalActionTypes {
  Close = 'CLOSE_MODAL',
  CloseAll = 'CLOSE_ALL_MODALS',
  Open = 'OPEN_MODAL',
  Destroy = 'DESTROY_MODAL',
}

type ModalActionMap = import('types').ActionMap<ModalPayload>;
type HandlerMap = import('types').HandlerMap<ModalActionMap, InitialStateType>;

interface ModalObject extends OpenModalConfig {
  content: import('react').ReactNode;
  title: string;
  id: string;
  closed?: boolean;
  persistOnRouteChange?: boolean;
}

type ModalPayload = {
  [ModalActionTypes.Open]: ModalObject;
  [ModalActionTypes.Close]: { id: string };
  [ModalActionTypes.Destroy]: { id: string };
  [ModalActionTypes.CloseAll]: never;
};

export type ModalActions = ModalActionMap[keyof ModalActionMap];

type InitialStateType = {
  modals: ModalObject[];
  animated?: boolean;
};
export const initialState: InitialStateType = {
  modals: [],
  animated: false,
};

type ReactNode = import('react').ReactNode;

export const ModalStateContext = createContext<InitialStateType>(initialState);
export const ModalDispatchContext = createContext<import('react').Dispatch<ModalActions>>(noop);

const handlerMap: HandlerMap = {
  [ModalActionTypes.Open]: (state, action) => ({
    ...state,
    modals: [
      ...state.modals.filter((modal) => modal.id !== action.payload.id),
      {
        ...action.payload,
        title: action.payload.title || '',
        id: action.payload.id,
        closed: false,
      },
    ] as ModalObject[],
  }),
  [ModalActionTypes.Close]: (state, action) => ({
    ...state,
    modals: state.modals.map((modal) => ({
      ...modal,
      closed: modal.id === action.payload.id ? true : modal.closed,
    })),
  }),
  [ModalActionTypes.Destroy]: (state, action) => ({
    ...state,
    modals: state.modals.filter((modal) => modal.id !== action.payload.id),
  }),
  [ModalActionTypes.CloseAll]: (state) => ({
    ...state,
    modals: state.modals.filter(({ persistOnRouteChange }) => !!persistOnRouteChange),
  }),
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: InitialStateType, action: ModalActions) => {
  const handler = handlerMap[action.type];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return handler ? handler(state, action as any) : state;
};

export const ModalProvider = ({ children, animated = false }: { children: ReactNode; animated?: boolean }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    animated,
  });

  const router = useRouter();

  const closeAllModals = () => {
    dispatch({
      type: ModalActionTypes.CloseAll,
    });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeAllModals);
    return () => {
      router.events.off('routeChangeStart', closeAllModals);
    };
  }, [state.modals]);

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState(): InitialStateType {
  return useContext(ModalStateContext);
}

export function useModalDispatch(): import('react').Dispatch<ModalActions> {
  return useContext(ModalDispatchContext);
}

export interface OpenModalConfig extends Partial<ModalProps> {
  content: ReactNode;
  title: string;
  /**
   * Unique ID for the modal to be used to programatically close it. I
   * If not provided, we generate a unique ID automatically
   * `openModal` returns the modal id in both cases.
   */
  id?: string;
  persistOnRouteChange?: boolean;
  scrollOnOverflow?: boolean;
}

export type CloseModalConfig = { id: string };

export function useModalActions(): {
  openModal: (a: OpenModalConfig) => void;
  closeModal: (a: CloseModalConfig) => void;
  closeModals: (args: CloseModalConfig[]) => void;
} {
  const dispatch = useContext(ModalDispatchContext);
  const modalStateContext = useContext(ModalStateContext);
  const animated = !!modalStateContext?.animated;
  /**
   * Returns unique modal id which can be used to hide modal.
   */
  const openModal = (args: OpenModalConfig): string => {
    // auto-generate id if not given
    const id = args.id || nanoid(4);
    dispatch({
      type: ModalActionTypes.Open,
      payload: {
        id,
        initialState: { visible: true, animated },
        ...args,
      },
    });
    return id;
  };

  const closeModal = (args: CloseModalConfig): void =>
    dispatch({
      type: ModalActionTypes.Close,
      payload: {
        id: args.id,
      },
    });

  const closeModals = (args: CloseModalConfig[]): void => {
    for (const arg of args) {
      dispatch({
        type: ModalActionTypes.Close,
        payload: {
          id: arg.id,
        },
      });
    }
  };

  return { openModal, closeModal, closeModals };
}

export const testable = {
  handlerMap,
  initialState,
  reducer,
};
