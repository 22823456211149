import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE, fetchApi } from 'utils/request';

export const listModelsTE = <E = CF.API.ApiError>(
  {
    abortSignal,
    userId,
    appId,
    params = '',
    sessionToken = undefined,
  }: { abortSignal?: AbortSignal; userId?: string; appId?: string; params?: string; sessionToken?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Models.ListModelsResponse> =>
  fetchTE<CF.API.Models.ListModelsResponse, E>(
    {
      signal: abortSignal,
      path: `/api/_api/v2${userId ? `/users/${userId}` : ''}${appId ? `/apps/${appId}` : ''}/models${params ? `?${params}` : ''}`,
      method: 'GET',
      sessionToken,
    },
    onRejected,
  );

export const apiListModels = ({
  userId,
  params = '',
  sessionToken,
}: {
  userId?: string;
  params?: string;
  sessionToken?: string;
}): Promise<CF.API.Models.ListModelsResponse> =>
  fetchApi({
    path: userId ? `/api/_api/v2/users/${userId}/models${params ? `?${params}` : ''}` : `/api/_api/v2/models${params ? `?${params}` : ''}`,
    sessionToken,
    method: 'GET',
  });
