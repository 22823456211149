import { cx } from '@linaria/core';
import { MouseEventHandler, useCallback } from 'react';
import { blobToBase64 } from 'utils/general';
import { useDropzone } from 'react-dropzone';
import cogoToast from 'cogo-toast';
import { Spinner } from 'components/Spinner/Spinner';
import { genGradient } from 'components/EntityCard/utils';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { useHasScopes } from 'modules/Auth/hooks/scopes';
import { getAllCoverImages, SupportedCoverImageEntities } from 'utils/coverImage';
import { IconCamera, IconDelete } from 'components/Icons';
import { useMeasure } from 'react-use';
import { queryKeys, useMutationTE } from 'utils/react-query';
import { useQueryClient } from 'react-query';
import { observer } from 'mobx-react-lite';
import { either } from 'fp-ts';
import { noop } from 'utils/fp';
import { useProgressiveImg } from 'utils/hooks/useProgressiveImage';
import { fileUploadArea, thumbnail, uploadAreaText, spinnerDiv, noImageApp } from './CoverImage.styles';
import { ACTION_UPLOAD, coverImageApiEffects } from './apiEffects';

const dragActiveState = (
  <p>
    <span className="bold">Drop files here...</span>
  </p>
);

const dragInactiveState = <span className="bold">Change cover image</span>;

interface CoverImageProps {
  entity: SupportedCoverImageEntities;
  entityName: CF.UI.EditableEntityNames;
}

export const CoverImage: React.FC<CoverImageProps> = observer(({ entity, entityName }) => {
  const [measureRef, { width }] = useMeasure<HTMLDivElement>();
  const queryClient = useQueryClient();
  const { smallImage, largeImage } = getAllCoverImages(entity);
  const { src } = useProgressiveImg(smallImage, largeImage);
  const queryInvalidationKey = getQueryInvalidationKey(entity, entityName);
  const requiredScopes = getPermissionMatchers(entityName);
  const mutationFn = getMutationFn(entity, entityName);
  const isUploadingAllowed = useHasScopes(requiredScopes, { appId: 'app_id' in entity ? entity.app_id : entity.id, userOrOrgId: entity.user_id });

  const { mutate, isLoading } = useMutationTE(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryInvalidationKey);
    },
    onError: ({ props }) => {
      cogoToast.error(props?.reason || 'Failed to update cover image.');
    },
  });

  const onDrop = useCallback(
    (files) => {
      async function upload(file: File): Promise<void> {
        try {
          const base64 = (await blobToBase64(file)).split(';base64,')[1];
          mutate({ image: { base64 }, action: ACTION_UPLOAD[entityName], base64Image: base64 });
        } catch (e: unknown) {
          console.error(e);
        }
      }

      const fileToUpload = files[0];
      upload(fileToUpload);
    },
    [mutate, isLoading],
  );

  const deleteCoverImage: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    mutate({ image: { url: entity?.image?.url }, action: 'remove', base64Image: '' });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/jpg,image/jpeg,image/png,image/tif,image/tiff,image/bmp,image/webp',
  });

  if (!isUploadingAllowed) {
    return (
      <div
        {...getRootProps()}
        ref={measureRef}
        aria-labelledby="file-upload-area-text"
        data-testid="cover-image"
        className={cx(fileUploadArea)}
        style={{ height: width }}
      >
        {src ? (
          <div
            className={thumbnail}
            style={{
              backgroundImage: `url("${src}")`,
            }}
          />
        ) : (
          <div
            className={noImageApp}
            style={{
              background: genGradient(entity.id, 'diagonal'),
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      ref={measureRef}
      aria-labelledby="file-upload-area-text"
      data-testid="cover-image-editable"
      className={cx(fileUploadArea, isDragActive && 'dragging')}
      style={{ height: width }}
    >
      <button type="button" onClick={open}>
        <input {...getInputProps()} />
        <div id="file-upload-area-text" className={uploadAreaText}>
          {src ? (
            <>
              <div
                data-testid="uploaded-cover-image"
                className={thumbnail}
                style={{
                  backgroundImage: `url("${src}")`,
                }}
              >
                <div data-testid="upload-area">
                  <IconCamera data-testid="upload-icon" size={24} />
                  {!isDragActive ? dragInactiveState : dragActiveState}
                </div>
                <div onKeyPress={noop} className="test" tabIndex={0} role="button" data-testid="delete-area" onClick={deleteCoverImage}>
                  <IconDelete data-testid="delete-icon" size={24} />
                </div>
              </div>
            </>
          ) : (
            <div data-testid="no-image-upload-area" className="upload-area">
              <IconCamera size={24} />
              {!isDragActive ? dragInactiveState : dragActiveState}
            </div>
          )}
        </div>
        {isLoading && (
          <div className={spinnerDiv}>
            <Spinner />
          </div>
        )}
      </button>
    </div>
  );
});

function getPermissionMatchers(entityName: CF.UI.EditableEntityNames) {
  switch (entityName) {
    case 'app':
      return [permissionMatchers.app.edit];
    case 'model':
      return [permissionMatchers.model.edit];
    case 'workflow':
      return [permissionMatchers.workflow.edit];
    case 'module':
      return [permissionMatchers.modules.edit];
    case 'dataset':
      return [permissionMatchers.datasets.edit];
    default:
      return [];
  }
}

function getMutationFn(entity: SupportedCoverImageEntities, entityName: CF.UI.EditableEntityNames) {
  return (variables: { base64Image: string; action: 'remove' | 'overwrite' | 'merge'; image: CF.API.Image }) => {
    const { action, image } = variables;
    const appId = 'app_id' in entity ? entity.app_id : entity.id;
    const userOrOrgId = entity.user_id;

    switch (entityName) {
      case 'app':
        return coverImageApiEffects.updateAppTE(userOrOrgId, appId, image, action)();
      case 'model':
        return coverImageApiEffects.updateModelTE(userOrOrgId, appId, entity.id, image, action)();
      case 'workflow':
        return coverImageApiEffects.updateWorkflowTE(userOrOrgId, appId, entity.id, image, action)();
      case 'module':
        return coverImageApiEffects.updateModuleTE(userOrOrgId, appId, entity, image, action)();
      case 'dataset':
        return coverImageApiEffects.updateDatasetTE(userOrOrgId, appId, entity.id, image, action)();
      default:
        return Promise.resolve(either.right({}));
    }
  };
}

function getQueryInvalidationKey(entity: SupportedCoverImageEntities, entityName: CF.UI.EditableEntityNames) {
  const appId = 'app_id' in entity ? entity.app_id : entity.id;
  const userOrOrgId = entity.user_id;

  switch (entityName) {
    case 'app':
      return [queryKeys.Apps, { appId, userOrOrgId }];
    case 'model':
      return [queryKeys.Models, { appId, userOrOrgId, modelId: entity.id }];
    case 'workflow':
      return [queryKeys.Workflows, { appId, userOrOrgId, workflowId: entity.id }];
    case 'module':
      return [queryKeys.Modules, { appId, userOrOrgId, moduleId: entity.id }];
    case 'dataset':
      return [queryKeys.DataSet, { appId, userOrOrgId, datasetId: entity.id }];
    default:
      return [];
  }
}
