import { cx } from '@linaria/core';
import { textRegularLight } from 'styles/typography';
import { flex } from 'styles/utils';
import { errorStateContainer } from 'utils/uiStates.styles';
import { noAppSubText, noAppContent, noAppTitle } from './NoAppState.styles';

interface NoSearchStateProps {
  title: string;
  subtitle: string;
}

export const NoSearchState: React.FC<NoSearchStateProps> = (props: NoSearchStateProps) => {
  const { title, subtitle } = props;
  return (
    <div data-testid="default-empty-apps-ui" className={errorStateContainer}>
      <div className={cx(flex.init, flex.direction.column, flex.alignItems.center, noAppContent)}>
        <h3 className={cx(noAppTitle, 'title')}>{title}</h3>
        <h4 className={cx(textRegularLight, noAppSubText)}>{subtitle}</h4>
      </div>
    </div>
  );
};
