import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualSegmenterIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.85922 9.75C7.85922 9.75 10.4968 5.625 15.1125 5.625C19.7282 5.625 22.3657 9.75 22.3657 9.75C22.3657 9.75 19.7282 13.875 15.1125 13.875C10.4968 13.875 7.85922 9.75 7.85922 9.75Z"
      stroke={color}
      strokeWidth="0.9375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1126 12.225C16.4479 12.225 17.5303 11.1169 17.5303 9.75002C17.5303 8.38313 16.4479 7.27502 15.1126 7.27502C13.7773 7.27502 12.6948 8.38313 12.6948 9.75002C12.6948 11.1169 13.7773 12.225 15.1126 12.225Z"
      fill={color}
    />
    <path
      d="M24.375 23.0625V18.1875C24.375 16.8413 23.2837 15.75 21.9375 15.75C20.5913 15.75 19.5 16.8413 19.5 18.1875V23.0625C19.5 24.4087 20.5913 25.5 21.9375 25.5C23.2837 25.5 24.375 24.4087 24.375 23.0625Z"
      fill={color}
    />
    <path
      d="M16.9406 18.1874V23.0624C16.9406 24.0721 16.1221 24.8906 15.1125 24.8906H15.1125C14.1028 24.8906 13.2843 24.0721 13.2843 23.0624V18.1874C13.2843 17.1778 14.1028 16.3593 15.1125 16.3593H15.1125C16.1221 16.3593 16.9406 17.1778 16.9406 18.1874Z"
      stroke={color}
      strokeWidth="1.21875"
    />
    <path
      d="M10.1157 18.1874V23.0624C10.1157 24.0721 9.29718 24.8906 8.28754 24.8906H8.28753C7.27789 24.8906 6.45941 24.0721 6.45941 23.0624V18.1874C6.45941 17.1778 7.27789 16.3593 8.28753 16.3593H8.28754C9.29718 16.3593 10.1157 17.1778 10.1157 18.1874Z"
      stroke={color}
      strokeWidth="1.21875"
    />
  </svg>
);
