import { css } from '@linaria/core';
import { textRegular } from 'styles/typography';
import { baseTheme, baseUnits, theming } from 'styles/utils';
export const textNormal = "omponents__EntityDetails__EntityDetails_styles_ts-textNormal-t15bk4g4";
export const wrapper = "omponents__EntityDetails__EntityDetails_styles_ts-wrapper-w1jxj641";
export const group = "omponents__EntityDetails__EntityDetails_styles_ts-group-gsc30qk";
export const textHeading = `${"omponents__EntityDetails__EntityDetails_styles_ts-textHeading-tr6de19"}

  ${textRegular}
`;
export const textSubtitle = "omponents__EntityDetails__EntityDetails_styles_ts-textSubtitle-t1vw149q";

require("./EntityDetails_styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./EntityDetails_styles.ts");