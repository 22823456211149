import { cx } from '@linaria/core';
import { Row } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import { oneViewContainer } from './OneViewCell.styles';

export const OneViewCell = ({ row, style }: { row: Row; style?: Record<never, string> }) => {
  return (
    <div className={cx('row', oneViewContainer)} {...row.getRowProps({ style })}>
      {row.cells.map((cell) => {
        return (
          <div className="cell" key={uuidv4()}>
            <div className="headerCell"> {cell.render('Header')}</div>
            <div className="valueCell">{cell.render('Cell')}</div>
          </div>
        );
      })}
    </div>
  );
};
