import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { EmptyObject } from 'types/empty';
import { fetchTE, fetchApi } from 'utils/request';

/**
 * @deprecated use apiGetUserMe instead we are moving towards dropping TaskEither
 */
export const getUserMeTE = <E = CF.API.ApiError>(
  _params?: EmptyObject,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.GetUserMeResponse> =>
  fetchTE<CF.API.Users.GetUserMeResponse, E>(
    {
      path: '/api/_api/v2/users/me',
      method: 'GET',
    },
    onRejected,
  );

/**
 * @deprecated apiGetUserFeaturedFlags apiGetUserMe instead we are moving towards dropping TaskEither
 */
export const getUserFeaturedFlagsTE = <E = CF.API.ApiError>(
  _params?: EmptyObject,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.GetUserFeaturedFlagsResponse> =>
  fetchTE<CF.API.Users.GetUserFeaturedFlagsResponse, E>(
    {
      path: '/api/_api/v2/users/me/feature_flags',
      method: 'GET',
    },
    onRejected,
  );

export const apiGetUserMe = ({ sessionToken }: { sessionToken?: string }): Promise<CF.API.Users.GetUserMeResponse> =>
  fetchApi({
    path: '/api/_api/v2/users/me',
    sessionToken,
    method: 'GET',
  });

export const apiGetUserFeaturedFlags = ({ sessionToken }: { sessionToken?: string }): Promise<CF.API.Users.GetUserFeaturedFlagsResponse> =>
  fetchApi({
    path: '/api/_api/v2/users/me/feature_flags',
    sessionToken,
    method: 'GET',
  });

export const apiUserLogout = (): Promise<CF.API.BaseResponse> =>
  fetchApi({
    path: '/api/_api/v2/users/me/logout',
    method: 'POST',
    body: {},
  });
