import { css, cx } from '@linaria/core';
import { Spinner } from 'components/Spinner';
import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { textRegular } from 'styles/typography';
import { baseTheme, flex } from 'styles/utils';
interface ScrollTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  isFetching: boolean;
  hasNextPage?: boolean;
  hasFilters: boolean;
  fetchNextPage: () => void;
}
const loadingIndicator = "omponents__Table__ScrollTrigger_tsx-loadingIndicator-lpjvl8s";
export const ScrollTrigger = ({
  isFetching,
  hasFilters,
  hasNextPage,
  fetchNextPage,
  className,
  ...rest
}: ScrollTriggerProps): JSX.Element => {
  /** trigger next page on scrolling to bottom */
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref, {
    rootMargin: '0px'
  });
  useEffect(() => {
    if (!hasFilters && hasNextPage && !isFetching && intersection && intersection.intersectionRatio > 0) {
      fetchNextPage();
    }
  }, [intersection, hasFilters, hasNextPage, isFetching]);
  return <div ref={ref} className={cx('scroll-trigger', loadingIndicator, flex.init, flex.justifyContent.center, flex.alignItems.center, className)} {...rest}>
      {!hasFilters && (hasNextPage || isFetching) ? <Spinner /> : <span className={textRegular}>no more items</span>}
    </div>;
};

require("./ScrollTrigger.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./ScrollTrigger.tsx");