import { cx } from '@linaria/core';
import { forwardRef } from 'react';
import { Input as ReakitInput, InputProps as InputPropsReactkit } from 'reakit/Input';
import { inputStyles } from './Input.styles';

export type InputProps = Omit<InputPropsReactkit, 'onChange'> & {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  variant?: 'invalid';
  as?: 'input' | 'textarea';
};

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, variant, ...props }, forwardedRef) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <ReakitInput ref={forwardedRef} className={cx(inputStyles, className, variant)} {...props} />
));
