import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export interface fetchAuthBannersResponse {
  total: number;
  results: HUBSPOT.API.AuthBannerResult[];
}

export const fetchAuthBannerTE = <E = CF.API.ApiError>(
  { portalId = '4505120', tableId = '3825190' }: { portalId?: string; tableId?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, fetchAuthBannersResponse> =>
  fetchTE<fetchAuthBannersResponse, E>(
    {
      path: `/cms/v3/hubdb/tables/${tableId}/rows?portalId=${portalId}`,
      apiHost: 'https://api.hubapi.com',
      method: 'GET',
      useRequestPrefixHeader: false,
    },
    onRejected,
  );
