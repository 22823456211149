import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { cx } from '@linaria/core';
import { tooltipStyles } from './Tooltip.styles';

export interface CopyableTooltipProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  children: React.ReactElement;
  data: React.ReactChild;
  showArrow?: boolean;
  muiTooltipProps?: Omit<MuiTooltipProps, 'title' | 'children'>;
}

export const CopyableTooltip: React.FC<CopyableTooltipProps> = ({ children, data, showArrow, className, muiTooltipProps = {}, ...rest }) => {
  const { componentsProps, ...restProps } = muiTooltipProps;

  return (
    <MuiTooltip
      title={
        <div className={cx(className, tooltipStyles)} {...rest}>
          {data}
        </div>
      }
      placement="top"
      arrow={showArrow}
      componentsProps={{
        tooltip: { style: { background: 'transparent', padding: 0 } },
        arrow: { style: { color: 'rgba(23, 31, 41, 0.9)' } },
        ...componentsProps,
      }}
      {...restProps}
    >
      {children}
    </MuiTooltip>
  );
};
