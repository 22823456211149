/* eslint-disable no-console */
import { useCallback } from 'react';
import { IconOverview, IconInputs, IconLabel, IconSettings, IconFolderOpenv1, IconCPU, IconShare2, IconModule } from 'components/Icons';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { useHasScopes } from 'modules/Auth/hooks/scopes';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { useHasAccessToAppSettings } from 'modules/AppSettings/AppSettings.hooks';
import { isIMVForDemo } from 'modules/InstalledModuleVersions/utils/imv_utils';
import { FixedModuleVersionsSidebarMenu, ModuleVersionsSidebarMenu } from 'modules/InstalledModuleVersions/components/IMVSidebarMenu';
import { MenuGroup, MenuItem, MenuStack } from './components/Menu';

/**
 * Dynamic modules are enabled when the MODULE_MANAGER_HOST variable is defined and has a value.
 */
const FIXED_MODULES = getConfig().publicRuntimeConfig.NEXT_PUBLIC_FIXED_MODULES;
const MODULE_MANAGER_HOST = getConfig().publicRuntimeConfig.NEXT_PUBLIC_MODULE_MANAGER_HOST;
const DYNAMIC_MODULES_ENABLED = MODULE_MANAGER_HOST && MODULE_MANAGER_HOST.length > 0;

interface SidebarMenuContentInterface {
  isCollapsed?: boolean;
}

export const SidebarMenuContent: React.FC<SidebarMenuContentInterface> = ({ isCollapsed }) => {
  const {
    appId = '',
    userOrOrgId,
    installedModuleVersionId,
  } = useRouter().query as { appId: string; userOrOrgId: string; installedModuleVersionId?: string };

  const appHref = appRelativeHref(userOrOrgId, appId);
  const hasAccessToAppSettings = useHasAccessToAppSettings();
  const hasAccessToInput = useHasScopes([permissionMatchers.inputs.list]);
  const hasAccessToTask = useHasScopes([permissionMatchers.tasks.list]);
  const hasAccessToDataset = useHasScopes([permissionMatchers.datasets.list]);
  const showInstalledModules = Boolean(DYNAMIC_MODULES_ENABLED || isIMVForDemo(userOrOrgId));
  const showFixedModules = Boolean(!DYNAMIC_MODULES_ENABLED && FIXED_MODULES && FIXED_MODULES.length);
  const renderMenuText = useCallback((text) => (!isCollapsed ? text : ''), [isCollapsed]);

  return (
    <MenuStack withDivider={isCollapsed} data-testid="sidebar-menu-content">
      <MenuGroup title={renderMenuText('App Information')}>
        <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref()} icon={IconOverview} title="Overview" />
        {hasAccessToAppSettings && (
          <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/settings')} icon={IconSettings} title="Settings" />
        )}
      </MenuGroup>

      <MenuGroup title={renderMenuText('AI Lake')}>
        {hasAccessToInput && (
          <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/inputs')} icon={IconInputs} title="Inputs" />
        )}
        {hasAccessToDataset && (
          <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/datasets')} icon={IconFolderOpenv1} title="Datasets">
            {renderMenuText('Datasets')}
          </MenuItem>
        )}
        <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/models')} icon={IconCPU} title="Models" />
        <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/workflows')} icon={IconShare2} title="Workflows" />
        {DYNAMIC_MODULES_ENABLED && (
          <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/modules')} icon={IconModule} title="Modules">
            {renderMenuText('Modules')}
          </MenuItem>
        )}
        {hasAccessToTask && (
          <MenuItem isCollapsed={isCollapsed} className="sidebar-menu-item" href={appHref('/tasks')} icon={IconLabel} title="Labeling Tasks">
            {renderMenuText('Labeling Tasks')}
          </MenuItem>
        )}
      </MenuGroup>

      {showInstalledModules ? (
        <ModuleVersionsSidebarMenu
          userOrOrgId={userOrOrgId}
          appId={appId}
          installedModuleVersionId={installedModuleVersionId}
          appHref={appHref}
          isCollapsed={isCollapsed}
        />
      ) : showFixedModules ? (
        <FixedModuleVersionsSidebarMenu fixedModules={FIXED_MODULES} appHref={appHref} isCollapsed={isCollapsed} />
      ) : null}
    </MenuStack>
  );
};

export function appRelativeHref(userOrOrgId: string, appId: string): (path?: string) => string {
  return (path = '') => (appId ? `/${userOrOrgId}/${appId}${path}` : '');
}
