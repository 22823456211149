import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateWorkflowTE = <E = CF.API.ApiError>(
  {
    userId,
    appId,
    body,
  }: { userId: string; appId: string; body: { workflows: Partial<CF.API.Workflows.Workflow>[]; action?: 'remove' | 'overwrite' | 'merge' } },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Workflows.ListWorkflowsRes> =>
  fetchTE<CF.API.Workflows.ListWorkflowsRes, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/workflows`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );
