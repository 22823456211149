import { useDisclosureState, Disclosure, DisclosureContent } from 'reakit/Disclosure';
import { motion, Variants } from 'framer-motion';
import { cx } from '@linaria/core';
import { IconDropArrowDown, IconDropArrowUp } from 'components/Icons';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { caretIcon, wrapper } from './CollapsableItem_styles';
const variants: Variants = {
  visible: {
    height: 'auto'
  },
  hidden: {
    height: 0
  }
};
export interface CollapsableItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: React.ReactNode;
  showCaret?: boolean;
  opened?: boolean;
}
export const CollapsableItem: React.FC<CollapsableItemProps> = ({
  title,
  showCaret = true,
  opened,
  children,
  ...rest
}) => {
  const disclosure = useDisclosureState({
    visible: opened,
    animated: 250
  });
  const Icon = disclosure.visible ? IconDropArrowUp : IconDropArrowDown;
  return <div className={cx(wrapper, rest.className)} {...rest}>
      <Disclosure data-testid="disclosure-trigger" className={cx(flex.init, flex.alignItems.center, 'CollapsableItem-trigger', disclosure.visible && 'CollapsableItem--opened')} {...disclosure}>
        <div className={cx('fullWidth', flex.init)}>{title}</div>
        {showCaret && <Icon className={caretIcon} size={20} />}
      </Disclosure>
      <motion.div className={cssHelpers.overflow.y.hidden} transition={{
      type: 'tween',
      duration: 0.25
    }} variants={variants} animate={disclosure.visible ? 'visible' : 'hidden'}>
        <DisclosureContent className="CollapsableItem-content" data-testid="disclosure-content" {...disclosure}>
          {children}
        </DisclosureContent>
      </motion.div>
    </div>;
};