import { css, cx } from '@linaria/core';
import { tooltipStyles } from 'components/Tooltip/Tooltip.styles';
import { breakpoints, flex, theming } from 'styles/utils';
export const container = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-container-c1dypkbn";
export const addButton = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-addButton-ac06bwk";
export const regularFontWeight = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-regularFontWeight-raq5ew5";
export const cardTitle = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-cardTitle-czb38vw";
export const resourceCount = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-resourceCount-rf75n56";
export const appResourceCard = cx("odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-appResourceCard-axmwe6x", flex.init, flex.gap.half);
export const displayOnTablet = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-displayOnTablet-d78c838";
export const displayOnDesktop = "odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-displayOnDesktop-d18bx3it";
export const tooltip = cx("odules__Apps__components__AppViewer__AppResources__AppResources_styles_ts-tooltip-t1qxg9y5", tooltipStyles);

require("./AppResources.styles.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./AppResources.styles.ts");