import Image from 'next/image';
import { textRegular, textRegularLight } from 'styles/typography';
import { wrapper, main } from './EmptyMarkdown.styles';

export const EmptyMarkdown = (): React.ReactElement => {
  return (
    <div className={main}>
      <p className={textRegularLight}>The owner of this app has not added any notes</p>
      <div className={wrapper}>
        <div>
          <Image src="/robot.svg" width={160} height={160} />
          <h2 className={textRegular}>Check back often</h2>
          <p className={textRegularLight}>Many exciting things are on the way!</p>
        </div>
      </div>
    </div>
  );
};
