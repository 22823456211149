import { cx } from '@linaria/core';
import { observer } from 'mobx-react-lite';
import { wrapper, group, textHeading, textSubtitle } from './EntityDetails_styles';

export type EntityField = {
  [key: string]: React.ReactNode;
};
export interface EntityOverviewProps extends React.HTMLAttributes<HTMLUListElement> {
  fields: EntityField[];
}

const EntityDetails_: import('react').FC<EntityOverviewProps> = ({ fields, className, ...rest }) => (
  <ul className={cx(wrapper, className)} {...rest}>
    {fields.map(({ label, value }: EntityField) => (
      <li key={`group-${label}`} data-testid={`group-${label}`} className={group}>
        {label && (
          <div data-testid={`field-label-${label}`} className={textHeading}>
            {label}
          </div>
        )}
        <div data-testid={`field-value-${label}`} className={textSubtitle}>
          {value}
        </div>
      </li>
    ))}
  </ul>
);

export const EntityDetails = observer(EntityDetails_);
