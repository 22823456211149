import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type ForgotPasswordPayload = {
  email: string;
};

export const postForgotPasswordTE = <E = CF.API.ApiError>(
  values: ForgotPasswordPayload,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.ForgotPasswordResponse> =>
  fetchTE<CF.API.Users.ForgotPasswordResponse, E>(
    {
      path: '/api/_api/v2/request_password_reset',
      method: 'POST',
      body: {
        ...values,
      },
    },
    onRejected,
  );
