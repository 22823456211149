import { IconCloseRound, IconSearch } from 'components/Icons';
import { baseTheme, flex } from 'styles/utils';
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { SearchInput } from 'components/Search/SearchInput';
import { cx } from '@linaria/core';
import { useRef } from 'react';
import { TextTooltip } from 'components/Tooltip';
import { SearchWrapper, chips, iconPopOverDisclosure, popOverContainer, popOverStyle, tooltipCls } from './SearchFilter.styles';

export interface SearchFilterProps {
  columnId?: string;
  filterValue: string;
  setFilter: (value: string) => void;
  onFilterChange?: (value: string) => void;
  isMobileView?: boolean;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({ columnId, filterValue, setFilter, onFilterChange, isMobileView }) => {
  const popover = usePopoverState({ modal: true });
  const inputRef = useRef<HTMLInputElement>(null);
  const chipsValue = inputRef?.current?.value;

  const handleSearch = (value: string) => {
    setFilter(value);
    onFilterChange?.(value);
  };

  const clearAll = () => {
    setFilter('');
    onFilterChange?.('');
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  if (isMobileView) {
    return (
      <SearchInput
        wrapperClassName={SearchWrapper}
        value={filterValue ?? ''}
        setValue={handleSearch}
        placeholder="Search"
        ref={inputRef}
        data-testid="seach-filter-input"
      />
    );
  }

  return (
    <>
      <Popover {...popover} className={popOverStyle} key={columnId}>
        <div data-testid="popover" className={popOverContainer}>
          <SearchInput value={filterValue} setValue={handleSearch} placeholder="Search" ref={inputRef} data-testid="seach-filter-input" />
        </div>
      </Popover>
      <div className={cx(flex.init, flex.gap.half)}>
        {chipsValue && (
          <button type="button" className={cx(chips, 'chips-custom-class')} data-testid="filter-chips">
            <IconCloseRound size={16} color={baseTheme.light.colors.gray700} onClick={clearAll} />
            <TextTooltip arrowProps={{ className: 'darkArrow' }} showArrow text={chipsValue} className={tooltipCls}>
              {`"${chipsValue || ''}"`}
            </TextTooltip>
          </button>
        )}
        <PopoverDisclosure {...popover} key={columnId}>
          <button type="button" className={iconPopOverDisclosure} data-testid="seach-filter-icon">
            <IconSearch size={15} color={baseTheme.light.colors.gray400} />
          </button>
        </PopoverDisclosure>
      </div>
    </>
  );
};
