import { css, cx } from '@linaria/core';
import { baseUnits, breakpoints, flex, theming } from 'styles/utils';
export const createOrganizationWrapper = "odules__Organizations__components__CreateOrganization__styles_ts-createOrganizationWrapper-c13gulul";
export const orgInfoFormStyles = cx("odules__Organizations__components__CreateOrganization__styles_ts-orgInfoFormStyles-o108p78k");
export const formStyles = cx("odules__Organizations__components__CreateOrganization__styles_ts-formStyles-f1ssltvh", flex.init, flex.direction.column);
export const buttonWrapper = cx("odules__Organizations__components__CreateOrganization__styles_ts-buttonWrapper-bngcuw4", flex.init, flex.alignItems.center, flex.justifyContent.end);
export const addEmailBtn = "odules__Organizations__components__CreateOrganization__styles_ts-addEmailBtn-a47ohr0";
export const dropdownOptionWrapper = "odules__Organizations__components__CreateOrganization__styles_ts-dropdownOptionWrapper-dnqbur8";
export const dropdownWrapper = "odules__Organizations__components__CreateOrganization__styles_ts-dropdownWrapper-d1q7e99v";
export const heading = "odules__Organizations__components__CreateOrganization__styles_ts-heading-h2wp4sc";
export const subheading = "odules__Organizations__components__CreateOrganization__styles_ts-subheading-s16h2tft";
export const closeIcon = "odules__Organizations__components__CreateOrganization__styles_ts-closeIcon-clmzcgz";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");