import { useState, useEffect, useCallback } from 'react';
import { either } from 'fp-ts';
import { queryKeys, useQueryTE } from 'utils/react-query';
import type { AuthMethod } from 'api/users/sso';
import type { SSOProvider } from 'modules/Auth/components/types';
import { authApiEffects } from 'modules/Auth/apiEffects';
import { SSOButton } from './SSOButton';
import { ssoProviderContainer, ssoProviderSpinner, ssoProviderWrapper } from '../styles';

type ssoProviderState = {
  allowUserPass: boolean;
  supportedProviders: SSOProvider[];
};

const methodTypeEnum = {
  userPass: 1,
  saml: 2,
  oauth: 3,
};
const initialProviderState: ssoProviderState = {
  allowUserPass: false,
  supportedProviders: [],
};

interface SSOProvidersInterface {
  isLoading?: boolean;
  isSignup?: boolean;
  recoveryPath?: string;
}

export const SSOProviders = ({ recoveryPath, isLoading, isSignup }: SSOProvidersInterface): JSX.Element => {
  const [allowUserPassword] = useState(false);
  const [providerState, setProviderState] = useState<typeof initialProviderState>(initialProviderState);

  const { data } = useQueryTE(
    [queryKeys.SSOMethods],
    authApiEffects.listSSOMethodsTE(`community=true${recoveryPath ? `&recovery_path=${encodeURIComponent(recoveryPath)}` : ''}`),
    {
      // We need to refetch after every ~8 mins: https://clarifai.atlassian.net/browse/MRK-3336
      refetchInterval: 8 * 60 * 1000,
    },
  );

  useEffect(() => {
    either.fold(
      () => setProviderState(initialProviderState),
      (providers: AuthMethod[]) => {
        const supportedProviders = deriveStateChangeFromDiscoveryMethods(providers);
        setProviderState(supportedProviders);
      },
    )(data);
  }, [data]);

  const deriveStateChangeFromDiscoveryMethods = useCallback(
    (methods = []) => {
      const nextState: ssoProviderState = {
        allowUserPass: allowUserPassword,
        supportedProviders: [],
      };

      const mapDiscoveryMethods = (method: SSOProvider): void => {
        const idProviders = [...nextState.supportedProviders];

        switch (method.type) {
          case methodTypeEnum.userPass:
            nextState.allowUserPass = nextState.allowUserPass || method.enabled; // shouldn't override if already allowed
            break;

          case methodTypeEnum.saml:
          case methodTypeEnum.oauth:
            nextState.supportedProviders = [...idProviders, method];
            break;

          default:
            break;
        }
      };

      methods.forEach(mapDiscoveryMethods);
      return nextState;
    },
    [allowUserPassword],
  );

  return (
    <div className={ssoProviderWrapper}>
      {Boolean(providerState.supportedProviders.length) && <hr data-content="or" data-testid="or-hr" />}
      <div className={ssoProviderContainer}>
        {isLoading && <div className={ssoProviderSpinner} />}
        {providerState.supportedProviders.map(
          ({ name, icon, auth_link, enabled }: SSOProvider) =>
            enabled && <SSOButton key={name} auth_link={auth_link} name={name} icon={icon} is_signup={isSignup} />,
        )}
      </div>
    </div>
  );
};
