import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const SMSIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 46 46">
    <path
      id="iconmonstr-sms-2"
      d="M22.387,4.731c10.287,0,18.655,6.7,18.655,14.937,0,9.173-9.6,14.852-18.488,14.852a31.8,31.8,0,0,1-8.2-1.2c-1.866,1.144-2.974,1.935-7.97,3.4a17.459,17.459,0,0,0,1.121-7.957c-1.561-1.866-3.778-4.477-3.778-9.089,0-8.236,8.369-14.937,18.655-14.937Zm0-3.731C10.563,1,0,8.884,0,19.669A16.449,16.449,0,0,0,3.819,30.162c.1,3.414-1.908,8.313-3.718,11.88,4.854-.877,11.755-2.813,14.883-4.731a31.977,31.977,0,0,0,7.572.938c13.216,0,22.219-9.024,22.219-18.583C44.773,8.826,34.138,1,22.387,1Zm-8.3,18.937a2.8,2.8,0,0,1,.688,1.929,2.929,2.929,0,0,1-1.164,2.4,5.1,5.1,0,0,1-3.246.923,6.976,6.976,0,0,1-3.153-.685l.511-2.02a6.159,6.159,0,0,0,2.8.7,2.269,2.269,0,0,0,1.28-.312,1.009,1.009,0,0,0,.043-1.664,5,5,0,0,0-1.435-.666q-3.07-1.024-3.069-3.2A2.859,2.859,0,0,1,8.47,15.027a4.666,4.666,0,0,1,3.011-.91,6.868,6.868,0,0,1,2.864.556l-.558,1.957a5.478,5.478,0,0,0-2.339-.522,2.085,2.085,0,0,0-1.17.285.888.888,0,0,0-.065,1.468,6.427,6.427,0,0,0,1.7.776,5.161,5.161,0,0,1,2.177,1.3ZM36.856,19.9a5.224,5.224,0,0,0-2.2-1.317,6.534,6.534,0,0,1-1.677-.765.831.831,0,0,1,.06-1.377,2.019,2.019,0,0,1,1.136-.276,5.4,5.4,0,0,1,2.377.55l.59-2.073-.041-.022a7,7,0,0,0-2.892-.56,4.722,4.722,0,0,0-3.045.923,2.918,2.918,0,0,0-1.153,2.364c0,1.476,1.048,2.569,3.115,3.255a5.137,5.137,0,0,1,1.412.659.948.948,0,0,1-.041,1.569,2.187,2.187,0,0,1-1.244.3A6.122,6.122,0,0,1,30.41,22.4l-.541,2.14a7.078,7.078,0,0,0,3.224.709,5.157,5.157,0,0,0,3.281-.937,2.99,2.99,0,0,0,1.186-2.449,2.868,2.868,0,0,0-.705-1.968ZM24.461,14.292l-2.291,7.4-1.892-7.4H16.937l-.683,10.727h2.293l.381-8.66h.034s1.31,5.369,2.2,8.483h1.923q2.222-6.769,2.61-8.483h.049q0,2.275.254,8.66h2.421L27.84,14.292Z"
      transform="translate(0 -1)"
      fill={color}
    />
  </svg>
);
