import { defaultIconProps, IconProps } from './IconProps';

export const IconSortDown: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.80786 5.26953L0.926709 1.81215C0.492491 1.29109 0.863016 0.500001 1.54129 0.500001L6.45855 0.500001C7.13682 0.500001 7.50735 1.29109 7.07313 1.81215L4.19197 5.26953C4.09203 5.38947 3.90781 5.38947 3.80786 5.26953Z"
      fill={color}
    />
  </svg>
);

IconSortDown.defaultProps = defaultIconProps;
