import { Instance } from 'mobx-state-tree';
import { ModelMSTInstance } from 'modules/Models/listingStore/mst-types';
import { workflowMST } from 'modules/Workflows/listingStore/mst-types';
import { SupportedListingEntities } from 'modules/Listings/types';
import { appendCachebusterParam } from './url';

export type SupportedCoverImageEntities =
  | CF.API.Apps.App
  | CF.API.Models.Model
  | CF.API.Workflows.Workflow
  | CF.API.Modules.Module
  | CF.API.Datasets.Dataset;

export enum CoverImageSize {
  SMALL = 'small',
  LARGE = 'large',
}

/**
 * @returns The uploaded cover image of an entity (if any)
 * @note If you need to get the thumbnail image of an entity, then use getFeaturedCardThumbnail()
 */
export function constructCoverImageURL(entity: SupportedCoverImageEntities, preferredSize: CoverImageSize = CoverImageSize.LARGE) {
  if (entity?.image?.hosted && entity.image.hosted?.prefix && entity.image.hosted?.sizes?.[0] && entity.image?.hosted?.suffix) {
    const isPreferredSizeAvailable = preferredSize && entity.image.hosted.sizes.includes(preferredSize);
    const size = isPreferredSizeAvailable ? preferredSize : entity?.image?.hosted?.sizes?.[0];
    return `${entity.image.hosted.prefix}/${size}/${entity.image.hosted.suffix}`;
  } else if (entity?.image?.url) {
    return entity.image.url;
  }

  return '';
}

/**
 * @returns Either uploaded cover image or gets image from inputs under the entity (only models & workflows are supported as of now)
 */
export function constructThumbnailURL(entity: SupportedCoverImageEntities | SupportedListingEntities): string | undefined {
  const coverImageUrl = constructCoverImageURL(entity as CF.API.Apps.App);
  if (coverImageUrl) {
    return coverImageUrl;
  }

  return (
    (entity.hasOwnProperty('model_version') && getModelFallbackImage(entity as CF.API.Models.Model)) ||
    (entity.hasOwnProperty('nodes') && getWorkFlowFallbackImage(entity as CF.API.Workflows.Workflow)) ||
    ''
  );
}

export function getModelFallbackImage(entity: ModelMSTInstance | CF.API.Models.Model): string {
  const presetsImgUrl = entity.presets?.icon?.url;
  const modelThumbnail = presetsImgUrl || getMetadataFallbackImage(entity.metadata);
  return modelThumbnail ? appendCachebusterParam(modelThumbnail) : '';
}

export function getWorkFlowFallbackImage(entity: Instance<typeof workflowMST> | CF.API.Workflows.Workflow): string {
  const workflowThumbnail = entity.metadata?.icon?.url || getMetadataFallbackImage(entity.metadata);
  return workflowThumbnail ? appendCachebusterParam(workflowThumbnail) : '';
}

function getMetadataFallbackImage(metadata?: CF.API.Metadata): string | undefined {
  const metadataPresetInputs = metadata?.presetInputs as CF.API.PresetInput[] | undefined;
  const metadataFallbackImage = metadataPresetInputs?.find((presetInput) => presetInput?.type === 'image')?.url;
  return metadataFallbackImage;
}

export const getAllCoverImages = (entity: SupportedCoverImageEntities) => {
  const smallImage = constructCoverImageURL(entity, CoverImageSize.SMALL);
  const largeImage = constructCoverImageURL(entity, CoverImageSize.LARGE);
  return {
    smallImage: constructCoverImageURL(entity, CoverImageSize.SMALL),
    largeImage: constructCoverImageURL(entity, CoverImageSize.LARGE),
    isCoverImageAvailable: !!smallImage || !!largeImage,
  };
};
