import React, { FC } from 'react';
import { cx } from '@linaria/core';
import { textWrapper, iconWrapper, labelWrapper, iconClose } from 'components/ModelTypeIcon/ModelTypeIcon.styles';
import { TypeToIconInfoMap } from 'components/ModelTypeIcon/constants';
import { IconCloseRound } from 'components/Icons';

export type ModelTypeIconProps = {
  modelTypeId: string;
  modelTypeName?: string;
  type?: 'icon' | 'textBar';
  onClose?: (type: string) => void;
};

// Design link: https://www.figma.com/file/Lejo9j5VAjCNxRfyVv58tu/DSGN-187---Add-icons-for-model-types?node-id=70%3A10290
export const ModelTypeIcon: FC<ModelTypeIconProps> = ({ modelTypeId, modelTypeName, type = 'textBar', onClose }) => {
  const { textColor, bgColor, shadow, IconElement, title } = TypeToIconInfoMap[modelTypeId] || TypeToIconInfoMap.default;

  if (type === 'icon') {
    return (
      // TODO: CSS for this is not finished. Need to add alignment
      <div
        className={cx(iconWrapper)}
        style={{
          background: shadow,
          boxShadow: `0px 0px 0px 0.625rem ${bgColor}`,
        }}
        data-testid={`${modelTypeId}-type-icon`}
        aria-label={`icon for ${modelTypeId}`}
      >
        <IconElement color={textColor} bgColor={shadow} size={44} />
      </div>
    );
  }

  return (
    <div className={cx(textWrapper)} style={{ background: bgColor, color: textColor }}>
      <IconElement color={textColor} bgColor={bgColor} size={18} />
      <div className={labelWrapper}>{modelTypeName || title || modelTypeId}</div>
      {onClose && <IconCloseRound size={10} color="#0046D4" className={iconClose} onClick={() => onClose(modelTypeId)} />}
    </div>
  );
};
