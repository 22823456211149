import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const TextClassifierIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75022 20.2463H1.31537L5.02535 9.49939H7.95347L11.6582 20.2463H9.22338L6.53139 11.9552H6.44744L3.75022 20.2463ZM3.59804 16.022H9.34931V17.7958H3.59804V16.022Z"
      fill={color}
    />
    <path
      d="M25.4886 20.604L21.0571 23.1625V18.0453L25.4886 20.604ZM17.941 20.604C17.941 21.9093 16.8829 22.9673 15.5775 22.9673C14.2722 22.9673 13.2141 21.9093 13.2141 20.604C13.2141 19.2986 14.2722 18.2404 15.5775 18.2404C16.8829 18.2404 17.941 19.2986 17.941 20.604ZM21.5003 21.0471H15.5775V20.1607H21.5003V21.0471Z"
      fill={color}
    />
    <path
      d="M25.4886 14.6572L21.0571 17.2158V12.0987L25.4886 14.6572ZM17.941 14.6572C17.941 15.9625 16.8829 17.0207 15.5775 17.0207C14.2722 17.0207 13.2141 15.9625 13.2141 14.6572C13.2141 13.3519 14.2722 12.2938 15.5775 12.2938C16.8829 12.2938 17.941 13.3519 17.941 14.6572ZM21.5003 15.1004H15.5775V14.2141H21.5003V15.1004Z"
      fill={color}
    />
    <path
      d="M25.4886 8.71057L21.0571 11.2691V6.15204L25.4886 8.71057ZM17.941 8.71057C17.941 10.0159 16.8829 11.0741 15.5775 11.0741C14.2722 11.0741 13.2141 10.0159 13.2141 8.71057C13.2141 7.40527 14.2722 6.34709 15.5775 6.34709C16.8829 6.34709 17.941 7.40527 17.941 8.71057ZM21.5003 9.15373H15.5775V8.26742H21.5003V9.15373Z"
      fill={color}
    />
  </svg>
);
