import { useAuthState } from 'modules/Auth/AuthContext';
import { ReactElement, FC } from 'react';

type Config = {
  hideFor: 'NONE' | 'GUEST' | 'USER';
  placeholder?: ReactElement | null;
};

function makeConfig(): Config {
  return {
    hideFor: 'NONE',
    placeholder: null,
  };
}

export function withAuth<T>(config = makeConfig()): (c: FC<T>) => FC<T> {
  return (Component: FC<T>): FC<T> =>
    (props: T) => {
      const baseConfig = makeConfig();
      const { hideFor, placeholder } = {
        ...baseConfig,
        ...config,
      };
      const { authData } = useAuthState();
      const isLoggedIn = Boolean(authData);

      if (getShouldRenderPlaceholder({ hideFor, isLoggedIn })) {
        return placeholder || null;
      }

      return <Component {...{ ...props, isLoggedIn }} />;
    };
}

function getShouldRenderPlaceholder({ hideFor, isLoggedIn }: { hideFor: 'NONE' | 'GUEST' | 'USER'; isLoggedIn: boolean }): boolean {
  if (hideFor === 'GUEST' && !isLoggedIn) {
    return true;
  }

  if (hideFor === 'USER' && isLoggedIn) {
    return true;
  }

  return false;
}

export const testable = { getShouldRenderPlaceholder };
