import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listWorkflowsByAppTE = <E = CF.API.ApiError>(
  { userOrOrgId, appId, params = '', sessionToken }: { userOrOrgId?: string; appId?: string; params?: string; sessionToken?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Workflows.ListWorkflowsRes> =>
  fetchTE<CF.API.Workflows.ListWorkflowsRes, E>(
    {
      path: `/api/_api/v2/users/${userOrOrgId}/apps/${appId}/workflows${params ? `?${params}` : ''}`,
      method: 'GET',
      sessionToken,
    },
    onRejected,
  );
