import { css } from '@linaria/core';
import { baseUnits, theming } from 'styles/utils';
export const radioGroupWrapper = "omponents__RadioGroup__RadioGroup_styles_ts-radioGroupWrapper-ra3so7h";

/* input[type="radio"]:checked + label:after,
  input[type="radio"]:not(:checked) + label:after {
    content: '';
    height: calc(${baseUnits.halfUnit} / 0.5);
    width: calc(${baseUnits.halfUnit} / 0.5);
    ${theming((theme) => ({
    background: theme.colors.white,
  }))};
    position: absolute;
    top: calc(${baseUnits.halfUnit} / 0.5);
    left: calc(${baseUnits.halfUnit} / 0.5);
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  } */
export const disabledButton = "omponents__RadioGroup__RadioGroup_styles_ts-disabledButton-d1kymq1w";

require("./RadioGroup_styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./RadioGroup_styles.ts");