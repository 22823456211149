import { Dialog as ReakitDialog, DialogBackdrop, DialogStateReturn } from 'reakit/Dialog';
import { cx } from '@linaria/core';
import { useCallback, useEffect, useRef } from 'react';
import { IconCloseRound } from 'components/Icons';
import getConfig from 'next/config';
import { Button } from 'components/Button';
import { noop } from 'utils/fp';
import { useRouter } from 'next/router';
import { usePreviousRoute, useUserNeeds2FA } from 'modules/Auth/hooks';
import { PendingVerification } from 'modules/Auth/components/PendingVerification';
import { useLocalStorage } from 'react-use';
import { appStorage } from 'utils/appStorage';
import { ForgotPassword } from 'modules/Auth/components/RestorePassword/ForgotPassword/ForgotPassword';
import { TwoFactorLoginForm } from 'modules/Auth/components/TwoFactorAuth/TwoFactorLoginForm';
import { SignInView } from './SignInView';
import { SignUpView } from './SignUpView';
import { dialogStyles, backdropStyles, closeButtonStyles, forgotPasswordStyles, TwoFAStyles } from './styles';

export enum AuthModalState {
  SHOW_SIGN_IN = 'SHOW_SIGN_IN',
  SHOW_SIGN_UP = 'SHOW_SIGN_UP',
  SHOW_PENDING_VERIFICATION = 'SHOW_PENDING_VERIFICATION',
  SHOW_2FA = 'SHOW_2FA',
  SHOW_FORGOT_PASSWORD = 'SHOW_FORGOT_PASSWORD',
}

export const AuthModal = ({
  dialog,
  state,
  setState,
  authData,
  onAuth,
}: {
  dialog: DialogStateReturn;
  state: AuthModalState;
  setState: (state: AuthModalState) => void;
  authData?: CF.LocalUserWithOrg;
  onAuth?: (signup?: boolean) => void;
}): JSX.Element => {
  const router = useRouter();
  const { asPath } = router;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPathBeforeSignUp] = useLocalStorage('path-before-signup', asPath);
  const { publicRuntimeConfig } = getConfig();
  const routeData = usePreviousRoute();

  const title = 'Log in to Clarifai';

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (dialog.visible) {
      ref.current?.focus();
    }
  }, [dialog.visible]);

  const userNeeds2FA = useUserNeeds2FA();

  useEffect(() => {
    if (userNeeds2FA) {
      setState(AuthModalState.SHOW_2FA);
    } else if (state === AuthModalState.SHOW_2FA) {
      dialog.hide();
    }
  }, [userNeeds2FA, state]);

  const resetLoginMode = useCallback(() => {
    setState(AuthModalState.SHOW_SIGN_IN);
  }, []);

  const handleLoginRedirection = () => {
    const previousRoute = routeData?.previous;
    const currentRoute = routeData?.active;
    const path = previousRoute?.path.includes('/explore') ? currentRoute?.path : previousRoute?.path;

    router.replace(path ?? currentRoute?.path ?? '/explore');
  };

  const onSignIn = useCallback(() => {
    onAuth?.();
    dialog.hide();
    handleLoginRedirection();
  }, [routeData]);

  return (
    <DialogBackdrop {...dialog} className={cx(backdropStyles, 'reakit-dialog-backdrop')} data-testid="authDialogBackdrop" onMouseDown={() => noop()}>
      {(!authData || state === AuthModalState.SHOW_PENDING_VERIFICATION || state === AuthModalState.SHOW_2FA) && (
        <ReakitDialog
          hideOnClickOutside={false}
          hideOnEsc={false}
          className={cx(dialogStyles, 'reakit-dialog')}
          {...dialog}
          aria-label={title}
          ref={ref}
        >
          {state !== AuthModalState.SHOW_PENDING_VERIFICATION && (
            <Button
              variant="tertiary"
              className={closeButtonStyles}
              onClick={() => {
                dialog.hide();
              }}
            >
              <IconCloseRound size={24} />
            </Button>
          )}
          {state === AuthModalState.SHOW_2FA && (
            <div className={TwoFAStyles}>
              <TwoFactorLoginForm onBackClick={resetLoginMode} />
            </div>
          )}
          {state === AuthModalState.SHOW_FORGOT_PASSWORD && (
            <div className={forgotPasswordStyles}>
              <ForgotPassword onBackClick={() => setState(AuthModalState.SHOW_SIGN_IN)} />
            </div>
          )}
          {state === AuthModalState.SHOW_SIGN_IN && (
            <SignInView
              onForgotPasswordClick={() => setState(AuthModalState.SHOW_FORGOT_PASSWORD)}
              onSignUpClick={() => setState(AuthModalState.SHOW_SIGN_UP)}
              onSignin={onSignIn}
            />
          )}
          {state === AuthModalState.SHOW_SIGN_UP && (
            <SignUpView
              onSignUp={() => {
                setPathBeforeSignUp(asPath);
                appStorage.set('recovery-path', asPath);
                setState(AuthModalState.SHOW_PENDING_VERIFICATION);
                if (onAuth) {
                  onAuth(true);
                }
              }}
              onSignInClick={() => setState(AuthModalState.SHOW_SIGN_IN)}
              siteKey={publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
              defaultCaptchaToken={publicRuntimeConfig.NEXT_PUBLIC_DEFAULT_CAPTCHA_TOKEN}
            />
          )}
          {state === AuthModalState.SHOW_PENDING_VERIFICATION && <PendingVerification />}
        </ReakitDialog>
      )}
    </DialogBackdrop>
  );
};
