import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const OpticalCharacterRecognizerIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0833 4.18333H6.9167C5.40713 4.18333 4.18338 5.40708 4.18338 6.91665V21.0833C4.18338 22.5928 5.40713 23.8166 6.9167 23.8166H21.0833C22.5929 23.8166 23.8166 22.5928 23.8166 21.0833V6.91665C23.8166 5.40708 22.5929 4.18333 21.0833 4.18333Z"
      stroke={color}
      strokeWidth="1.36667"
    />
    <path
      d="M12.1958 17.5H10.5929L13.0353 10.4251H14.9629L17.4019 17.5H15.7989L14.0268 12.0418H13.9715L12.1958 17.5ZM12.0957 14.7191H15.8819V15.8867H12.0957V14.7191Z"
      fill={color}
    />
    <path d="M12 8.00002H8.97278C8.43551 8.00002 7.99997 8.43556 7.99997 8.97281V12" stroke={color} strokeWidth="0.972804" />
    <path d="M7.99997 16V19.0272C7.99997 19.5645 8.43551 20 8.97278 20H12" stroke={color} strokeWidth="0.972804" />
    <path d="M16.0001 8.00002H19.0273C19.5645 8.00002 20.0001 8.43556 20.0001 8.97281V12" stroke={color} strokeWidth="0.972804" />
    <path d="M16.0001 20H19.0273C19.5645 20 20.0001 19.5645 20.0001 19.0272V16" stroke={color} strokeWidth="0.972804" />
  </svg>
);
