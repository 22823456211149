import { Button } from 'components/Button';
import { IconCloseRound } from 'components/Icons';
import { useModalActions } from 'components/ModalManager/Context';
import { useTabState, Tab, TabList } from 'reakit/Tab';
import { Table } from 'components/Table';
import { Spinner } from 'components/Spinner';
import { Column } from 'components/Table/type';
import { header, tableStyle, viewAllModalContent } from './ResourceModalViewer.styles';
import { RESOURCE_VIEWER_MODAL_ID, ResourceViewerTable, TABS, Tabs } from './constantAndTypes';
import { makeColumns } from './TableConfig';
import { useResourceData } from './useResourceData';

export type Props = {
  selectedTab: Tabs;
  app: CF.API.Apps.App;
};

export const ResourceModalViewer: React.FC<Props> = ({ selectedTab, app }) => {
  const { closeModal } = useModalActions();
  const tab = useTabState({ selectedId: selectedTab });

  const { data, isLoading } = useResourceData({ selectedId: tab.selectedId as Tabs, app });

  const handleCancelClick = () => {
    closeModal({ id: RESOURCE_VIEWER_MODAL_ID });
  };

  const renderTable = () => {
    return (
      <div className={tableStyle}>
        <Table<ResourceViewerTable>
          tableOptions={{
            columns: makeColumns(tab.selectedId as Tabs) as Column<ResourceViewerTable>[],
            data: data as ResourceViewerTable[],
          }}
          noRecordsText="No resource found"
        />
        {isLoading && <Spinner />}
      </div>
    );
  };

  return (
    <div className={viewAllModalContent}>
      <div className={header}>
        <span>Resources on {app.id}</span>

        <Button variant="tertiary" className="close-button" onClick={handleCancelClick}>
          <IconCloseRound size={24} />
        </Button>
      </div>
      <TabList {...tab} className="tabList" data-testid="tabList">
        {TABS.map((tabName: string) => (
          <Tab {...tab} key={tabName} id={tabName} className="tabItem" aria-label={tabName}>
            {tabName}
          </Tab>
        ))}
      </TabList>
      {renderTable()}
    </div>
  );
};
