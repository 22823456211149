import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const starWorkflowTE = <E = CF.API.ApiError>(
  { userId, appId, body }: { userId: string; appId: string; body: { workflow_stars: { workflow_id: string }[] } },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Workflows.WorkflowStarResponse> =>
  fetchTE<CF.API.Workflows.WorkflowStarResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/workflow_stars`,
      method: 'POST',
      body,
    },
    onRejected,
  );
