import { css } from '@linaria/core';
import { flex, baseUnits, theming } from 'styles/utils';
export const wrapper = "omponents__CollapsibleItem__CollapsableItem_styles_ts-wrapper-wxlq8dp";
export const menuContentWrapper = `${"omponents__CollapsibleItem__CollapsableItem_styles_ts-menuContentWrapper-m35fcpe"}

  ${flex.init}
  ${flex.direction.column}
`;
export const caretIcon = "omponents__CollapsibleItem__CollapsableItem_styles_ts-caretIcon-czzmcd5";

require("./CollapsableItem_styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CollapsableItem_styles.ts");