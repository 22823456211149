import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const DefaultModelTypeIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="6" height="6" rx="1" stroke={color} strokeLinejoin="round" />
    <rect x="4" y="14" width="6" height="6" rx="1" stroke={color} strokeLinejoin="round" />
    <rect x="14" y="14" width="6" height="6" rx="1" stroke={color} strokeLinejoin="round" />
    <rect x="14" y="4" width="6" height="6" rx="1" stroke={color} strokeLinejoin="round" />
  </svg>
);
