import { CreateAppSVG } from './CreateAppSVG';
import { iconContainer } from './styles';

export const AppFormIconArea = () => {
  return (
    <div className={iconContainer}>
      <CreateAppSVG />
      <div className="header">Setup your app</div>
      <div className="desc">
        The App ID is the unique identifier for your new application; make sure to choose a unique and memorable one. The name will apply to URLs and
        redirections. We recommend not changing it in the future.
      </div>
    </div>
  );
};
