import { css } from '@linaria/core';
import { baseUnits, theming, flex } from 'styles/utils';
export const wrapper = "omponents__Card__Card_styles_ts-wrapper-ws3qh0o";
export const headingWrapper = `${"omponents__Card__Card_styles_ts-headingWrapper-h1790niq"}
  ${flex.init}
  ${flex.justifyContent.between}
  ${flex.alignItems.center}
`;
export const headingWrapperWithBorder = `${"omponents__Card__Card_styles_ts-headingWrapperWithBorder-htnin9q"}
  ${headingWrapper}
`;
export const heading = "omponents__Card__Card_styles_ts-heading-h1hjr4ug";
export const actionWrapper = "omponents__Card__Card_styles_ts-actionWrapper-a19h4nxk";
export const body = "omponents__Card__Card_styles_ts-body-breavuc";

require("./Card.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Card.styles.ts");