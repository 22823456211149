import React from 'react';
import { listModelsByAppTE } from 'api/models/listModelsByApp';
import { useQueryTE } from 'utils/react-query';
import { errorToReactLeft, pipe, noop } from 'utils/fp';
import { either } from 'fp-ts';
import { ModelTypeIcon } from 'components/ModelTypeIcon/ModelTypeIcon';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { useRouter } from 'next/router';
import { container, typeButton } from './AppModelTypes.styles';

interface Props {
  userOrOrgId: string;
  appId: string;
}

export const AppModelTypes: React.FC<Props> = ({ userOrOrgId, appId }): JSX.Element => {
  const params = 'dont_fetch_from_main=true';
  const { data: modelsListE } = useQueryTE(
    ['Models', { appId, userId: userOrOrgId, params }],
    listModelsByAppTE({ userOrOrgId, appId, params }, errorToReactLeft),
  );

  const router = useRouter();

  const onTypeClick = (modelType: string): void => {
    router.push({
      pathname: '/[userOrOrgId]/[appId]/models',
      query: {
        userOrOrgId,
        appId,
        filterModelType: modelType,
      },
    });
  };

  return (
    <div>
      {pipe(
        modelsListE,
        either.fold(reactLeftToJSX(), ({ models }) => {
          if (models.length === 0) {
            return <>App has no models</>;
          }

          const modelTypesCount = models.reduce((acc: Record<string, number>, el) => {
            const model_type_id = el.model_type_id;
            const cnt = acc[model_type_id] ? acc[model_type_id] + 1 : 1;
            return {
              ...acc,
              [model_type_id]: cnt,
            };
          }, {});
          const modelTypes: [string, number][] = Object.entries(modelTypesCount);
          const top5types = modelTypes.sort((a, b) => (a[1] < b[1] ? 1 : b[1] < a[1] ? -1 : 0)).splice(0, 5);

          return (
            <div className={container}>
              {top5types.map((modelType) => (
                <div key={modelType[0]} role="button" tabIndex={0} onKeyPress={noop} className={typeButton} onClick={() => onTypeClick(modelType[0])}>
                  <ModelTypeIcon modelTypeId={modelType[0]} />
                </div>
              ))}
              {/* {modelTypes.length - top5types.length > 0 && <div className={typeWrapper}>And other ...</div>} */}
            </div>
          );
        }),
      )}
    </div>
  );
};
