export const ORGANIZATION_ROLE_ID_ADMIN = 'org-owner';

export const ORGANIZATION_ROLE_ID_MEMBER = 'org-member';

export const ORGANIZATION_ROLE_ID_MEMBER_MIN = 'org-member-min-access-role';

export type RolesT = typeof ORGANIZATION_ROLE_ID_ADMIN | typeof ORGANIZATION_ROLE_ID_MEMBER | typeof ORGANIZATION_ROLE_ID_MEMBER_MIN;

export const ENTERPRISE_PLAN_ID = 'enterprise-plan';

export const INTERNAL_PLAN_ID = 'internal-plan';

export const TEAM_ROLE_TYPE = 0 as CF.API.Organizations.TeamRoleType;

export const ORG_ROLE_TYPE = 1 as CF.API.Organizations.OrgRoleType;

export const MAX_ORGANIZATIONS = 20;
