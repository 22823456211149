import { cx } from '@linaria/core';
import { Button as ReakitButton } from 'reakit';
import type { ButtonProps as RBtnProps } from 'reakit';
import { textRegular } from 'styles/typography';
import { buttonStyles } from './Button.styles';
import { ButtonLoader } from './ButtonLoader';

export type ButtonProps = RBtnProps & {
  as?: 'div' | 'span' | 'a';
  isLoading?: boolean;
  kind?: 'primary' | 'secondary' | 'translucent' | 'outline' | 'outline-simple' | 'link' | 'error' | 'light';
};

export const Button: import('react').FC<ButtonProps> = ({ as, kind = 'primary', isLoading, children, className, ...rest }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore problem with the "as" prop annotation
  <ReakitButton as={as} {...rest} className={cx(buttonStyles, `btn-${kind}`, textRegular, className)}>
    {isLoading && <ButtonLoader />}
    {children}
  </ReakitButton>
);
