/* eslint-disable react/default-props-match-prop-types */
import { cx } from '@linaria/core';
import { textSmall } from 'styles/typography';
import React, { MouseEventHandler } from 'react';
import { starContainer, starCount } from './Star_styles';

export interface StarProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  count?: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isStarred: boolean;
}

export const Star: React.FC<StarProps> = ({ className, isStarred, count, onClick, ...rest }) => {
  return (
    <div className={cx(starContainer, className)} {...rest}>
      {count && count >= 0 ? <span className={cx(textSmall, starCount)}>{count}</span> : null}
      {/** If no onClick; it's assumed that parent has  */}
      {onClick ? (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          data-testid={isStarred ? 'btn-unstar' : 'btn-star'}
          type="button"
          className={cx('starIcon', isStarred === true && 'starActive')}
          onClick={onClick}
        />
      ) : (
        <div className={cx('starIcon', isStarred === true && 'starActive')} />
      )}
    </div>
  );
};

Star.defaultProps = {
  isStarred: false,
  count: -1,
};
