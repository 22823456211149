import React from 'react';
import { PopoverStateReturn, Popover, TextSmall, TextNormal } from 'components/Popover';
import { IconCloseSquare } from 'components/Icons';
import { helpTooltipWrapper, spacingList, tooltipTitleWrapper } from './HelpTooltip.styles';

export type TooltipText = string | string[];

export interface HelpTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  tooltipTitle?: string;
  tooltipText?: TooltipText;
  popover: PopoverStateReturn;
  onCloseButton?: React.MouseEventHandler<HTMLButtonElement>;
}

export const HelpTooltip: React.FC<HelpTooltipProps> = ({ popover, tooltipText, tooltipTitle, onCloseButton, ...rest }): JSX.Element => (
  <Popover aria-label="Help" tabIndex={0} popover={popover} className={helpTooltipWrapper} {...rest} style={{ maxWidth: 250 }}>
    <div className={tooltipTitleWrapper}>
      <TextNormal>{tooltipTitle}</TextNormal>
      {onCloseButton && <IconCloseSquare data-testid="helpTooltip-close" size={18} onClick={onCloseButton} color="#808080" />}
    </div>

    {Array.isArray(tooltipText) ? (
      <div className={spacingList}>
        {tooltipText.map((eachTooltipText) => (
          <TextSmall key={eachTooltipText}>{eachTooltipText}</TextSmall>
        ))}
      </div>
    ) : (
      <TextSmall>{tooltipText}</TextSmall>
    )}
  </Popover>
);
