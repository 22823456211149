export const AppTypeSVG = () => (
  <svg width="100%" height="100%" viewBox="0 0 168 169" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="84" cy="84.5" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_383_18300)">
      <circle cx="84" cy="84.5" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_383_18300)">
      <circle cx="84" cy="84.5" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="85.5" cy="133.5" rx="10.5" ry="3" fill="#CCDCFB" />
    <ellipse cx="69.5" cy="125.5" rx="10.5" ry="3" fill="#CCDCFB" />
    <g filter="url(#filter2_ddi_383_18300)">
      <path
        d="M48.5847 59.79C45.9199 60.1581 44.058 62.6166 44.4261 65.2814L48.2896 93.255C48.6576 95.9198 51.1162 97.7816 53.7809 97.4136L81.7545 93.5501C84.4193 93.1821 86.2812 90.7235 85.9131 88.0587L82.0496 60.0851C81.6816 57.4204 79.223 55.5585 76.5583 55.9265L48.5847 59.79Z"
        fill="url(#paint0_radial_383_18300)"
        stroke="#6695F3"
        strokeWidth="1.0526"
      />
    </g>
    <g filter="url(#filter3_d_383_18300)">
      <path
        d="M72.9644 72.3027L65.3803 77.9382M65.3803 77.9382L56.5886 74.4761M65.3803 77.9382L66.5949 87.0894M74.5687 80.6971L73.5309 72.8781C73.4871 72.548 73.4652 72.383 73.3971 72.2422C73.3368 72.1177 73.2506 72.0075 73.1443 71.919C73.0241 71.819 72.8692 71.758 72.5594 71.636L64.9055 68.6219C64.6122 68.5064 64.4655 68.4486 64.3169 68.4381C64.1854 68.4287 64.0533 68.4463 63.9288 68.4896C63.7881 68.5385 63.6615 68.6326 63.4085 68.8206L56.8058 73.7268C56.5386 73.9254 56.4049 74.0247 56.315 74.1526C56.2355 74.2658 56.181 74.3947 56.1553 74.5306C56.1262 74.6842 56.1481 74.8493 56.1919 75.1793L57.2296 82.9984C57.2735 83.3285 57.2954 83.4935 57.3635 83.6342C57.4238 83.7587 57.51 83.8689 57.6163 83.9574C57.7365 84.0575 57.8914 84.1185 58.2012 84.2405L65.8551 87.2545C66.1484 87.3701 66.2951 87.4278 66.4437 87.4384C66.5752 87.4477 66.7073 87.4302 66.8318 87.3869C66.9725 87.3379 67.099 87.2439 67.3521 87.0559L73.9548 82.1496C74.222 81.951 74.3557 81.8517 74.4456 81.7238C74.5251 81.6106 74.5796 81.4818 74.6053 81.3458C74.6344 81.1922 74.6125 81.0272 74.5687 80.6971Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter4_ddi_383_18300)">
      <path
        d="M93.7449 70.201C91.1916 69.3544 88.4353 70.738 87.5887 73.2913L78.701 100.095C77.8543 102.649 79.2379 105.405 81.7913 106.252L108.595 115.139C111.149 115.986 113.905 114.602 114.752 112.049L123.639 85.245C124.486 82.6916 123.102 79.9354 120.549 79.0887L93.7449 70.201Z"
        fill="url(#paint1_radial_383_18300)"
        stroke="#6695F3"
        strokeWidth="1.0526"
      />
    </g>
    <g filter="url(#filter5_d_383_18300)">
      <path
        d="M94.7056 85.4883C95.2074 83.8927 95.4583 83.0949 95.9712 82.5865C96.4223 82.1393 97.0101 81.8452 97.6505 81.7465C98.3785 81.6342 99.2038 81.8937 100.854 82.4127L107.141 84.3897C108.791 84.9087 109.616 85.1683 110.149 85.677C110.618 86.1245 110.931 86.7021 111.045 87.3271C111.175 88.0375 110.924 88.8353 110.422 90.4309L107.316 100.307C106.815 101.903 106.564 102.701 106.051 103.209C105.6 103.656 105.012 103.95 104.371 104.049C103.643 104.162 102.818 103.902 101.168 103.383L94.8812 101.406C93.2308 100.887 92.4056 100.627 91.8728 100.119C91.4042 99.6712 91.0905 99.0935 90.9766 98.4686C90.847 97.7582 91.0979 96.9604 91.5997 95.3648L94.7056 85.4883Z"
        fill="white"
      />
      <path
        d="M103.47 92.6274L97.7717 90.8355M98.4764 95.2315L96.5771 94.6342M106.564 89.4261L98.9663 87.0369"
        stroke="#0046D4"
        strokeWidth="1.59282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter id="filter0_d_383_18300" x="10" y="10.5" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_383_18300" result="shape" />
      </filter>
      <filter id="filter1_d_383_18300" x="0" y="0.5" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_383_18300" result="shape" />
      </filter>
      <filter
        id="filter2_ddi_383_18300"
        x="28.6478"
        y="42.3203"
        width="73.0437"
        height="73.044"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.17224" />
        <feGaussianBlur stdDeviation="7.60285" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.1052" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_383_18300" result="effect2_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_383_18300" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.12623" dy="4.4104" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_383_18300" />
      </filter>
      <filter
        id="filter3_d_383_18300"
        x="50.1457"
        y="62.4382"
        width="30.4689"
        height="31.0002"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.2487" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_383_18300" result="shape" />
      </filter>
      <filter
        id="filter4_ddi_383_18300"
        x="62.7201"
        y="56.3923"
        width="76.9002"
        height="76.9002"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.17224" />
        <feGaussianBlur stdDeviation="7.60285" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.1052" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_383_18300" result="effect2_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_383_18300" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.12623" dy="4.4104" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_383_18300" />
      </filter>
      <filter
        id="filter5_d_383_18300"
        x="81.7946"
        y="73.5026"
        width="38.9543"
        height="38.9543"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.2487" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_383_18300" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_383_18300" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_383_18300"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.6856 65.5686) rotate(43.3623) scale(41.1795 53.2818)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_383_18300"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(98.461 78.9631) rotate(69.5703) scale(41.1795 53.2818)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
    </defs>
  </svg>
);
