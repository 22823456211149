import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ImageToTextIcon: FC<IconProps> = ({ color, bgColor, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.63547 15.9187L6.39513 10.2792C7.16303 9.12738 8.83033 9.05749 9.69192 10.141L11.725 12.6978C12.5226 13.7008 14.0363 13.7297 14.8715 12.7578L15.9613 11.4897C16.8345 10.4736 18.4332 10.5597 19.1922 11.6637L22.1175 15.9187"
      stroke={color}
      strokeWidth="1.02083"
    />
    <path
      d="M17.6897 8.49411C18.4799 8.49411 19.1205 7.85352 19.1205 7.0633C19.1205 6.27309 18.4799 5.63249 17.6897 5.63249C16.8994 5.63249 16.2589 6.27309 16.2589 7.0633C16.2589 7.85352 16.8994 8.49411 17.6897 8.49411Z"
      stroke={color}
      strokeWidth="0.680556"
    />
    <path
      d="M18.9198 2.56663H5.83335C4.02922 2.56663 2.56668 4.02917 2.56668 5.8333V15.3775C2.56668 17.1816 4.02922 18.6442 5.83335 18.6442H18.9198C20.7239 18.6442 22.1865 17.1816 22.1865 15.3775V5.8333C22.1865 4.02917 20.7239 2.56663 18.9198 2.56663Z"
      stroke={color}
      strokeWidth="1.63333"
    />
    <path
      d="M19.1656 25.7396C22.7963 25.7396 25.7395 22.7964 25.7395 19.1657C25.7395 15.535 22.7963 12.5917 19.1656 12.5917C15.5349 12.5917 12.5917 15.535 12.5917 19.1657C12.5917 22.7964 15.5349 25.7396 19.1656 25.7396Z"
      fill={bgColor}
      stroke={color}
      strokeWidth="1.02083"
    />
    <path
      d="M17.8232 21.4639H16.4994L18.5166 15.6207H20.1085L22.1228 21.4639H20.7991L19.3354 16.956H19.2897L17.8232 21.4639ZM17.7404 19.1671H20.8675V20.1315H17.7404V19.1671Z"
      fill={color}
    />
  </svg>
);
