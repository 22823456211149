import { PopoverDisclosure, PopoverStateReturn } from 'reakit/Popover';
import { cx } from '@linaria/core';

export interface PopoverActionProps extends React.HTMLAttributes<HTMLElement> {
  popover: PopoverStateReturn;
  disabled?: boolean;
}

export const PopoverAction: React.FC<PopoverActionProps> = ({ popover, children, ...rest }) => (
  <PopoverDisclosure className={cx(rest.className)} {...rest} {...popover}>
    {children}
  </PopoverDisclosure>
);
