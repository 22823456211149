export const ImportModelIcon = ({ width = 80, height = 80 }: { width: number | string; height: number | string }) => (
  <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="4" fill="#FAD21F" />
    <path d="M40 25V45" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.3333 38.3333L40 45L46.6667 38.3333" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M33.3333 28.3333H26.6667C25.7826 28.3333 24.9348 28.6845 24.3097 29.3096C23.6845 29.9348 23.3333 30.7826 23.3333 31.6667V48.3333C23.3333 49.2174 23.6845 50.0652 24.3097 50.6904C24.9348 51.3155 25.7826 51.6667 26.6667 51.6667H53.3333C54.2174 51.6667 55.0652 51.3155 55.6904 50.6904C56.3155 50.0652 56.6667 49.2174 56.6667 48.3333V31.6667C56.6667 30.7826 56.3155 29.9348 55.6904 29.3096C55.0652 28.6845 54.2174 28.3333 53.3333 28.3333H46.6667"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
