import { Fragment } from 'react';
import { toggleContainer } from './styles';

type SwitchAppViewToggleProps = {
  toggles: { id: string; name: string }[];
  active: string;
  onToggle: (item: string) => void;
};

export const SwitchAppViewToggle = ({ toggles, active, onToggle }: SwitchAppViewToggleProps): JSX.Element => {
  return (
    <div className={toggleContainer}>
      <div className="toggle_radio">
        {toggles.map((toggle) => {
          return (
            <Fragment key={toggle.id}>
              <input
                id={toggle.id}
                name="toggle_option"
                className="toggle_option"
                type="radio"
                checked={active === toggle.id}
                onChange={() => onToggle(toggle.id)}
              />
              <label className="toggle_label" htmlFor={toggle.id}>
                {toggle.name}
              </label>
            </Fragment>
          );
        })}
        <div className="toggle" />
      </div>
    </div>
  );
};
