import { ReactNode } from 'react';
import { wrapper } from './Group.styles';

export interface GroupInterface extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  headerActions?: ReactNode;
}

export const Group: React.FC<GroupInterface> = ({ title, headerActions = null, children, ...rest }) => {
  return (
    <div className={wrapper} {...rest}>
      <div className="group-header">
        <h2 className="group-title">{title}</h2>
        {headerActions}
      </div>
      {children}
    </div>
  );
};
