import { cx } from '@linaria/core';
import { makeUserFullName } from 'utils/users';
import { useCurrentUserId, useSwitchUserAccount } from 'modules/Auth/hooks';
import { useAuthState } from 'modules/Auth/AuthContext';
import { Button } from 'components/deprecated/Button';
import { cssHelpers } from 'styles/utils/core';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { useOpenCreateOrgModal } from 'modules/Organizations/hooks';
import { MAX_ORGANIZATIONS } from 'modules/Organizations/constants';
import { TextTooltip } from 'components/Tooltip';
import { tooltipCls } from 'modules/Models/components/ModelViewer/Header/Header.styles';
import { accountSelector, accountDefault } from './styles';
import { Account } from '../Account/Account';
export const AccountSelector: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}): JSX.Element => {
  const currentUserId = useCurrentUserId();
  const {
    authData
  } = useAuthState();
  const [hasOrgAccess, canCreateMultipleOrgs] = useFeaturedFlags(['OrgCreateOrganization', 'MultiOrganization']);
  const switchUserAccount = useSwitchUserAccount();
  const openCreateOrgModal = useOpenCreateOrgModal();
  const loggedInUserFullName = authData ? makeUserFullName(authData) : '';
  const orgsList = authData?.organizations?.map(({
    organization
  }) => organization) || [];
  const isMaxOrgsReached = orgsList?.length >= MAX_ORGANIZATIONS;
  const allowedToCreateMultiOrgs = canCreateMultipleOrgs && orgsList?.length < MAX_ORGANIZATIONS;
  const showTooltipText = orgsList.length === 1 && !allowedToCreateMultiOrgs ? 'Multiple Organizations feature is available on the Enterprise plan' : isMaxOrgsReached ? 'You reached the maximum number of Organizations' : null;
  return <div className={cx(accountSelector, className)} {...rest}>
      <Account type="user" name={loggedInUserFullName} selected={authData?.user_id === currentUserId} className={cx(!orgsList.length && accountDefault)} onSelect={() => switchUserAccount(authData?.user_id)} id={authData?.user_id} />
      <>
        {orgsList.map(org => <Account key={org.id} type="org" name={org.name} id={org.id} selected={org.id === currentUserId} onSelect={() => switchUserAccount(org.id)} />)}
        {hasOrgAccess ? <TextTooltip arrowProps={{
        className: 'darkArrow'
      }} hidden={!showTooltipText} showArrow text={showTooltipText} className={tooltipCls}>
            <Button kind="outline" className={cx('fullWidth', cssHelpers.margin.yb[1])} onClick={openCreateOrgModal} disabled={isMaxOrgsReached || orgsList.length === 1 && !allowedToCreateMultiOrgs}>
              + Add an Organization
            </Button>
          </TextTooltip> : null}
      </>
    </div>;
};