import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const KNNClassifierIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 23.75C19.3848 23.75 23.75 19.3848 23.75 14C23.75 8.61519 19.3848 4.24997 14 4.24997C8.61526 4.24997 4.25003 8.61519 4.25003 14C4.25003 19.3848 8.61526 23.75 14 23.75Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M10.0625 21.125C12.5478 21.125 14.5625 19.1103 14.5625 16.625C14.5625 14.1397 12.5478 12.125 10.0625 12.125C7.57725 12.125 5.56253 14.1397 5.56253 16.625C5.56253 19.1103 7.57725 21.125 10.0625 21.125Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M18.5938 14C19.6811 14 20.5625 13.1186 20.5625 12.0312C20.5625 10.9439 19.6811 10.0625 18.5938 10.0625C17.5064 10.0625 16.625 10.9439 16.625 12.0312C16.625 13.1186 17.5064 14 18.5938 14Z"
      fill={color}
    />
  </svg>
);
