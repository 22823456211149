import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const EmailIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44.503 44.503">
    <path
      id="iconmonstr-email-11"
      d="M22.252,3.729A18.543,18.543,0,1,1,3.709,22.272,18.564,18.564,0,0,1,22.252,3.729Zm0-3.709A22.252,22.252,0,1,0,44.5,22.272,22.253,22.253,0,0,0,22.252.02Zm0,23.272L11.141,14.817h22.22L22.252,23.292Zm0,2.388L11.126,17.1V29.652H33.378V17.1Z"
      transform="translate(0 -0.02)"
      fill={color}
    />
  </svg>
);
