import { toLowerCase } from 'fp-ts/lib/string';

export const permissionMatchers = {
  imvs: {
    list: makeEndpointScopeMatcher('/clarifai.api.V2/ListInstalledModuleVersions'),
  },
  modelVersion: {
    delete: makeEndpointScopeMatcher('/clarifai.api.V2/DeleteModelVersion', [
      'concepts:get',
      'models:delete',
      'models:add',
      'models:get',
      'workflows:get',
    ]),
    train: makeEndpointScopeMatcher('/clarifai.api.V2/PostModelVersions'),
  },
  modelVersionMetrics: {
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostModelVersionMetrics'),
  },
  model: {
    edit: makeEndpointScopeMatcher('/clarifai.api.V2/PatchModels', ['models:add']),
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostModels', ['models:add']),
    predict: makeEndpointScopeMatcher('/clarifai.api.V2/PostModelOutputs'),
  },
  models: {
    batchDelete: makeEndpointScopeMatcher('/clarifai.api.V2/DeleteModels', ['models:delete', 'models:add', 'models:get']),
  },
  workflow: {
    edit: makeEndpointScopeMatcher('/clarifai.api.V2/PatchWorkflows', ['workflows:add', 'models:get', 'workflows:get']),
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostWorkflows', ['workflows:add']),
    predict: makeEndpointScopeMatcher('/clarifai.api.V2/PostWorkflowResults'),
  },
  app: {
    view: makeEndpointScopeMatcher('/clarifai.api.V2/GetApp', ['apps:get']),
    edit: makeEndpointScopeMatcher('/clarifai.api.V2/PatchApps', ['apps:add', 'apps:get']),
    delete: makeEndpointScopeMatcher('/clarifai.api.V2/DeleteApps', ['apps:delete', 'apps:add', 'apps:get']),
    transfer: makeEndpointScopeMatcher('/clarifai.api.V2/PatchAppOwner', ['apps:patch']),
  },
  collaborators: {
    list: makeEndpointScopeMatcher('/clarifai.api.V2/ListCollaborators'),
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PatchCollaborators', ['collaborators:add', 'collaborators:get', 'collaborators:delete']),
  },
  inputs: {
    list: makeEndpointScopeMatcher('/clarifai.api.V2/ListInputs'),
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostInputs', ['inputs:add']),
  },
  datasets: {
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostDatasets', ['datasets:add']),
    edit: makeEndpointScopeMatcher('/clarifai.api.V2/PatchDatasets'),
    list: makeEndpointScopeMatcher('/clarifai.api.V2/ListDatasets'),
  },
  tasks: {
    list: makeEndpointScopeMatcher('/clarifai.api.V2/ListTasks'),
  },
  modules: {
    delete: makeEndpointScopeMatcher('/clarifai.api.V2/DeleteModules', ['modules:delete', 'modules:add', 'modules:get']),
    edit: makeEndpointScopeMatcher('/clarifai.api.V2/PatchModules'),
    add: makeEndpointScopeMatcher('/clarifai.api.V2/PostModules', ['modules:add']),
  },
};

function makeEndpointScopeMatcher(endpoint: string, requiredScopes?: string[]) {
  return (res: CF.API.Users.FetchScopesResponse | CF.API.ApiKeys.KeyData): boolean => {
    const scopes = res.scopes?.map(toLowerCase) || [];

    if (scopes.includes('all')) return true; // early exit if user has access to everything

    const endpoints = res.endpoints || [];
    const hasEndpointPermission = endpoints.includes(endpoint);
    const hasScopePermission = requiredScopes ? requiredScopes.every((requiredScope) => scopes.includes(requiredScope)) : true;
    return hasEndpointPermission && hasScopePermission;
  };
}
