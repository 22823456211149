import { cx, css } from '@linaria/core';
import { flex, theming, breakpoints } from 'styles/utils';
export const wrapper = cx("odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g", flex.init);
export const cardTitle = "odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardTitle-cc4773";
export const cardDescription = "odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardDescription-c1i4t87k";
export const cardThumbnailWrapper = cx("odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardThumbnailWrapper-cp4ds8", flex.init, flex.justifyContent.center, flex.alignItems.center);
export const cardThumbnailImage = "odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardThumbnailImage-cda3i3b";
export const tag = "odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-tag-t1efwikp";
export const modelTag = cx("odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-modelTag-m1qqbslx", tag);
export const workflowTag = cx("odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-workflowTag-w13f12vw", tag);
export const dot = "odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-dot-dnbeebw";
export const footerWrapper = cx("odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-footerWrapper-f1wnfkcw", flex.init, flex.alignItems.center);

require("./FeaturedEntityCard.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./FeaturedEntityCard.styles.tsx");