import React from 'react';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { taskPageApiEffects as appPageApiEffects } from 'modules/Tasks/apiEffects';
import { pipe } from 'utils/fp';
import { SingleAppView } from 'modules/Apps/components/AppViewer/SingleAppView';
import { either } from 'fp-ts';

interface AppOverviewProps extends React.HTMLAttributes<HTMLElement> {
  appId: string;
  userOrOrgId: string;
  isAppTemplate?: boolean;
}

export const AppOverview: React.FC<AppOverviewProps> = ({ appId, userOrOrgId, isAppTemplate }) => {
  const currentApp = useCurrentApp({ appId, userOrOrgId });

  return (
    <>
      {currentApp && <SingleAppView app={currentApp} mdxSource={undefined} appId={appId} userOrOrgId={userOrOrgId} isAppTemplate={isAppTemplate} />}
    </>
  );
};

export function useCurrentApp({ appId = '', userOrOrgId }: { appId: string; userOrOrgId: string }) {
  const { data: appE } = useQueryTE([queryKeys.Apps, { appId, userOrOrgId }], appPageApiEffects.fetchAppTE({ appId, userId: userOrOrgId }));

  return pipe(
    appE,
    either.getOrElseW(() => undefined),
  );
}
