import React from 'react';
import { PredUiItem } from 'utils/cfUiItems/predictions';
import { IWorkflowPredictionStoreMST } from 'modules/Predictions/mst/WorkflowPredictionStore';

export enum TSupportedPredictionCategories {
  Image = 'IMAGE_RENDER',
  ImageJson = 'IMAGE_JSON',
  Text = 'TEXT_RENDER',
  TextJson = 'TEXT_JSON',
  Audio = 'AUDIO_RENDER',
  AudioJson = 'AUDIO_JSON',
  Unsupported = 'UNSUPPORTED',
  Any = 'ANY',
  Initialising = 'waiting',
}

export interface PredictionViewConfig {
  sidebar: {
    title: string;
    /** Needs to call store.setHasTrained and store.setVersionId appropriately */
    VersionSelector: React.FC;

    /** Takes in PredUiItem[] and can render whatever it needs to */
    PredictionList?: React.FC<{ items: PredUiItem[] }>;
    /** Needs to call store.setSearchTerm appropriately */
    Search?: React.FC<{ disabled?: boolean }>;

    SettingsPopoverContent?: React.FC<{ closePopover: () => void }>;
  };

  canvas: {
    /** Should return any Konva nodes to be rendered as regions on the media */
    RegionList: React.FC<{
      scale?: number;
      media?: HTMLImageElement | HTMLVideoElement;
      predictionStore?: IWorkflowPredictionStoreMST;
    }>;
    mediaList: React.ReactElement;
  };
}

export const predictionCategoryToInputType = (category: TSupportedPredictionCategories): CF.API.InputType | null => {
  switch (category) {
    case TSupportedPredictionCategories.Image:
    case TSupportedPredictionCategories.ImageJson:
      return 'image';
    case TSupportedPredictionCategories.Audio:
    case TSupportedPredictionCategories.AudioJson:
      return 'audio';
    case TSupportedPredictionCategories.Text:
    case TSupportedPredictionCategories.TextJson:
      return 'text';
    case TSupportedPredictionCategories.Any:
      return 'any';
    default:
      // TSupportedPredictionCategories.Unsupported cannot be mapped to any input type
      console.error(`Unsupported prediction category: ${category}`);
      return null;
  }
};

export const predictionCategoryIsUsable = (category: TSupportedPredictionCategories): boolean => {
  if (category === TSupportedPredictionCategories.Initialising || category === TSupportedPredictionCategories.Unsupported) {
    return false;
  }
  return true;
};
