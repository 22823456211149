import { Star } from 'components/Star';
import { observer } from 'mobx-react-lite';
import { useStarEntity } from 'modules/Listings/hooks/useStarEntity';
import { SupportedListingEntities } from 'modules/Listings/types';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { useIsLoggedIn } from 'modules/Auth/hooks';
import { getExplorePageNameByPath } from 'utils/urls/pageName';
import { useRouter } from 'next/router';

type StarComponentProps = {
  entity: SupportedListingEntities;
};

export const StarComponent: React.FC<StarComponentProps> = observer(({ entity }) => {
  const router = useRouter();

  const handleToggle = useStarEntity(entity, getExplorePageNameByPath(router.pathname) || 'explore_page');
  const isLoggedIn = useIsLoggedIn();

  return (
    <Star
      className={cx(flex.justifyContent.center, flex.direction.rowReverse)}
      count={(entity as { star_count: number }).star_count}
      isStarred={isLoggedIn ? Boolean((entity as { is_starred: boolean }).is_starred) : true}
      onClick={isLoggedIn ? handleToggle : undefined}
      data-testid="featured-card-star"
    />
  );
});
