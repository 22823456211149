/* eslint-disable react/no-array-index-key */
import { cx } from '@linaria/core';
import { useMemo } from 'react';
import cogoToast from 'cogo-toast';
import { Form, Formik } from 'formik';
import { Col, Row } from 'components/Grid';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import { Button } from 'components/Button';
import { IconPlus } from 'components/Icons';
import { pipe } from 'utils/fp';
import { useMutationTE } from 'utils/react-query';
import { createBulkUserInvitesTE } from 'api/organizations';
import { useOrgRoles } from 'modules/Organizations/hooks';
import { ORGANIZATION_ROLE_ID_MEMBER, ORG_ROLE_TYPE } from 'modules/Organizations/constants';
import { addEmailBtn, buttonWrapper, dropdownOptionWrapper, dropdownWrapper, formStyles } from './styles';

type AddMemberField = {
  invitee_email: string;
  role: { name: string; value: string };
};

type FormValues = {
  members: AddMemberField[];
};

export type AddMembersProps = {
  createdOrg?: CF.API.Organizations.Organization;
  closeAction: () => void;
};

export const AddMembers: React.FC<AddMembersProps> = ({ createdOrg, closeAction }) => {
  const roles = useOrgRoles(ORG_ROLE_TYPE);

  const defaultSelectedRole = useMemo(() => roles.find((role) => role.value === ORGANIZATION_ROLE_ID_MEMBER), [roles]);

  const defaultMemberValue: AddMemberField = useMemo(
    () => ({ invitee_email: '', role: defaultSelectedRole || roles[0] }),
    [roles, defaultSelectedRole],
  );

  const inviteBulkMembersMutation = useMutationTE(
    (members: AddMemberField[]) =>
      pipe(
        createBulkUserInvitesTE({
          orgId: createdOrg?.id || '',
          invitations: members.map((member) => ({
            memberEmail: member.invitee_email.trim(),
            memberRoleId: member.role.value,
          })),
        }),
      )(),
    {
      onSuccess: (_, members) => {
        cogoToast.success(`Invited ${members.length} members to ${createdOrg?.name.trim() || createdOrg?.id}.`);
        closeAction();
      },
      onError: (props) => {
        cogoToast.error(props.message || 'There was an error inviting members.', { heading: 'Error' });
      },
    },
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        members: [defaultMemberValue],
      }}
      onSubmit={(values) => {
        const membersWithEmail = values.members.filter((member) => member.invitee_email.trim().length);
        inviteBulkMembersMutation.mutate(membersWithEmail);
      }}
    >
      {({ values, isValid, dirty, setFieldValue }) => (
        <Form className={formStyles}>
          <p className="dialog-text">Harnesses the collective intelligence and perspectives of team members to drive innovation.</p>

          {values.members?.map((_, index) => (
            <Row spacing="1rem" key={`add-member-${index}`} data-testid="add-member-row">
              <Col xs={7}>
                <InputField
                  name={`members.${index}.invitee_email`}
                  label={!index ? 'Invite members by email' : undefined}
                  placeholder="Add an email"
                />
              </Col>
              <Col xs={5}>
                <SelectField
                  name={`members.${index}.role`}
                  label={!index ? 'Role' : undefined}
                  placeholder="Select Role"
                  options={roles}
                  className={dropdownWrapper}
                  menuWrapperClass={dropdownOptionWrapper}
                />
              </Col>
            </Row>
          ))}

          <Button
            variant="tertiary"
            iconSize={20}
            LeftIcon={IconPlus}
            size="md"
            className={addEmailBtn}
            onClick={() => {
              setFieldValue('members', values.members.concat(defaultMemberValue));
            }}
          >
            Add email
          </Button>

          <div className={cx('button-group', buttonWrapper)}>
            <Button variant="tertiary" size="md" data-testid="skip-add-members-btn" onClick={closeAction}>
              Skip
            </Button>
            <Button
              variant="primary"
              size="md"
              type="submit"
              data-testid="add-members-btn"
              disabled={inviteBulkMembersMutation.isLoading || !isValid || !dirty}
              loading={inviteBulkMembersMutation.isLoading}
            >
              Send invites
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
