import { SelectOption } from 'components/Select/SelectControlled';
import { genericStringRules } from 'modules/User/components/Settings/ContactInformation';
import { hasNonWhitespaceEdge, isPrintable } from 'utils/strings/strings';
import { object, string, AnyObjectSchema } from 'yup';

export enum ONBOARDING_STEP_ID {
  Profile = 'Profile',
  Goal = 'Goal',
  MaybeCreateOrg = 'MaybeCreateOrg',
}

export const ONBOARDING_MODAL_TITLE = {
  [ONBOARDING_STEP_ID.Profile]: 'Complete your profile',
  [ONBOARDING_STEP_ID.Goal]: 'We want to know more about your goals',
  [ONBOARDING_STEP_ID.MaybeCreateOrg]: 'Before finishing, do you want to create an organization?',
};

export const ONBOARDING_STEPS = [
  {
    stepId: ONBOARDING_STEP_ID.Profile,
    modalTitle: 'Complete your profile',
  },
  {
    stepId: ONBOARDING_STEP_ID.Goal,
    modalTitle: 'We want to know more about your goals',
  },
  {
    stepId: ONBOARDING_STEP_ID.MaybeCreateOrg,
    modalTitle: 'Before finishing, do you want to create an organization?',
  },
];

export const ONBOARDING_STEP_IDS = ONBOARDING_STEPS.map((step) => step.stepId);

export const ONBOARDING_STEP_IDS_WITHOUT_ORG = ONBOARDING_STEP_IDS.filter((stepId) => stepId !== ONBOARDING_STEP_ID.MaybeCreateOrg);

export type FormValues = {
  userId: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  country?: SelectOption;
  onboardingJobTitle?: string;
  intention?: string;
  referralSource?: string;
  onboardingJobRole?: SelectOption;
};

export const strictStringRule = genericStringRules
  .test('has-non-whitespace-edge', 'Must start and end with non-whitespace character.', (value) => {
    return hasNonWhitespaceEdge(value || '');
  })
  .test('isPrintable', 'Must contain only printable characters.', (value) => {
    return isPrintable(value || '');
  });

export const strictlyRequiredStringRule = strictStringRule.required('Required');

/**
 * For partially validating the form depending on which part
 * of onboarding is being shown.
 *
 * @link https://clarifai.atlassian.net/browse/MRK-3861
 */
export const onboardingFormValidationSchema: Record<string, AnyObjectSchema> = {
  [ONBOARDING_STEP_ID.Profile]: object().shape({
    userId: strictlyRequiredStringRule,
    company: strictlyRequiredStringRule,
    onboardingJobTitle: genericStringRules,
    onboardingJobRole: object().shape({ name: string(), value: string().required('Required') }),
    country: object().shape({ name: string(), value: string() }),
  }),
  [ONBOARDING_STEP_ID.Goal]: object().shape({
    intention: string().required('Required'),
  }),
  [ONBOARDING_STEP_ID.MaybeCreateOrg]: object(),
};
