import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listModelTypesTE = <E = CF.API.ApiError>(
  _: unknown,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Models.ListModelTypesResponse> =>
  fetchTE<CF.API.Models.ListModelTypesResponse, E>(
    {
      path: '/api/_api/v2/models/types',
      method: 'GET',
    },
    onRejected,
  );
