/* eslint-disable no-param-reassign */
import { types } from 'mst-effect';
import Router from 'next/router';

export const DEFAULT_SORT_BY_IN_COMMUNITY = 'sort_by_star_count';
export const DEFAULT_SORT_BY_IN_OWN_RESOURCES = 'sort_by_modified_at';

// Default ordering is described here : https://clarifai.atlassian.net/browse/MRK-2554
// And here: https://clarifai.atlassian.net/browse/MRK-2611
function isAscendingOrder(sortByVal: string, sortByAsc?: string): boolean {
  if (sortByAsc) {
    return sortByAsc === 'true';
  }

  // Always set descreasing order by default for these sortBy criterias
  const defaultDescOrderFor = ['sort_by_star_count', 'sort_by_modified_at', 'sort_by_created_at'];

  if (defaultDescOrderFor.includes(sortByVal)) return false;
  return true;
}

// Default sorting is described here: https://clarifai.atlassian.net/browse/MRK-3050
function getDefaultSortBy(): string {
  const { router } = Router;

  if (router?.asPath.startsWith('/explore')) {
    return DEFAULT_SORT_BY_IN_COMMUNITY;
  }

  return DEFAULT_SORT_BY_IN_OWN_RESOURCES;
}

// type inference is more powerful in case of MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeSortable(initialQuery: Record<string, string | undefined> = {}, params?: { disableRouterUpdates: boolean }) {
  const { disableRouterUpdates } = params || { disableRouterUpdates: false };
  const sortByVal = initialQuery.sortByVal || getDefaultSortBy();
  const sortByAsc = isAscendingOrder(sortByVal, initialQuery.sortByAsc);

  return types
    .model({
      sortBy: types.optional(types.model({ value: types.string, asc: types.boolean }), {
        value: sortByVal,
        asc: sortByAsc,
      }),
    })
    .actions((self) => {
      const updateRoute = (): void => {
        const { router } = Router;
        // if block only for TS strict adherence
        /* istanbul ignore if  */
        if (router && !disableRouterUpdates) {
          router.push(
            {
              query: {
                ...router.query,
                sortByVal: self.sortBy.value,
                sortByAsc: String(self.sortBy.asc),
              },
            },
            undefined,
            { shallow: true },
          );
        }
      };
      return {
        setAscending(asc: boolean) {
          self.sortBy.asc = asc;
          updateRoute();
        },
        setSortBy(sortBy: string) {
          // Always reset the ordering when user changes sortBy criteria
          self.sortBy.asc = isAscendingOrder(sortBy);
          self.sortBy.value = sortBy;
          updateRoute();
        },
      };
    });
}
