import { cx } from '@linaria/core';
import { borderRounded } from 'styles/utils';
import { CSSProperties } from 'react';
import { iconWrapper } from './TextIcon_styles';

export interface TextIconProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  background?: string;
  size?: number;
}

export const TextIcon: import('react').FC<TextIconProps> = (props) => {
  const { text, background, style, className, size, ...rest } = props;

  // Only Apply size props if size is provided.
  // This condition is added for backward compatibility with older components created before size prop was introduced
  const sizeProps: CSSProperties = size
    ? {
        width: size,
        height: size,
        fontSize: size / 3,
      }
    : {};

  const styleProps: CSSProperties = {
    ...style,
    ...(background && { backgroundColor: background }),
    ...sizeProps,
  };

  return (
    <div {...rest} className={cx(iconWrapper, borderRounded, className)} style={styleProps}>
      {text}
    </div>
  );
};
