import { useAuthState } from 'modules/Auth/AuthContext';
import { ListView } from 'modules/Listings/components/ListView';
import { ENTITY_SLUGS, ListingsStateProvider, useListingStore } from 'modules/Listings/store/StateContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { makeUserFullName } from 'utils/users';
import { FILTER_ID, FilterTabs, IFilter } from 'modules/Listings/components/AllStarTemplateToggle';
import { Sorter } from 'modules/Listings/components/Sorter/Sorter';
import { ListGridToggle } from 'modules/Listings/components/ListGridToggle/ListGridToggle';
import { flex } from 'styles/utils';
import { Tab, TabList, TabOptions, TabStateReturn, useTabState } from 'reakit';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { UI_STATES } from 'utils/uiStates/uiStates';
import { CircleLoader } from 'components/CircleLoader';
import { cssHelpers } from 'styles/utils/core';
import { cx } from '@linaria/core';
import { observer } from 'mobx-react-lite';
import { Search } from 'modules/Listings/components/Search';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { getAppId, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { appTemplatesListType, createAppTemplatesList } from './createAppTemplatesList';
import { flexAlign, listHeadingWrapper, loader, searchToolbarContainer } from './SelectAppTemplates.styles';
enum TABS_ID {
  COMMUNITY_TEMPLATES = 'community',
  USER_TEMPLATES = 'user',
  ORG_TEMPLATES = 'org',
}
interface TemplateTabProps extends TabOptions {
  title: string;
}
interface SelectAppTemplateProps {
  onSelectApp: (args: {
    userId: string;
    appId: string;
  }) => void;
}
const LOADING_PLACEHOLDER_TEXT: Record<TABS_ID, string> = {
  [TABS_ID.COMMUNITY_TEMPLATES]: 'Loading public apps from the Clarifai Community',
  [TABS_ID.USER_TEMPLATES]: 'Loading your templates',
  [TABS_ID.ORG_TEMPLATES]: 'Loading your Organization templates'
};
const TemplateTab: React.FC<Omit<TemplateTabProps, 'children'>> = observer(({
  title,
  ...props
}) => {
  return <Tab className="tabItem" {...props}>
      <span className="truncate">{title}</span>templates
    </Tab>;
});
const LoadingPlaceholder: React.FC<{
  text: string;
}> = ({
  text
}) => {
  return <div className={loader}>
      <div>
        <CircleLoader />
      </div>
      <p>{text}</p>
    </div>;
};
const TabSwitcher: React.FC<{
  tabState: TabStateReturn;
}> = observer(({
  tabState
}) => {
  const {
    authData
  } = useAuthState();
  const currentUser = useCurrentUser();
  const isOrgAccount = useIsOrgAccount();
  return <TabList {...tabState} className="tabList">
      <TemplateTab {...tabState} id={TABS_ID.COMMUNITY_TEMPLATES} title="Community" />
      <TemplateTab {...tabState} id={TABS_ID.USER_TEMPLATES} title={authData ? makeUserFullName(authData) : ''} />
      {isOrgAccount && <TemplateTab {...tabState} id={TABS_ID.ORG_TEMPLATES} title={currentUser ? makeUserFullName(currentUser) : ''} />}
    </TabList>;
});
const ListHeading: React.FC<{
  tabState: TabStateReturn;
}> = observer(({
  tabState
}) => {
  const appTemplatesStore = useCurrentStore();
  const activeToggle = useMemo(() => appTemplatesStore.defaultParams.includes('&starred_only=true') ? FILTER_ID.STARRED : FILTER_ID.ALL, [appTemplatesStore.defaultParams]);
  const onToggleFilterTabs = useCallback(({
    filterParams
  }: IFilter) => {
    appTemplatesStore.setDefaultParams(filterParams);
  }, [appTemplatesStore.setDefaultParams]);
  return <div className={listHeadingWrapper}>
      <div className="tabSwitcherWrapper">
        <TabSwitcher tabState={tabState} />
      </div>
      <div className={cx(flexAlign, searchToolbarContainer)}>
        <Search placeholder="Search" />
        {/* Place the mobile filter here in future */}
        <ListGridToggle />
      </div>
      <div className={flexAlign}>
        <FilterTabs activeToggle={activeToggle} onToggle={onToggleFilterTabs} showStarred />
        <Sorter />
      </div>
    </div>;
});
export const SelectAppTemplate: React.FC<SelectAppTemplateProps> = observer(({
  onSelectApp
}) => {
  const queryClient = useQueryClient();
  const tabState = useTabState({
    baseId: TABS_ID.COMMUNITY_TEMPLATES
  });
  const selectedTabId = ((tabState.selectedId || tabState.currentId) as TABS_ID);
  const {
    authData
  } = useAuthState();
  const currentUser = useCurrentUser();
  // We need to preserve the viewMode value when template tabs are changed
  const [initialQuery, setInitialQuery] = useState({
    viewMode: 'grid'
  });
  const fetchForUserId = useMemo(() => ({
    [TABS_ID.COMMUNITY_TEMPLATES]: undefined,
    [TABS_ID.USER_TEMPLATES]: authData?.user_id,
    [TABS_ID.ORG_TEMPLATES]: currentUser?.user_id
  }), [authData?.user_id, currentUser?.user_id]);
  const appTemplatesStore = useListingStore<appTemplatesListType>(useCallback(() => createAppTemplatesList({
    queryClient,
    initialQuery,
    userIdFromRouter: fetchForUserId[selectedTabId],
    overrideUrlClick: entity => onSelectApp({
      userId: getUserId(entity),
      appId: getAppId(entity)
    }),
    uiStateMap: {
      [UI_STATES.loading]: () => <LoadingPlaceholder text={LOADING_PLACEHOLDER_TEXT[selectedTabId]} />
    }
  }), [selectedTabId, fetchForUserId, initialQuery]), [selectedTabId, fetchForUserId, initialQuery]);
  useEffect(() => {
    setInitialQuery({
      viewMode: appTemplatesStore?.viewMode
    });
  }, [appTemplatesStore?.viewMode]);
  useEffect(() => {
    appTemplatesStore.load();
  }, [appTemplatesStore]);
  useEffect(() => {
    setInitialQuery({
      viewMode: appTemplatesStore?.viewMode
    });
  }, [appTemplatesStore?.viewMode]);
  return <ListingsStateProvider store={appTemplatesStore} entityType={ENTITY_SLUGS.apps}>
      <ListHeading tabState={tabState} />
      <div className={cx(flex.flex.one, cssHelpers.margin.yt.half)}>
        <ListView pageName="select_app_template_modal" showTags />
      </div>
    </ListingsStateProvider>;
});