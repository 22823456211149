import { cx } from '@linaria/core';
import { Checkbox } from 'components/Checkbox';
import { CheckboxField } from 'components/Formik/CheckboxField';
import { fieldWrapper } from 'components/Formik/Forms.styles';
import { useCallback, useState } from 'react';
import { checkboxLabelWrapper, consentCheckbox, consentCheckboxWrapper } from '../CreateApplication/styles';

export const ContentMessage = (): JSX.Element => (
  <div className={checkboxLabelWrapper}>
    You agree that your content will not contain any sensitive personal information of any individual unless you have obtained all necessary consents
    to use and disclose such information and have all related policies and procedures in place as required by applicable law. Contact{' '}
    <a href="mailto:support@clarifai.com" className="link">
      support@clarifai.com
    </a>
  </div>
);

type Handler = (v: boolean) => void;

export function ConsentCheckboxField({
  name,
  withFormik = true,
  disabled = false,
  className,
  ...rest
}:
  | { name: string; withFormik?: true; className?: string; disabled?: boolean }
  | { name: string; withFormik: false; onChange: Handler; className?: string; disabled?: boolean }): JSX.Element {
  return (
    <div className={className || consentCheckboxWrapper}>
      {withFormik === false ? (
        <CheckboxRegular name={name} onChange={(rest as { onChange: Handler }).onChange} disabled={disabled} />
      ) : (
        <CheckboxField data-testid="consentCheckbox" name={name} className={consentCheckbox} disabled={disabled}>
          <ContentMessage />
        </CheckboxField>
      )}
    </div>
  );
}

function CheckboxRegular({ name, onChange, disabled }: { name: string; onChange: Handler; disabled: boolean }): JSX.Element {
  const [value, setValue] = useState(false);
  const handleOnChange = useCallback((e) => {
    onChange(e.target.checked);
    setValue((v) => !v);
  }, []);

  return (
    <div className={cx(fieldWrapper)}>
      <Checkbox data-testid="consentCheckbox" id={name} className="formField" checked={value} onChange={handleOnChange} disabled={disabled}>
        <ContentMessage />
      </Checkbox>
    </div>
  );
}
