import { IconImage, IconTextSquare, IconVideoRecorder, IconVolumeMin } from 'components/Icons';
import { formatNumber } from 'utils/number';
import { capitalize } from '@mui/material';
import { TagItem } from 'components/EntityCard/types';
import { TAG_THEME } from 'components/EntityCard/TagList';
import { SupportedListingEntities } from '../types';
import { formatAppResourceCounts } from '../hooks/useAppResourceCountsConditionally';
import { getEntityId } from './entityCardUtils';

interface IGetEntityTagsParams {
  entity: SupportedListingEntities;
  href: string;
  appResourceCounts: CF.API.Apps.AppResourceCounts;
}

const iconMapping = {
  image: IconImage,
  video: IconVideoRecorder,
  text: IconTextSquare,
  audio: IconVolumeMin,
};

export const isDatasetEntity = (href: string) => href?.includes('datasets');

const getInputTypesTags = (version: CF.API.Datasets.DatasetVersion) => {
  const typeKey = '/INPUT_TYPE:';
  return Object.entries(version.metrics)
    .filter(([key]) => key?.includes(typeKey))
    .map((rawValue) => {
      const [key, value] = rawValue as [string, CF.API.Datasets.DatasetVersionMetric];
      const inputType = key.split(typeKey)[1];
      const suffix = Number(value?.inputs_count ?? 0) > 1 ? 's' : '';
      const Icon = iconMapping?.[inputType as keyof typeof iconMapping] || <></>;
      return {
        icon: <Icon size={16} color="#195AFF" />,
        item: `${capitalize(inputType)}${suffix}`,
        uniqueKey: inputType,
        theme: TAG_THEME.BLUE,
      };
    });
};

const getDatasetTags = (entity: SupportedListingEntities) => {
  const version = (entity as CF.API.Datasets.Dataset).version as CF.API.Datasets.DatasetVersion;
  if (version?.hasOwnProperty('metrics')) {
    const inputTypesTags = getInputTypesTags(version);
    return Object.entries(version.metrics)
      .filter(([key]) => key === '/')
      .reduce((_, rawValue) => {
        const [, value] = rawValue as [string, CF.API.Datasets.DatasetVersionMetric];
        const totalInputs = formatNumber(Number(value?.inputs_count ?? 0));
        const inputTagCount = { item: `${totalInputs} Inputs`, uniqueKey: 'inputs' };
        const totalConcepts = Number(value?.positive_input_tags_count ?? 0);
        const conceptTagCount = totalConcepts > 0 ? [{ item: `${formatNumber(totalConcepts)} Concepts`, uniqueKey: 'concepts' }] : [];
        return [...inputTypesTags, inputTagCount, ...conceptTagCount];
      }, [] as TagItem[]);
  }
  return [];
};

export const getEntityTags = ({ href, entity, appResourceCounts }: IGetEntityTagsParams) => {
  if (isDatasetEntity(href)) {
    return getDatasetTags(entity);
  }
  return formatAppResourceCounts(appResourceCounts).map((count) => ({ uniqueKey: `${getEntityId(entity)}-tags`, item: count }));
};
