import { useCallback } from 'react';
import cogoToast from 'cogo-toast';
import { useIsLoggedIn } from 'modules/Auth/hooks';
import { useRouter } from 'next/router';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useToggle } from 'react-use';
import { getAppId, getEntityId, getEntityName, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { capitalize } from '@mui/material';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { SupportedListingEntities } from '../types';

export function useStarEntity(entity: SupportedListingEntities, pageName: string): (e: React.MouseEvent) => void {
  const { openLoginModal } = useAuthActions();
  const isLoggedIn = useIsLoggedIn();
  const { track } = useDataCollection();
  const router = useRouter();
  const [isLoading, toggleLoading] = useToggle(false);

  return useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      if (!isLoggedIn) {
        cogoToast.info(`You need to be logged in to star/unstar ${getEntityName(entity)}s`);
        openLoginModal();
      }

      if (isLoading) {
        return;
      }

      if (entity && 'is_starred' in entity) {
        if (entity.is_starred && 'unstar' in entity) {
          track(`Unstar ${capitalize(getEntityName(entity) || 'Unknown')} Clicked`, {
            pageName,
            userOrOrgId: getUserId(entity),
            appId: getAppId(entity),
            id: getEntityId(entity),
          });
          entity.unstar(toggleLoading);
        } else if (!entity.is_starred && 'star' in entity) {
          track(`Star ${capitalize(getEntityName(entity) || 'Unknown')} Clicked`, {
            pageName,
            userOrOrgId: getUserId(entity),
            appId: getAppId(entity),
            id: getEntityId(entity),
          });
          entity.star(toggleLoading);
        }
      }
    },
    [entity, track, router],
  );
}
