import { types } from 'mst-effect';

export const FilterConfigItemMst = types
  .model({
    title: types.string,
    type: types.string,
    field: types.string,
    field_type: types.string,
    filterable: types.maybe(types.boolean),
    value: types.array(types.string),
    display: types.array(
      types.model({
        label: types.string,
        value: types.string,
      }),
    ),
    resetInternalState: types.maybe(types.boolean),
  })
  .named('FilterConfigItem');
