import { taskEither, either } from 'fp-ts';
import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions, QueryFunction, UseQueryResult } from 'react-query';
import { pipe } from 'fp-ts/lib/function';
import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { throwErrorOnLeftTE } from './throwErrorOnLeft';

type PickedRQProps<TData, TError> = Pick<
  UseQueryResult<TData, TError>,
  'isFetching' | 'dataUpdatedAt' | 'isPlaceholderData' | 'isPreviousData' | 'isStale' | 'refetch' | 'remove'
>;
/**
 * useQueryTE = useQuery from react-query; with support for TaskEither; and made for UI-only.
 *
 * useQueryTE can **ONLY** work with a TaskEither which returns Either<ReactLeft, Whatever>. i.e. the Left type should always be ReactLeft.
 * We must pass errorToReactLeft to `onError` (2nd arg) of api functions to make this happen. This is because useQueryTE is a React hook;
 * and will be used in UI rendering and ReactLeft mandates/facilitates rendering Loading/Error states.
 *
 * TaskEithers never fail; this function internally informs RQ of errors so that it doesn't consider error (Left) as good data.
 * This makes sure RQ doesn't cache errors, as that's unexpected behavior.
 *
 * @param queryKey react-query QueryKey
 * @param queryFn react-query QueryFn
 * @param options react-query Options
 * @param shouldThrow optional parameter to change what we consider as error; by default all Left is error
 */
export const useQueryTE = <
  L extends ReactLeft,
  R,
  TQueryFnData extends either.Either<L, R>,
  TError extends either.Left<L> | null,
  TData extends either.Either<never, R>,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: (ctx: QueryFunctionContext<TQueryKey>) => ReturnType<taskEither.TaskEither<L, R>>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    initialLeftValue?: L;
  },
  shouldThrow?: (a: L) => boolean,
): {
  data: either.Either<L, R>;
  _fetchStatus: UseQueryResult<TData, TError>['status'];
} & PickedRQProps<TData, TError> => {
  const queryFunctionThatThrowsError = ((ctx: QueryFunctionContext<TQueryKey>) =>
    pipe(
      // use queryFn as taskEither
      () => queryFn(ctx),
      // if there is left; throw error so that react-query can understand
      throwErrorOnLeftTE(shouldThrow),
    )()) as QueryFunction<TQueryFnData, QueryKey>;

  const { initialLeftValue, ...rqOptions } = options || {};
  const { error, data, isFetching, status, dataUpdatedAt, isPlaceholderData, isPreviousData, isStale, refetch, remove } = useQuery(
    queryKey,
    queryFunctionThatThrowsError,
    rqOptions,
  );

  const initialValue = either.left(initialLeftValue || ({ type: UI_STATES.loading } as unknown as TQueryFnData));

  const eitherData = (error || // error needs to be first as it can be null (just how react-query works) // type is either.left
    // if no error; try to return data
    data || // type is either.right
    initialValue) as either.Either<L, R>; // type can be either.left or either.right

  /**
   * ************* ATTENTION *************
   * We intentionally limit the output from useQuery because default react-query output promotes
   * dangerous and flaky usage of boolean flags to keep track of everything.
   * Specially because we use TaskEithers; we don't need any more info than what is returned from here.
   * We might decide to add more info in the future based on a specific use case; but isError, isSuccess and
   * the entire RQ family of booleans will not be a part of it. They are all derived from "status" which
   * we have anyway exported as _fetchStatus from here
   *
   * For the use case of rendering old data while new is fetched; refer to react-query docs `keepPreviousData`
   * The `isPreviousData` prop can be used in conjunction with
   */

  return {
    // unlike pure react-query; our "eitherData" can also be an "error" (ReactLeft | Right)
    data: eitherData,

    dataUpdatedAt,

    // 2 booleans to know react-query specific info about data
    isPlaceholderData,
    isStale,

    // actions: `refetch` triggers refetch and `remove` removes from cache
    refetch,
    remove,

    /** 2 booleans exposed to allow for `keepPreviousData` use-case; i.e. showing loader while new data is fetched */
    isFetching,
    isPreviousData,

    /**
     * Prefer using Either + ReactLeft to track error/loading etc; exposing nonetheless
     * as even if **FetchStatus should not drive UI**; we might need this info for some
     * unforeseen complex logic in a `useEffect` some day
     */
    _fetchStatus: status,
  };
};
