import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteTeamMembersTE = <E = CF.API.ApiError>(
  { orgId, teamId, body }: { orgId: string; teamId: string; body: { user_ids: string[] } },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams/${teamId}/users`,
      method: 'DELETE',
      body,
    },
    onRejected,
  );
