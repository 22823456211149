import type { Listable } from 'modules/Listings/store/models/Listable';
import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'components/Spinner';
import { loadingIndicator } from './List.styled';

/**
 * Triggers fetching of next page on scrolling to bottom
 */
export const ScrollTrigger: React.FC<{
  store: Listable;
}> = observer(({
  store
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref, {
    rootMargin: '0px'
  });
  useEffect(() => {
    if ('hasInfinitePagination' in store && intersection && intersection.intersectionRatio > 0) {
      if (store.hasInfinitePagination && store.hasHydratedList && !store.hasReachedEnd) {
        store.nextPage();
      }
    }
  }, [intersection, store]);
  if (!('hasInfinitePagination' in store)) {
    return null;
  }
  return <div ref={ref} className={loadingIndicator}>
      {store.hasReachedEnd ? <span /> : <Spinner />}
    </div>;
});