import { Star } from 'components/Star';
import { observer } from 'mobx-react-lite';
import { useStarEntity } from 'modules/Listings/hooks/useStarEntity';
import { SupportedListingEntities } from 'modules/Listings/types';
import { getExplorePageNameByPath } from 'utils/urls/pageName';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { star } from './Star.styles';

interface StarComponentProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  entity: SupportedListingEntities;
}

export const StarComponent: React.FC<StarComponentProps> = observer(({ entity, className, ...rest }) => {
  const router = useRouter();
  const handleToggle = useStarEntity(entity, getExplorePageNameByPath(router.pathname) || 'unknown');
  return (
    <Star
      className={cx(star, className)}
      count={(entity as { star_count: number }).star_count}
      isStarred={Boolean((entity as { is_starred: boolean }).is_starred)}
      onClick={handleToggle}
      {...rest}
    />
  );
});
