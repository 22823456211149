import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const AudioEmbedderIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6562 21L26.25 14.875L21.6562 8.75" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.34375 21L1.75 14.875L6.34375 8.75" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.56018 11.939V17.8113" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
    <path d="M11.6066 13.8445V15.9056" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
    <path d="M14.653 10.9862V18.764" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
    <path d="M17.6993 12.8917V16.8584" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
    <path d="M20.7457 14.056V15.6936" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
  </svg>
);
