/* eslint-disable no-plusplus */
import { useMemo } from 'react';
import { adjustHue, hsl, lighten } from 'polished';

export const hash = (str: string): number => {
  let hashVal = 5381;
  let i = str.length;

  while (i) {
    // eslint-disable-next-line no-bitwise
    hashVal = (hashVal * 33) ^ str.charCodeAt(--i);
  }

  // eslint-disable-next-line no-bitwise
  return hashVal >>> 0;
};

const genHue = (n: number): number => {
  const ignoreHueMin = 55;
  const ignoreHueMax = 205;
  let hue = n % 360;

  if (hue > ignoreHueMin && hue < ignoreHueMax) {
    // ignore these color range (yellow and green)
    hue += ignoreHueMax - ignoreHueMin;
  }

  return hue;
};

export const genColor = (uid: string, saturation: number = 0.95, lightness: number = 0.51): string => {
  const n = hash(uid);
  const hue = genHue(n);
  return hsl({ hue, saturation, lightness });
};

export const genGradient: (uid: string, type: string) => string = (uid, type = 'radial') => {
  if (uid) {
    const n = hash(uid);
    const hue = genHue(n);
    const c1 = hsl({ hue, saturation: 0.95, lightness: 0.51 });
    return genGradientFromColor(c1, type);
  }

  throw new TypeError('uid is required');
};

export const genGradientFromColor: (c1: string, type: string) => string = (c1, type = 'radial') => {
  const c2 = adjustHue(45, lighten(0.2, c1));

  switch (type) {
    case 'diagonal':
      return `linear-gradient(to top right, ${c1}, ${c2})`;

    case 'radial':
      return `radial-gradient(circle, ${c1}, ${c2})`;

    case 'horizontal':
      return `linear-gradient(${c1}, ${c2})`;

    case 'vertical':
      return `linear-gradient(to right, ${c1}, ${c2})`;

    default:
      return `linear-gradient(45.4deg, ${c1} 6.41%, ${c2} 98.99%)`;
  }
};

export const useShortDescription = (description: string, wordsCount: number): string => {
  const result = useMemo((): string => {
    let shortDescription = description.split(' ').splice(0, wordsCount).join(' ');
    if (shortDescription.length && shortDescription.length < description.length) {
      if (shortDescription[shortDescription.length - 1] === ',' || shortDescription[shortDescription.length - 1] === '.') {
        shortDescription = shortDescription.slice(0, Math.max(0, shortDescription.length - 1));
      }
      shortDescription += '...';
    }
    return shortDescription;
  }, [description, wordsCount]);
  return result;
};
