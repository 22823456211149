import React, { useRef, useState } from 'react';
import { useMutationTE } from 'utils/react-query';
import { renderLeftError } from 'utils/renderLeftError';
import { errorToReactLeft } from 'utils/fp';
import { ForgotPasswordPayload, postForgotPasswordTE } from 'api/users';
import { IconArrowLeft, IconMailFill, IconKeyFill } from 'components/Icons';
import { Link } from 'components/Link';
import { pipe } from 'fp-ts/lib/function';
import { either } from 'fp-ts';
import {
  restorePasswordBody,
  restorePasswordContainer,
  restorePasswordHeading,
  restorePasswordNav,
  restorePasswordTextGrey,
} from '../RestorePassword.styles';
import { ForgotPasswordForm } from './ForgotPasswordForm';

const DEFAULT_MESSAGE = 'We sent a password reset link to the email';

type ForgotPasswordFieldTypes = {
  email: string;
};

export const ForgotPassword = ({ onBackClick }: { onBackClick?: () => void }): JSX.Element => {
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { dataE, mutate, isLoading } = useMutationTE((body: ForgotPasswordPayload) => postForgotPasswordTE(body, errorToReactLeft)(), {
    onSuccess: () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setShowResend(true);
      }, 5000);
      setShowCheckYourEmail(true);
    },
  });

  const successMessage: string = dataE
    ? pipe(
        dataE,
        either.map((res) => res?.status?.description || DEFAULT_MESSAGE),
        either.getOrElseW(() => '' as const),
      )
    : '';

  const handleSubmit = (data: ForgotPasswordPayload): void => {
    mutate(data);
  };

  const handleResend = (data: ForgotPasswordFieldTypes): void => {
    setShowResend(false);
    mutate(data);
  };

  return (
    <div className={restorePasswordContainer}>
      <div className={restorePasswordBody}>
        {showCheckYourEmail ? (
          <IconMailFill size={56} color="#F2F6FE" data-testid="mail-icon" />
        ) : (
          <IconKeyFill size={56} color="#F2F6FE" data-testid="key-icon" />
        )}
        <h1 className={restorePasswordHeading}>{showCheckYourEmail ? 'Check your email' : 'Forgot password?'}</h1>
        <p className={restorePasswordTextGrey}>{showCheckYourEmail ? successMessage : "No worries, we'll send you reset instructions."}</p>

        {renderLeftError(dataE)}

        <ForgotPasswordForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          showCheckYourEmail={showCheckYourEmail}
          onResend={handleResend}
          showResend={showResend}
        />

        <Link
          href="/login"
          className={restorePasswordNav}
          onClick={(e) => {
            if (onBackClick) {
              e.preventDefault();
              onBackClick();
            }
          }}
        >
          <IconArrowLeft size={18} />
          Back to log in
        </Link>
      </div>
    </div>
  );
};
