export const ScrollWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ style, children, ...rest }) => (
  <div
    style={{
      width: '100%',
      overflow: 'auto',
      maxHeight: '100vh',
      maxWidth: '100vw',
      ...style,
    }}
    {...rest}
  >
    {children}
  </div>
);
