import { compose, join, map, identity, split, toUpper } from 'rambda';
import { isBlank, strlen } from 'printable-characters';

// Creates a Unique string of specified length
export const makeUID = (length = 16): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/*
 * Takes a string, reduces it to a color by computing a hash of the
 * characters and taking the first 6 hex digts as a color.
 */
export const stringToHash = (str: string): string => {
  let hash = str
    .split('')
    // eslint-disable-next-line no-bitwise
    .reduce((h, ch) => (h << 5) - h + ch.charCodeAt(0), 0)
    .toString(16)
    .replace('-', '');

  while (hash.length < 6) {
    hash += hash;
  }
  return hash;
};

export const stringToColor = (str: string): string => {
  const hash = stringToHash(str);
  return `#${hash.slice(0, 6)}`;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : null;
};

const validChar = /\w/;

/*
 * Sanitizes an input string such that it becomes easy to embed in a URL.
 * In practice, this means replacing consecutive invalid characters with a
 * single hyphen.
 */
export const sanitizeString = (str = ''): string => {
  let prevHyphen = false;

  return str
    .split('')
    .map((ch) => {
      if (validChar.test(ch)) {
        prevHyphen = false;
        return ch;
      }
      if (!prevHyphen) {
        prevHyphen = true;
        return '-';
      }
      return false;
    })
    .filter((ch) => !!ch)
    .join('');
};

export const makeInitials = (name: string): string =>
  compose(join(''), (input: string[]) => input.slice(0, 2), map(identity((val) => val.charAt(0))), split(' '), toUpper)(name);

export interface Token {
  text: string;
  start: number;
  end: number;
}

export function tokenizeString(str: string): Token[] {
  const tokens = [] as Token[];
  let start = 0;
  let end = 0;
  do {
    end = str.indexOf(' ', start);
    if (end === -1) {
      end = str.length;
    }
    const text = str.slice(start, end);
    const token = {
      text,
      start,
      end,
    };
    tokens.push(token);
    start = end + 1;
  } while (start < str.length);

  return tokens;
}

/**
 * Given an input string, `str`, and the selection start and endpoints, this function will return
 * the currently focused token, the token the user is currently editing, as well as the start and end
 * indices of that token within the larger input string.
 * @param str The full input string to search.
 * @param cursor The position of the user's cursor range
 */
export function getCurrentlyFocusedToken(str: string, cursor: number): Token | undefined {
  if (!str) {
    return undefined;
  }

  const tokens = tokenizeString(str);

  return tokens.find((token) => {
    return token.start <= cursor && cursor < token.end + 1;
  });
}

// Check if a string contains only printable characters used in names
export function isPrintable(value: string): boolean {
  if (isBlank(value)) {
    return false;
  }

  return strlen(value) === value.length;
}

// Check if the string has non-whitespace characters at the start or end
export function hasNonWhitespaceEdge(value: string): boolean {
  return value.length > 0 && value.trim().length === value.length;
}
