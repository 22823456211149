import React, { ReactElement, FC } from 'react';
import { cx } from '@linaria/core';
import { breadCrumbsWrapper } from './Breadcrumbs.styles';
import { BreadcrumbItemProps } from './components/BreadcrumbItem/BreadcrumbItem';

type BreadCrumbChildren<T> = T | (T | null)[];

export interface BreadCrumbsProps {
  children: BreadCrumbChildren<ReactElement<BreadcrumbItemProps>>;
  className?: string;
}

export const Breadcrumbs: FC<BreadCrumbsProps> = ({ children, className }: BreadCrumbsProps) => (
  <ul className={cx(breadCrumbsWrapper, className)}>{children}</ul>
);
