import { cx } from '@linaria/core';
import { IconCheck } from 'components/Icons';
import { TextSmall } from 'components/Popover';
import { sorterItemsWrapper } from './SorterItems.styles';

type ItemValue = string | number | boolean;

type SorterItemsProps = {
  items: { name: string; value: ItemValue }[];
  selectedValue: ItemValue;
  onChange: (data: { value: ItemValue }) => void;
};

export const SorterItems: React.FC<SorterItemsProps> = ({ items, selectedValue, onChange }) => {
  return (
    <>
      {items.map((order) => {
        return (
          <div className={sorterItemsWrapper} key={order.name}>
            <TextSmall role="button" onClick={() => onChange({ value: order.value })} className={cx(selectedValue === order.value ? 'bold' : '')}>
              <IconCheck size={18} className={cx(selectedValue === order.value ? '' : 'hidden')} color="#000" />
              {order.name}
            </TextSmall>
          </div>
        );
      })}
    </>
  );
};
