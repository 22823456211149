import { Button } from 'components/Button';
import { IconPlayNext, IconPlayPrev } from 'components/Icons';
import { container, pages, inactiveBtn, whiteBtn } from './Pagination.styles';

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (size: number) => void;
  pageOptions: number[];
  pageIndex: number;
}

function generatePagination(current: number, total: number) {
  if (total <= 1) return [1];
  const center = [current - 2, current - 1, current, current + 1, current + 2];
  const filteredCenter = center.filter((p) => p > 1 && p < total);
  const includeThreeLeft = current === 5;
  const includeThreeRight = current === total - 4;
  const includeLeftDots = current > 5;
  const includeRightDots = current < total - 4;

  if (includeThreeLeft) filteredCenter.unshift(2);
  if (includeThreeRight) filteredCenter.push(total - 1);

  if (includeLeftDots) filteredCenter.unshift(-1);
  if (includeRightDots) filteredCenter.push(-1);

  return [1, ...filteredCenter, total];
}

export const Pagination: React.FC<PaginationProps> = ({
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageOptions,
  gotoPage,
  pageIndex,
}): JSX.Element => {
  return (
    <div className={container} data-testid="pagination">
      <Button
        data-testid="prevBtn"
        variant="secondary"
        className={whiteBtn}
        LeftIcon={IconPlayPrev}
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        Previous
      </Button>
      <div className={pages}>
        {generatePagination(pageIndex, pageOptions.length).map((page) => {
          const isActive = pageIndex === page - 1;
          return (
            <Button
              data-testid={`page${page}`}
              key={page}
              className={!isActive ? inactiveBtn : ''}
              variant={isActive ? 'secondary' : 'link'}
              onClick={() => gotoPage(page - 1)}
            >
              {page !== -1 ? page : '...'}
            </Button>
          );
        })}
      </div>

      <Button data-testid="nextBtn" variant="secondary" className={whiteBtn} RightIcon={IconPlayNext} onClick={nextPage} disabled={!canNextPage}>
        Next
      </Button>
    </div>
  );
};
