import cogoToast from 'cogo-toast';

/**
 * Copy some arbitrary text to the user's clipboard.
 * @param {string} text - the text to be copied to the clipboard
 *
 * @returns {Promise} - resolves if the copy succeeds;
 *                      rejects if the copy fails/throws an error.
 */
export const clipboardTextCopy = (text: string): Promise<string> => {
  const dummyEl = document.createElement('textarea');
  dummyEl.textContent = text;
  dummyEl.setAttribute('style', 'position: absolute; top: 0; opacity: 0;');
  document.body.append(dummyEl);
  dummyEl.select();
  return new Promise((resolve, reject) => {
    try {
      const success = document.execCommand('copy');
      if (success) {
        resolve(text);
      } else {
        reject();
      }
    } catch (e) {
      reject(e);
    }
    dummyEl.remove();
  });
};

/**
 * Function to copy text to clipboard and produce Toast message
 * based on success.
 */
export const copyToClipboard = (text: string, message?: React.ReactNode | string) => {
  return async () => {
    await clipboardTextCopy(text)
      /* istanbul ignore next */
      .then(() => cogoToast.success(message || `${text} copied to clipboard.`))
      .catch((e) => cogoToast.error(e));
  };
};
