import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchApi, fetchTE } from 'utils/request';

export const apiFetchCollaborators = ({
  userId,
  appId,
  params,
}: CF.API.Collaborators.FetchCollaboratorsRequest): Promise<CF.API.Collaborators.FetchCollaboratorsResponse> =>
  fetchApi({
    path: `/api/_api/v2/users/${userId}/apps/${appId}/collaborators${params ? `?${params}` : ''}`,
    method: 'GET',
  });

export const apiFetchAppMembers = ({
  orgId,
  appId,
}: CF.API.Collaborators.FetchAppMembersRequest): Promise<CF.API.Collaborators.FetchAppMembersResponse> =>
  fetchApi({
    path: `/api/_api/v2/organizations/${orgId}/apps/${appId}/members`,
    method: 'GET',
  });

/**
 * @deprecated we are moving towards dropping TaskEither
 */
export const fetchCollaboratorsTE = <E = CF.API.ApiError>(
  { userId, appId, params }: CF.API.Collaborators.FetchCollaboratorsRequest,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Collaborators.FetchCollaboratorsResponse> =>
  fetchTE<CF.API.Collaborators.FetchCollaboratorsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/collaborators${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );

/**
 * @deprecated we are moving towards dropping TaskEither
 */
export const fetchAppMembersTE = <E = CF.API.ApiError>(
  { orgId, appId }: CF.API.Collaborators.FetchAppMembersRequest,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Collaborators.FetchAppMembersResponse> =>
  fetchTE<CF.API.Collaborators.FetchAppMembersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/apps/${appId}/members`,
      method: 'GET',
    },
    onRejected,
  );
