import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchTeamTE = <E = CF.API.ApiError>(
  { orgId, teamId }: { orgId?: string; teamId: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.FetchTeamResponse> =>
  fetchTE<CF.API.Organizations.FetchTeamResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams/${teamId}`,
      method: 'GET',
    },
    onRejected,
  );
