import { cx } from '@linaria/core';
import { Button } from 'components/deprecated/Button';
import { IconFileText, IconPlus } from 'components/Icons';
import { cssHelpers } from 'styles/utils/core';
import { useCallback } from 'react';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { useCreateApplicationModal } from 'modules/Apps/hooks';
import { useRouter } from 'next/router';
import { buttonIcon, quickStartButton, createAppButton, mainContainer } from './NoAppState.styles';
import { quickStartGuide } from './NoAppState.constants';
import { useIsOwner } from './NoAppState.hooks';
export const NoAppCTA: React.FC = () => {
  const isOwner = useIsOwner();
  const {
    load
  } = useCurrentStore();
  const router = useRouter();
  const {
    canCreateApplication,
    openCreateApplicationModal
  } = useCreateApplicationModal({
    onAppCreated: createdApp => {
      load();
      router.push(`/${createdApp.userId}/${createdApp.appId}`);
    }
  });
  const openQuickStartGuide = useCallback(() => {
    window.open(quickStartGuide, '_blank');
  }, []);
  return <div className={cx(mainContainer, 'fullWidth')}>
      <Button data-testid="quick-start-guide-button" type="button" kind="outline-simple" className={cx(quickStartButton, cssHelpers.margin.yt.half)} onClick={openQuickStartGuide}>
        <IconFileText className={cx(buttonIcon)} size={22} color="#101828" />
        Quick start guide
      </Button>
      {isOwner && canCreateApplication && <Button data-testid="create-app-button" type="button" kind="primary" className={cx(createAppButton, cssHelpers.margin.yt.half)} onClick={openCreateApplicationModal}>
          <IconPlus className={cx(buttonIcon)} size={22} color="white" />
          Create an App
        </Button>}
    </div>;
};