import { getEntityVisibility } from 'modules/Listings/helpers/entityCardUtils';
import React, { useEffect, useRef, useState } from 'react';
import cogoToast from 'cogo-toast';
import { errorToReactLeft, noop } from 'utils/fp';
import { updateModelTE } from 'api/models/updateModel';
import { pick } from 'rambda';
import { pipe } from 'fp-ts/lib/function';
import { either } from 'fp-ts';
import { useAsyncFn, useMountedState } from 'react-use';
import { useQueryClient } from 'react-query';
import { ModelEvaluationTypeEnum } from 'types/api/enums';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { useHasScopes } from 'modules/Auth/hooks/scopes';
import { useModelTypeInfo } from 'modules/Models/hooks/useModelTypeInfo';
import { updateModelIdTE } from 'api/models/updateModelId';
import { ReactLeft } from 'utils/uiStates/uiStates';

export type UseEditableModelType = {
  data: CF.API.Models.Model;
  setValue: (key: string, value: unknown, shouldSave?: boolean) => void;
  save: () => void;
  isEditable: boolean;
  hasDeleteVersionAccess: boolean;
  visibility: ReturnType<typeof getEntityVisibility>;
  updateId: (id: string) => Promise<either.Either<ReactLeft, CF.API.Models.ListModelsResponse>>;
  evaluationType: ModelEvaluationTypeEnum;
};

export function useEditableModel(modelFromApi: CF.API.Models.Model, userAndAppIds?: { appId: string; userOrOrgId: string }): UseEditableModelType {
  const userCanEdit = useHasScopes([permissionMatchers.model.edit], userAndAppIds);
  const canUserDeleteModelVersion = useHasScopes([permissionMatchers.modelVersion.delete], userAndAppIds);

  const [model, setModel] = useState<CF.API.Models.Model>({ ...modelFromApi, description: modelFromApi.description || '--' });
  const isMounted = useMountedState();
  const lastSaved = useRef({ ...modelFromApi });

  const saveModel = useSaveModel({
    // even though unit test specifically checks and asserts that state isnt updated after unmount; somehow coverage isn't being met :(
    makeSetModel: /* istanbul ignore next */ () => (isMounted() /* istanbul ignore next */ ? setModel : /* istanbul ignore next */ noop),
    lastSaved,
  });

  useEffect(() => {
    setModel({ ...modelFromApi, description: modelFromApi.description || '--' });
  }, [modelFromApi]);

  const setValue = (key: string, value: unknown, shouldSave?: boolean): void => {
    const newModel = {
      ...model,
      [key]: value,
    };
    setModel(newModel);
    if (shouldSave) {
      saveModel(newModel);
    }
  };

  const modelTypeInfo = useModelTypeInfo(modelFromApi);

  const result = {
    data: model,
    setValue: userCanEdit ? setValue : noop,
    save: userCanEdit ? () => saveModel(model) : noop,
    isEditable: userCanEdit,
    hasDeleteVersionAccess: canUserDeleteModelVersion,
    visibility: getEntityVisibility(model),
    updateId: (id: string) =>
      updateModelIdTE(
        {
          userId: model.user_id,
          appId: model.app_id,
          body: {
            ids: [{ id: model.id, new_id: id }],
            action: 'overwrite',
          },
        },
        errorToReactLeft,
      )(),
    evaluationType: modelTypeInfo.evaluationType,
  };

  return result;
}

export enum SupportedEditableModelFields {
  id = 'id',
  description = 'description',
  notes = 'notes',
  metadata = 'metadata',
}

function useSaveModel({
  makeSetModel,
  lastSaved,
}: {
  makeSetModel: () => React.Dispatch<React.SetStateAction<CF.API.Models.Model>>;
  lastSaved: React.MutableRefObject<CF.API.Models.Model>;
}): (m: CF.API.Models.Model) => Promise<CF.API.Models.Model> {
  const queryClient = useQueryClient();

  const [, doSave] = useAsyncFn(
    async (model: CF.API.Models.Model) => {
      const modelToUpdate: Pick<CF.API.Models.Model, SupportedEditableModelFields> = pick(['id', 'description', 'notes', 'metadata'])(model);

      pipe(
        await updateModelTE({
          userId: model.user_id,
          appId: model.app_id,
          body: { models: [modelToUpdate], action: 'merge' },
        })(),
        either.fold(
          () => {
            makeSetModel()({ ...lastSaved.current });
            cogoToast.error('Could not save model details.');
          },
          ({ models: [updatedModel] }) => {
            cogoToast.success('Model Updated.');
            lastSaved.current = { ...updatedModel };
            makeSetModel()(updatedModel);
            queryClient.invalidateQueries(['Models']);
          },
        ),
      );
      return model;
    },
    [makeSetModel, lastSaved],
  );

  return (model: CF.API.Models.Model) => doSave(model);
}

export type EditableModel = ReturnType<typeof useEditableModel>;

export const testable = { useSaveModel, useEditableModel };
