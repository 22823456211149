import { HTMLAttributes, useCallback } from 'react';
import { cx } from '@linaria/core';
import { Popover, PopoverAction, usePopoverState } from 'components/Popover';
import { IconCheck, IconArrowUpDown } from 'components/Icons';
import { smallTitle, textRegular, textRegularLight } from 'styles/typography';
import { flex } from 'styles/utils';
import { makeUserFullName } from 'utils/users';
import { stringToColor, makeInitials } from 'utils/strings/strings';
import { TextIcon } from 'components/TextIcon';
import { useAuthState, useAuthActions } from 'modules/Auth/AuthContext';
import { useCurrentUser } from 'modules/Auth/hooks';
import { useRouter } from 'next/router';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { appInfoArea, shrinkText } from 'modules/Sidebar/Sidebar.styles';
import { thumbnailSize } from 'components/Thumbnail/Thumbnail.styles';
import { accountSwitchAvatar, accountSwitchPopover, accountSwitchItem } from './Sidebar.styles';

interface AccountItemProps extends HTMLAttributes<HTMLButtonElement> {
  name: string;
  selected?: boolean;
  isOwner?: boolean;
}

const AccountItem: React.FC<AccountItemProps> = ({ name, selected, isOwner, ...rest }) => {
  return (
    <button data-testid={`account-${name}`} type="button" className={cx(accountSwitchItem, !selected && 'not-selected')} {...rest}>
      {selected && <IconCheck size={24} color="#000" />}
      <TextIcon text={makeInitials(name)} background={stringToColor(name)} className={accountSwitchAvatar} />
      <span className={cx('fullname', isOwner ? textRegular : textRegularLight)}>{name}</span>
    </button>
  );
};

export const AccountSwitcher: React.FC = () => {
  const router = useRouter();
  const popover = usePopoverState();
  const currentUser = useCurrentUser();
  const [hasOrgAccess] = useFeaturedFlags(['OrgCreateOrganization']);
  const { authData } = useAuthState();
  const { changeUserAccountById } = useAuthActions();
  const fullname = currentUser ? makeUserFullName(currentUser) : '';

  const handleChangeUserId = useCallback(
    (accountType, accountId) => {
      if (currentUser?.user_id === accountId) {
        return;
      }

      changeUserAccountById(accountId);

      if (accountType === 'org') {
        router.push('/settings/organization');
        return;
      }

      router.push('/settings');
    },
    [changeUserAccountById, router],
  );

  return (
    <>
      <PopoverAction popover={popover} disabled={!hasOrgAccess} data-testid="account-switcher" data-collapsed="false" className={appInfoArea}>
        <TextIcon className={thumbnailSize} text={makeInitials(fullname)} background={stringToColor(fullname)} data-testid="account-thumbnail" />
        <div data-testid="current-fullname" className={cx('appid', shrinkText, flex.flex.one)} style={{ textAlign: 'left' }}>
          {fullname}
        </div>
        {hasOrgAccess && <IconArrowUpDown size={20} style={{ minWidth: 20 }} />}
      </PopoverAction>

      {hasOrgAccess && (
        <Popover data-testid="account-switch-popover" tabIndex={0} aria-label="switch account" popover={popover} className={accountSwitchPopover}>
          <AccountItem
            isOwner
            name={authData ? makeUserFullName(authData) : ''}
            selected={currentUser?.user_id === authData?.user_id}
            onClick={() => handleChangeUserId('user', authData?.user_id)}
          />
          <div className="divider" />
          <span className={smallTitle}>ORGANIZATIONS</span>
          {authData?.organizations?.map(({ organization }) => (
            <AccountItem
              key={organization.id}
              name={organization.name}
              selected={currentUser?.user_id === organization.id}
              onClick={() => handleChangeUserId('org', organization.id)}
            />
          ))}
        </Popover>
      )}
    </>
  );
};
