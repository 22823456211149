import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import dynamic from 'next/dynamic';
import type { MarkdownPreviewProps } from '@uiw/react-markdown-preview';
import type { MDEProps } from './MarkdownEditor';
export const MarkdownEditor = dynamic<MDEProps>(() => import('./MarkdownEditor').then(mod => mod.MarkdownEditor_), {
  ssr: false
});
export const MarkdownPreview = dynamic<MarkdownPreviewProps>(import('@uiw/react-markdown-preview').then(x => x.default), {
  ssr: false
});