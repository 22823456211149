import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export interface UserConsentPayload {
  consent: boolean;
  fields: ('gdpr' | 'tos' | 'pii')[];
}

export const updateUserConsentTE = <E = CF.API.ApiError>(
  values: UserConsentPayload,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.GetUserMeResponse> =>
  fetchTE<CF.API.Users.GetUserMeResponse, E>(
    {
      path: '/api/_api/v2/users/me/consent',
      method: 'POST',
      body: {
        consent: values.consent,
        fields: values.fields,
      },
    },
    onRejected,
  );
