/* istanbul ignore file */
// ignoring because markdown editor not working in jest
import { Button } from 'components/deprecated/Button';
import { Card } from 'components/Card/Card';
import React, { useState } from 'react';
import { MdxProps } from 'types';
import { EditableMarkdown } from './EditableMarkdown';

export function EditableMarkdownCard({
  mdxSource,
  editableEntity,
  style,
}: {
  mdxSource?: MdxProps;
  editableEntity: CF.UI.EditableEntity;
  style?: React.CSSProperties;
}): JSX.Element | null {
  const [isEditing, setIsEditing] = useState(false);
  const toggleEditing = (): void =>
    setIsEditing((isE) => {
      if (isE) {
        editableEntity.save();
        return false;
      }
      return true;
    });

  return (
    <Card
      style={{ minHeight: '50vh', ...style }}
      data-testid="markdown-card"
      titleSeparator
      title="Notes"
      actionList={
        editableEntity.isEditable
          ? [
              <Button key="edit" kind="link" onClick={toggleEditing} style={{ fontSize: '0.875rem' }}>
                {isEditing ? 'Save' : 'Edit'}
              </Button>,
            ]
          : []
      }
    >
      <EditableMarkdown mdxSource={mdxSource} editableEntity={editableEntity} isEditing={isEditing} />
    </Card>
  );
}

EditableMarkdownCard.defaultProps = {
  style: {},
};
