import { useState, FocusEvent, MouseEvent, useCallback, forwardRef } from 'react';
import { Input } from 'components/Input';
import { IconSearch, IconCloseRound } from 'components/Icons';
import { cx } from '@linaria/core';
import { wrapper, searchInput, searchIcon, closeIcon, placeholderEllipsis } from './Search.styles';

interface SearchInputInterface {
  value?: string;
  isClearable?: boolean;
  placeholder?: string;
  wrapperClassName?: string;
  iconClassName?: string;
  inputClassName?: string;
  setValue: (value: string) => void;
  [key: string]: unknown;
}

export const SearchInput = forwardRef(
  (
    { placeholder, value, setValue, wrapperClassName, iconClassName, inputClassName, isClearable, ...props }: SearchInputInterface,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [eventType, setEventType] = useState('');
    const handleEvents = (event: FocusEvent | MouseEvent): void => {
      setEventType(event.type);
    };

    const onClear = useCallback(
      (event: MouseEvent): void => {
        event.stopPropagation();
        setValue('');
      },
      [setValue],
    );

    const getColor = (): string => {
      if (value) {
        return '#195AFF';
      }
      switch (eventType) {
        case 'focus':
          return '#195AFF';
        case 'mouseenter':
          return '#282636';
        default:
          return '#808080';
      }
    };

    return (
      <div className={cx(wrapperClassName, wrapper)}>
        <IconSearch className={cx(searchIcon, iconClassName)} data-testid="search-icon" size={22} color={getColor()} />
        <Input
          className={cx(searchInput, placeholderEllipsis, 'fullWidth', inputClassName)}
          placeholder={placeholder}
          value={value}
          data-testid="listingSearchInput"
          onChange={(e) => setValue(e.target.value)}
          onFocus={handleEvents}
          onBlur={handleEvents}
          onMouseEnter={handleEvents}
          onMouseLeave={handleEvents}
          ref={ref}
          {...props}
        />

        {isClearable && Boolean(value) && (
          <button title="Clear" type="button" aria-label="Clear search" data-testid="clearSearch" onClick={onClear} className={closeIcon}>
            <IconCloseRound size={20} />
          </button>
        )}
      </div>
    );
  },
);
