import { useCallback } from 'react';
import cogoToast from 'cogo-toast';
import { either, taskEither } from 'fp-ts';
import { createKeyTE, createAppTE } from 'api/apps';
import { errorToReactLeft, pipe } from 'utils/fp';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { identity } from 'rambda';
import { useAuthState } from 'modules/Auth/AuthContext';

const USER_CONSENT_FACE = 50001;

export function useCreateApplicationTE({ showApiKeyToasts }: { showApiKeyToasts?: boolean }): (values: {
  userId: string; // userId or OrgId
  appId: string; // app id
  language: string;
  baseWorkflow: string;
  description?: string;
  legalConsent?: boolean;
}) => taskEither.TaskEither<ReactLeft, CF.API.Apps.createMockApp> {
  const { authData } = useAuthState();

  return useCallback(
    (values) =>
      pipe(
        // *** CREATE APP **
        createAppTE(
          {
            userId: values.userId,
            body: {
              id: values.appId,
              description: values.description,
              default_language: values.language,
              default_workflow_id: values.baseWorkflow,
              ...(values.legalConsent ? { legal_consent_status: USER_CONSENT_FACE } : {}),
            },
          },
          errorToReactLeft,
        ),
        // *** If app creation successful; create API key; but don't fail app creation if API key creation fails ***
        taskEither.chainFirstTaskK((res) => {
          // chainFirst is just like chain, i.e. calls another API with result of 1st; except that
          // chainFirst returns the result of the first Task; not second one.
          // chainFirstTaskK is chainFirst; except it doesn't fail the first req if second fails (https://github.com/gcanti/fp-ts/issues/1039)

          // skip creating api key for org as its not supported yet
          if (authData?.user_id !== values.userId) return taskEither.fromEither(either.left({} as ReactLeft));

          return createNewKey({
            body: {
              apps: res.apps,
              description: `${values.appId}-all-scopes`,
              scopes: ['All'],
            },
            userId: values.userId,
            showApiKeyToasts,
          });
        }),
      ),
    [showApiKeyToasts],
  );
}

/** Create API Key for application and show toasts */
export function createNewKey({
  userId,
  body,
  showApiKeyToasts,
}: {
  body: CF.API.Apps.CreateKeyBody;
  userId: string;
  showApiKeyToasts?: boolean;
}): taskEither.TaskEither<ReactLeft, CF.API.Apps.CreateKeyResponse> {
  return pipe(
    createKeyTE({ userId, body }, errorToReactLeft),
    showApiKeyToasts
      ? taskEither.fold(
          (y) => {
            cogoToast.error('Could not create API key');
            return taskEither.left(y);
          },
          (x) => {
            cogoToast.success(`Key ${body.description} created!`);
            return taskEither.right(x);
          },
        )
      : identity,
  );
}
