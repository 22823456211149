import React from 'react';
import { Instance, IAnyModelType } from 'mobx-state-tree';
import { useAutoDestroyMst } from './useAutoDestroyMst';

export function useMstModel<T extends IAnyModelType>(
  Model: T,
  deps: React.DependencyList,
  configFactory?: () => Parameters<T['create']>,
): Instance<T> {
  const model = React.useMemo(() => {
    return Model.create(...(configFactory ? configFactory() : []));
  }, [Model, ...deps]);

  useAutoDestroyMst(model);

  return model;
}

/* istanbul ignore next: https://clarifai.atlassian.net/browse/LT-2756 */
export function useMaybeNullMstModel<T extends IAnyModelType>(
  enabled: boolean,
  Model: T,
  deps: React.DependencyList,
  configFactory?: () => Parameters<T['create']>,
): Instance<T> | null {
  const model = React.useMemo(() => {
    return enabled ? Model.create(...(configFactory ? configFactory() : [])) : null;
  }, [Model, ...deps]);

  useAutoDestroyMst(model);

  return model;
}
