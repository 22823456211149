import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const searchConceptsListTE = <E = CF.API.ApiError>(
  { appId, userId, page = 1, perPage = 500, search = '' }: CF.API.Concepts.FetchConceptsRequest & { search?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Concepts.MultiConceptsResponse> => {
  return fetchTE<CF.API.Concepts.MultiConceptsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/concepts/searches?page=${page}&per_page=${perPage}`,
      method: 'POST',
      body: {
        concept_query: {
          name: search,
        },
      },
    },
    onRejected,
  );
};

export const fetchConceptsListTE = <E = CF.API.ApiError>(
  { appId, userId, page = 1, perPage = 500 }: CF.API.Concepts.FetchConceptsRequest,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Concepts.MultiConceptsResponse> =>
  fetchTE<CF.API.Concepts.MultiConceptsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/concepts?page=${page}&per_page=${perPage}`,
      method: 'GET',
    },
    onRejected,
  );

export const fetchModelConceptsListTE = <E = CF.API.ApiError>(
  { appId, modelId, versionId, userId, page = 1, perPage = 500, search = '' }: CF.API.Concepts.FetchModelConceptsRequest,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Concepts.MultiConceptsResponse> => {
  const basePath = `/api/_api/v2/users/${userId}/apps/${appId}/models/${modelId}`;
  const queryParams = `?page=${page}&per_page=${perPage}${search ? `&search=${search}` : ''}`;
  const modelUrl = `${basePath}/concepts${queryParams}`;
  const versionsUrl = `${basePath}/versions/${versionId}/concepts${queryParams}`;
  return fetchTE<CF.API.Concepts.MultiConceptsResponse, E>(
    {
      path: versionId ? versionsUrl : modelUrl,
      method: 'GET',
    },
    onRejected,
  );
};
