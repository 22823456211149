import { cx } from '@linaria/core';
import { success, error, info } from './Alert.styles';

export type AlertStatus = 'success' | 'error' | 'info';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  type: AlertStatus;
}

const AlertMap = {
  success,
  error,
  info,
};

export const Alert = ({ className, type, ...rest }: AlertProps): JSX.Element => (
  <div className={cx(className, AlertMap[type])} data-alert-type={type} {...rest}>
    {rest.children}
  </div>
);
