import { css, cx } from '@linaria/core';
import { theming, breakpoints, flex } from 'styles/utils';
import { textSmall } from 'styles/typography';
import { cssHelpers } from 'styles/utils/core';
import { heading, textGrey, textLink } from '../styles';
const commonMargin = '2rem';
export const twoFactorAuthContainer = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthContainer-tscy6h6";
export const twoFactorAuthBody = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthBody-t1st54gs";
export const twoFactorAuthHeading = cx(heading, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHeading-ttvvkfu");
export const twoFactorAuthTextGrey = cx(textGrey, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthTextGrey-t126f2sb");
export const twoFactorAuthInput = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthInput-tlx0aez";
export const twoFactorAuthLabel = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLabel-tr0hwo1";
export const twoFactorAuthButton = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthButton-tn0u7wl";
export const twoFactorAuthLink = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLink-tvhjlku";
export const twoFactorAuthHint = cx(textSmall, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt");
export const twoFactorLoginContainer = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginContainer-t14magdv";
export const twoFactorAuthLoginHint = cx(textGrey, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLoginHint-t1fdih3n");
export const twoFactorAuthLoginSubHeader = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLoginSubHeader-tum2aws";
export const twoFactorAuthNav = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91";
export const twoFactorLoginLinkText = cx(textLink, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginLinkText-t17ohv03");
export const twoFactorLoginNav = cx(twoFactorAuthNav, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginNav-tuhfq2");
export const twoFARecoveryInput = "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryInput-t1jipu46";
export const twoFARecoveryForm = cx(flex.init, flex.justifyContent.center, flex.alignItems.center, flex.direction.column, "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryForm-tqr9szb");
export const twoFARecoveryButton = cx(cssHelpers.margin.y[1], "odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryButton-t1vwdzzr");

require("./TwoFactorAuth.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./TwoFactorAuth.styles.ts");