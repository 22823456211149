import { css } from '@linaria/core';
import { baseUnits, theming, flex } from 'styles/utils';
export const cardWrapper = `${"omponents__Popover__Popover_styles_ts-cardWrapper-c1nw7sja"}

  ${flex.init}
  ${flex.direction.column}
`;
export const popoverWrapper = "omponents__Popover__Popover_styles_ts-popoverWrapper-p3wma9z";
export const popoverGroup = "omponents__Popover__Popover_styles_ts-popoverGroup-p4io2yz";
export const segmentTitle = "omponents__Popover__Popover_styles_ts-segmentTitle-sr6w81o";
export const textNormal = "omponents__Popover__Popover_styles_ts-textNormal-te57xab";
export const textSmall = "omponents__Popover__Popover_styles_ts-textSmall-t757i85";

require("./Popover_styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Popover_styles.ts");