import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useUtmFields = (): { name: string; value: string }[] => {
  const router = useRouter();

  const hash = useMemo(
    () =>
      router.asPath
        .split('#')[1]
        ?.split('&')
        .reduce<Record<string, string>>((acc, h) => {
          const [key, value] = h.split('=');
          return { ...acc, [key]: value };
        }, {}) || {},
    [router],
  );
  const query = router.query as Record<string, string>;

  const utmResources = useMemo(
    () =>
      [
        { name: 'utm_source', value: hash.utm_source === 'hs_email' ? '' : hash.utm_source || query.utm_source },
        { name: 'utm_campaign', value: hash.utm_campaign || query.utm_campaign },
        { name: 'utm_medium', value: hash.utm_medium || query.utm_medium },
        { name: 'utm_parameter', value: hash.utm_parameter },
        { name: 'utm_term', value: hash.utm_term || query.utm_term },
        { name: 'gclid', value: query.gclid },
      ].filter((obj) => !!obj.value),
    [hash, query],
  );

  return utmResources;
};
