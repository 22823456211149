import { InputProps } from 'components/Input';
import { Input as ReakitInput } from 'reakit/Input';
import { cx } from '@linaria/core';
import { forwardRef } from 'react';
import { inputStyles } from './InputWithIcon.styles';

export interface InputWithIconProps extends InputProps {
  LeftIcon?: React.ReactNode;
  RightIcon?: React.ReactNode;
}

export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(({ id, variant, LeftIcon, RightIcon, ...props }, forwardedRef) => {
  return (
    <label htmlFor={id} className={cx(inputStyles, props.className, variant)}>
      {LeftIcon}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ReakitInput ref={forwardedRef} id={id} {...props} />
      {RightIcon}
    </label>
  );
});
