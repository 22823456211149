import { types, isAlive } from 'mobx-state-tree';
import { AnyInstance } from 'mst-effect';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withRunInAction<T>() {
  return types.model({}).actions((self) => ({
    runInAction(fn: () => T) {
      if (fn && isAlive(self)) {
        return fn();
      }

      return undefined;
    },
  }));
}

export function updateMST<T>(self: AnyInstance, fn: () => T): T | undefined {
  if (isAlive(self) && self.runInAction) {
    return self.runInAction(() => fn());
  }

  return undefined;
}
