import { useMemo } from 'react';
import { useAutoDestroyMst } from 'utils/mst/useAutoDestroyMst';
import { observer } from 'mobx-react-lite';

import { useIsLoggedIn } from 'modules/Auth/hooks';
import { appTemplatesListType } from 'modules/Apps/components/AppTemplates/createAppTemplatesList';
import type { collaborationsListType, appsListType, workflowsListType, modelsListType, modulesListType, datasetsListType } from './Store.types';
import { ENTITY_SLUGS as E_S, CurrentStoreContext, ListingEntityTypeContext } from './CurrentStoreContext';

export const ENTITY_SLUGS = E_S;

export type AllListStoreTypes =
  | workflowsListType
  | modelsListType
  | appsListType
  | collaborationsListType
  | modulesListType
  | datasetsListType
  | appTemplatesListType;

type Props<T> = { store: T; entityType: E_S };

type StateProviderI<T = AllListStoreTypes> = React.FC<Props<T>>;

const StateProvider_: StateProviderI = ({ children, store, entityType }): JSX.Element => {
  return (
    <CurrentStoreContext.Provider value={store as unknown as AllListStoreTypes}>
      <ListingEntityTypeContext.Provider value={entityType}>{children}</ListingEntityTypeContext.Provider>
    </CurrentStoreContext.Provider>
  );
};

export const ListingsStateProvider = observer(StateProvider_);

export function useListingStore<T>(creator: () => T, deps: unknown[]): T {
  const isLoggedIn = useIsLoggedIn();
  // make sure Listing store is reset on auth change
  const model = useMemo(creator, [creator, ...deps, isLoggedIn]);
  useAutoDestroyMst(model);
  return model;
}

export const testable = { useStore: useListingStore };
