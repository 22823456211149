import { css, cx } from '@linaria/core';
import { baseUnits, flex, theming } from 'styles/utils';
import { textTitle } from 'styles/typography';
export const wrapper = "omponents__EntityCard__EntityCardGrid_styled_ts-wrapper-wq3iqp";
export const modelTypeClass = "omponents__EntityCard__EntityCardGrid_styled_ts-modelTypeClass-m1cvhu2i";
export const modelTypeItemCrontainerClass = "omponents__EntityCard__EntityCardGrid_styled_ts-modelTypeItemCrontainerClass-mcsymvs";
export const modelTypeItemIconClass = "omponents__EntityCard__EntityCardGrid_styled_ts-modelTypeItemIconClass-mh4auqr";
export const defaultWorkflowIdClass = "omponents__EntityCard__EntityCardGrid_styled_ts-defaultWorkflowIdClass-d1jsu86w";
export const blockDiv = "omponents__EntityCard__EntityCardGrid_styled_ts-blockDiv-bwyq5v4";
export const flex5wrap = "omponents__EntityCard__EntityCardGrid_styled_ts-flex5wrap-f1q1zre4";
export const flexSpaceBetween = "omponents__EntityCard__EntityCardGrid_styled_ts-flexSpaceBetween-f10rpkka";
export const flex10gap = "omponents__EntityCard__EntityCardGrid_styled_ts-flex10gap-f1e4p4cd";
export const wrapperVertical = `${"omponents__EntityCard__EntityCardGrid_styled_ts-wrapperVertical-wglt434"}
`;
export const detailsContainer2 = "omponents__EntityCard__EntityCardGrid_styled_ts-detailsContainer2-dd8mmiq";
export const iconGridContainer = "omponents__EntityCard__EntityCardGrid_styled_ts-iconGridContainer-ij2fyoq";
export const titleStyles = "omponents__EntityCard__EntityCardGrid_styled_ts-titleStyles-t1xugg1h";
export const actionWrapper = cx("omponents__EntityCard__EntityCardGrid_styled_ts-actionWrapper-a5d9gxx", flex.init, flex.alignItems.center);
export const gridIconFallback = `${"omponents__EntityCard__EntityCardGrid_styled_ts-gridIconFallback-gtjvav8"}
  ${textTitle}
  ${flex.init}
  ${flex.justifyContent.center}
  ${flex.alignItems.center}  
`;
export const moreActionsWrapper = "omponents__EntityCard__EntityCardGrid_styled_ts-moreActionsWrapper-m1m0kt9n";

require("./EntityCardGrid.styled.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./EntityCardGrid.styled.ts");