/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { cx } from '@linaria/core';
import { IconExpandDown } from 'components/Icons';
import { noop } from 'utils/fp';
import type { Cell, Row } from 'react-table';
import { MouseEvent, SyntheticEvent, useMemo } from 'react';
import { accordionContainer } from './AccordionCell.styles';

type IProps = {
  row: Row;
  style?: Record<string, string>;
  expanded?: boolean;
  onChange?: (_event: SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
};

const actionId = 'actions';

const getAccordionExpandedRows = (cells: Cell[]) => {
  return cells?.filter((cell, index) => cell.column.id !== actionId && index !== 0) ?? [];
};

const ActionCell = ({ cell, onClick }: { cell: Cell; onClick: (event: MouseEvent<HTMLDivElement>) => void }) => {
  return (
    <div className={cx('valueCell', 'action-cell')} onClick={onClick} tabIndex={0} role="button" onKeyDown={noop}>
      {(cell && cell?.render('Cell')) ?? ''}
    </div>
  );
};

export const AccordionCell = ({ row, style, expanded, onChange }: IProps) => {
  const actionCellIndex = useMemo(() => row.cells.findIndex((cell) => cell.column.id === actionId), [row.cells]);
  const actionCell = row.cells[actionCellIndex];

  const handleCellValueClick = (event: MouseEvent<HTMLDivElement>) => {
    // Prevents the accordion from expanding
    event.stopPropagation();
  };

  return (
    <div className={cx(accordionContainer, 'row')} {...row.getRowProps({ style })}>
      <Accordion elevation={0} disableGutters expanded={expanded} onChange={(_event, isExpanded) => onChange?.(_event, isExpanded)}>
        <AccordionSummary
          className="accordionSummary"
          expandIcon={<IconExpandDown size={16} />}
          aria-controls="table-header"
          id={row.cells?.[0]?.column?.id ?? 'header'}
        >
          <div className="valueCell" onClick={handleCellValueClick} tabIndex={0} role="button" onKeyDown={noop}>
            {row?.cells?.[0]?.render('Cell') ?? ''}
          </div>
          <ActionCell cell={actionCell} onClick={handleCellValueClick} />
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          {getAccordionExpandedRows(row.cells).map((cell) => {
            return (
              <div className="cell" {...cell.getCellProps()}>
                <div className="headerCell"> {cell.render('Header')}</div>
                <div className="valueCell">{cell.render('Cell')}</div>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
