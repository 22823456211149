import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const VisualEmbedderIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.4375 19.25L3.5 14L7.4375 8.75" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.4375 19.25L25.375 14L21.4375 8.75" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.75 13.8524C8.75 13.8524 10.8935 10.5 14.6447 10.5C18.396 10.5 20.5394 13.8524 20.5394 13.8524C20.5394 13.8524 18.396 17.2048 14.6447 17.2048C10.8935 17.2048 8.75 13.8524 8.75 13.8524Z"
      stroke={color}
      strokeWidth="0.761904"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6447 15.8638C15.7299 15.8638 16.6096 14.9633 16.6096 13.8524C16.6096 12.7415 15.7299 11.8409 14.6447 11.8409C13.5595 11.8409 12.6798 12.7415 12.6798 13.8524C12.6798 14.9633 13.5595 15.8638 14.6447 15.8638Z"
      fill={color}
    />
  </svg>
);
