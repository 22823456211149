/* eslint-disable no-param-reassign */
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { types } from 'mst-effect';
import Router from 'next/router';
import { noop } from 'rxjs';
import { ReactLeft } from 'utils/uiStates/uiStates';

// "fetcher" might fetch the list with "Mixed Success" status:
// https://clarifai.atlassian.net/browse/MRK-2655
export type MixedFetchedData<T> = { mixedSuccessStatus: boolean; list: T[] };
export type FetchedData<T> = MixedFetchedData<T> | T[];

// type inference is more powerful in case of MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withEndlessPagination<T>({
  initalPage = 1,
  initialPageSize = 24,
  initialQuery = {},
  disableRouterUpdates = false,
}: {
  initialQuery: Record<string, string>;
  initalPage?: number;
  initialPageSize?: number;
  fetcher: (params: string, self?: unknown) => TaskEither<ReactLeft, FetchedData<T>>;
  disableRouterUpdates?: boolean;
}) {
  /* istanbul ignore next: runtime type safety */
  const initPageNum = !Number.isNaN(Number(initialQuery.page)) ? Number(initialQuery.page) : initalPage;
  /* istanbul ignore next: runtime type safety */
  const initPageSize = !Number.isNaN(Number(initialQuery.perPage)) ? Number(initialQuery.perPage) : initialPageSize;

  return types
    .model({
      hasReachedEnd: types.optional(types.boolean, false),
      hasInfinitePagination: types.frozen<true>(true),
      currentPage: types.optional(types.number, initPageNum),
      perPage: types.optional(types.number, initPageSize),
      disableRouterUpdates,
    })
    .views((self) => ({
      get pageInfo() {
        return {
          per_page: self.perPage,
          page: self.currentPage,
        };
      },
    }))
    .actions((self) => {
      const updateRoute = ({ page, perPage }: { page: number; perPage: number }): void => {
        const { router } = Router;
        // if block only for TS strict adherence
        /* istanbul ignore next: TS strict mode adherence; no need to add coverage of non existent router object  */
        if (router) {
          router.push(
            {
              query: {
                ...router.query,
                page,
                perPage,
              },
            },
            undefined,
            { shallow: true },
          );
        }
      };
      return {
        setCurrentPage(page: number) {
          if (!self.disableRouterUpdates) {
            updateRoute({ page, perPage: self.perPage });
          }
          self.currentPage = page;
        },
        setPageSize(perPage: number) {
          if (!self.disableRouterUpdates) {
            updateRoute({ perPage, page: self.currentPage });
          }
          self.perPage = perPage;
        },
        resetPage() {
          if (!self.disableRouterUpdates) {
            updateRoute({ page: 1, perPage: initPageSize });
          }
          self.currentPage = 1;
          self.perPage = initPageSize;
          self.hasReachedEnd = false;
        },
      };
    })
    .actions((self) => ({
      nextPage: self.hasReachedEnd ? noop : () => self.setCurrentPage(self.currentPage + 1),
      // previous page functionality not necessary with infiniteScroll; but commented
      // in case we need to implemented page buttons after API for page counts exists
      // prevPage: () => self.setCurrentPage(Math.max(1, self.currentPage - 1)),
    }));
}
