import { cx, css } from '@linaria/core';
import { baseUnits, theming, breakpoints } from 'styles/utils';
export const createApplicationWrapper = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-createApplicationWrapper-crlvwma";
export const dropdownWrapper = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-dropdownWrapper-dktvhjh";
export const selectMenuWrapper = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-selectMenuWrapper-s1mrlavn";
export const heading = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-heading-h1raokqd";
export const subheading = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-subheading-s1l4vmr0";
export const closeIcon = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-closeIcon-czs99rw";
export const alertText = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-alertText-a2p3489";
export const consentCheckboxWrapper = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-consentCheckboxWrapper-c127xjw4";
export const consentCheckbox = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-consentCheckbox-cxbtyyx";
export const checkboxLabelWrapper = cx("odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-checkboxLabelWrapper-ccgno4s", alertText);
export const overrideInputField = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-overrideInputField-op79nle";
export const createAppGormGroup = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-createAppGormGroup-c4h7pot";
export const createAppGormGroupDisabled = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-createAppGormGroupDisabled-cnohxcj";
export const advancedSwitch = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-advancedSwitch-a11mkqpp";
export const advancedSettings = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-advancedSettings-a1yp69m1";
export const inputField = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-inputField-i1j4wt16";
export const fieldDesc = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-fieldDesc-f1xchqzl";
export const fieldTopMargin = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-fieldTopMargin-fnceqh0";
export const fieldBottomMargin = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-fieldBottomMargin-f1tabov2";
export const height100 = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-height100-h5jx3rq";
export const height0 = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-height0-h1kka89q";
export const container = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-container-cjq1y1e";
export const height100proc = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-height100proc-hlp6o05";
export const fullScreenModalStyle = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-fullScreenModalStyle-f1wncgkx";
export const modalStyle = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-modalStyle-mjerpx0";
export const mobileBtns = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-mobileBtns-mlld281";
export const showOnMobile = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-showOnMobile-sw2u6aj";
export const hideOnMobile = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-hideOnMobile-h14cczy4";
export const progressBar = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-progressBar-p1auq297";
export const backButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-backButton-b9jvzec";
export const mobileTitle = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-mobileTitle-m78i1me";
export const appOverviewContainer = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-appOverviewContainer-a1nbzjwt";
export const mobileButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-mobileButton-myrvyyr";
export const desktopButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-desktopButton-dqzruec";
export const chooseTemplateContainer = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-chooseTemplateContainer-cr68lek";
export const animatedViewsDiv = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-animatedViewsDiv-a1y8izs2";
export const headerDiv = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-headerDiv-hzq36yk";
export const defaultRadioButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-defaultRadioButton-d1w26lha";
export const activeRadioButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-activeRadioButton-a16fw0lm";
export const inputTypeRadioContainer = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-inputTypeRadioContainer-i1lwta0z";
export const radioLabel = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-radioLabel-rcacrdh";
export const recommendWorkflow = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-recommendWorkflow-rq150qh";
export const radioButtonMessageLabel = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-radioButtonMessageLabel-rzg4hma";
export const disableRadioButton = "odules__Apps__components__CreateApplication__CreateApplicationModal_styles_ts-disableRadioButton-dqusows";

require("./CreateApplicationModal.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CreateApplicationModal.styles.ts");