export const CustomModelIcon = ({ width = 80, height = 80 }: { width: number | string; height: number | string }) => (
  <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="4" fill="#DC6803" />
    <path
      d="M55.3333 56.6667C55.8001 56.6667 56.0334 56.6667 56.2117 56.5758C56.3685 56.4959 56.496 56.3685 56.5759 56.2117C56.6667 56.0334 56.6667 55.8 56.6667 55.3333V38C56.6667 37.5333 56.6667 37.2999 56.5759 37.1217C56.496 36.9649 56.3685 36.8374 56.2117 36.7575C56.0334 36.6667 55.8001 36.6667 55.3333 36.6667L51.3333 36.6667C50.8666 36.6667 50.6333 36.6667 50.455 36.7575C50.2982 36.8374 50.1707 36.9649 50.0908 37.1217C50 37.2999 50 37.5333 50 38V42C50 42.4667 50 42.7001 49.9092 42.8783C49.8293 43.0351 49.7018 43.1626 49.545 43.2425C49.3667 43.3333 49.1334 43.3333 48.6667 43.3333H44.6667C44.2 43.3333 43.9666 43.3333 43.7884 43.4242C43.6316 43.5041 43.5041 43.6315 43.4242 43.7883C43.3333 43.9666 43.3333 44.2 43.3333 44.6667V48.6667C43.3333 49.1334 43.3333 49.3667 43.2425 49.545C43.1626 49.7018 43.0351 49.8293 42.8783 49.9092C42.7001 50 42.4667 50 42 50H38C37.5333 50 37.2999 50 37.1217 50.0908C36.9649 50.1707 36.8374 50.2982 36.7575 50.455C36.6667 50.6333 36.6667 50.8666 36.6667 51.3333V55.3333C36.6667 55.8 36.6667 56.0334 36.7575 56.2117C36.8374 56.3685 36.9649 56.4959 37.1217 56.5758C37.2999 56.6667 37.5333 56.6667 38 56.6667L55.3333 56.6667Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6667 31.3333C36.6667 30.8666 36.6667 30.6333 36.7575 30.455C36.8374 30.2982 36.9649 30.1707 37.1217 30.0908C37.2999 30 37.5333 30 38 30H42C42.4667 30 42.7001 30 42.8783 30.0908C43.0351 30.1707 43.1626 30.2982 43.2425 30.455C43.3333 30.6333 43.3333 30.8666 43.3333 31.3333V35.3333C43.3333 35.8 43.3333 36.0334 43.2425 36.2117C43.1626 36.3685 43.0351 36.4959 42.8783 36.5758C42.7001 36.6667 42.4667 36.6667 42 36.6667H38C37.5333 36.6667 37.2999 36.6667 37.1217 36.5758C36.9649 36.4959 36.8374 36.3685 36.7575 36.2117C36.6667 36.0334 36.6667 35.8 36.6667 35.3333V31.3333Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 41.3333C25 40.8666 25 40.6333 25.0908 40.455C25.1707 40.2982 25.2982 40.1707 25.455 40.0908C25.6333 40 25.8666 40 26.3333 40H30.3333C30.8001 40 31.0334 40 31.2117 40.0908C31.3685 40.1707 31.496 40.2982 31.5758 40.455C31.6667 40.6333 31.6667 40.8666 31.6667 41.3333V45.3333C31.6667 45.8 31.6667 46.0334 31.5758 46.2117C31.496 46.3685 31.3685 46.4959 31.2117 46.5758C31.0334 46.6667 30.8001 46.6667 30.3333 46.6667H26.3333C25.8666 46.6667 25.6333 46.6667 25.455 46.5758C25.2982 46.4959 25.1707 46.3685 25.0908 46.2117C25 46.0334 25 45.8 25 45.3333V41.3333Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3333 24.6667C23.3333 24.2 23.3333 23.9666 23.4242 23.7883C23.5041 23.6315 23.6315 23.5041 23.7884 23.4242C23.9666 23.3333 24.2 23.3333 24.6667 23.3333H28.6667C29.1334 23.3333 29.3667 23.3333 29.545 23.4242C29.7018 23.5041 29.8293 23.6315 29.9092 23.7883C30 23.9666 30 24.2 30 24.6667V28.6667C30 29.1334 30 29.3667 29.9092 29.545C29.8293 29.7018 29.7018 29.8293 29.545 29.9092C29.3667 30 29.1334 30 28.6667 30H24.6667C24.2 30 23.9666 30 23.7884 29.9092C23.6315 29.8293 23.5041 29.7018 23.4242 29.545C23.3333 29.3667 23.3333 29.1334 23.3333 28.6667V24.6667Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
