import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listTaskAssignmentsTE = <E = CF.API.ApiError>(
  {
    appId,
    userId,
    taskId,
  }: {
    appId: string;
    userId: string;
    taskId: string;
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.ListTaskAssignment.Response> =>
  fetchTE<CF.API.ListTaskAssignment.Response, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/tasks/${taskId}/assignments`,
      method: 'GET',
    },
    onRejected,
  );
