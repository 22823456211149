import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const MultiModalEmbedderIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.7333" cy="3.23333" r="1.23333" stroke={color} />
    <circle cx="4.73333" cy="3.73333" r="1.23333" stroke={color} />
    <circle cx="3.73333" cy="11.7333" r="1.23333" stroke={color} />
    <path d="M5.38336 5.03334L6.4667 6.76667" stroke={color} />
    <path d="M12 4L8.5 7M11.6667 10.2333L8.63334 8.93333M8.63334 11.9667L7.76667 9.36666M4.5 11L6.5 9" stroke={color} />
    <circle cx="9.06332" cy="13.2667" r="1.23333" stroke={color} />
    <circle cx="12.9667" cy="10.6667" r="1.23333" stroke={color} />
    <circle cx="7.33334" cy="8.06666" r="1.23333" stroke={color} />
  </svg>
);
