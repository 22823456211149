import { IconQuestion } from 'components/Icons';
import { CopyableTooltip } from 'components/Tooltip';
import { contentStyles } from 'components/Tooltip/Tooltip.styles';
import { Link } from 'components/Link';
import { flex } from 'styles/utils';
import { cx } from '@linaria/core';
import { wfOnly, wfOnlyTooltip } from './Footer.styled';
import { EntityCardFooterItem } from './types';
export const EntityCardFooter: React.FC<{
  items?: EntityCardFooterItem[];
  workflowOnly?: boolean;
}> = ({
  items = [],
  workflowOnly = false
}): JSX.Element => {
  return <>
      {workflowOnly && <WorkflowOnlyTag />}
      {items.map(({
      uniqueKey,
      item
    }) => {
      return <span className="footer-span" key={uniqueKey}>
            {item}
          </span>;
    })}
    </>;
};
export function WorkflowOnlyTag(): JSX.Element {
  return <span className={flex.init}>
      <div className={wfOnly}>
        Workflow-Only
        <CopyableTooltip showArrow className={wfOnlyTooltip} data={<>
              {`Workflow-only models require certain type of input such as cropped images for workflows like detection -> crop -> classification. 
                Check out Clarifai’s drag-n-drop workflow editor `}
              <Link href="https://docs.clarifai.com/portal-guide/workflows/workflow-builder" className="link">
                documentation
              </Link>
              .
            </>}>
          <div className={cx(contentStyles, flex.init, flex.alignItems.center)}>
            <IconQuestion size={16} color="currentColor" />
          </div>
        </CopyableTooltip>
      </div>
    </span>;
}