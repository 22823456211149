import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listStarredAppsTE = <E = CF.API.ApiError>(
  { userId, params = '' }: { userId?: string; params?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.ListAppsResponse> =>
  fetchTE<CF.API.Apps.ListAppsResponse, E>(
    {
      path: userId
        ? `/api/_api/v2/users/${userId}/apps?additional_fields=stars${params ? `&${params}` : ''}`
        : `/api/_api/v2/apps?additional_fields=stars${params ? `&${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
