import { useRouter } from 'next/router';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useMemo } from 'react';

export function useIsOwner(): boolean {
  const query = useRouter().query as Record<string, string>;
  const { userOrOrgId } = query;
  const currentUserId = useCurrentUserId();

  const isOwner = useMemo(() => userOrOrgId === currentUserId, [userOrOrgId, currentUserId]);

  return isOwner;
}
