import { toJS } from 'mobx';
import { getParent, getRoot, IAnyStateTreeNode, IStateTreeNode } from 'mobx-state-tree';
import { noop } from 'utils/fp';
import { ListingFilterItem, ListingSortBy } from './types';

export function makeOptions(arr: readonly string[]): { label: string; value: string }[] {
  return arr.map((x) => ({
    label: x,
    value: x,
  }));
}

export function makeParamString({
  defaultParams,
  filters,
  sortBy,
  perPage,
  page,
  searchTermValue,
}: {
  defaultParams: string;
  filters: ListingFilterItem[];
  sortBy: ListingSortBy;
  perPage?: number;
  page?: number;
  searchTermValue?: string;
}): string {
  const filtersObj = toJS(filters) as ListingFilterItem[];
  const searchParams = new URLSearchParams({
    ...(sortBy?.value ? { [sortBy.value]: String(true) } : {}),
    ...(sortBy?.asc ? { sort_ascending: String(sortBy.asc) } : {}),
    ...(searchTermValue ? { search: searchTermValue } : {}),
    ...(perPage ? { per_page: String(perPage) } : {}),
    ...(page ? { page: String(page) } : {}),
  });

  // Append filters to the search params
  for (const f of filtersObj) {
    for (const v of f.value) {
      searchParams.append(f.field, v);
    }
  }

  const params = `${searchParams.toString()}&${defaultParams}`;
  return params === '&' ? '' : params;
}

export function safelyRemoveFromParentIfUsedInListable(self: IAnyStateTreeNode): boolean {
  let parentMSTArray: IStateTreeNode<IAnyStateTreeNode> | undefined;
  try {
    parentMSTArray = getParent(self);
  } catch {
    noop();
  }
  if (parentMSTArray) {
    // expected Listable to be rootNode
    const rootNode = getRoot(parentMSTArray) as { deleteItem?: (item: unknown) => boolean };
    if (rootNode && 'deleteItem' in rootNode && rootNode.deleteItem) {
      rootNode.deleteItem(self);
      return true;
    }

    return false;
  }

  return false;
}
