import { Column, Row, RowData } from '@tanstack/react-table';
import { useMemo } from 'react';
import { SelectFilter } from './SelectFilter';
import { SearchFilter } from './SearchFilter';

export const FilterComponentsMapping = {
  select: SelectFilter,
  search: SearchFilter,
};

const DEFAULT_FILTER_VARIANT = 'search';

export const TableFilters = <D extends unknown>({ column, isMobileView }: { column: Column<D, unknown>; isMobileView?: boolean }) => {
  const filterVariant = column.columnDef.meta?.filterVariant ?? DEFAULT_FILTER_VARIANT;

  const FilterComponent = FilterComponentsMapping[filterVariant as keyof typeof FilterComponentsMapping];

  if (!FilterComponent) {
    return null;
  }

  const preFilterRows = useMemo(() => column.getFacetedRowModel().rows, [column]);

  return (
    <FilterComponent
      preFilteredRows={preFilterRows as Row<RowData>[]}
      columnId={column.id}
      filterValue={column.getFilterValue() as string}
      setFilter={column.setFilterValue}
      isMobileView={isMobileView}
      onFilterChange={column.columnDef.meta?.onFilterChange}
    />
  );
};
