import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const TextToAudioIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 2.401 24.5001 H 0.3824 L 3.458 15.591 H 5.8854 L 8.9566 24.5001 H 6.9382 L 4.7064 17.6268 H 4.6369 L 2.401 24.5001 Z M 2.2748 20.9981 H 7.0425 V 22.4685 H 2.2748 V 20.9981 Z"
      fill={color}
    />
    <path d="M 5.5659 6.0581 V 15.8645" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M 9.4032 9.0427 V 12.8799" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M 13.2405 4.5659 V 17.3568" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M 17.0777 7.5504 V 14.3722" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
    <path d="M 20.915 9.3741 V 12.5481" stroke={color} strokeWidth="1.97954" strokeLinecap="round" />
  </svg>
);
