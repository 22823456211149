import { either } from 'fp-ts';
import { pickModelInfo } from 'modules/Predictions_deprecated/helpers';
import { TSupportedPredictionCategories } from 'modules/Predictions/types';
import { useMemo } from 'react';
import { pipe } from 'utils/fp';
import { useQueryTE } from 'utils/react-query';
import { ModelEvaluationTypeEnum } from 'types/api/enums';
import { modelPageApiEffects } from '../apiEffects';

export function useModelTypeInfo(partialModel: Partial<CF.API.Models.Model> | undefined): {
  modelTypeFields: string[];
  inputField: string;
  outputField: string;
  modelTypeForUi: TSupportedPredictionCategories;
  modelId: string;
  appId: string;
  userId: string;
  trainable: boolean;
  evaluationType: ModelEvaluationTypeEnum;
} {
  const { data } = pipe(useQueryTE('ModelTypes', modelPageApiEffects.listModelTypesTE(), { staleTime: Number.POSITIVE_INFINITY }));

  const modelTypes = pipe(
    data,
    either.getOrElseW(() => false as const),
  );

  const typeInfo = useMemo(() => {
    if (!modelTypes) {
      return { inputField: '', outputField: '', trainable: false };
    }
    // defensive check due to API model_types data not being consistent with available model types
    const foundModelType = modelTypes.model_types.find((i) => i.id === partialModel?.model_type_id);
    if (!foundModelType) {
      return { inputField: '', outputField: '', trainable: false };
    }
    const modelTypeFields = foundModelType.model_type_fields?.map((field) => field.path);
    const inputField = foundModelType.input_fields && foundModelType.input_fields[0];
    const outputField = foundModelType.output_fields && foundModelType.output_fields[0];
    const trainable = foundModelType.trainable;
    const evaluationType = foundModelType.evaluation_type;
    return { inputField, outputField, trainable, evaluationType, modelTypeFields };
  }, [partialModel, modelTypes]);

  const { modelType, modelId, userId, appId } = pickModelInfo(partialModel, {
    inputField: typeInfo.inputField || '',
    outputField: typeInfo.outputField || '',
  });

  return {
    modelTypeFields: typeInfo.modelTypeFields || [],
    inputField: typeInfo.inputField || '',
    outputField: typeInfo.outputField || '',
    trainable: typeInfo.trainable || false,
    evaluationType: typeInfo.evaluationType,
    modelTypeForUi: modelType,
    modelId,
    userId,
    appId,
  };
}
