import { either } from 'fp-ts';
import { useContext } from 'react';
import { noop } from 'utils/fp';
import { AllListStoreTypes } from 'modules/Listings/store/StateContext';
import { CurrentStoreContext, ENTITY_SLUGS, ListingEntityTypeContext } from './CurrentStoreContext';

export const initMockStore = {
  list: either.left('LOADING'),
  searchTermValue: '',
  sortBy: { value: '', asc: false },
  setSearchTermValue: (_a: string) => {
    console.warn('No valid provided to Search component that expects Searchable MST');
  },
  setSortBy: (_a: string) => {
    console.warn('No valid provided to Sort component that expects Sortable MST');
  },
  setAscending: (_a: boolean) => {
    console.warn('No valid provided to Sort component that expects Sortable MST');
  },
  sortCriteria: [] as { name: string; value: string }[],
  load: noop,
  isPaginated: true,
  nextPage: noop as () => void,
  hasHydratedList: false,
  getUiStateMap: () => undefined,
} as unknown as AllListStoreTypes;

export type TCurrentStore = typeof initMockStore;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCurrentStore() {
  const currentStore = useContext<AllListStoreTypes>(CurrentStoreContext) || initMockStore;
  return currentStore;
}

export function useListingEntityType(): ENTITY_SLUGS {
  return useContext<ENTITY_SLUGS>(ListingEntityTypeContext);
}

export const testable = {
  initMockStore,
};
