import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const TextEmbedderIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.0312 20.625L26.25 15L22.0312 9.375" stroke={color} strokeWidth="1.64062" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.96875 20.625L3.75 15L7.96875 9.375" stroke={color} strokeWidth="1.64062" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.4849 18.75H10.3647L13.5953 9.39171H16.1451L19.3712 18.75H17.2509L14.9067 11.5302H14.8336L12.4849 18.75ZM12.3524 15.0716H17.3606V16.6161H12.3524V15.0716Z"
      fill={color}
    />
  </svg>
);
