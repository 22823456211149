import { cx } from '@linaria/core';
import { either, taskEither } from 'fp-ts';
import React, { useState, useCallback, ChangeEvent, FC, useRef, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import cogoToast from 'cogo-toast';
import { useModalActions } from 'components/ModalManager/Context';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { countryCollection, jobRoleCollection } from 'modules/User/components/Settings/ContactInformation/constants';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useMutationTE, useQueryTE } from 'utils/react-query';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { alphabeticalSort } from 'utils/collections';
import { updateUserProfileTE, UserBody } from 'api/users/updateUser';
import { getUserMeTE, updateUserConsentTE } from 'api/users';
import { omit } from 'rambda';
import nProgress from 'nprogress';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { HUBSPOT_CUSTOM_EVENTS_NAME, HUBSPOT_FORM_IDS, sendEvent, submitFormApi } from 'utils/hubspotApi/hubspotApi';
import { appStorage } from 'utils/appStorage';
import { useOpenCreateOrgModal } from 'modules/Organizations/hooks';
import { dialogComponents } from 'components/Dialog/dialogComponents';
import { hideOnMobile, progressBar, showOnMobile } from 'modules/Apps/components/CreateApplication/CreateApplicationModal.styles';
import { useBoolean } from 'react-use';
import { makeUserFullName } from 'utils/users';
import { useAnimatedViews } from 'modules/Apps/components/CreateApplication/useAnimatedViews';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { animatedViewsDiv, wrapper } from './OnboardingDialog.styles';
import { ProfileInfo } from './components/ProfileInfo';
import { GoalInfo } from './components/GoalInfo';
import { WelcomeToClarifai } from './components/WelcomeToClarifai';
import {
  FormValues,
  strictlyRequiredStringRule,
  onboardingFormValidationSchema,
  ONBOARDING_MODAL_TITLE,
  ONBOARDING_STEP_ID,
  ONBOARDING_STEP_IDS,
  ONBOARDING_STEP_IDS_WITHOUT_ORG,
} from './utils';
import { Footer } from './components/Footer';

type OnboardingDialogProps = {
  user: CF.API.Users.User;
};

const ONBOARDING_STEPS_VIEW_STYLES = {
  classNames: {
    mountedStyle: 'mountedStyle',
    unmountedStyle: 'unmountedStyle',
    mountedBackStyle: 'mountedBackStyle',
    unmountedBackStyle: 'unmountedBackStyle',
  },
};

export const OnboardingDialog_: FC<OnboardingDialogProps> = ({ user }) => {
  const isGDPRConsentPending = !user?.date_gdpr_consent || !user?.date_tos_consent;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isConsentGranted, setIsConsentGranted] = useState<boolean>(!isGDPRConsentPending);
  // We need to store this value to use it after our API call when all data was updated
  const isGDPRConsentPendingPersisted = useRef(isGDPRConsentPending);
  const queryClient = useQueryClient();
  const userOrOrgId = useCurrentUserId();
  const { closeModal } = useModalActions();
  const { updateUserId } = useAuthActions();
  const { track } = useDataCollection();
  const countries = alphabeticalSort(countryCollection);
  const jobRoles = jobRoleCollection;
  const [hasOrgAccess] = useFeaturedFlags(['OrgCreateOrganization']);
  const openCreateOrgModal = useOpenCreateOrgModal();
  const [showWelcomeMsg, setShowWelcomeMsg] = useBoolean(false);

  const onboardingStepIds = useMemo(() => (hasOrgAccess ? ONBOARDING_STEP_IDS : ONBOARDING_STEP_IDS_WITHOUT_ORG), [hasOrgAccess]);

  const [animatedState, { goBack, goToView, afterAnimation }] = useAnimatedViews(onboardingStepIds, ONBOARDING_STEPS_VIEW_STYLES);

  const canGoBack = useMemo(() => animatedState.step !== ONBOARDING_STEP_ID.MaybeCreateOrg && animatedState.prevSteps.length > 0, [animatedState]);

  const goToViewClbk = useCallback(
    (viewId: string) => {
      goToView(viewId);
    },
    [goToView],
  );

  const goBackClbk = useCallback(() => goBack(), [goBack]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onIdChange, { errorString }] = useIdValidation$({
    userId: userOrOrgId,
    entityName: 'user',
  });

  const moveToCreateOrg = useCallback(() => {
    closeOnboardingModal();
    openCreateOrgModal();
  }, [closeModal, openCreateOrgModal]);

  const handleLegalCheckboxChange = useCallback((e) => {
    setIsConsentGranted(e.target.checked);
  }, []);

  const handleUserIdChange = useCallback(
    (setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
      (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        onIdChange(value);
        setFieldValue('userId', value);
      },
    [onIdChange],
  );

  const closeOnboardingModal = () => {
    closeModal({ id: 'onboarding_dialog' });
  };

  // This is required because the 'firstName' and 'lastName' fields may or may not
  // be shown in the UI depending on whether the user has already filled them out.
  const validate = async (values: FormValues) => {
    const errors: { [key: string]: string | undefined } = {};
    await strictlyRequiredStringRule.validate(values.firstName).catch((err) => {
      errors.firstName = err.message;
    });
    await strictlyRequiredStringRule.validate(values.firstName).catch((err) => {
      errors.secondName = err.message;
    });
    return errors;
  };

  const updateContactInfoMutation = useMutationTE(
    (values: UserBody) =>
      pipe(
        updateUserConsentTE({ consent: isConsentGranted, fields: ['gdpr', 'tos'] }, errorToReactLeft),
        // return result of previous task; don't fail the whole operation if app creation fails
        // taskEither.chainFirstTaskK(() => createDefaultAppTE),
        taskEither.chain(() => updateUserProfileTE({ userId: 'me', body: { info: omit(['user_id'], values.info) } }, errorToReactLeft)),
        taskEither.chain(() => updateUserProfileTE({ userId: 'me', body: { info: { user_id: values.info?.user_id } } }, errorToReactLeft)),
      )(),
    {
      onMutate: () => {
        nProgress.start();
      },
      onSettled: () => {
        nProgress.done();
      },
      onSuccess: ({ user: updatedUser }) => {
        cogoToast.success('Contact information updated');
        if (isGDPRConsentPendingPersisted.current) {
          queryClient.refetchQueries(); // we need to refetch all queries if user don't have GDPR or TOC
        } else {
          queryClient.invalidateQueries([queryKeys.LoggedInUser]);
          queryClient.invalidateQueries([queryKeys.Users, { userOrOrgId: updatedUser.id }]);
        }
        track('CTA', { param1: 'onboarding_dialog', param2: 'completed' });
        appStorage.set('first_app_not_created', 'true');
        submitFormApi({
          formId: HUBSPOT_FORM_IDS.signUpForm,
          fields: [
            { name: 'firstname', value: updatedUser.first_name },
            { name: 'lastName', value: updatedUser.last_name },
            { name: 'email', value: updatedUser.email_addresses[0].email },
            { name: 'jobtitle', value: updatedUser.job_title || '' },
            { name: 'job_function', value: updatedUser.job_role || '' },
            { name: 'company', value: updatedUser.company_name || '' },
            { name: 'intension', value: updatedUser.intention || '' },
          ],
        });
        sendEvent({ eventName: HUBSPOT_CUSTOM_EVENTS_NAME.signupCompletedDate, email: updatedUser.email_addresses[0].email, properties: {} });
        sendEvent({ eventName: HUBSPOT_CUSTOM_EVENTS_NAME.firstVisit, email: updatedUser.email_addresses[0].email, properties: {} });
        updateUserId(updatedUser.id);

        if (hasOrgAccess) {
          goToViewClbk(ONBOARDING_STEP_ID.MaybeCreateOrg);
        } else {
          setShowWelcomeMsg(true);
        }
      },
      onError: ({ props }) => {
        cogoToast.error(props?.reason || 'Could not update the profile', { heading: 'Error' });
        queryClient.invalidateQueries([queryKeys.LoggedInUser]);
      },
    },
  );

  const mobileHeaderAndProgressbar = (
    <div>
      <div className={cx(showOnMobile, 'mobile-header')}>
        <dialogComponents.GoBack onClick={goBackClbk} className={!canGoBack ? 'hide' : ''} />
        <span className="step-count" />
      </div>
      <div className={cx(progressBar, showWelcomeMsg ? showOnMobile : null)}>
        <div
          className="app-creation-progress-bar"
          data-progressbar-complete={animatedState.step === ONBOARDING_STEP_ID.MaybeCreateOrg}
          style={{
            width: `${((animatedState.prevSteps.length + 1) / onboardingStepIds.length) * 100}%`,
          }}
        />
      </div>
    </div>
  );

  const desktopHeader = (
    <div className="header">
      {canGoBack ? <dialogComponents.GoBack className={hideOnMobile} onClick={goBackClbk} /> : null}
      <h1 className="modal-title">{ONBOARDING_MODAL_TITLE[animatedState.step as keyof typeof ONBOARDING_MODAL_TITLE]}</h1>
    </div>
  );

  return (
    <div className={cx(wrapper, 'fullWidth')}>
      {mobileHeaderAndProgressbar}
      {showWelcomeMsg ? (
        <WelcomeToClarifai userFullname={makeUserFullName(user)} closeAction={closeOnboardingModal} />
      ) : (
        <>
          {desktopHeader}
          <Formik
            initialValues={{
              userId: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
              company: user.company_name,
              onboardingJobTitle: user.job_title,
              onboardingJobRole: jobRoles.find((j) => j.value === user.job_role),
              country: countries.find((c) => c.value === user.user_detail?.country),
              intention: user.intention,
              referralSource: user.referral_source,
            }}
            onSubmit={(values: FormValues) => {
              updateContactInfoMutation.mutate({
                info: {
                  user_id: values.userId,
                  company_name: values.company,
                  country: values.country?.value,
                  job_role: values.onboardingJobRole?.value,
                  job_title: values.onboardingJobTitle,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  intention: values.intention,
                  referral_source: values.referralSource,
                },
              });
            }}
            validationSchema={onboardingFormValidationSchema[animatedState.step]}
            validate={validate}
            validateOnChange
            validateOnBlur
            validateOnMount
          >
            {({ setFieldValue }) => (
              <>
                <Form className={animatedViewsDiv}>
                  {animatedState.mounted[ONBOARDING_STEP_ID.Profile] && (
                    <ProfileInfo
                      handleUserIdChange={handleUserIdChange(setFieldValue)}
                      userIdError={errorString}
                      isGDPRConsentPending={isGDPRConsentPending}
                      isConsentGranted={isConsentGranted}
                      handleLegalCheckboxChange={handleLegalCheckboxChange}
                      className={animatedState.classNames[ONBOARDING_STEP_ID.Profile]}
                      onAnimationEnd={(params) => {
                        afterAnimation(ONBOARDING_STEP_ID.Profile, params.animationName);
                      }}
                    />
                  )}
                  {animatedState.mounted[ONBOARDING_STEP_ID.Goal] && (
                    <GoalInfo
                      className={animatedState.classNames[ONBOARDING_STEP_ID.Goal]}
                      onAnimationEnd={(params) => {
                        afterAnimation(ONBOARDING_STEP_ID.Goal, params.animationName);
                      }}
                    />
                  )}
                  {animatedState.mounted[ONBOARDING_STEP_ID.MaybeCreateOrg] && (
                    <p
                      className={cx('dialog-text', animatedState.classNames[ONBOARDING_STEP_ID.MaybeCreateOrg])}
                      onAnimationEnd={(params) => {
                        afterAnimation(ONBOARDING_STEP_ID.MaybeCreateOrg, params.animationName);
                      }}
                    >
                      This will allow you to share your projects with teammates, define roles for members and many more features that we have for
                      Organizations!
                    </p>
                  )}
                </Form>

                <Footer
                  step={animatedState.step}
                  isSubmitting={updateContactInfoMutation.isLoading}
                  handleContinue={() => goToViewClbk(ONBOARDING_STEP_ID.Goal)}
                  handleCreateOrg={moveToCreateOrg}
                  handleSkip={() => setShowWelcomeMsg(true)}
                  userIdError={errorString}
                />
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export const OnboardingDialog = (): JSX.Element => {
  const sessionToken = useAuthState().authData?.session_token;
  const { data } = useQueryTE([queryKeys.LoggedInUser, { sessionToken }], getUserMeTE(undefined, errorToReactLeft));

  return pipe(
    data,
    either.fold(reactLeftToJSX(), ({ user }) => <OnboardingDialog_ user={user} />),
  );
};

export const OnboardingDialog_testable = {
  OnboardingDialog_,
};
