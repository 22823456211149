import { useState, useEffect } from 'react';

export const useProgressiveImg = (lowQualitySrc: string, highQualitySrc: string, delay = 100) => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    setSrc(lowQualitySrc);
    const timer = setTimeout(() => {
      if (!highQualitySrc || src === highQualitySrc) return;
      const img = new Image();
      img.src = highQualitySrc;
      img.addEventListener('load', () => {
        setSrc(highQualitySrc);
      });
    }, delay);
    return () => clearTimeout(timer);
  }, [lowQualitySrc, highQualitySrc]);
  return { src, blur: src === lowQualitySrc };
};
