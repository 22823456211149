import React, { useCallback, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { Form, Formik, FormikHelpers } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { RuledPasswordField } from 'components/Formik/RuledPasswordField';
import { useMutationTE } from 'utils/react-query';
import { SignupPayload } from 'api/users/signup';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { withAuth } from 'hocs/withAuth/withAuth';
import { Col, Row } from 'components/Grid';
import { Button } from 'components/deprecated/Button';
import { Checkbox } from 'components/Checkbox';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { renderLeftError } from 'utils/renderLeftError';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRouter } from 'next/router';
import 'react-credit-cards/es/styles-compiled.css';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { appStorage } from 'utils/appStorage';
import { getChangedRules, getPreparedRules, isValidPassword, RulesInterface } from 'modules/User/utils/password';
import { usePasswordRules } from 'components/Input/components/RuledPasswordInput/usePasswordRules';
import { object, string, boolean } from 'yup';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { HUBSPOT_CUSTOM_EVENTS_NAME, HUBSPOT_FORM_IDS, sendEvent, submitFormApi } from 'utils/hubspotApi/hubspotApi';
import { useUtmFields } from 'modules/Auth/hooks/useUtmFields';
import { HubspotFields } from 'modules/Auth/components/Signup/HubspotFields';
import { formGroup, formGroupLast, logo, title, forms, checkboxes, textLink, captcha, fieldLabel, checkboxLabel, logoImage } from './Signup.styles';
import { strictlyRequiredStringRule } from '../OnboardingDialog/utils';
type SignupFieldTypes = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  captcha_response_token_v3: string;
  consent: boolean;
};
const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  consent: false,
  captcha_response_token_v3: ''
};
type Props = {
  logoVisible?: boolean;
  siteKey?: string;
  defaultCaptchaToken?: string;
  onSignUp: () => void;
};
const signUpValidationSchema = object().shape({
  first_name: strictlyRequiredStringRule,
  last_name: strictlyRequiredStringRule,
  email: string().required('Required').email('Email must be a valid email'),
  password: strictlyRequiredStringRule,
  captcha_response_token_v3: string().required(''),
  consent: boolean().oneOf([true], '')
});
function SignupFormInternal({
  logoVisible,
  siteKey,
  defaultCaptchaToken,
  onSignUp
}: Props): import('react').ReactElement {
  const [rules, setRules] = useState<RulesInterface>({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const {
    makeSignupTE
  } = useAuthActions();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const router = useRouter();
  const {
    query
  } = (router as {
    query: {
      from?: string;
    };
  });
  const {
    policies
  } = usePasswordRules();
  const {
    track
  } = useDataCollection();
  const utmResources = useUtmFields();
  useEffect(() => {
    if (policies) {
      setRules(getPreparedRules(policies, ''));
    }
  }, [policies]);
  const onSignUpBtnClick = (btnText: string): void => {
    track('SignUp Button Clicked', {
      pageName: 'sign_up_page',
      text: btnText
    });
  };
  const {
    dataE,
    mutate: signup,
    isLoading
  } = useMutationTE((body: SignupPayload) => makeSignupTE(body)(), {
    onSuccess: data => {
      track('Signup Success', {
        pageName: router.pathname
      });
      submitFormApi({
        formId: HUBSPOT_FORM_IDS.signUpForm,
        fields: [{
          name: 'firstname',
          value: data.first_name
        }, {
          name: 'lastname',
          value: data.last_name
        }, {
          name: 'email',
          value: data.email
        }, ...utmResources]
      });
      sendEvent({
        eventName: HUBSPOT_CUSTOM_EVENTS_NAME.signupFormSubmitted,
        email: data.email,
        properties: {}
      });
      if (query.from) {
        appStorage.set('recovery-path', query.from);
      }
      cogoToast.success('Sign up successful!');
      onSignUp();
    },
    onError: () => {
      track('Signup Failure', {
        pageName: router.pathname
      });
      setRefreshReCaptcha(r => !r);
    }
  });
  const handleLegalCheckboxChange = useCallback((event, setFieldValue) => {
    const value = event.target.checked;
    setFieldValue('consent', value);
  }, []);
  const handleFormSubmit = (values: SignupFieldTypes, {
    setFieldValue
  }: FormikHelpers<SignupFieldTypes>): void => {
    const {
      first_name,
      last_name,
      email,
      password,
      captcha_response_token_v3,
      consent
    } = values;
    signup({
      first_name,
      last_name,
      email: email?.toLowerCase(),
      password,
      captcha_response_token_v3,
      gdpr_consent: consent,
      tos_consent: consent,
      marketing_consent: consent
    });

    // Reset captcha token only if siteKey exists, which is the case for non on-prem deployments.
    if (siteKey) {
      setFieldValue('captcha_response_token_v3', '');
    }
  };
  const handlePasswordChange = async (val: string | undefined, setFieldValue: (field: string, message: string | undefined) => void): Promise<null> => {
    await setFieldValue('password', val);
    if (policies && rules) {
      setRules(prevRules => getChangedRules(prevRules, policies, val));
      setIsPasswordValid(isValidPassword(rules));
    }
    return null;
  };
  return <div>
      {logoVisible && <a className={logo} onClick={() => onSignUpBtnClick('Logo')} rel="noreferrer" target="_blank" href="/">
          <div className={logoImage} data-testid="clarifai-logo" />
        </a>}
      <h1 className={title}>Get started with a free Clarifai account. No credit card required.</h1>
      {renderLeftError(dataE)}
      <Formik onSubmit={handleFormSubmit} initialValues={{
      ...defaultValues,
      // Need to set default captcha response token for on-prem deployments: https://clarifai.atlassian.net/browse/MRK-2749
      ...(!siteKey ? {
        captcha_response_token_v3: defaultCaptchaToken
      } : {})
    }} validationSchema={signUpValidationSchema}>
        {({
        isValid,
        dirty,
        values,
        setFieldValue
      }) => {
        const onRecaptchaVerify = useCallback((captchaToken: string) => {
          setFieldValue('captcha_response_token_v3', captchaToken);
        }, [setFieldValue]);
        return <Form className={forms}>
              <HubspotFields />
              <Row spacing={1}>
                <Col xs={12} md={6}>
                  <InputField name="first_name" label="First Name" type="text" placeholder="First Name" formGroupClassName={formGroup} required />
                </Col>
                <Col xs={12} md={6}>
                  <InputField name="last_name" label="Last Name" type="text" placeholder="Last Name" formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  <InputField name="email" label="Email" type="email" id="email" placeholder="Email" formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  <div className={formGroupLast}>
                    <label htmlFor="password" className={fieldLabel}>
                      Password *
                    </label>
                    <RuledPasswordField rules={rules} onChange={event => handlePasswordChange(event.target.value, setFieldValue)} id="password" name="password" placeholder="Password" />
                  </div>
                </Col>
              </Row>
              {siteKey && <Row spacing={1}>
                  <Col xs={12}>
                    <div className={captcha}>
                      <GoogleReCaptcha onVerify={onRecaptchaVerify} refreshReCaptcha={refreshReCaptcha} />
                    </div>
                  </Col>
                </Row>}
              <Row spacing={1}>
                <Col xs={12}>
                  <Checkbox name="consent" onChange={event => handleLegalCheckboxChange(event, setFieldValue)} checked={values.consent} data-testid="legal-consent" className={checkboxes}>
                    <span className={checkboxLabel}>
                      I agree to Clarifai&apos;s&nbsp;
                      <a className={textLink} onClick={() => onSignUpBtnClick('Terms of Service')} rel="noreferrer" target="_blank" href="https://www.clarifai.com/terms">
                        Terms of Service
                      </a>
                      &nbsp;and have read and acknowledge the &nbsp;
                      <a className={textLink} onClick={() => onSignUpBtnClick('Privacy Policy')} rel="noreferrer" target="_blank" href="https://www.clarifai.com/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </span>
                  </Checkbox>
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  <ButtonGroup className={cx(flex.init, flex.justifyContent.end, 'buttons')}>
                    <Button onClick={() => onSignUpBtnClick('Create account')} className="fullWidth" kind="primary" type="submit" disabled={!isValid || !dirty || isLoading || !isPasswordValid}>
                      Create account
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>;
      }}
      </Formik>
    </div>;
}
SignupFormInternal.defaultProps = {
  logoVisible: true
};
export const SignupForm = withAuth<Props>({
  hideFor: 'USER'
})(SignupFormInternal);