import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ContextBasedClassifierIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9474 6.46951L8.14659 8.93503C7.92307 9.13243 7.92109 9.41041 8.14659 9.60781L10.0988 11.3267C10.8722 12.0062 10.8722 12.9583 10.0988 13.6391L8.14659 15.3594C7.92109 15.5554 7.92307 15.8334 8.14659 16.0308L10.0988 17.7497C10.8722 18.4292 10.8722 19.3813 10.0988 20.0622L8.14659 21.781C7.92307 21.9784 7.92307 22.2564 8.14659 22.4538L10.9474 24.9192C11.0839 25.0414 11.1531 25.1852 11.1531 25.3288C11.1531 25.5304 11.0186 25.729 10.7674 25.8606C10.3342 26.0862 9.71512 26.0326 9.38084 25.7384L6.58003 23.2742C5.80665 22.5948 5.80665 21.6426 6.58003 20.9618L8.53032 19.2429C8.75382 19.0455 8.75382 18.7676 8.53032 18.5702L6.58003 16.8513C5.80665 16.1705 5.80665 15.2184 6.58003 14.5389L8.53032 12.82C8.75382 12.6226 8.75382 12.3446 8.53032 12.1472L6.58003 10.4283C5.80665 9.74747 5.80665 8.79537 6.58003 8.11589L9.38084 5.65037C9.71512 5.35493 10.3342 5.30121 10.7674 5.52683C11.1986 5.75377 11.2797 6.17543 10.9474 6.46951Z"
      fill={color}
    />
    <path
      d="M27.8704 22.6087L22.7172 25.5837V19.6335L27.8704 22.6087ZM19.0937 22.6087C19.0937 24.1265 17.8632 25.3569 16.3454 25.3569C14.8275 25.3569 13.597 24.1265 13.597 22.6087C13.597 21.0907 14.8275 19.8604 16.3454 19.8604C17.8632 19.8604 19.0937 21.0907 19.0937 22.6087ZM23.2326 23.1239H16.3454V22.0933H23.2326V23.1239Z"
      fill={color}
    />
    <path
      d="M27.8704 15.6938L22.7172 18.6689V12.7186L27.8704 15.6938ZM19.0937 15.6938C19.0937 17.2116 17.8632 18.4421 16.3454 18.4421C14.8275 18.4421 13.597 17.2116 13.597 15.6938C13.597 14.1759 14.8275 12.9454 16.3454 12.9454C17.8632 12.9454 19.0937 14.1759 19.0937 15.6938ZM23.2326 16.2091H16.3454V15.1785H23.2326V16.2091Z"
      fill={color}
    />
    <path
      d="M27.8704 8.77879L22.7172 11.7539V5.80365L27.8704 8.77879ZM19.0937 8.77879C19.0937 10.2967 17.8632 11.5271 16.3454 11.5271C14.8275 11.5271 13.597 10.2967 13.597 8.77879C13.597 7.26093 14.8275 6.03047 16.3454 6.03047C17.8632 6.03047 19.0937 7.26093 19.0937 8.77879ZM23.2326 9.29409H16.3454V8.26349L23.2326 8.26347V9.29409Z"
      fill={color}
    />
  </svg>
);
