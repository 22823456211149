import { cx } from '@linaria/core';
import { Button } from 'components/Button';
import { IconCloseSquare } from 'components/Icons';
import { cssHelpers, flex } from 'styles/utils/core';
import { titles, textRegularLight } from 'styles/typography';
import { useRouter } from 'next/router';
import { dialogWrapper, closeIcon } from './Modals.styles';
type Props = {
  authLink: string;
  onClose: () => void;
};
export const IdentityProviderTestModal: React.FC<Props> = ({
  authLink,
  onClose
}) => {
  const router = useRouter();
  return <div className={dialogWrapper}>
      <div className={cx(flex.init, flex.justifyContent.between, flex.alignItems.center)}>
        <h2 className={titles}>Test Identity Provider</h2>
        <IconCloseSquare size={20} onClick={onClose} className={closeIcon} />
      </div>

      <div className={textRegularLight}>
        <p className={cssHelpers.margin.yb[1]}>Testing the authentication flow will re-direct you to your identity provider for verification.</p>
        <p className={cssHelpers.margin.yb[1]}>If the authentication is successfully you will be redirected back to identity provider details.</p>
      </div>

      <div className={cx('buttons', flex.init, flex.alignItems.center, flex.justifyContent.end)}>
        <Button size="md" variant="tertiary" onClick={onClose} className={cssHelpers.margin.xr[1]}>
          Cancel
        </Button>
        <Button size="md" onClick={() => router.replace(authLink)}>
          Test
        </Button>
      </div>
    </div>;
};