import { useCallback, useState } from 'react';
import { withAuth } from 'hocs/withAuth/withAuth';
import { dialogComponents } from 'components/Dialog/dialogComponents';
import { wrapper } from 'modules/Auth/components/OnboardingDialog/OnboardingDialog.styles';
import { hideOnMobile, showOnMobile } from 'modules/Apps/components/CreateApplication/CreateApplicationModal.styles';
import { cx } from '@linaria/core';
import { AddMembers } from './AddMembers';
import { OrgInfo, OrgInfoProps } from './OrgInfo';
import { createOrganizationWrapper } from './styles';

export enum CREATE_ORG_STEP {
  OrgCreate = 'OrgCreate',
  AddMember = 'AddMember',
}

export type CreateOrganizationProps = OrgInfoProps & {
  closeAction: () => void;
};

const CreateOrganization_: React.FC<CreateOrganizationProps> = ({ closeAction, orgCreateCallback }) => {
  const [step, setStep] = useState(CREATE_ORG_STEP.OrgCreate);
  const [createdOrg, setCreatedOrg] = useState<CF.API.Organizations.Organization | undefined>();

  const afterOrgCreate = useCallback(
    (org: CF.API.Organizations.Organization) => {
      setCreatedOrg(org);
      setStep(CREATE_ORG_STEP.AddMember);
      orgCreateCallback?.(org);
    },
    [orgCreateCallback, setStep],
  );

  const mobileHeader = (
    <div className={cx(showOnMobile, 'mobile-header', 'mobile-close-btn-wrapper')}>
      <dialogComponents.CloseButton onClick={closeAction} className="mobile-close-btn" data-testid="close-create-organization" />
    </div>
  );

  return (
    <div className={cx(wrapper, createOrganizationWrapper)}>
      {mobileHeader}

      <div className="header">
        <h1 className="modal-title">
          {step === CREATE_ORG_STEP.OrgCreate && 'Create an organization'}
          {step === CREATE_ORG_STEP.AddMember && 'Do you want to invite members to your organization?'}
        </h1>
        <dialogComponents.CloseButton onClick={closeAction} className={hideOnMobile} data-testid="close-create-organization" />
      </div>

      {step === CREATE_ORG_STEP.OrgCreate && <OrgInfo orgCreateCallback={afterOrgCreate} />}
      {step === CREATE_ORG_STEP.AddMember && <AddMembers createdOrg={createdOrg} closeAction={closeAction} />}
    </div>
  );
};

export const CreateOrganization = withAuth<CreateOrganizationProps>({ hideFor: 'GUEST' })(CreateOrganization_);
