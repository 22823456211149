import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export interface AuthMethod {
  id: string;
  type: number;
  name: string;
  enabled: boolean;
  auth_link: string;
  background_color: string;
  icon: string;
  text_color: string;
}

export interface SSOMethodsResponse {
  status: CF.API.Status;
  auth_methods: AuthMethod[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listSSOMethodsTE = <E = CF.API.ApiError>(
  { params = '' }: { params?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, SSOMethodsResponse> =>
  fetchTE<SSOMethodsResponse, E>(
    {
      path: `/api/_api/v2/auth/methods${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
