export type Tabs = 'Datasets' | 'Models' | 'Workflows' | 'Modules';

export const TABS: Tabs[] = ['Datasets', 'Models', 'Workflows', 'Modules'];

type ResponseKey = 'datasets' | 'models' | 'workflows' | 'modules';

export const QUERY_MAPPING: Record<Tabs, { key: ResponseKey; queryParam: string }> = {
  Datasets: {
    key: 'datasets',
    queryParam: 'page=1&per_page=700',
  },
  Models: {
    key: 'models',
    queryParam: 'dont_fetch_from_main=true&page=1&per_page=700',
  },
  Workflows: {
    key: 'workflows',
    queryParam: 'page=1&per_page=700',
  },
  Modules: {
    key: 'modules',
    queryParam: 'page=1&per_page=700',
  },
};

export interface IModelsRecord {
  id: string;
  description: string;
  model_type_id: string;
  input: string;
  output: string;
}

export interface IDatasetsRecord {
  id: string;
  description: string;
  annotations_count: number;
  inputs_count: number;
  version: { id: string };
}

export interface IWorkflowRecord {
  id: string;
  description: string;
  nodes: Array<{ model: { id: string } }>;
  default_workflow_id: string;
}

export interface IModuleRecord {
  id: string;
  description: string;
}

export type ResourceViewerTable = IModelsRecord | IWorkflowRecord | IDatasetsRecord | IModuleRecord;

export const RESOURCE_VIEWER_MODAL_ID = 'resource_modal_viewer';
