import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { Col, Row } from 'components/Grid';
import { Button } from 'components/deprecated/Button';
import { object, string } from 'yup';

export interface SecurityCodeFieldProps {
  onSubmit: (obj: { passCode: string }) => void;
  actionButtonText?: string;
  isLoading?: boolean;
}

const forgotPasswordValidationSchema = object().shape({
  passCode: string().required('Required').min(6, 'Code is too short, expecting 6 digits'),
});

export function SecurityCodeForm({ onSubmit, actionButtonText = 'Verify', isLoading }: SecurityCodeFieldProps): JSX.Element {
  return (
    <Formik onSubmit={onSubmit} initialValues={{ passCode: '' }} validationSchema={forgotPasswordValidationSchema}>
      {({ isValid, dirty, setFieldValue }) => {
        return (
          <Form>
            <Row>
              <Col marginRight={1}>
                <InputField
                  name="passCode"
                  maxLength={6}
                  placeholder="Six digit code"
                  data-testid={`${actionButtonText.replace(/\s/g, '')}-2fa-input`}
                  onChange={(event) => {
                    const { value } = event.target;
                    const re = /^[\d\b]+$/;
                    if (value === '' || re.test(value)) {
                      setFieldValue('passCode', value);
                    }
                  }}
                />
              </Col>
              <Col marginRight={1}>
                <Button
                  kind="primary"
                  className="bold"
                  type="submit"
                  data-testid={`${actionButtonText.replace(/\s/g, '')}-2fa-submit`}
                  disabled={!isValid || !dirty || isLoading}
                  isLoading={isLoading}
                >
                  {actionButtonText}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
