import { noop } from 'utils/fp';
import { copyToClipboard } from 'utils/copyToClipboard';
import { EmailShareButton, FacebookShareButton, RedditShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { usePopoverState, PopoverStateReturn, Popover, PopoverAction } from 'components/Popover';
import {
  IconShare2,
  IconCopy,
  IconEmailOutline,
  IconFacebookOutline,
  IconRedditOutline,
  IconTwitterOutline,
  IconLinkedInOutline,
} from 'components/Icons';
import type { IconProps } from 'components/Icons/IconProps';
import { option, wrapper, popoverTitle } from './SocialShare.styles';

export interface SharePopoverProps {
  url: string;
  popover: PopoverStateReturn;
}

export interface ShareOptionProps extends IconProps {
  icon: React.FC<IconProps>;
  title: string;
}

export const SocialShare: React.FC<{ pathname: string }> = ({ pathname }) => {
  const popover = usePopoverState();
  const url = pathnameToURL(pathname);

  return (
    <>
      <div tabIndex={0} title="Share" onClick={preventPropagation()} onKeyDown={noop} role="button">
        <PopoverAction data-testid="social-share" popover={popover}>
          <IconShare2 size={18} />
        </PopoverAction>
      </div>
      <SharePopover url={url} popover={popover} />
    </>
  );
};

const SharePopover: React.FC<SharePopoverProps> = ({ url, popover }) => {
  return (
    <Popover tabIndex={0} aria-label="Share" popover={popover} className={wrapper}>
      <h1 className={popoverTitle}>Share</h1>
      <button aria-label="Copy URL" type="button" onClick={preventPropagation(copyToClipboard(url))}>
        <ShareOption icon={IconCopy} title="Copy URL" />
      </button>
      <TwitterShareButton url={url}>
        <ShareOption icon={IconTwitterOutline} title="Twitter" />
      </TwitterShareButton>
      <FacebookShareButton url={url}>
        <ShareOption icon={IconFacebookOutline} title="Facebook" />
      </FacebookShareButton>
      <RedditShareButton url={url}>
        <ShareOption icon={IconRedditOutline} title="Reddit" id={url} />
      </RedditShareButton>
      <LinkedinShareButton url={url}>
        <ShareOption icon={IconLinkedInOutline} title="LinkedIn" />
      </LinkedinShareButton>
      <EmailShareButton url={url}>
        <ShareOption icon={IconEmailOutline} title="Email" />
      </EmailShareButton>
    </Popover>
  );
};

const ShareOption: React.FC<ShareOptionProps> = ({ icon: Icon, title, ...rest }) => (
  <div className={option}>
    <Icon size={16} strokeWidth={1} {...rest} />
    <span className="option-title">{title}</span>
  </div>
);

export function preventPropagation(callback?: () => void) {
  return (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (callback) {
      callback();
    }
  };
}

export function pathnameToURL(pathname: string): string {
  const noLeadingSlashes = pathname.replace(/^\/+/, '');
  return `${window.location.origin}/${noLeadingSlashes}`;
}
