export const formatNumber = (num: number) => {
  const oneMillion = 1000000;
  const hunderedThousand = 10000;
  if (num >= oneMillion) {
    return `${(num / oneMillion).toFixed(1)}M`;
  } else if (num >= hunderedThousand) {
    return `${Math.round(num / 1000).toFixed(0)}K`;
  } else {
    return Number(num).toLocaleString('en-US');
  }
};

export const addLeadingZero = (number: number) => (number < 10 ? `0${number}` : number);
