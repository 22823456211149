export interface IFilter {
  name: string;
  filterParams: string;
  id: string;
}

export const TOGGLE_IDS = ['first_toggle', 'second_toggle', 'third_toggle'];

export enum FILTER_ID {
  ALL = 'all',
  STARRED = 'starred',
  TEMPLATES = 'templates',
}

export const FILTER_PARAMS = {
  [FILTER_ID.ALL]: 'filter_by_user_id=true&additional_fields=stars',
  [FILTER_ID.STARRED]: 'filter_by_user_id=true&additional_fields=stars&starred_only=true',
  [FILTER_ID.TEMPLATES]: 'filter_by_user_id=true&additional_fields=stars&template_only=true',
};

export const FILTERS: IFilter[] = [
  { name: 'All', id: FILTER_ID.ALL, filterParams: FILTER_PARAMS[FILTER_ID.ALL] },
  { name: 'Starred', id: FILTER_ID.STARRED, filterParams: FILTER_PARAMS[FILTER_ID.STARRED] },
  { name: 'Templates', id: FILTER_ID.TEMPLATES, filterParams: FILTER_PARAMS[FILTER_ID.TEMPLATES] },
];
