import { Tooltip } from 'components/Tooltip';
import { TooltipInitialState } from 'reakit/ts';
import { cx } from '@linaria/core';
import { omit } from 'rambda';
import { flex } from 'styles/utils/core';
import { ReactNode } from 'react';
import { contentStyles, tooltipStyles, tooltipWrapperStyles } from './Tooltip.styles';

export interface TextTooltipProps extends React.HTMLAttributes<HTMLSpanElement> {
  tooltipClassName?: string;
  initialState?: TooltipInitialState;
  text: ReactNode;
  showArrow?: boolean;
  arrowProps?: React.HTMLAttributes<HTMLDivElement>;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const TextTooltip = ({
  className,
  tooltipClassName,
  initialState,
  text,
  children,
  showArrow,
  arrowProps,
  contentProps = {},
  ...rest
}: TextTooltipProps): JSX.Element => (
  <Tooltip
    className={cx(tooltipWrapperStyles, tooltipClassName)}
    initialState={initialState}
    showArrow={showArrow}
    arrowProps={arrowProps}
    data={
      <div className={cx(className, tooltipStyles)} {...rest}>
        {text}
      </div>
    }
    hidden={rest?.hidden}
  >
    <div
      className={cx(flex.init, flex.justifyContent.center, flex.alignItems.center, contentProps.className, contentStyles)}
      {...omit(['className'], contentProps)}
    >
      {children}
    </div>
  </Tooltip>
);

TextTooltip.defaultProps = {
  initialState: { placement: 'top-start' },
};
