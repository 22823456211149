import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchPasswordPoliciesTE = <E = CF.API.ApiError>(
  { userId }: { userId?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.PasswordPoliciesResponse> =>
  fetchTE<CF.API.Users.PasswordPoliciesResponse, E>(
    {
      path: userId ? `/api/_api/v2/users/${userId}/password_policies` : '/api/_api/v2/password_policies/global',
      method: 'GET',
    },
    onRejected,
  );
