export const HubspotFields = (): JSX.Element => (
  <>
    <input name="utm_source" type="hidden" />
    <input name="utm_medium" type="hidden" />
    <input name="utm_campaign" type="hidden" />
    <input name="utm_content" type="hidden" />
    <input name="utm_term" type="hidden" />
    <input name="gclid" type="hidden" />
  </>
);
