import { useRef, useCallback, RefObject } from 'react';

export const useSubmitOutside = (): [RefObject<HTMLFormElement>, () => void] => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmitThroughRef = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formRef.current]);
  return [formRef, handleSubmitThroughRef];
};
