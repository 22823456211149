import { Button } from 'components/Button';
import { cx } from '@linaria/core';
import { TextIcon } from 'components/TextIcon';
import { makeInitials, stringToColor } from 'utils/strings/strings';
import { format } from 'date-fns';
import { makeUserFullName } from 'utils/users';
import { avatar } from '../Account/styles';
import { orgInviteWrapper } from './OrgInvitation.styles';
import { useAcceptOrgInvitation, useDeclineOrgInvitation } from '../JoinOrganization/hooks';

export const OrgInvitation: React.FC<{ invitation: CF.API.Organizations.OrgMemberInvite }> = ({ invitation }) => {
  const handleAcceptInvitation = useAcceptOrgInvitation(invitation);
  const handleDeclineInvitation = useDeclineOrgInvitation(invitation);

  return (
    <div className={orgInviteWrapper} data-testid={`org-invitation-${invitation.id}`}>
      <div className="title">
        <TextIcon
          text={makeInitials(invitation.organization.name)}
          background={stringToColor(invitation.organization.name)}
          className={cx('avatar', avatar)}
        />
        <h3>You have been invited to join {invitation.organization.name} organization</h3>
      </div>
      <p>
        {makeUserFullName(invitation.inviter as CF.API.Users.User)} has invited you to join as a {invitation.role.name}
      </p>
      <p>{format(new Date(invitation.created_at), 'EEE d LLL H:mm')}h.</p>
      <div className="button-group">
        <Button
          data-testid="org-invite-accept-btn"
          variant="primary"
          size="sm"
          loading={handleAcceptInvitation.isLoading}
          onClick={() => handleAcceptInvitation.mutate()}
        >
          Accept
        </Button>
        <Button
          data-testid="org-invite-decline-btn"
          variant="tertiary"
          size="sm"
          disabled={handleDeclineInvitation.isLoading}
          onClick={() => handleDeclineInvitation.mutate()}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};
