import { Col, Row } from 'components/Grid';
import { intentionCollection } from 'modules/User/components/Settings/ContactInformation/constants';
import { ChipOptionsField } from 'components/Formik/ChipOptionsField';
import { InputField } from 'components/Formik/InputField';

export const GoalInfo: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div {...props}>
      <Row spacing="1.5rem">
        <Col xs={12}>
          <ChipOptionsField name="intention" id="intention" label="What is your primary goal?" options={intentionCollection} required />
        </Col>
        <Col xs={12}>
          <InputField label="How did you know about Clarifai?" name="referralSource" id="referralSource" placeholder="Write how you knew us..." />
        </Col>
      </Row>
    </div>
  );
};
