import { listFiltersTE } from 'api/filterConfig/listFilters';
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { types } from 'mobx-state-tree';
import { dissoc, isEmpty, omit } from 'rambda';
import { errorToReactLeft, noop } from 'utils/fp';
import type { ParsedUrlQuery } from 'querystring';
import { applySnapshotAsync } from 'utils/mst/applySnapshotAsync';
import { withRunInAction } from 'utils/withRunInAction';
import Router from 'next/router';
import { FilterConfigItemMst } from '../mst-types';

export const onError = errorToReactLeft;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/explicit-function-return-type
export function createFilterable(
  {
    entityType,
    defaultValues,
    disableFeature,
    ignoreQueryFields = [],
  }: {
    entityType: CF.API.Filters.SupportedFilterEntities;
    defaultValues?: Record<string, string[]>;
    disableFeature: boolean;
    ignoreQueryFields?: string[];
  },
  initialQuery: Record<string, string> = {},
  params?: { disableRouterUpdates: boolean },
) {
  const { disableRouterUpdates } = params || { disableRouterUpdates: false };

  const currentQuery = { ...initialQuery };
  ignoreQueryFields.forEach((ignoreQueryField) => delete currentQuery[ignoreQueryField]);

  return types.compose(
    withRunInAction(),
    types
      .model({
        isFilterable: types.frozen<boolean>(true),
        filters: types.array(FilterConfigItemMst),
        isInitialisingFilters: !isEmpty(currentQuery),
      })
      .views((self) => ({
        get filterFieldMap() {
          return self.filters.reduce((acc, currVal) => ({ ...acc, [currVal.field]: true }), {});
        },
      }))
      .actions((self) => ({
        _applyRouterData() {
          const { router } = Router;
          if (initialQuery.filterData) {
            try {
              const filterData = JSON.parse(initialQuery.filterData) as { field: string; value: string[] }[];
              if (!Array.isArray(filterData)) {
                throw new TypeError('Invalid filter data in URL (not an array)');
              }

              const validFilterItems = filterData.filter((i) => {
                return 'field' in i && 'value' in i && i.field in self.filterFieldMap && Array.isArray(i.value);
              });

              if (!validFilterItems.length) {
                throw new TypeError('Invalid filter data in URL (invalid data in array)');
              }

              validFilterItems.forEach((routerItem) => {
                const foundFilterConfigItem = self.filters.find((filterConfigItem) => filterConfigItem.field === routerItem.field);
                if (foundFilterConfigItem) foundFilterConfigItem.value.replace(routerItem.value);
              });
            } catch {
              // remove invalid filterData from URL
              /* istanbul ignore if */
              if (router && !disableRouterUpdates) {
                router.push({
                  query: {
                    ...omit(['filterData'], router.query),
                  },
                });
              }
            }
          }
        },
        runInAction(fn: () => void) {
          return fn();
        },
        updateFilterRoute: () => {
          const { router } = Router;
          const filterArray = self.filters
            .map((filterItem) => ({
              field: filterItem.field,
              value: filterItem.value,
            }))
            .filter((x) => Boolean(x.value?.length));

          /* istanbul ignore if */
          if (router && !disableRouterUpdates) {
            router.push(
              {
                query: {
                  ...(dissoc('filterData', router.query) as ParsedUrlQuery),
                  ...(filterArray.length ? { filterData: JSON.stringify(filterArray) } : {}),
                },
              },
              undefined,
              { shallow: true },
            );
          }
        },
      }))
      .actions((self) => ({
        setInitialised: () => {
          self.isInitialisingFilters = false;
        },
        makeSetFilter(field: string) {
          return (value: string[]) => {
            self.runInAction(() => {
              const foundFilterConfigItem = self.filters.find((filterConfigItem) => filterConfigItem.field === field);
              if (foundFilterConfigItem) foundFilterConfigItem.value.replace(value);
            });
            self.updateFilterRoute();
          };
        },
      }))
      .actions((self) => ({
        fetchFilters: async () => {
          const filterDataE = await listFiltersTE({ entityType }, errorToReactLeft)();

          pipe(
            filterDataE,
            either.map((response) => {
              return response.data.map((filter) => {
                const value = defaultValues && defaultValues[filter.field] ? defaultValues[filter.field] : ([] as string[]);
                return { ...filter, display: filter.values.map((item) => ({ label: item.name, value: item.id })), value, resetInternalState: false };
              });
            }),
            either.fold(noop, (filterData) => {
              self.runInAction(() => {
                applySnapshotAsync(self.filters, filterData);
                self._applyRouterData();
              });
            }),
          );
          self.setInitialised();
        },
      }))
      .actions((self) => ({
        clearAllFilters() {
          return () => {
            self.runInAction(() => {
              self.filters.map((filter) => {
                filter.value.replace([]);
                filter.resetInternalState = true;
                return filter;
              });
            });
            self.updateFilterRoute();
          };
        },
      }))
      .actions((self) => ({
        toggleResetInternalState(field: string) {
          return (nextValue?: boolean) => {
            self.runInAction(() => {
              const foundFilterConfigItem = self.filters.find((filterConfigItem) => filterConfigItem.field === field);
              if (foundFilterConfigItem)
                foundFilterConfigItem.resetInternalState = nextValue !== undefined ? nextValue : !foundFilterConfigItem.resetInternalState;
            });
          };
        },
      }))
      .actions((self) => ({
        afterCreate() {
          if (typeof window !== 'undefined' && !disableFeature) self.fetchFilters();
        },
      })),
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function defineNotFilterable() {
  return types.model({
    isFilterable: types.frozen<boolean>(false),
    filters: types.array(FilterConfigItemMst),
  });
}
