import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const createKeyTE = <E = CF.API.ApiError>(
  {
    userId,
    body,
  }: {
    userId?: string;
    body: CF.API.Apps.CreateKeyBody;
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Apps.CreateKeyResponse> =>
  fetchTE<CF.API.Apps.CreateKeyResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/keys`,
      method: 'POST',
      body: {
        keys: [body],
      },
    },
    onRejected,
  );
