import { taskEither, either } from 'fp-ts';

/**
 * USAGE (default):
 * ```
 *   const taskThatThrowsOnLeft = pipe(
 *     someTE,
 *     throwErrorOnLeftTE(),
 *   )
 *  try { taskThatThrowsOnLeft() } catch (e) { ... }
 *
 * ```
 *
 * USAGE (custom logic):
 * ```
 *   const shouldThrowError = (leftValue) => (
 *     leftValue.type === UI_STATES.error
 *     && leftValue.props.statusCode === 500
 *   );
 *   const taskThatThrowsOnLeft = pipe(
 *     someTE,
 *     throwErrorOnLeftTE(shouldThrow),
 *   )
 *  try { taskThatThrowsOnLeft() } catch (e) { ... }
 *
 * ```
 */

const alwaysThrowOnEveryLeft = (): boolean => true;

export function throwErrorOnLeftTE<L, R>(
  shouldThrow: (left: L) => boolean = alwaysThrowOnEveryLeft,
): (fa: taskEither.TaskEither<L, R>) => taskEither.TaskEither<L, R> {
  return taskEither.mapLeft<L, L>(throwErrorOnLeft(shouldThrow));
}

export function throwErrorOnLeft<L>(shouldThrow: (left: L) => boolean = alwaysThrowOnEveryLeft) {
  return (leftVal: L) => {
    if (shouldThrow(leftVal)) {
      // throws either.Left<L> so it can be caught and used as data in useQueryTE etc
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw either.left(leftVal);
    }
    return leftVal;
  };
}
