/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { either, taskEither } from 'fp-ts';
import { pipe } from 'utils/fp';
import { MutateOptions, MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { identity } from 'rambda';
import { throwErrorOnLeftTE } from './throwErrorOnLeft';

type Handlers<L, R, TVariables, TContext> = {
  onSuccess?: (data: R, variables: TVariables, context: TContext | undefined) => Promise<unknown> | void;
  onError?: (error: L, variables: TVariables, context: TContext | undefined) => Promise<unknown> | void;
};

interface WithDataE<L, R> {
  dataE: either.Either<L, R>;
  data: R;
  error: L;
}

export type UseMutationTEResult<L, R, TError extends either.Left<L>, TData extends either.Right<R>, TVariables = void, TContext = unknown> = Omit<
  UseMutationResult<TData, TError, TVariables, TContext>,
  'data' | 'error'
> &
  WithDataE<L, R> & {
    mutateTE: (
      variables: TVariables,
      options?: MutateOptions<TData, TError, TVariables, TContext> | undefined,
    ) => taskEither.TaskEither<TError, TData>;
  };

export function useMutationTE<L, R, TError extends either.Left<L>, TData extends either.Right<R>, TVariables = void, TContext = unknown>(
  mutationFn: (variables: TVariables) => Promise<either.Either<L, R>>,

  options: Handlers<L, R, TVariables, TContext> &
    Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn' | 'onError' | 'onSuccess'> = {},
): UseMutationTEResult<L, R, TError, TData, TVariables, TContext> {
  const { onSuccess, onError, ...restOpts } = options;

  const withErrorReporting = ((variables: TVariables) =>
    pipe(
      // mutationFn as TaskEither
      () => mutationFn(variables),
      // report error to react-query
      throwErrorOnLeftTE(),
    )()) as MutationFunction<TData, TVariables>;

  const mutationResult = useMutation(withErrorReporting, {
    ...restOpts,
    ...(onSuccess
      ? {
          onSuccess: (successE, ...rest: [variables: TVariables, context: TContext | undefined]) => {
            if ('right' in successE) onSuccess(successE.right, ...rest);
          },
        }
      : {}),
    ...(onError ? { onError: (errE: TError, ...rest: [variables: TVariables, context: TContext | undefined]) => onError(errE.left, ...rest) } : {}),
  });

  return Object.assign(mutationResult, {
    // dataE is the new property we add over RQ defaults
    dataE: (mutationResult.error || mutationResult.data) as either.Either<L, R>,
    // we break out of either type to make `error` and `data` conform to "default" RQ types
    error: (mutationResult.error?.left || null) as unknown as L,
    data: mutationResult.data?.right as unknown as R,
    mutateTE: taskEither.tryCatchK(mutationResult.mutateAsync, identity),
  }) as UseMutationTEResult<L, R, TError, TData, TVariables, TContext>;
}
