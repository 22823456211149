export type Entity = CF.API.Apps.App | Partial<CF.API.Workflows.Workflow> | Partial<CF.API.Models.Model> | CF.API.Modules.Module;

/**
 * App type guard
 */
export const isApp = (entity: Entity): entity is CF.API.Apps.App => {
  return 'default_workflow_id' in entity;
};

/**
 * Model type guard
 */
export const isModel = (entity: Entity | null): entity is CF.API.Models.Model => {
  return !!entity && 'model_type_id' in entity;
};

/**
 * Workflow type guard
 */
export const isWorkflow = (entity: Entity | null): entity is CF.API.Workflows.Workflow => {
  return !!entity && !('model_type_id' in entity);
};

/**
 * Module type guard
 */
export const isModule = (entity: Entity): entity is CF.API.Modules.Module => {
  return 'module_version' in entity;
};
