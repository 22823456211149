import { cx } from '@linaria/core';
import { last } from 'rambda';
import { noop } from 'utils/fp';
import { SupportedListingEntities } from 'modules/Listings/types';
import { flex } from 'styles/utils';
import { getExplorePageNameByPath } from 'utils/urls/pageName';
import { getAppId, getEntityId, getEntityName, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { WrapLink } from 'components/EntityCard';
import { MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { FooterBreadcrumbs } from 'components/EntityCard/FooterBreadcrumbs';
import { capitalize } from '@mui/material';
import { constructThumbnailURL } from 'utils/coverImage';
import { Image } from 'components/Image';
import {
  wrapper,
  modelTag,
  cardTitle,
  cardDescription,
  cardThumbnailWrapper,
  cardThumbnailImage,
  workflowTag,
  dot,
  footerWrapper,
} from './FeaturedEntityCard.styles';
import { StarComponent } from './StarComponent';

export interface FeaturedEntityCardProps extends React.HTMLAttributes<HTMLDivElement> {
  entity: SupportedListingEntities;
  href?: string;
}

export const EntityTag: React.FC<{ entity: SupportedListingEntities }> = ({ entity }) => {
  const entityIsModel = 'model_version' in entity;
  return (
    <span data-testid="featured-card-tag" className={entityIsModel ? modelTag : workflowTag}>
      {entityIsModel ? 'Model' : 'Workflow'}
    </span>
  );
};

export const Footer: React.FC<{ entity: SupportedListingEntities }> = ({ entity }) => {
  return (
    <div className={footerWrapper}>
      <FooterBreadcrumbs entity={entity} />
      <span className={dot} />
      <StarComponent entity={entity} />
    </div>
  );
};

export const FeaturedEntityCard: React.FC<FeaturedEntityCardProps> = ({ onClick, entity, href }): JSX.Element => {
  const url = constructThumbnailURL(entity);
  const title = getFeaturedCardTitle(entity);
  const alt = url ? last(url.split('/')) : '';
  const { track } = useDataCollection();
  const router = useRouter();

  const onCardClick = (e: MouseEvent<HTMLDivElement>): void => {
    track(`Featured ${capitalize(getEntityName(entity) || 'Unknown')} Clicked`, {
      userOrOrgId: getUserId(entity),
      appId: getAppId(entity),
      id: getEntityId(entity),
      pageName: getExplorePageNameByPath(router.pathname),
    });

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <WrapLink href={href}>
      <div className={wrapper} onClick={onCardClick} role="button" onKeyDown={noop} tabIndex={0} title={title}>
        <div className={cx(flex.init, flex.direction.column, flex.flex.one)}>
          <EntityTag entity={entity} />
          <h1 className={cardTitle}>{title}</h1>
          <p className={cx(cardDescription, flex.flex.one)}>{'description' in entity && entity.description}</p>
          <Footer entity={entity} />
        </div>
        <div className={cardThumbnailWrapper}>
          <Image loading="eager" key={title} src={url ?? ''} alt={alt} className={cardThumbnailImage} objectFit="contain" width={324} height={340} />
        </div>
      </div>
    </WrapLink>
  );
};

export function getFeaturedCardTitle(entity: SupportedListingEntities): string {
  // for models
  if ('model_version' in entity) {
    return entity.name;
  }
  return 'id' in entity ? entity.id : '';
}
