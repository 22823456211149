import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { useIsOwner } from 'components/NoAppState/NoAppState.hooks';
import { useRouter } from 'next/router';
import { useAppLevelScopes } from 'modules/Auth/hooks/scopes';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { either, pipe } from 'utils/fp';
import { useIsLoggedIn } from 'modules/Auth/hooks';

/**
 * Checks app settings visibility according to: https://clarifai.atlassian.net/browse/MRK-2790
 */
export function useHasAccessToAppSettingsE() {
  const { userOrOrgId } = useRouter().query as { userOrOrgId: string };
  const { data: userScopesE } = useAppLevelScopes();
  const isAppOwner = useIsOwner();
  const isLoggedIn = useIsLoggedIn();
  const userOrg = useCurrentUserOrg();

  // Early return
  if (!isLoggedIn) return either.right(false);

  // This flag is to check whether viewing user is an org account and the
  // app is also under the same org
  const isAppUnderOrg = userOrg?.organization.id === userOrOrgId;

  const canViewAppE = pipe(userScopesE, either.map(permissionMatchers.app.view));
  const canViewAppSettingsE = pipe(
    canViewAppE,
    either.map((canViewApp) => {
      if (isAppUnderOrg) return canViewApp;
      return isAppOwner;
    }),
  );

  return canViewAppSettingsE;
}

export function useHasAccessToAppSettings() {
  const canViewAppSettingsE = useHasAccessToAppSettingsE();
  return pipe(
    canViewAppSettingsE,
    either.getOrElse(() => false),
  );
}
