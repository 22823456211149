import { IListResourceByAppResponse, apiListResourceByApp } from 'api/resource/listResourceByApp';
import { useQuery } from 'react-query';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { listModelTypesTE } from 'api/models/listModelTypes';
import { convertToMap } from 'utils/array';
import { either, errorToReactLeft, pipe } from 'utils/fp';
import { Either } from 'fp-ts/lib/Either';
import { QUERY_MAPPING, Tabs } from './constantAndTypes';
import { datasetsRecordMapper, modelsRecordMapper, workflowMapper } from './TableConfig';

type Params = {
  selectedId: Tabs;
  app: CF.API.Apps.App;
};

const getData = (responseData: IListResourceByAppResponse, app: CF.API.Apps.App, modelTypesMap: Record<string, CF.API.Models.ModelTypesEntity>) => {
  if ('models' in responseData) {
    return modelsRecordMapper(responseData.models, modelTypesMap);
  } else if ('workflows' in responseData) {
    return workflowMapper(responseData.workflows, app);
  } else if ('datasets' in responseData) {
    return datasetsRecordMapper(responseData.datasets);
  } else if ('modules' in responseData) {
    return responseData.modules;
  } else {
    return [];
  }
};

export const useResourceData = ({ selectedId, app }: Params) => {
  const { data: resources, isLoading } = useQuery({
    queryKey: [
      queryKeys.resourceViewer,
      {
        userOrOrgId: app.user_id,
        appId: app.id,
        queryParam: QUERY_MAPPING[selectedId as Tabs]?.queryParam,
        resourceType: QUERY_MAPPING[selectedId as Tabs]?.key,
      },
    ],
    queryFn: () =>
      apiListResourceByApp({
        userOrOrgId: app.user_id,
        appId: app.id,
        queryParam: QUERY_MAPPING[selectedId as Tabs]?.queryParam,
        resourceType: QUERY_MAPPING[selectedId as Tabs]?.key,
      }),
  });
  const { data: modelTypesRawData, isFetching } = useQueryTE(queryKeys.ModelTypes, listModelTypesTE(null, errorToReactLeft), {
    staleTime: Number.POSITIVE_INFINITY,
    enabled: selectedId === 'Models',
  });
  const modelTypes = pipe(
    (modelTypesRawData ?? {}) as unknown as Either<unknown, CF.API.Models.ListModelTypesResponse>,
    either.fold(
      () => [],
      (data: CF.API.Models.ListModelTypesResponse) => {
        return data?.model_types;
      },
    ),
  );

  const modelTypesMap = convertToMap<CF.API.Models.ModelTypesEntity>((modelTypes ?? []) as Array<CF.API.Models.ModelTypesEntity>, 'id');

  return {
    data: getData((resources ?? {}) as IListResourceByAppResponse, app, (modelTypesMap ?? []) as Record<string, CF.API.Models.ModelTypesEntity>),
    isLoading: isLoading || isFetching,
  };
};
