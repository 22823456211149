import { Row, RowData } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';

export interface CellTimestampProps {
  value?: string;
  formatString?: string;
  testId?: string;
}

export const Timestamp = ({ value, formatString, testId = '' }: CellTimestampProps): JSX.Element => (
  <div data-testid={testId}>{formatTimestamp(value, formatString)}</div>
);

export function formatTimestamp(value?: string, formatString: string = 'MMM dd, yyyy h:mm a') {
  return value ? format(parseISO(new Date(value).toISOString()), formatString) : '';
}

export const DateTimeStampFilter = (row: Row<RowData>, columnId: string, filterValue: string) => {
  const cellValue = formatTimestamp(row.getValue(columnId));
  return cellValue.toLowerCase().includes(filterValue.toLocaleLowerCase());
};
