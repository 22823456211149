import { reduce, replace, identity } from 'rambda';

enum CustomVariableName {
  user_id = 'user_id',
  app_id = 'app_id',
}

export type CustomVariables = Record<CustomVariableName, string>;

const ReplaceWithMapping: Record<CustomVariableName, string> = {
  [CustomVariableName.user_id]: '{{user_id}}',
  [CustomVariableName.app_id]: '{{app_id}}',
};

export function replaceCustomVariables(markdown?: string, customVariables?: CustomVariables) {
  if (!customVariables || !markdown) {
    return markdown;
  }

  const replacerFns = Object.entries(customVariables).map(([customVariableName, replaceWith]) => {
    const replaceWithCustomVariable = ReplaceWithMapping[customVariableName as CustomVariableName] as string | undefined;
    if (!replaceWithCustomVariable) {
      return identity;
    }

    const regex = new RegExp(replaceWithCustomVariable, 'g');
    return replace(regex, replaceWith);
  });

  return reduce((processedMarkdown, replacerFn) => replacerFn(processedMarkdown), markdown, replacerFns);
}
