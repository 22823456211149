import { fetchAppLevelScopesTE } from 'api/users/appLevelScopes';
import { updateAppTE } from 'api/apps/updateApp';
import { updateAppIdTE } from 'api/apps/updateAppId';
import { either } from 'fp-ts';
import { toJS } from 'mobx';
import { types, Instance } from 'mst-effect';
import { omit } from 'rambda';
import { pipe, errorToReactLeft } from 'utils/fp';
import { permissionMatchers } from 'utils/scopes/permissionMatchers';
import { updateMST, withRunInAction } from 'utils/withRunInAction';
import { applyEdits, setEditableEntityValue } from 'modules/EntityViewers/editableEntityHelpers';
import { appMST } from './listingStore/mst-types';

type SetValueT<K extends keyof CF.API.Apps.App> = (key: K, value: CF.API.Apps.App[K], shouldSave?: boolean) => Promise<void>;

export const editableAppMST = types
  .compose(
    withRunInAction(),
    types.model({
      isEditable: types.maybe(types.optional(types.boolean, false)),
      // needs listing store actions as well
      data: appMST,
      appId: types.string,
      userId: types.string,
      image: types.maybe(
        types.model({
          url: types.optional(types.string, ''),
          base64: types.maybe(types.string),
          hosted: types.maybe(
            types.model({
              crossorigin: types.maybe(types.string),
              sizes: types.optional(types.array(types.string), []),
              prefix: types.optional(types.string, ''),
              suffix: types.optional(types.string, ''),
            }),
          ),
        }),
      ),
    }),
  )
  .named('EditableAppMST')
  .actions((self) => {
    // private variable hidden from consumers
    let lastSaved: CF.API.Apps.App = toJS(self.data);
    function setLastSaved(a: typeof self.data): typeof lastSaved {
      lastSaved = toJS(a);
      return lastSaved;
    }
    const saveApp = async (reindex = false): Promise<typeof self.data> =>
      pipe(
        await updateAppTE({
          userId: self.userId,
          body: { app: omit(['metadata', 'sample_ms', 'image'], self.data), action: 'overwrite', reindex },
        })(),
        applyEdits('app', self, lastSaved, setLastSaved),
        // temporary typecasting due to lack of strict mode causing issues with MST optional types
        (x) => x as unknown as typeof self.data,
      );

    const setValue: SetValueT<keyof CF.API.Apps.App> = async (key, value, shouldSave) => {
      setEditableEntityValue(self, key, value);
      if (shouldSave) {
        await saveApp();
      }
    };

    return {
      setValue,
      save: saveApp,
      updateId: (id: string) =>
        updateAppIdTE(
          {
            userId: self.userId,
            body: {
              ids: [{ id: self.appId, new_id: id }],
              action: 'overwrite',
            },
          },
          errorToReactLeft,
        )(),
      async afterCreate() {
        const dataE = await fetchAppLevelScopesTE({
          appId: self.appId,
          userId: self.userId,
        })();

        updateMST(self, () => {
          self.isEditable = pipe(dataE, either.map(permissionMatchers.app.edit), either.getOrElse(defaultToFalse));
          /* istanbul ignore next */
          if (self.data) {
            lastSaved = toJS(self.data);
          }
        });
      },
    };
  });

export type TEditableAppMST = Instance<typeof editableAppMST>;

const defaultToFalse = (): boolean => false;

export const testable = { defaultToFalse };
