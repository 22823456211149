import { css } from '@linaria/core';
import { Button } from 'components/Button';
export const ClosePromptModal = ({
  closeAction
}: {
  closeAction: (flag: boolean) => void;
}): JSX.Element => {
  return <div className={container}>
      <h3>Do you want to exit the app creation?</h3>
      <p>The app will not be created.</p>
      <Button variant="tertiary" onClick={() => closeAction(true)} className={button100}>
        Yes, exit the app creation
      </Button>
      <Button variant="tertiary" onClick={() => closeAction(false)} className={button100}>
        Cancel
      </Button>
    </div>;
};
const container = "odules__Apps__components__CreateApplication__ClosePromptModal_tsx-container-cpvvj5b";
const button100 = "odules__Apps__components__CreateApplication__ClosePromptModal_tsx-button100-b1blej4w";

require("./ClosePromptModal.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./ClosePromptModal.tsx");