import React from 'react';
import { breadCrumbItem, breadCrumbItemActive } from './BreadcrumbItem.styles';

export interface BreadcrumbItemProps {
  active?: boolean;
  children: React.ReactNode;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ active, children }) => (
  <li data-testid="breadcrumb-item" className={active ? breadCrumbItemActive : breadCrumbItem}>
    {children}
  </li>
);
