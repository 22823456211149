import { Popover, PopoverAction, PopoverGroup, PopoverStateReturn, usePopoverState } from 'components/Popover';
import { IconButton, IconButtonProps } from 'components/Button';
import { cx } from '@linaria/core';
import { IconBell, IconBellV2, IconCloseSquare } from 'components/Icons';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { OrgInvitation } from 'modules/Organizations/components/OrgInvitation';
import { noop } from 'utils/fp';
import { useModalActions, useModalState } from 'components/ModalManager/Context';
import { Button } from 'components/ui_library/Button';
import { IconProps } from 'components/Icons/IconProps';
import { omit } from 'rambda';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { ScrollWrapper } from 'components/Dialog/ScrollWrapper';
import {
  badgeStyles,
  notiticationPopoverStyles,
  btnNoBorder,
  navBarDropdown,
  navLink__Active,
  navLink__Radius,
  notificationModalStyles,
  notificationModalWrapperStyles,
  emptyNotifications,
} from './styles';

const NOTIFICATIONS_MODAL_ID = 'notifications_modal_id';

interface NotificationButtonProps extends Omit<IconButtonProps, 'Icon' | 'aria-label' | 'onClick'> {
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
}

interface BadgeIconProps extends React.PropsWithChildren<IconProps> {
  badgeProps?: BadgeProps;
}

type DesktopNotificationsPopoverProps = {
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
  popover: PopoverStateReturn;
};

function BadgeIcon({ badgeProps, ...iconProps }: BadgeIconProps) {
  const WrappedBadgeValue = badgeProps?.badgeContent ? <p data-testid="notification-badge-count">{badgeProps?.badgeContent}</p> : null;

  const restBadgeProps = omit(['badgeContent'], badgeProps);

  return (
    <Badge color="error" overlap="circular" className={badgeStyles} badgeContent={WrappedBadgeValue} {...restBadgeProps}>
      <IconBell {...iconProps} />
    </Badge>
  );
}

function EmptyNotifications() {
  return (
    <div data-testid="empty-notifications" className={emptyNotifications}>
      <IconBellV2 size={112} />
      <h2>No new notifications.</h2>
      <p>Check back later.</p>
    </div>
  );
}

function DesktopNotificationsPopover(props: DesktopNotificationsPopoverProps) {
  return (
    <Popover popover={props.popover} data-testid="notifications-popover" className={notiticationPopoverStyles}>
      <ScrollWrapper style={{ maxHeight: 500 }}>
        {props.orgInvitesList?.length ? (
          props.orgInvitesList.map((invitation) => (
            <PopoverGroup key={invitation.id}>
              <OrgInvitation invitation={invitation} />
            </PopoverGroup>
          ))
        ) : (
          <EmptyNotifications />
        )}
      </ScrollWrapper>
    </Popover>
  );
}

function MobileModalContent(props: { orgInvitesList?: CF.API.Organizations.OrgMemberInvite[] }) {
  const { closeModal } = useModalActions();

  return (
    <>
      <section className="heading">
        <Button variant="unstyled" onClick={() => closeModal({ id: NOTIFICATIONS_MODAL_ID })}>
          <IconCloseSquare size={24} />
        </Button>
      </section>

      <section className="body">
        <h1>Notifications</h1>
        {props.orgInvitesList?.length ? (
          props.orgInvitesList.map((invitation) => <OrgInvitation key={invitation.id} invitation={invitation} />)
        ) : (
          <EmptyNotifications />
        )}
      </section>
    </>
  );
}

export function NotificationButton({ orgInvitesList, ...props }: NotificationButtonProps) {
  const router = useRouter();
  const { width } = useWindowSize();
  const { openModal, closeModal } = useModalActions();
  const { modals } = useModalState();
  const notificationPopover = usePopoverState({ placement: 'bottom-end' });
  const notificationModalState = useMemo(() => modals.find((modal) => modal.id === NOTIFICATIONS_MODAL_ID), [modals]);

  /**
   * Don't show notifications for joining org page, otherwise there would be dual
   * way to join an org. Currently only "org invite" notifications are shown,
   * hence we need to hide notifications entirely. In future when we will support
   * other types of notifications, then we can filter out the org invite ones.
   */
  if (router.pathname.startsWith('/join_org')) {
    return null;
  }

  const openNotificationsModal = useCallback(
    () =>
      openModal({
        id: NOTIFICATIONS_MODAL_ID,
        title: '',
        makeActions: noop,
        contentWrapperClassName: notificationModalStyles,
        className: notificationModalWrapperStyles,
        closeOnBackdropClick: true,
        content: <MobileModalContent orgInvitesList={orgInvitesList} />,
      }),
    [openModal, orgInvitesList],
  );

  // Based on window's width, toggle opening modal for mobile and popover for desktop
  const handleNotificationsToggle = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (width < 720) {
        // Since mobile shows a modal, clicking the backdrop will anyway close the modal,
        // so no need of extra logic to toggle it
        openNotificationsModal();
        return;
      }

      // Toggle the popover for desktop
      if (notificationPopover.visible) {
        notificationPopover.hide();
      } else {
        notificationPopover.show();
      }
    },
    [width, notificationPopover, openNotificationsModal],
  );

  // If window's width gets resized, then close either the modal or popover
  // whichever is not meant to be shown for that screen size.
  useEffect(() => {
    if (width < 720 && notificationPopover.visible) {
      notificationPopover.hide();
    }

    if (width >= 720 && notificationModalState && !notificationModalState.closed) {
      closeModal({ id: NOTIFICATIONS_MODAL_ID });
    }
  }, [width, notificationModalState, notificationPopover]);

  return (
    <>
      <PopoverAction popover={notificationPopover}>
        <IconButton
          key="notifications-popover"
          aria-label="notifications"
          variant="tertiary"
          size={20}
          data-testid="show-notifications-btn"
          Icon={(iconProps) => <BadgeIcon badgeProps={{ badgeContent: orgInvitesList?.length }} {...iconProps} />}
          className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius, notificationPopover.visible && 'active')}
          onClick={handleNotificationsToggle}
          {...props}
        />
      </PopoverAction>
      <DesktopNotificationsPopover popover={notificationPopover} orgInvitesList={orgInvitesList} />
    </>
  );
}
