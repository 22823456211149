import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ImageColorRecognizerIcon: FC<IconProps> = ({ color, bgColor, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3334 2.38335H5.4167C3.74143 2.38335 2.38336 3.74142 2.38336 5.41669V14.0834C2.38336 15.7586 3.74143 17.1167 5.4167 17.1167H17.3334C19.0087 17.1167 20.3667 15.7586 20.3667 14.0834V5.41669C20.3667 3.74142 19.0087 2.38335 17.3334 2.38335Z"
      stroke={color}
      strokeWidth="1.51667"
    />
    <path
      d="M2.4375 14.625L5.86987 9.47645C6.58291 8.40689 8.13113 8.34199 8.93118 9.34811L10.7603 11.6484C11.5009 12.5798 12.9065 12.6066 13.682 11.7041L14.645 10.5835C15.4559 9.64003 16.9403 9.71999 17.6451 10.7451L20.3125 14.625"
      stroke={color}
      strokeWidth="0.947916"
    />
    <path
      d="M16.25 7.80905C16.973 7.80905 17.559 7.22298 17.559 6.50002C17.559 5.77707 16.973 5.19099 16.25 5.19099C15.5271 5.19099 14.941 5.77707 14.941 6.50002C14.941 7.22298 15.5271 7.80905 16.25 7.80905Z"
      stroke={color}
      strokeWidth="0.631945"
    />
    <path
      d="M17.875 23.2812C20.8608 23.2812 23.2813 20.8608 23.2813 17.875C23.2813 14.8892 20.8608 12.4687 17.875 12.4687C14.8892 12.4687 12.4688 14.8892 12.4688 17.875C12.4688 20.8608 14.8892 23.2812 17.875 23.2812Z"
      stroke={color}
      fill={bgColor}
      strokeWidth="2.18749"
      strokeDasharray="2.19 2.19"
    />
    <path
      d="M17.8752 21.125C19.6701 21.125 21.1252 19.6699 21.1252 17.875C21.1252 16.0801 19.6701 14.625 17.8752 14.625C16.0803 14.625 14.6252 16.0801 14.6252 17.875C14.6252 19.6699 16.0803 21.125 17.8752 21.125Z"
      fill={color}
    />
  </svg>
);
