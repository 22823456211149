import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { settingsSidebarWrapper } from 'modules/User/components/Settings/styles';
import { Sidebar } from '../components/Sidebar';

export function withOrganizationSidebar<T>(Component: React.FC<T>) {
  return (props: T): JSX.Element => {
    return (
      <div className={cx(flex.init, settingsSidebarWrapper)}>
        <Sidebar className="settingsSidebar" data-testid="orgSettingsSidebar" />

        <div className="settingsContent" data-testid="orgSettingsContent">
          <Component {...props} />
        </div>
      </div>
    );
  };
}
