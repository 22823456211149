import { cx } from '@linaria/core';
import { popoverGroup, textNormal, segmentTitle, textSmall } from './Popover_styles';

export const PopoverGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }): JSX.Element => (
  <div className={cx(popoverGroup, className)} {...rest}>
    {children}
  </div>
);

export const TextNormal: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({ children, className, ...rest }): JSX.Element => (
  <span className={cx(textNormal, className)} {...rest}>
    {children}
  </span>
);

export const SegmentTitle: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({ children, className, ...rest }): JSX.Element => (
  <h5 className={cx(segmentTitle, className)} {...rest}>
    {children}
  </h5>
);

export const TextSmall: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({ children, className, ...rest }): JSX.Element => (
  <span className={cx(textSmall, className)} {...rest}>
    {children}
  </span>
);
