import { format } from 'date-fns';
import { SupportedListingEntities } from '../types';

export const getEntityId = (entity: SupportedListingEntities): string => {
  return 'app_owner' in entity ? entity.app.id : 'user_id' in entity ? entity.id : '';
};

export const getAppId = (entity: SupportedListingEntities): string => {
  if ('app_owner' in entity) {
    return entity.app.id;
  } else {
    return 'app_id' in entity ? entity.app_id : entity.id;
  }
};

export const getAppDefaultWorkflow = (entity: SupportedListingEntities): string => {
  if ('default_workflow_id' in entity) {
    return entity.default_workflow_id;
  } else if ('app' in entity && 'default_workflow_id' in entity.app) {
    return entity.app.default_workflow_id;
  } else {
    return '';
  }
};

export const getUserId = (entity: SupportedListingEntities): string => {
  if ('app_owner' in entity) {
    return String(entity.app_owner.id);
  } else {
    return 'user_id' in entity ? entity.user_id : '';
  }
};

export const getEntityName: (entity: SupportedListingEntities | { id: string }) => string | undefined = (entity) => {
  // apps
  if ('default_workflow_id' in entity || 'app_owner' in entity) {
    return 'app';
  }

  // workflows
  if ('nodes' in entity) {
    return 'workflow';
  }

  // models
  if ('model_version' in entity) {
    return 'model';
  }

  // modules
  if ('module_version' in entity) {
    return 'module';
  }

  return undefined;
};

export const getEntityDescription: (entity: SupportedListingEntities | { description: string } | { app: { description: string } }) => string = (
  entity,
) => {
  if ('description' in entity) {
    return entity.description ? entity.description : '';
  } else if ('app' in entity && 'description' in entity.app) {
    return entity.app.description ? entity.app.description : '';
  }
  return '';
};

export const getEntityTitle: (entity: SupportedListingEntities | { id: string }) => string = (entity) => {
  // apps
  if ('default_workflow_id' in entity) {
    return `${entity.user_id}/${entity.id}`;
  }

  // workflows
  if ('nodes' in entity) {
    return `${entity.user_id}/${entity.app_id}/${entity.id}`;
  }

  // models
  if ('model_version' in entity) {
    return `${entity.user_id}/${entity.app_id}/${entity.id}`;
  }

  // modules
  if ('module_version' in entity) {
    return `${entity.user_id}/${entity.app_id}/${entity.id}`;
  }

  // collaborators
  if ('app_owner' in entity) {
    return `${entity.app_owner.id}/${entity.app.id}/${entity.app.name}`;
  }

  // handle some unknown entity
  return entity.id;
};

export const getEntityVisibility = (
  entity:
    | { visibility: { gettable: number } }
    | {
        app_owner: unknown;
        app: { visibility: { gettable: number } };
      },
): CF.API.VisibilityTypes => {
  const VISIBILITY_TYPES = {
    0: 'UNKNOWN_VISIBILITY',
    10: 'PRIVATE',
    30: 'ORG',
    50: 'PUBLIC',
  };
  const visibilityValue = 'app_owner' in entity ? entity.app?.visibility?.gettable?.toString() : entity?.visibility?.gettable?.toString();

  return (VISIBILITY_TYPES[visibilityValue as unknown as keyof typeof VISIBILITY_TYPES] || VISIBILITY_TYPES[0]) as CF.API.VisibilityTypes;
};

type EntityFooterItems = {
  uniqueKey: string;
  item: string;
}[];

export const getFooterItems = (entity: SupportedListingEntities): EntityFooterItems => {
  const items = [];

  if ('modified_at' in entity) {
    items.push({
      uniqueKey: 'updatedAt',
      item: `Updated at ${format(new Date(entity.modified_at as string), 'MMM dd, yyyy')}`,
    });
  } else if ('created_at' in entity) {
    // Use created date when modified date is not available
    items.push({
      uniqueKey: 'createdAt',
      item: `Created at ${format(new Date(entity.created_at as string), 'MMM dd, yyyy')}`,
    });
  } else if ('app' in entity) {
    const appEntity = (entity as { app: { created_at?: string; modified_at?: string } }).app;
    if ('modified_at' in appEntity) {
      items.push({
        uniqueKey: 'updatedAt',
        item: `Updated at ${format(new Date(appEntity.modified_at as string), 'MMM dd, yyyy')}`,
      });
    } else if ('created_at' in appEntity) {
      items.push({
        uniqueKey: 'createdAt',
        item: `Created at ${format(new Date(appEntity.created_at as string), 'MMM dd, yyyy')}`,
      });
    }
  }

  return items;
};
