import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type ResetPasswordPayload = {
  new_password: string;
  key: string;
};

export const resetPasswordTE = <E = CF.API.ApiError>(
  values: ResetPasswordPayload,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.ResetPasswordResponse> =>
  fetchTE<CF.API.Users.ResetPasswordResponse, E>(
    {
      path: '/api/_api/v2/complete_password_reset',
      method: 'POST',
      body: {
        ...values,
      },
    },
    onRejected,
  );
