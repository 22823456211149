import { fetchApi } from 'utils/request';

export const updateAppTemplate = ({
  userId,
  appId,
  is_template,
}: {
  userId: string;
  appId: string;
  is_template: boolean;
}): Promise<CF.API.Apps.ListAppsResponse> =>
  fetchApi({
    path: `/api/_api/v2/users/${userId}/apps`,
    method: 'PATCH',
    body: {
      apps: [
        {
          id: appId,
          is_template,
        },
      ],
      action: 'overwrite',
    },
  });
