import { Image } from 'components/Image';
import { wrapper, iconWrapper, userLetterWrapper, textWrapper } from './ClarifaiLogoItem.styled';
export const ClarifaiTextItem = ({
  text
}: {
  text: string;
}): JSX.Element => {
  return <div className={textWrapper}>{text}</div>;
};
export const ClarifaiLogoItem = ({
  userId
}: {
  userId: string;
}): JSX.Element => <div className={wrapper}>
    {userId === 'clarifai' ? <div className={iconWrapper}>
        <Image src="/clarifai-icon.svg" alt="Clarifai" height={20} width={20} />
      </div> : <div className={userLetterWrapper}>{userId[0].toUpperCase()}</div>}

    <ClarifaiTextItem text={userId} />
  </div>;