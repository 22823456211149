import React, { ReactChild } from 'react';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

interface MotionMenuInterface extends HTMLMotionProps<'div'> {
  isOpen?: boolean;
  children: ReactChild;
}

export const Drawer: React.FC<MotionMenuInterface> = ({ isOpen = false, children, ...rest }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: '-100%', height: 0, opacity: 0 }}
          animate={{ y: 0, height: 'auto', opacity: 1 }}
          exit={{ y: '-100%', height: 0, opacity: 0 }}
          transition={{ type: 'tween', duration: 0.25 }}
          data-testid="motion-menu"
          {...rest}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
