import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const ClustererIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4352 3.19473H6.27984C5.86151 3.19473 5.52472 3.4964 5.52472 3.8681V8.0766C5.52472 8.4483 5.86151 8.74997 6.27984 8.74997H21.4352C21.8507 8.74997 22.1904 8.4483 22.1904 8.0766V3.8681C22.1904 3.49505 21.8507 3.19473 21.4352 3.19473ZM20.6801 7.40324H7.03499V4.54146H20.6801V7.40324Z"
      fill={color}
    />
    <path d="M13.822 8.53625V13.6642" stroke={color} strokeWidth="1.21075" />
    <path d="M23.5436 12.8807V18.0087" stroke={color} strokeWidth="1.21075" />
    <path d="M13.8576 12.3822V17.5102" stroke={color} strokeWidth="1.21075" />
    <path d="M4.17151 12.8807V18.0087" stroke={color} strokeWidth="1.21075" />
    <path d="M4.17151 13.4861H23.5436" stroke={color} strokeWidth="1.21075" />
    <path
      d="M4.31396 23.4208C5.73 23.4208 6.87792 22.2728 6.87792 20.8568C6.87792 19.4408 5.73 18.2928 4.31396 18.2928C2.89792 18.2928 1.75 19.4408 1.75 20.8568C1.75 22.2728 2.89792 23.4208 4.31396 23.4208Z"
      fill={color}
    />
    <path
      d="M13.8575 23.1359C15.1948 23.1359 16.279 22.0517 16.279 20.7144C16.279 19.377 15.1948 18.2928 13.8575 18.2928C12.5201 18.2928 11.436 19.377 11.436 20.7144C11.436 22.0517 12.5201 23.1359 13.8575 23.1359Z"
      fill={color}
    />
    <path
      d="M23.686 23.4208C25.1021 23.4208 26.25 22.2728 26.25 20.8568C26.25 19.4408 25.1021 18.2928 23.686 18.2928C22.27 18.2928 21.1221 19.4408 21.1221 20.8568C21.1221 22.2728 22.27 23.4208 23.686 23.4208Z"
      fill={color}
    />
  </svg>
);
