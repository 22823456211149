import { Field, FieldProps, FieldConfig } from 'formik';
import { Checkbox } from 'components/Checkbox';
import { CheckboxProps } from 'components/Checkbox/Checkbox';
import { cx } from '@linaria/core';
import { baseTheme } from 'styles/utils';
import { useCallback } from 'react';
import { fieldWrapper } from './Forms.styles';

type CheckboxFieldProps = {
  label?: string;
  /** @deprecated this prop is going to be removed soon as instances of use are removed */
  customError?: string;
} & Omit<CheckboxProps, 'value' | 'defaultValue'>;

function CheckboxField_({ field, children, meta, className, id, form, ...restInputProps }: CheckboxFieldProps & FieldProps): React.ReactElement {
  const error = meta.error;
  const isErrored = meta.touched && error;
  const value = Boolean(field.value);

  return (
    <div className={cx(fieldWrapper, isErrored && 'invalid')}>
      <Checkbox
        id={id}
        className={cx(className, 'formField', isErrored && 'invalid')}
        {...field}
        {...restInputProps}
        value={String(value)}
        checked={value}
        onChange={() => form.setFieldValue(field.name, !field.value)}
      >
        {children}
      </Checkbox>
      {isErrored && (
        <p
          style={{
            color: baseTheme.light.colors.red,
            fontSize: '10px',
            position: 'absolute',
            display: 'block',
            marginTop: '5px',
            marginBottom: '10px',
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
}

export function CheckboxField(props: CheckboxFieldProps & Omit<FieldConfig, 'component' | 'children'>): JSX.Element {
  /* eslint-disable react/no-children-prop */
  // avoid field rerendering on parent rerender
  const children = useCallback((fieldProps: FieldProps) => <CheckboxField_ {...fieldProps} {...props} />, [props]);

  return <Field {...props} children={children} />;
}
