import { cx } from '@linaria/core';
import { TableCell, TableRow as MTableRow } from '@mui/material';
import { flexRender, Row, RowData } from '@tanstack/react-table';
import { forwardRef, memo, Ref } from 'react';
import { getCellStyles, MIN_ROW_HEIGHT_PX } from '../utils';

type Props = {
  row: Row<RowData>;
  height?: number;
};

const _MuiTableCell = forwardRef(({ row, height = MIN_ROW_HEIGHT_PX }: Props, ref: Ref<HTMLTableCellElement>) => {
  return (
    <MTableRow key={row.id} style={{ height: `${height}px` }} className="table-content-row" role="row">
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell
            className={cx('table-cell', cell.column.columnDef.meta?.sticky && 'sticky')}
            key={cell.id}
            style={getCellStyles(cell.column)}
            ref={ref}
            data-label={cell.column.columnDef.header}
            role="cell"
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </MTableRow>
  );
});

export const MuiTableCell = memo(_MuiTableCell);
