import { AccordionCell } from './AccordionCell';
import { TableCells } from './DesktopCells';
import { MuiTableCell } from './MTableCell';
import { MuiTableCellMobile } from './MTableCellMobile';
import { OneViewCell } from './OneViewCell';

const LAYOUT_CELLS = {
  Accordion: {
    Mobile: AccordionCell,
    Desktop: TableCells,
  },
  Grid: {
    Mobile: MuiTableCellMobile,
    Desktop: MuiTableCell,
  },
  OneView: {
    Mobile: OneViewCell,
    Desktop: TableCells,
  },
};

export const layoutResolver = (rawLayoutId: keyof typeof LAYOUT_CELLS | undefined, isMobile?: boolean) => {
  const defaultLayoutId: keyof typeof LAYOUT_CELLS = isMobile ? 'Grid' : 'Grid';
  const layoutId = rawLayoutId ?? defaultLayoutId;
  const Cell = isMobile ? LAYOUT_CELLS[layoutId]?.Mobile : LAYOUT_CELLS[layoutId]?.Desktop;
  return {
    Cell,
    layoutId,
  };
};

export type LAYOUT_CELLS_TYPE = keyof typeof LAYOUT_CELLS;
