import { Table } from '@tanstack/react-table';
import { Pagination } from './Pagination/Pagination';

interface Props<D extends unknown> {
  table: Table<D>;
  enablePagination: boolean;
}

export const TableFooter = <D extends unknown>({ table, enablePagination }: Props<D>) => {
  return enablePagination ? (
    <tfoot>
      <tr>
        <td colSpan={table.getVisibleLeafColumns().length}>
          <Pagination
            previousPage={table.previousPage}
            nextPage={table.nextPage}
            canPreviousPage={table.getCanPreviousPage()}
            canNextPage={table.getCanNextPage()}
            gotoPage={table.setPageIndex}
            pageOptions={table.getPageOptions()}
            pageIndex={table.getState().pagination.pageIndex}
          />
        </td>
      </tr>
    </tfoot>
  ) : null;
};
