/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, Fragment, useLayoutEffect } from 'react';
import { TableCell } from '@mui/material';
import { cx } from '@linaria/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ScrollTrigger } from './ScrollTrigger';
import { IRows } from './type';

export const TableRow = <D extends object>({ rows, Cell }: Pick<IRows<D>, 'rows' | 'Cell'>) => {
  return (
    <>
      {rows.map((row) => {
        return <Cell row={row} style={{}} />;
      })}
    </>
  );
};

export const VirtualisedTableRow = forwardRef(
  <D extends object = {}>(
    { rows, state, infinite, Cell, isMobileView, rowHeight }: Pick<IRows<D>, 'rows' | 'state' | 'infinite' | 'Cell' | 'isMobileView' | 'rowHeight'>,
    ref: any,
  ) => {
    const totalColumn = rows[0]._getAllVisibleCells().length;
    const virtualizer = useVirtualizer({
      count: rows.length,
      getScrollElement: () => ref.current,
      estimateSize: () => (isMobileView ? 36 * totalColumn : rowHeight),
      overscan: 20,
    });

    useLayoutEffect(() => {
      virtualizer?.measure?.();
    }, [virtualizer, isMobileView]);

    const items = virtualizer.getVirtualItems();

    const [before, after] =
      items.length > 0 ? [items?.[0].start - virtualizer.options.scrollMargin, virtualizer.getTotalSize() - items?.[items.length - 1].end] : [0, 0];

    const rowNode = virtualizer.getVirtualItems().map((virtualRow) => {
      const row = rows[virtualRow.index];
      const isLoaderRow = virtualRow.index === rows.length - 1;

      return (
        <Fragment key={row.id}>
          <Cell height={virtualRow.size} row={row} />
          {isLoaderRow && infinite && (
            <TableCell className={cx('norecord-cell')} colSpan={totalColumn}>
              <ScrollTrigger
                hasFilters={Boolean(state.columnFilters?.length)}
                isFetching={infinite.isFetching}
                fetchNextPage={infinite.fetchNextPage}
                hasNextPage={infinite.hasNextPage}
                style={{ textAlign: 'center' }}
              />
            </TableCell>
          )}
        </Fragment>
      );
    });

    return (
      <>
        {before > 0 && (
          <tr>
            <td colSpan={totalColumn} style={{ height: before }} />
          </tr>
        )}
        {rowNode}
        {after > 0 && (
          <tr>
            <td colSpan={totalColumn} style={{ height: after }} />
          </tr>
        )}
      </>
    );
  },
);
