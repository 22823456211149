import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import cogoToast from 'cogo-toast';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { useRouter } from 'next/router';
import { either } from 'fp-ts';
import { noop, pipe } from 'utils/fp';
import { IconEdit } from 'components/Icons';
import { useIsOwner } from 'components/NoAppState/NoAppState.hooks';
import { useConfirmationModal } from 'modules/EntityViewers/components/EditEntityId/EditEntityId';
import { observer } from 'mobx-react-lite';
import { container, editIconWrapper } from './EditableTitle.styles';

type EntityName = 'model' | 'workflow' | 'app' | 'module';

type Props = {
  editableEntity: CF.UI.EditableEntity;
  entityName: EntityName;
};

export const EditableTitle: React.FC<Props> = observer(({ editableEntity, entityName }): JSX.Element => {
  const isOwner = useIsOwner();
  const router = useRouter();
  const ref = useRef<HTMLInputElement>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [newId, setNewId] = useState(editableEntity.data.id);

  const [validateCallback, { errorString }] = useIdValidation$({
    userId: editableEntity.data.user_id,
    appId: 'app_id' in editableEntity.data ? editableEntity.data.app_id : undefined,
    entityName,
  });

  const updateId: () => Promise<void> =
    // temporary check until all enitities have ID editing feature
    'updateId' in editableEntity && newId !== editableEntity.data.id
      ? (): Promise<void> =>
          editableEntity?.updateId
            ? editableEntity.updateId(newId).then((value) => {
                return pipe(value, either.fold(failed, succeeded));
              })
            : Promise.resolve(undefined)
      : () => Promise.resolve(undefined);

  const onCancelModal = useCallback(() => {
    setNewId(editableEntity.data.id);
  }, []);

  const openConfirmationModal = useConfirmationModal(updateId, entityName, onCancelModal);

  const failed = (l: ReactLeft): void => {
    cogoToast.error(l.props?.reason, { heading: l.props?.title });
    setNewId(editableEntity.data.id);
  };

  const succeeded = (): void => {
    router.push({
      query: {
        ...router.query,
        [`${entityName}Id`]: newId,
      },
    });
  };

  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = (evt) => {
    if (evt.key === 'Escape') {
      setIsEditable(false);
    }
    if (evt.key === 'Enter') {
      setIsEditable(false);
      openConfirmationModal();
    }
  };

  // reset the newId state when user goes to different app from sidebar
  useEffect(() => {
    if (editableEntity.data?.id !== newId && !isEditable) {
      setNewId(editableEntity.data.id);
    }
  }, [newId, setNewId, editableEntity.data, isEditable]);

  useEffect(() => {
    if (isEditable) {
      ref.current?.focus();
      ref.current?.setSelectionRange(0, ref.current?.value.length);
    }
  }, [isEditable]);

  return (
    <div className={container}>
      {isEditable ? (
        <>
          {errorString && <div className="errorDiv">{errorString}</div>}
          <input
            type="input"
            value={newId}
            ref={ref}
            onKeyUp={handleKey}
            onChange={(e) => {
              setNewId(e.target.value?.replaceAll(/[^\dA-Za-z-]/g, '-'));
              validateCallback(e.target.value);
            }}
            className="appIdEdit"
          />
          <div
            role="button"
            tabIndex={0}
            onKeyPress={noop}
            className="appIdEditBtn"
            onClick={() => {
              setIsEditable(false);
              openConfirmationModal();
            }}
          >
            Save
          </div>
        </>
      ) : (
        <>
          <div className="appId">{newId}</div>
          {isOwner && (
            <div
              role="button"
              tabIndex={0}
              onKeyPress={noop}
              onClick={() => setIsEditable(true)}
              className={editIconWrapper}
              data-testid="app-id-edit-icon"
            >
              <IconEdit size={15} color="#aaa" />
            </div>
          )}
        </>
      )}
    </div>
  );
});
