import { css } from '@linaria/core';
import { theming } from 'styles/utils';
export const contentStyles = "omponents__Tooltip__Tooltip_styles_tsx-contentStyles-cjmwj2s";
export const tooltipStyles = "omponents__Tooltip__Tooltip_styles_tsx-tooltipStyles-t14pxi6p";
export const tooltipWrapperStyles = "omponents__Tooltip__Tooltip_styles_tsx-tooltipWrapperStyles-t1wiooug";
export const gifTootltipStyles = "omponents__Tooltip__Tooltip_styles_tsx-gifTootltipStyles-g1dif6ti";
export const gifTootltipTextWrapperStyles = "omponents__Tooltip__Tooltip_styles_tsx-gifTootltipTextWrapperStyles-g5uzc3l";
export const giftooltipHeaderContainer = "omponents__Tooltip__Tooltip_styles_tsx-giftooltipHeaderContainer-gzon8i5";
export const gifTooltipHeaderText = "omponents__Tooltip__Tooltip_styles_tsx-gifTooltipHeaderText-gcvs68j";
export const giftooltipSmallText = "omponents__Tooltip__Tooltip_styles_tsx-giftooltipSmallText-g1a4yhjo";
export const hotkeyTextWrapper = "omponents__Tooltip__Tooltip_styles_tsx-hotkeyTextWrapper-hfmfuf6";

require("./Tooltip.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Tooltip.styles.tsx");