/* eslint-disable max-len */
import { listModelsByAppTE } from 'api/models/listModelsByApp';
import { listModelsTE } from 'api/models/listModels';
import { fetchModelTE } from 'api/models/fetchModel';
import { fetchModelVersionOutputTE } from 'api/models/fetchModelVersionOutput';
import { deleteModelTE } from 'api/models/deleteModel';
import { deleteModelVersionTE } from 'api/models/deleteModelVersion';
import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { errorToReactLeft } from 'utils/fp';
import { listModelTypesTE } from 'api/models/listModelTypes';
import { listModelVersionsTE } from 'api/models/listModelVersions';
import { postModelVersionMetricsTE } from 'api/models/postModelVersionMetrics';
import { fetchModelVersionMetricsTE } from 'api/models/fetchModelVersionMetrics';
import { trainModelTE } from 'api/models/trainModel';
import { fetchModelConceptsListTE } from 'api/concepts/fetchConceptsList';

export const modelPageApiEffects = {
  fetchModelTE: (
    modelId: string,
    appId: string,
    userId: string,
    params?: string,
    sessionToken?: string,
  ): TaskEither<ReactLeft, CF.API.Models.Model> =>
    pipe(
      fetchModelTE(
        {
          modelId,
          appId,
          userId,
          params,
          sessionToken,
        },
        errorToReactLeft,
      ),
      taskEither.map((x) => x.model),
    ),

  fetchModelVersionTE: (modelId: string, appId: string, userId: string): TaskEither<ReactLeft, CF.API.Models.Model> =>
    pipe(
      fetchModelVersionOutputTE(
        {
          modelId,
          appId,
          userId,
        },
        errorToReactLeft,
      ),
      taskEither.map((x) => x.model),
    ),

  listModelVersionsTE: (modelId: string, appId: string, userId: string, sessionToken?: string): TaskEither<ReactLeft, CF.API.Models.ModelVersion[]> =>
    pipe(
      listModelVersionsTE(
        {
          modelId,
          appId,
          userId,
          sessionToken,
        },
        errorToReactLeft,
      ),
      taskEither.map((x) => x.model_versions),
    ),

  listModelVersionConceptsTE: (modelId: string, versionId: string, appId: string, userId: string): TaskEither<ReactLeft, CF.API.Concept[]> =>
    pipe(
      fetchModelConceptsListTE(
        {
          modelId,
          appId,
          userId,
          versionId,
        },
        errorToReactLeft,
      ),
      taskEither.map((x) => x.concepts),
    ),

  listModelsByAppTE: (userOrOrgId: string, appId: string, params?: string): TaskEither<ReactLeft, CF.API.Models.Model[]> =>
    pipe(
      listModelsByAppTE({ userOrOrgId, appId, params }, errorToReactLeft),
      taskEither.map(({ models }) => models.filter((model) => model.app_id === appId)),
    ),

  listModelsByAppUnfilteredTE: (userOrOrgId: string, appId: string, params?: string): TaskEither<ReactLeft, CF.API.Models.Model[]> =>
    pipe(
      listModelsByAppTE({ userOrOrgId, appId, params }, errorToReactLeft),
      taskEither.map((x) => x.models),
    ),

  listModelsTE: (params?: string): TaskEither<ReactLeft, CF.API.Models.Model[]> =>
    pipe(
      listModelsTE({ params }, errorToReactLeft),
      taskEither.map((x) => x.models),
    ),

  deleteModelTE: (userId: string, appId: string, modelId: string): TaskEither<ReactLeft, CF.API.Status> =>
    pipe(deleteModelTE({ userId, appId, modelId }, errorToReactLeft)),

  deleteModelVersionTE: (userId: string, appId: string, modelId: string, modelVersionId: string): TaskEither<ReactLeft, CF.API.Status> =>
    pipe(deleteModelVersionTE({ userId, appId, modelId, modelVersionId }, errorToReactLeft)),

  listModelTypesTE: (): TaskEither<ReactLeft, CF.API.Models.ListModelTypesResponse> => {
    return listModelTypesTE({ appId: undefined, userId: undefined }, errorToReactLeft);
  },

  postModelVersionMetricsTE: (userId: string, appId: string, modelId: string, versionId: string) =>
    pipe(
      postModelVersionMetricsTE({
        userId,
        appId,
        modelId,
        versionId,
      }),
    ),

  fetchModelVersionMetricsTE: (userId: string, appId: string, modelId: string, versionId: string) =>
    pipe(
      fetchModelVersionMetricsTE({
        userId,
        appId,
        modelId,
        versionId,
      }),
    ),

  postTrainModelTE: (appId: string, userOrOrgId: string, modelId: string, body: { model_versions: Array<Partial<CF.API.Models.Model>> }) =>
    pipe(
      trainModelTE(
        {
          appId,
          userOrOrgId,
          modelId,
          body,
        },
        errorToReactLeft,
      ),
    ),
};
