import { Radio, RadioGroupHTMLProps, RadioGroup as ReakitRadioGroup, RadioStateReturn } from 'reakit/Radio';
import { cx } from '@linaria/core';
import { disabledButton, radioGroupWrapper } from './RadioGroup_styles';

export interface RadioGroupProps extends RadioGroupHTMLProps {
  className?: string;
  title?: string;
  options: { label: React.ReactNode | string; value: string | number; content?: React.ReactNode }[];
  separator?: boolean;
  radio: RadioStateReturn;
  disabled?: boolean;
  radioListClass?: string;
  activeClass?: string;
  activeValue?: string;
  disabledClassName?: string;
}

export function RadioGroup({
  className,
  title,
  options,
  radio,
  disabled,
  radioListClass,
  activeClass,
  disabledClassName,
  activeValue,
  separator,
  ...rest
}: RadioGroupProps): JSX.Element | null {
  return (
    <ReakitRadioGroup aria-label={title} data-testid="radioGroup" className={className} {...radio} {...rest}>
      <ul className="list">
        {options.map((option, index) => (
          <li
            key={`rd-${option.value}`}
            className={cx(
              radioGroupWrapper,
              radioListClass,
              activeValue === option.value && !disabled ? activeClass : disabledClassName,
              disabled && disabledButton,
            )}
          >
            <Radio {...radio} value={option.value} id={`rd-${option.value}`} disabled={disabled} />
            <label htmlFor={`rd-${option.value}`}>{option.label}</label>
            {option.content || null}
            {separator && index !== options.length - 1 && <div className="divider" />}
          </li>
        ))}
      </ul>
    </ReakitRadioGroup>
  );
}
