/* istanbul ignore file: https://clarifai.atlassian.net/browse/MRK-1574 */
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { appStorage } from 'utils/appStorage';
import { useDialogState } from 'reakit/Dialog';
import { errorToReactLeft, noop, pipe } from 'utils/fp';
import { apiUserLogout, getUserMeTE, LoginPayload } from 'api/users';
import { fetchUserOrgsTE } from 'api/organizations';
import { isUserVerified, isOnboardingPending } from 'utils/users';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { SignupPayload } from 'api/users/signup';
import { useQueryClient } from 'react-query';
import { either, taskEither } from 'fp-ts';
import { useRouter } from 'next/router';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { equals } from 'rambda';
import { cookieClientStorage } from 'utils/cookieStorage';
import { AuthModal, AuthModalState } from 'modules/Auth/components/AuthModal';
import { TotpLoginParams } from 'api/users/twoFactorAuthentication';
import { OnboardingDialog } from 'modules/Auth/components/OnboardingDialog';
import { useModalActions } from 'components/ModalManager/Context';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import getConfig from 'next/config';
import { Either } from 'fp-ts/lib/Either';
import cogoToast from 'cogo-toast';
import { loginAndSaveToStorage, signupAndSaveToStorage, logoutLocal, loginWithTwoFactorAndSaveToStorage } from './effects/api';
import { onboardingDialogClassName } from './components/OnboardingDialog/OnboardingDialog.styles';

const { publicRuntimeConfig } = getConfig();

const isOfflineMode = publicRuntimeConfig.OFFLINE_MODE as boolean;

export enum Types {
  LoggedIn = 'USER_LOGGED_IN',
  LoggedOut = 'USER_LOGGED_OUT',
}

type InitialStateType = {
  authData?: CF.LocalUserWithOrg;
  twoFactorAuthData?: TwoFactorAuthData;
};

const initialState: InitialStateType = {
  authData: undefined,
  twoFactorAuthData: undefined,
};

export type AuthActions = {
  logout: (a?: { redirect: boolean }) => void;
  updateUserId: (newUserId: string) => void;
  changeUserAccountById: (newCurrentUserId: string) => void;
  makeLoginTE: (values: LoginPayload) => () => Promise<either.Either<ReactLeft, CF.LocalUser>>;
  makeSignupTE: (values: SignupPayload) => () => Promise<either.Either<ReactLeft, CF.LocalUser>>;
  makeTwoFactorLoginTE: (values: TotpLoginParams) => () => Promise<either.Either<ReactLeft, CF.LocalUser>>;
  updateTwoFactorAuthData: (data?: TwoFactorAuthData) => void;
  openLoginModal: (mode?: AuthModalState, clbck?: () => void) => void;
  closeLoginModal: () => void;
  updateDontShowMessageAgainState: (dontShowMessageAgain: boolean) => void;
  refetchLogin: () => Promise<{ data: Either<ReactLeft, CF.API.Users.GetUserMeResponse> }>;
  refetchOrg: () => Promise<{ data: Either<ReactLeft, CF.API.Organizations.ListUserOrgResponse> }>;
  onAuthSuccess: (user: CF.LocalUserWithOrg, isSSO?: boolean) => void;
  updateAuthMetadata: (path: string, data: CF.API.JSON) => void;
};

export type TwoFactorAuthData = {
  user_id: string;
  state: string;
  two_factor_auth_enabled?: boolean;
};

export const AuthStateContext = createContext<InitialStateType>(initialState);
export const AuthDispatchContext = createContext<AuthActions>({
  updateUserId: noop,
  changeUserAccountById: noop,
  logout: noop,
  makeLoginTE: () => noop,
  makeSignupTE: () => noop,
  makeTwoFactorLoginTE: () => noop,
  updateTwoFactorAuthData: noop,
  openLoginModal: noop,
  closeLoginModal: noop,
  updateDontShowMessageAgainState: noop,
  refetchLogin: () => noop,
  refetchOrg: () => noop,
  onAuthSuccess: () => noop,
  updateAuthMetadata: noop,
} as unknown as AuthActions);

export function useAuthState(): InitialStateType {
  return useContext(AuthStateContext);
}

export function useAuthActions(): AuthActions {
  return useContext(AuthDispatchContext);
}

const TRACK_EVENT_NAMES: Record<AuthModalState, string> = {
  [AuthModalState.SHOW_SIGN_IN]: 'Sign In Dialog Opened',
  [AuthModalState.SHOW_SIGN_UP]: 'Sign Up Dialog Opened',
  [AuthModalState.SHOW_PENDING_VERIFICATION]: 'Pending Verification Dialog Opened',
  [AuthModalState.SHOW_FORGOT_PASSWORD]: 'Forgot Password Dialog Opened',
  [AuthModalState.SHOW_2FA]: '2FA Dialog Opened',
};

function checkValidCurrentUserId(authData?: CF.LocalUserWithOrgUserAndPlan, newCurrentUserId: string = '') {
  const userOrOrgIds = authData?.organizations?.map(({ organization }) => organization.id).concat([newCurrentUserId]) || [];
  return userOrOrgIds.includes(newCurrentUserId) ? newCurrentUserId : authData?.user_id;
}

function initializeAuthData(fromServerSide?: CF.LocalUserWithOrgUserAndPlan) {
  const fromLocalStorage = appStorage.get<CF.LocalUserWithOrgUserAndPlan | undefined>('authData');
  const authData = fromServerSide || fromLocalStorage;

  // "authData" from server-side has no concept of account switching between user & orgs
  // as its only implemented on client-side's local-storage, so we need to set the valid
  // value of "current_user_id" from local-storage.
  if (authData && fromLocalStorage?.current_user_id) {
    authData.current_user_id = checkValidCurrentUserId(authData, fromLocalStorage.current_user_id);
  }

  return authData;
}

export const AuthProvider: React.FC<React.PropsWithChildren<{ initialAuthData?: CF.LocalUserWithOrgUserAndPlan }>> = ({
  initialAuthData,
  children,
}) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [authData, setAuthData] = useState(initializeAuthData(initialAuthData));
  const [afterAuthCallback, setAfterAuthCallback] = useState<null | (() => void)>(null);
  const [twoFactorAuthData, setTwoFactorAuthData] = useState<TwoFactorAuthData | undefined>();
  const { openModal } = useModalActions();
  const { identify, reset, track } = useDataCollection();
  const dialog = useDialogState({ visible: false, animated: true });
  const [modalState, setModalState] = useState<AuthModalState>(AuthModalState.SHOW_SIGN_IN);
  const closeLoginModal = useCallback(() => dialog.hide(), [dialog]);
  const sessionToken = authData?.session_token || cookieClientStorage.get('session_token');

  const onAuth = useCallback(() => {
    if (afterAuthCallback) {
      afterAuthCallback();
      setAfterAuthCallback(null);
    }
  }, [setAfterAuthCallback, afterAuthCallback]);

  const openLoginModal = useCallback(
    (mode: AuthModalState = AuthModalState.SHOW_SIGN_IN, clbck: (() => void) | null = null) => {
      if (clbck) {
        setAfterAuthCallback(() => clbck);
      }
      setModalState(mode);
      dialog.show();
      track(TRACK_EVENT_NAMES[mode]);
    },
    [dialog],
  );

  const updateUserId = useCallback(
    (newUserId: string): void => {
      if (typeof window !== 'undefined' && window._sift) {
        window._sift.push(['_setUserId', newUserId]);
      }
      /* istanbul ignore next */
      setAuthData((p) => (p ? { ...p, user_id: newUserId } : undefined));
    },
    [setAuthData],
  );

  const changeUserAccountById = useCallback(
    (newCurrentUserId: string): void => {
      setAuthData((prevAuthData) => {
        if (prevAuthData) {
          const validCurrentUserId = checkValidCurrentUserId(prevAuthData, newCurrentUserId);
          return { ...prevAuthData, current_user_id: validCurrentUserId };
        }
        return prevAuthData;
      });
    },
    [setAuthData],
  );

  useEffect(() => {
    if (authData?.user_id) {
      identify(authData.user_id, { email: authData.email });
    }
  }, [authData, identify]);

  useEffect(() => {
    const cookieSessionToken = cookieClientStorage.get('session_token');

    if (cookieSessionToken && !authData?.session_token) {
      const defaultUser = {
        session_token: cookieSessionToken,
        first_name: '',
        last_name: '',
        email: '',
        user_id: '',
      };
      setAuthData(defaultUser);
    }
  }, [authData]);

  useEffect(() => {
    const cookieSessionToken = cookieClientStorage.get('session_token');
    const appStorageUser = appStorage.get('authData');

    if (appStorageUser && !cookieSessionToken) {
      appStorage.rm('authData');
      setAuthData(undefined);
    }
  }, []);

  useEffect(() => {
    if (!authData?.user_id) {
      return;
    }

    if (!authData?.is_email_verified && router.pathname !== '/pending-verification') {
      openLoginModal(AuthModalState.SHOW_PENDING_VERIFICATION);
    }

    if (authData?.is_onboarding_pending && authData?.is_email_verified && router.pathname !== '/verify-email') {
      openModal({
        id: 'onboarding_dialog',
        title: '',
        closeOnBackdropClick: false,
        hideOnEsc: false,
        content: <OnboardingDialog />,
        makeActions: noop,
        className: onboardingDialogClassName,
        contentWrapperClassName: 'fullHeight',
      });
      track('Onboarding Dialog Opened');
    }

    if (typeof window !== 'undefined' && window._sift && !isOfflineMode) {
      window._sift.push(['_setUserId', authData?.user?.id]);
    }

    if (typeof window !== 'undefined' && window.Intercom && publicRuntimeConfig.NEXT_PUBLIC_INTERCOM_API_ID && !isOfflineMode) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: publicRuntimeConfig.NEXT_PUBLIC_INTERCOM_API_ID,
        user_id: authData?.user_id,
        name: `${authData.first_name} ${authData.last_name}`,
        email: authData.email,
        created_at: authData?.user?.created_at,
        alignment: document.documentElement.clientWidth > 1024 ? 'right' : 'left',
      });
    }
  }, [authData, router.pathname]);

  const setUserMetaDetails = (user: CF.API.Users.User & CF.LocalUserWithOrg) => {
    const userDetails: CF.LocalUserWithOrg = {
      first_name: user.first_name,
      last_name: user.last_name,
      user_id: user.id,
      email: user.primary_email,
      is_email_verified: isUserVerified(user),
      is_onboarding_pending: isOnboardingPending(user),
      metadata: user.metadata,
      visibility: user.visibility,
      organizations: user.organizations,
      session_token: authData?.session_token || cookieClientStorage.get('session_token'),
    };
    setAuthData((existingUserData) => {
      return {
        ...existingUserData,
        ...userDetails,
      };
    });
  };

  const { data: loggedInUserE, refetch: refetchLogin } = useQueryTE(
    [
      queryKeys.LoggedInUser,
      {
        sessionToken,
      },
    ],
    getUserMeTE(undefined, errorToReactLeft),
    {
      enabled: Boolean(sessionToken),
      cacheTime: 1000,
    },
  ) as {
    data: Either<ReactLeft, CF.API.Users.GetUserMeResponse>;
    refetch: AuthActions['refetchLogin'];
  };

  // fetch user's organizations on mount
  const { data: loggedInUserOrgsE, refetch: refetchOrg } = useQueryTE([queryKeys.Organizations], fetchUserOrgsTE(undefined, errorToReactLeft), {
    enabled: Boolean(sessionToken) && Boolean(authData?.user_id),
    cacheTime: 1000,
  }) as {
    data: Either<ReactLeft, CF.API.Organizations.ListUserOrgResponse>;
    refetch: AuthActions['refetchOrg'];
  };

  useEffect(() => {
    pipe(
      loggedInUserE,
      either.fold(noop, ({ user }) => {
        // in case of social login; we start with blank user fields and update them after first fetch
        setAuthData((data) => {
          // user had already logged out while the new user is fetched; so we dont need to update state.
          if (!data) return data;

          // current_user_id and organizations will exist after user has logged in
          const metaDetails: CF.LocalUserWithOrg = {
            first_name: user.first_name,
            last_name: user.last_name,
            user_id: user.id,
            email: user.primary_email,
            is_email_verified: isUserVerified(user),
            is_onboarding_pending: isOnboardingPending(user),
            metadata: user.metadata,
            visibility: user.visibility,
            organizations: data.organizations && user.id === data.user_id ? data.organizations : [],
          };

          return {
            ...data,
            ...metaDetails,
            session_token: data.session_token,
          };
        });
      }),
    );
  }, [loggedInUserE]);

  useEffect(() => {
    pipe(
      loggedInUserOrgsE,
      either.fold(noop, ({ organizations }) => {
        // in case of social login; we start with blank user fields and update them after first fetch
        setAuthData((data) => {
          // user had already logged out while the new user is fetched; so we dont need to update state.
          if (!data) return data;

          const metaDetails: {
            organizations?: CF.API.Organizations.UserOrganization[] | undefined;
          } = {
            organizations: organizations || [],
          };

          return {
            ...data,
            ...metaDetails,
            session_token: data.session_token,
          };
        });
      }),
    );
  }, [loggedInUserOrgsE]);

  // update application state with auth data and sessionToken
  const onAuthSuccess = useCallback(
    (localUser: CF.LocalUser, isSSO?: boolean): void => {
      if (isSSO) {
        setUserMetaDetails(localUser as CF.API.Users.User & CF.LocalUserWithOrg);
        return;
      }
      setAuthData(localUser);
    },
    [setAuthData],
  );

  const updateAuthMetadata = useCallback(
    (path: string, data: CF.API.JSON) => {
      setAuthData((prevAuthData) => {
        if (!prevAuthData) {
          return prevAuthData;
        }
        const newData = { ...prevAuthData };
        newData.metadata = { ...newData.metadata, [path]: data };
        return newData;
      });
    },
    [setAuthData],
  );

  // clear RQ cache; leaving comments for historical documentation so that we don't accidentally
  // reimplement queryClient.clear() in the future; which can break UI in edge cases.
  const clearRQCache = useCallback((): void => {
    // await waitFor(() => queryClient.getMutationCache().findAll({ fetching: true }).length === 0, 1000);
    // await waitFor(() => queryClient.getQueryCache().findAll({ fetching: true }).length === 0, 1000);
    // queryClient.clear();

    // ^^^ NOTE on commented code above ^^^
    // queryClient.clear() is unsafe as it cancels ongoing requests and does NOT refetch active ones;
    // hence a need to engineer a better solution on login/logout.
    // https://github.com/tannerlinsley/react-query/discussions/1363#discussioncomment-162817
    // the above thread and comment by RQ creator helped come to this solution

    // read https://react-query.tanstack.com/guides/filters#_top < read this for insight in RQ Filters
    // active = being rendered by useQuery and inactive = not being currently rendered

    // this will remove all the inactive queries; i.e. "clear" all previous state
    queryClient.removeQueries();

    // this will "reset" (as if freshly mounted) all the active ones; i.e. refetch
    // global queries like getting app-level scopes; etc. unlike invalidateQueries; it also resets initialData
  }, [queryClient]);

  const makeLoginTE = (values: LoginPayload): taskEither.TaskEither<ReactLeft, CF.LocalUser> =>
    pipe(
      () => loginAndSaveToStorage(values),
      taskEither.mapLeft((x) => {
        return x;
      }),
      taskEither.map((x) => {
        const user = { ...x } as CF.LocalUser;
        user.is_email_verified = isUserVerified(user as unknown as CF.API.Users.User);
        if (user.session_token) {
          onAuthSuccess(user);
        } else {
          setTwoFactorAuthData({
            state: x.state as string,
            user_id: x.user_id,
            two_factor_auth_enabled: !!x.two_factor_auth_enabled,
          });
        }
        clearRQCache();
        return x;
      }),
    );

  const makeSignupTE = (values: SignupPayload): taskEither.TaskEither<ReactLeft, CF.LocalUser> =>
    pipe(
      () => signupAndSaveToStorage(values),
      taskEither.mapLeft((x) => {
        return x;
      }),
      taskEither.map((x) => {
        onAuthSuccess(x);
        clearRQCache();
        return x;
      }),
    );

  const makeTwoFactorLoginTE = (values: TotpLoginParams): taskEither.TaskEither<ReactLeft, CF.LocalUser> =>
    pipe(
      () => loginWithTwoFactorAndSaveToStorage(values),
      taskEither.mapLeft((x) => {
        return x;
      }),
      taskEither.map((x) => {
        const user = { ...x } as CF.LocalUser;
        user.is_email_verified = isUserVerified(user as unknown as CF.API.Users.User);
        onAuthSuccess(user);
        clearRQCache();
        return x;
      }),
    );

  const updateTwoFactorAuthDataAction = useCallback(
    (data: TwoFactorAuthData | undefined) => {
      setTwoFactorAuthData(data);
    },
    [setTwoFactorAuthData],
  );

  const logoutAction = useCallback(
    async ({ redirect }: { redirect?: boolean } = { redirect: true }) => {
      try {
        if (authData?.session_token) {
          apiUserLogout();
        }
        if (redirect && router.pathname !== '/') {
          router.push('/');
        }
        logoutLocal();
        reset();
        setAuthData(undefined);
        updateTwoFactorAuthDataAction(undefined);
        clearRQCache();
        if (typeof window !== 'undefined' && window.Intercom && publicRuntimeConfig.NEXT_PUBLIC_INTERCOM_API_ID && !isOfflineMode) {
          window.Intercom('shutdown');
        }
      } catch (e) {
        cogoToast.error('Failed to logout. Please try again.');
        console.error(e);
      }
    },
    [clearRQCache, router, router.pathname, identify],
  );
  const updateDontShowMessageAgainState = (dontShowMessageAgain: boolean) => {
    const _authData = {
      ...authData,
      metadata: { ...authData?.metadata, InputManager: { ...authData?.metadata?.InputManager, dontShowMessageAgain } },
    } as CF.LocalUserWithOrgUserAndPlan;
    setAuthData(_authData);
  };

  useEffect(() => {
    // when above effect updates local state with name, email, etc from server; update it in localStorage
    const authDataFromStorage = appStorage.get<CF.LocalUserWithOrg>('authData');
    const cookieSessionToken = cookieClientStorage.get('session_token');
    /* istanbul ignore if: https://clarifai.atlassian.net/browse/MRK-1249 */
    if (authData?.user_id && !equals(authData, authDataFromStorage) && cookieSessionToken) {
      appStorage.set('authData', authData);
    }
  }, [authData]);

  const authActions = {
    updateUserId,
    changeUserAccountById,
    logout: logoutAction,
    updateTwoFactorAuthData: updateTwoFactorAuthDataAction,
    makeLoginTE,
    makeSignupTE,
    makeTwoFactorLoginTE,
    openLoginModal,
    closeLoginModal,
    updateDontShowMessageAgainState,
    refetchLogin,
    refetchOrg,
    onAuthSuccess,
    updateAuthMetadata,
  };

  return (
    <AuthDispatchContext.Provider value={authActions}>
      <AuthStateContext.Provider value={{ authData, twoFactorAuthData }}>
        {children as ReactNode}

        <AuthModal authData={authData} dialog={dialog} state={modalState} setState={setModalState} onAuth={onAuth} />
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};
