import { IconCloseSquare } from 'components/Icons/IconCloseSquare';
import { cx } from '@linaria/core';
import { ReactNode } from 'react';
import { IconBack } from 'components/Icons';
import { closeButton, goBackButton, dialogHeading } from './dialogComponents.styles';

type DialogButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export const dialogComponents = {
  CloseButton: ({ className, ...props }: DialogButtonProps) => (
    <button className={cx(closeButton, className)} type="button" data-testid="dialog-closebtn" {...props}>
      <IconCloseSquare size={18} color="#282636" />
    </button>
  ),
  GoBack: ({ className, ...props }: DialogButtonProps) => (
    <button className={cx(goBackButton, className)} type="button" data-testid="dialog-gobackbtn" {...props}>
      <IconBack size={14} color="#282636" />
    </button>
  ),
  Heading: ({ children }: { children: ReactNode }) => <h3 className={dialogHeading}>{children}</h3>,
};
