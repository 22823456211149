import { fetchApi } from 'utils/request';

type ListResourceByAppParams = {
  userOrOrgId: string;
  appId: string;
  queryParam: string;
  resourceType: string;
};

export type IListResourceByAppResponse =
  | CF.API.Models.ListModelsResponse
  | CF.API.Workflows.ListWorkflowsRes
  | CF.API.Datasets.ListDatasetsResponse
  | CF.API.Modules.ListModulesResponse;

export const apiListResourceByApp = ({
  userOrOrgId,
  appId,
  queryParam = '',
  resourceType,
}: ListResourceByAppParams): Promise<IListResourceByAppResponse> =>
  fetchApi({
    path: `/api/_api/v2/users/${userOrOrgId}/apps/${appId}/${resourceType}${queryParam ? `?${queryParam}` : ''}`,
    method: 'GET',
  });
