import { useEventCallback } from 'rxjs-hooks';
import { debounceTime, distinctUntilChanged, from, map, switchMap, tap, filter, withLatestFrom } from 'rxjs';
import { validateIdTE } from 'api/validate/validateId';
import { either } from 'fp-ts';
import { pipe } from 'utils/fp';
import Nprogress from 'nprogress';

export function useIdValidation$({
  userId,
  appId,
  entityName,
}: {
  userId?: string;
  appId?: string;
  entityName: 'model' | 'workflow' | 'app' | 'user' | 'module';
}): [
  (e: string) => void,
  {
    validationResponse: false | CF.API.Validate.ValidateResponse;
    errorString: string;
  },
] {
  const [clickCallback, { validationResponse, errorString }] = useEventCallback<
    string,
    { validationResponse: CF.API.Validate.ValidateResponse | false; errorString: string },
    [typeof appId, typeof userId, typeof entityName]
  >(
    (event$, _, state$) =>
      event$.pipe(
        filter(Boolean),
        tap(() => {
          Nprogress.start();
        }),
        debounceTime(350),
        distinctUntilChanged(),
        withLatestFrom(state$),
        switchMap(([value, [appIdVal, userIdVal, entityNameVal]]) =>
          from(
            validateIdTE({
              body: {
                validations: [
                  {
                    object_type: entityNameVal,
                    user_id: userIdVal || '',
                    app_id: appIdVal,
                    object_id: value,
                  },
                ],
              },
            })(),
          ),
        ),
        map((responseE) => {
          const response = pipe(
            responseE,
            either.getOrElseW(() => false as const),
          );
          return { validationResponse: response, errorString: (response && response?.validation_results?.[0].errors?.[0].text) || '' };
        }),
        tap(() => {
          Nprogress.done();
        }),
      ),
    { validationResponse: false as const, errorString: '' },
    [appId, userId, entityName],
  );
  return [clickCallback, { validationResponse, errorString }];
}
