import { baseTheme } from 'styles/utils';
import { IconNoStarred, IconNoTemplate } from 'components/Icons';
import { container, subtitleStyle, textContainer, titleStyle } from './NoStarredResource.styles';

interface NoStarredTemplateResourceProps {
  title?: string;
  subtitle?: string;
  isTemplate?: boolean;
}

export const NoStarredTemplateResource: React.FC<NoStarredTemplateResourceProps> = ({ title = '', subtitle = '', isTemplate = false }) => {
  const Icon = isTemplate ? IconNoTemplate : IconNoStarred;
  return (
    <div className={container} data-testid={isTemplate ? 'noTemplateResource' : 'noStarredResource'}>
      <Icon size={168} color={baseTheme.light.colors.blue} data-testid={isTemplate ? 'noTemplateResource-icon' : 'noStarredResource-icon'} />
      <div className={textContainer}>
        <p className={titleStyle}>{title}</p>
        <p className={subtitleStyle}>{subtitle}</p>
      </div>
    </div>
  );
};
