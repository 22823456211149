import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteUserOrgMemberTE = <E = CF.API.ApiError>(
  { orgId, memberId }: { orgId: string; memberId: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/members/${memberId}`,
      method: 'DELETE',
    },
    onRejected,
  );
