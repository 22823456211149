import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

interface UTM {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
}

export interface SignupPayload {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  captcha_response_token_v3: string;
  gdpr_consent: boolean;
  tos_consent: boolean;
  marketing_consent: boolean;
  utm?: UTM;
}

export const postSignupTE = <E = CF.API.ApiError>(
  values: SignupPayload,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.SignupResponse> =>
  fetchTE<CF.API.Users.SignupResponse, E>(
    {
      path: '/api/_api/v2/signup?community=true',
      method: 'POST',
      body: {
        ...values,
      },
    },
    onRejected,
  );
