import { cx, css } from '@linaria/core';
import { baseUnits, flex, theming, breakpoints } from 'styles/utils';
import { textTitle } from 'styles/typography';
export const modelTypeClass = "omponents__EntityCard__EntityCardList_styled_ts-modelTypeClass-m1sdv6zv";
export const modelTypeItemCrontainerClass = "omponents__EntityCard__EntityCardList_styled_ts-modelTypeItemCrontainerClass-m1xz520t";
export const modelTypeItemIconClass = "omponents__EntityCard__EntityCardList_styled_ts-modelTypeItemIconClass-mftfk6w";
export const THUMBNAIL_SIZE = '180px';
export const iconFallback = `${"omponents__EntityCard__EntityCardList_styled_ts-iconFallback-ij1i86l"}
  ${textTitle}
  ${flex.init}
  ${flex.justifyContent.center}
  ${flex.alignItems.center}
`;
export const bodyWrapper = `${"omponents__EntityCard__EntityCardList_styled_ts-bodyWrapper-b11lim97"}
  ${flex.init}
  ${flex.flex.one}
  ${flex.direction.row}
  ${flex.alignItems.start}
  ${flex.justifyContent.between}
`;
export const detailsContainer2 = "omponents__EntityCard__EntityCardList_styled_ts-detailsContainer2-d101obgy";
export const actionsWrapper = cx("omponents__EntityCard__EntityCardList_styled_ts-actionsWrapper-av4qaql", flex.init, flex.justifyContent.end, flex.alignItems.start);
export const moreActionsWrapper = "omponents__EntityCard__EntityCardList_styled_ts-moreActionsWrapper-m1hkhv4b";
export const titleStyles = "omponents__EntityCard__EntityCardList_styled_ts-titleStyles-tjgcsec";
export const iconContainer = "omponents__EntityCard__EntityCardList_styled_ts-iconContainer-i1tk24b6";
export const contentWrapper = "omponents__EntityCard__EntityCardList_styled_ts-contentWrapper-clqdqoh";
export const wrapper = `${"omponents__EntityCard__EntityCardList_styled_ts-wrapper-wev3rfr"}
`;
export const hideBelow900 = "omponents__EntityCard__EntityCardList_styled_ts-hideBelow900-hhwlof2";

require("./EntityCardList.styled.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./EntityCardList.styled.ts");