import { TaskEither } from 'fp-ts/TaskEither';
import { fetchTE } from 'utils/request';

export interface OptionalUserOrgId {
  userOrgId?: string;
}
export const getUser2faMethodsTE = <E = CF.API.ApiError>(
  params?: OptionalUserOrgId,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.ListAuth2FAMethodsResponse> =>
  fetchTE<CF.API.Users.ListAuth2FAMethodsResponse, E>(
    {
      path: `/api/_api/v2/users/${params?.userOrgId ? params.userOrgId : 'me'}/auth/2fa/methods`,
      method: 'GET',
    },
    onRejected,
  );

export const postTotpRegisterEnableTE = <E = CF.API.ApiError>(
  params?: OptionalUserOrgId,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.PostAuth2FATotpRegisterEnableResponse> =>
  fetchTE<CF.API.Users.PostAuth2FATotpRegisterEnableResponse, E>(
    {
      path: `/api/_api/v2/users/${params?.userOrgId ? params.userOrgId : 'me'}/auth/2fa/totp/register/enable`,
      method: 'POST',
      body: {},
    },
    onRejected,
  );

export interface TotpRegisterVerifyParams extends OptionalUserOrgId {
  passCode: string;
  verifyState: string;
}

export const postTotpRegisterVerifyTE = <E = CF.API.ApiError>(
  params: TotpRegisterVerifyParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.PostAuth2FATotpRegisterVerifyResponse> =>
  fetchTE<CF.API.Users.PostAuth2FATotpRegisterVerifyResponse, E>(
    {
      path: `/api/_api/v2/users/${params.userOrgId ? params.userOrgId : 'me'}/auth/2fa/totp/register/verify`,
      method: 'POST',
      body: {
        passcode: params.passCode,
        state: params.verifyState,
      },
    },
    onRejected,
  );

export interface TotpDisableParams extends OptionalUserOrgId {
  passCode: string;
}

export const postTotpDisableTE = <E = CF.API.ApiError>(
  params: TotpDisableParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/users/${params.userOrgId ? params.userOrgId : 'me'}/auth/2fa/totp/disable`,
      method: 'POST',
      body: {
        passcode: params.passCode,
      },
    },
    onRejected,
  );

export interface TotpRegisterRecoverParams {
  state: string;
  recoveryCode: string;
  userOrgId: string;
}

export const postTotpRegisterRecoverTE = <E = CF.API.ApiError>(
  params: TotpRegisterRecoverParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/auth/2fa/totp/recover`,
      method: 'POST',
      body: {
        state: params.state,
        recovery_code: params.recoveryCode,
        user_id: params.userOrgId,
      },
    },
    onRejected,
  );

export interface TotpRegisterRecoverConfirmParams {
  state: string;
}

export const getTotpRegisterRecoverConfirmTE = <E = CF.API.ApiError>(
  params: TotpRegisterRecoverConfirmParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/auth/2fa/totp/recover/confirm?state=${params.state}`,
      method: 'GET',
    },
    onRejected,
  );

export interface TotpLoginParams {
  state: string;
  passcode: string;
  userOrgId: string;
}

export const postTotpLoginTE = <E = CF.API.ApiError>(
  params: TotpLoginParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Users.LoginResponse> =>
  fetchTE<CF.API.Users.LoginResponse, E>(
    {
      path: `/api/_api/v2/auth/2fa/totp/login`,
      method: 'POST',
      body: {
        long_lived: true,
        state: params.state,
        passcode: params.passcode,
        user_id: params.userOrgId,
      },
    },
    onRejected,
  );
