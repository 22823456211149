import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const searchOrgMembersTE = <E = CF.API.ApiError>(
  { orgId, query, params = '' }: { orgId: string; query: CF.API.Organizations.SearchOrgMembersQuery; params?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListOrganizationMembersResponse> =>
  fetchTE<CF.API.Organizations.ListOrganizationMembersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/members/searches${params ? `?${params}` : ''}`,
      method: 'POST',
      body: {
        organization_member_query: query,
      },
    },
    onRejected,
  );
