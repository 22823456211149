import { css } from '@linaria/core';
import { theming } from 'styles/utils';
export const _alertWrapper = "omponents__Alert__Alert_styles_ts-_alertWrapper-_w3hrhe";
export const success = `${"omponents__Alert__Alert_styles_ts-success-s1db4bfc"}
${_alertWrapper}
`;
export const error = `${"omponents__Alert__Alert_styles_ts-error-eu2cdgv"}
${_alertWrapper}
`;
export const info = `${"omponents__Alert__Alert_styles_ts-info-il8vftz"}
${_alertWrapper}
`;
export const welcomeAlertWrapper = "omponents__Alert__Alert_styles_ts-welcomeAlertWrapper-wm1txr";

require("./Alert.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Alert.styles.ts");