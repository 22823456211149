import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const postWorkflowsTE = <E = CF.API.ApiError>(
  {
    userId,
    appId,
    body,
  }: {
    userId?: string;
    appId?: string;
    params?: string;
    body: {
      id: string; // workflow id
      node_inputs?: { node_id: string };
      nodes: {
        id: string;
        model: {
          id: string;
          app_id: string;
          user_id: string;
          model_version: {
            id: string;
          };
        };
      }[];
    }[];
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Workflows.ListWorkflowsRes> =>
  fetchTE<CF.API.Workflows.ListWorkflowsRes, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/workflows`,
      method: 'POST',
      body: {
        workflows: body,
      },
    },
    onRejected,
  );
