import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const MultiModalToTextIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 8.5L5.5 6L2.5 3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 4H13.5" stroke={color} strokeLinecap="round" />
    <path d="M7.5 8.5H13.5" stroke={color} strokeLinecap="round" />
    <path d="M2.5 12.5L13.5 12.5" stroke={color} strokeLinecap="round" />
  </svg>
);
