import { FetchQueryOptions, QueryClient, QueryKey } from 'react-query';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { either } from 'fp-ts';
import { throwErrorOnLeftTE } from './throwErrorOnLeft';

/**
 * ReactQuery doesn't store data in cache if the Promise throws. TaskEithers don't throw by design.
 *
 * By default, we consider all Left values as Errors and invalidates cache.
 * However, we can pass a custom function that returns a Boolean by comparing the
 * Left value. If it returns false, Left would not be considered an error and will be cached.
 * */
export async function fetchQueryTE<
  Left,
  Right,
  TQueryFnData extends either.Either<Left, Right>,
  TError = never,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  queryKey: TQueryKey,
  queryFn: TaskEither<Left, Right>,
  options?: FetchQueryOptions<either.Either<Left, Right>, TError, TData, TQueryKey>,
  /**
   *  shouldThrow lets us now throw some Left values if we need them to be cached, for example:
   *  if the API gives us 0 predictions which we represent as ReactLeft<UI_STATES.empty>;
   *  then it's not an error, and can be cached.
   *  */
  shouldThrow?: (a: Left) => boolean,
): Promise<TData> {
  const taskThatThrowsOnLeft = pipe(
    queryFn,
    // always throw on every Left in case no `shouldThrow` logic provided
    throwErrorOnLeftTE(shouldThrow),
  );

  /* istanbul ignore if: runtime type safety */
  if (!queryClient) {
    throw new Error('No QueryClient provided.');
  }

  try {
    const data = await queryClient.fetchQuery(queryKey, taskThatThrowsOnLeft, options);
    return data;
    // taskThatThrowsOnLeft throws either.Left<L>
  } catch (leftE) {
    return Promise.resolve(leftE) as unknown as Promise<TData>;
  }
}
