import { IconLock } from 'components/Icons';
import { CopyableTooltip } from 'components/Tooltip';
import { contentStyles } from 'components/Tooltip/Tooltip.styles';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { container, tooltip } from './Lock.styles';

type LockComponentProps = {
  tooltipText?: string;
};

export const LockComponent: React.FC<LockComponentProps> = ({ tooltipText = '' }) => {
  return (
    <div className={container}>
      <CopyableTooltip data={tooltipText} data-testid="lock-tooltip" showArrow className={tooltip} muiTooltipProps={{ placement: 'bottom' }}>
        <div className={cx(contentStyles, flex.init, flex.alignItems.center)}>
          <IconLock size={20} data-testid="lock-icon" />
        </div>
      </CopyableTooltip>
    </div>
  );
};
