import { SelectOption, NestedSelectItem } from 'components/Select';
import { uniqWith } from 'rambda';
import { pipe } from './fp';

export const splitEvery =
  (len: number) =>
  <T = unknown>(items: T[]): T[][] => {
    const slices_ = [] as T[][];
    let i = 0;
    while (i < items.length) {
      slices_.push(items.slice(i, i + len));
      i += len;
    }
    return slices_;
  };

export const uniqueOptions = <T>(collection: (SelectOption & T)[]) =>
  pipe(
    collection,
    uniqWith((a, b) => a.value === b.value),
  );

export const alphabeticalSort = <T>(collection: (SelectOption & T)[]): (SelectOption & T)[] =>
  collection.sort((a, b) => {
    const nameLowerCased = {
      a: a.name.toLowerCase(),
      b: b.name.toLowerCase(),
    };

    if (nameLowerCased.a < nameLowerCased.b) return -1;
    if (nameLowerCased.a > nameLowerCased.b) return 1;
    return 0;
  });

export const alphabeticalSortNestedOptions = <T>(collection: NestedSelectItem<T>[]): NestedSelectItem<T>[] =>
  collection.map((option) => ({ ...option, list: alphabeticalSort(option.list) }));
