import { useCallback } from 'react';
import router, { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { Button } from 'components/Button';
import { DeleteAlert } from 'components/Alert';
import { PopoverStateReturn, Popover } from 'components/Popover';
import { copyToClipboard } from 'utils/copyToClipboard';
import { SupportedListingEntities } from 'modules/Listings/types';
import { useModalActions } from 'components/ModalManager/Context';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { IconDuplicate, IconCopy, IconLock, IconTrash } from 'components/Icons';
import { DuplicateApplication } from 'modules/Apps/components/DuplicateApplication';
import { noop } from 'utils/fp';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { getEntityId } from 'modules/Listings/helpers/entityCardUtils';
import { wrapper, option, danger } from './EntityPopover.styles';
const ENTITY_REMOVE_MODAL_ID = 'entity_remove';
const ENTITY_DUPLICATE_MODAL_ID = 'entity_duplicate';
export interface EntityPopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  entity: SupportedListingEntities;
  popover: PopoverStateReturn;
}
interface PopoverOptionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  leftIcon: React.ReactNode;
  rightIcon?: React.ReactNode;
}
export const EntityPopover: React.FC<EntityPopoverProps> = ({
  entity,
  popover,
  ...rest
}): JSX.Element => {
  const {
    load
  } = useCurrentStore();
  const {
    openModal,
    closeModal
  } = useModalActions();
  const [hasAppDuplicateAccess] = useFeaturedFlags(['DatastoreAppDuplication']);
  const entityId = getEntityId(entity);
  const {
    userOrOrgId
  } = (useRouter().query as {
    userOrOrgId: string;
  });
  const handleDuplicate = useCallback(() => {
    if (hasAppDuplicateAccess) {
      openModal({
        id: ENTITY_DUPLICATE_MODAL_ID,
        title: '',
        content: <DuplicateApplication closeAction={() => closeModal({
          id: ENTITY_DUPLICATE_MODAL_ID
        })} refetchList={load} entityId={entityId} />,
        makeActions: noop
      });
    }
  }, [entityId, hasAppDuplicateAccess]);
  const isAppEntity = ('default_workflow_id' in entity);
  const handleDelete = useCallback(() => {
    openModal({
      id: ENTITY_REMOVE_MODAL_ID,
      title: `Remove ${entityId}?`,
      content: <>
          <p>
            Are you sure you want to remove
            {` ${entityId}`}?
          </p>
          <DeleteAlert />
        </>,
      makeActions: () => <div className={cx(flex.init, flex.justifyContent.end)}>
          <Button size="md" variant="tertiary" onClick={() => closeModal({
          id: ENTITY_REMOVE_MODAL_ID
        })} className={cssHelpers.margin.x[1]}>
            Cancel
          </Button>
          {'delete' in entity && <Button size="md" variant="danger" onClick={() => entity.delete({
          closeDeleteModal: () => {
            closeModal({
              id: ENTITY_REMOVE_MODAL_ID
            });
            router.push(`/${userOrOrgId}`);
          }
        })}>
              Delete
            </Button>}
        </div>
    });
  }, [entity]);
  return <Popover tabIndex={0} aria-label={entityId} popover={popover} className={wrapper} {...rest}>
      {isAppEntity && <PopoverOption title="Duplicate" onClick={handleDuplicate} disabled={!hasAppDuplicateAccess} leftIcon={<IconDuplicate size={16} />} rightIcon={!hasAppDuplicateAccess && <IconLock size={16} />} />}
      <PopoverOption title="Copy ID" onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      copyToClipboard(entityId)();
    }} leftIcon={<IconCopy size={16} />} />
      <PopoverOption title="Delete" className={danger} onClick={handleDelete} leftIcon={<IconTrash size={16} />} />
    </Popover>;
};
const PopoverOption: React.FC<PopoverOptionProps> = ({
  leftIcon,
  rightIcon,
  title,
  className,
  ...rest
}) => {
  return <button type="button" className={cx(option, className)} {...rest}>
      {leftIcon}
      <h3>{title}</h3>
      {rightIcon}
    </button>;
};