import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const starAppTE = <E = CF.API.ApiError>(
  { userId, body }: { userId: string; body: { user_app_id: { user_id: string; app_id: string }; app_stars: { app_id: string }[] } },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Models.ModelStarResponse> =>
  fetchTE<CF.API.Models.ModelStarResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/app_stars`,
      method: 'POST',
      body,
    },
    onRejected,
  );
