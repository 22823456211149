import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { useRouter } from 'next/router';
import { Row, Col } from 'components/Grid';
import { Button } from 'components/deprecated/Button';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { LoginPayload } from 'api/users';
import { cx } from '@linaria/core';
import { Alert } from 'components/Alert';
import { IconInfo } from 'components/Icons';
import { Checkbox } from 'components/Checkbox';
import { Link } from 'components/Link';
import { flex } from 'styles/utils';
import { object, string } from 'yup';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { enforceInputHeight, formGroup, formGroupLast } from '../Signup/Signup.styles';
import { links, rememberMeGroup } from '../styles';

const loginValidationSchema = object().shape({
  email: string().required('Required').email('Email must be a valid email'),
  password: string().required('Required'),
});

interface LoginFormInterface {
  handleLoginFormSubmit: (values: LoginPayload) => void;
  isLoading?: boolean;
  showRemeberMeAndForgotPassword?: boolean;
  onForgotPasswordClick?: () => void;
}

export const LoginForm = ({
  handleLoginFormSubmit,
  isLoading,
  showRemeberMeAndForgotPassword,
  onForgotPasswordClick,
}: LoginFormInterface): JSX.Element => {
  const router = useRouter();
  const { track } = useDataCollection();
  const { error: ssoError } = router.query as { error?: string };

  const onLoginBtnClick = (btnText: string): void => {
    track(`${btnText} Clicked`, { pageName: 'login_page' });
  };

  return (
    <>
      {ssoError && (
        <Alert type="error">
          <div className={cx(flex.init)}>
            <IconInfo color="#CF536C" size={16} />
            <p>{ssoError}</p>
          </div>
        </Alert>
      )}

      <Formik
        initialValues={{
          email: '',
          password: '',
          long_lived: false,
        }}
        onSubmit={handleLoginFormSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ isValid, dirty, values, setFieldValue }) => {
          return (
            <Form>
              <Row spacing={1}>
                <Col xs={12}>
                  <div className={cx(formGroup, enforceInputHeight)}>
                    <InputField name="email" label="Email" type="email" id="email" placeholder="Email" required />
                  </div>
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  <div className={formGroupLast}>
                    <InputField name="password" type="password" label="Password" placeholder="Password" required />
                  </div>
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  {(showRemeberMeAndForgotPassword || !router.pathname?.includes('/login')) && (
                    <div className={rememberMeGroup}>
                      <Checkbox name="long_lived" checked={values.long_lived} onChange={(event) => setFieldValue('long_lived', event.target.checked)}>
                        Remember me
                      </Checkbox>
                      <Link
                        className={links}
                        href="/forgot-password"
                        onClick={(e) => {
                          onLoginBtnClick('Forgot Password');
                          if (onForgotPasswordClick) {
                            e.preventDefault();
                            onForgotPasswordClick();
                          }
                        }}
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  )}
                </Col>
              </Row>
              <Row spacing={1}>
                <Col xs={12}>
                  <ButtonGroup className={cx(flex.init, flex.justifyContent.end, 'buttons')}>
                    <Button
                      isLoading={isLoading}
                      className={cx('fullWidth bold', router.pathname?.includes('/login') && 'uppercase')}
                      kind="primary"
                      type="submit"
                      onClick={() => onLoginBtnClick('Log In')}
                      data-testid="signInButton"
                      disabled={!isValid || !dirty || isLoading}
                    >
                      Log in
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
