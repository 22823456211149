import { cx } from '@linaria/core';
import { formGroupEl } from './Forms.styles';

export const FormGroup: React.FC<
  React.PropsWithChildren<{
    id?: string;
    className?: string;
  }>
> = ({ children, id, className }) => (
  <div className={cx(formGroupEl, className)} id={id}>
    {children}
  </div>
);
