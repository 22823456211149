import { cx } from '@linaria/core';
import { wrapper, headingWrapper, headingWrapperWithBorder, heading, actionWrapper, body } from 'components/Card/Card.styles';
import { textRegular } from 'styles/typography';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  titleSeparator?: boolean;
  actionList?: React.ReactNode[];
  overrideCardBodyStyles?: string;
}

export const Card: React.FC<CardProps> = ({ title, titleSeparator, actionList, children, overrideCardBodyStyles, ...rest }) => {
  const hasCardHeader = Boolean(title || actionList?.length);
  const headingWrapperClassName = titleSeparator ? headingWrapperWithBorder : headingWrapper;

  return (
    <div className={cx(wrapper, rest.className)} {...rest}>
      {hasCardHeader && (
        <div className={headingWrapperClassName}>
          {title && <h3 className={cx(heading, textRegular)}>{title}</h3>}
          {actionList && (
            <div className={actionWrapper} data-testid="cfcomp-Card-actionList">
              {actionList.map((action) => action)}
            </div>
          )}
        </div>
      )}

      <div className={cx(body, overrideCardBodyStyles)}>{children}</div>
    </div>
  );
};
