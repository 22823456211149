import { ReactNode, FC } from 'react';
import { cx } from '@linaria/core';
import { Navbar } from 'components/Navbar';
import { ViewContent } from 'components/ViewContent';
import { appBody, main, content } from './styles';

export type PageWithSidebarProps = {
  children: ReactNode;
};

export const PageWrapper: FC<PageWithSidebarProps> = ({ children }) => (
  <div className={appBody}>
    <Navbar />
    <ViewContent>
      <main className={cx('main', main)} data-testid="main">
        <div className={cx('content', content)}>{children}</div>
      </main>
    </ViewContent>
  </div>
);
