import { cx } from '@linaria/core';
import React from 'react';
import { buttonGroup } from './ButtonGroup.styles';

type ButtonGroupProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className, ...rest }) => {
  return (
    <div className={cx(buttonGroup, className)} {...rest}>
      {children}
    </div>
  );
};
