import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const AnnotationWriterIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
    <path
      id="iconmonstr-pen-15"
      d="M21.494,16.5,6.631,24.585S4.092,34.549,0,40.9l4.588,4.614C10.872,41.471,20.9,38.858,20.9,38.858l8.085-14.863-7.5-7.493ZM18.338,35.639A81.085,81.085,0,0,0,7.12,39.825L10.546,36.4a4.172,4.172,0,0,1,2.443-1.195,2.416,2.416,0,1,0-2.7-2.7,4.175,4.175,0,0,1-1.193,2.443L5.719,38.319A79.228,79.228,0,0,0,9.861,27.147l10.958-5.961L24.3,24.67,18.338,35.64ZM22.786,12.4S26.859,4.876,29.376,0L45.517,16.141c-4.9,2.505-12.434,6.56-12.434,6.56l-10.3-10.3Z"
      transform="translate(0.001)"
      fill={color}
    />
  </svg>
);
