import { css } from '@linaria/core';
import { baseUnits, breakpoints, theming } from 'styles/utils';
import { baseTheme } from 'styles/utils/core';
export const modelViewWrapper = "odules__Models__components__ModelViewer__styles_ts-modelViewWrapper-myb8krj";
export const editableDetailsFormButton = "odules__Models__components__ModelViewer__styles_ts-editableDetailsFormButton-e17bd2f3";
export const headerOverride = "odules__Models__components__ModelViewer__styles_ts-headerOverride-h1rhqj2j";
export const modelInfoPadded = "odules__Models__components__ModelViewer__styles_ts-modelInfoPadded-mipnaut";
export const resourceInfo = "odules__Models__components__ModelViewer__styles_ts-resourceInfo-r76w5l2";
export const versionsSelectorContainerStyled = "odules__Models__components__ModelViewer__styles_ts-versionsSelectorContainerStyled-v1k45eoh";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");