/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQueryClient } from 'react-query';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { InputField } from 'components/Formik/InputField';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { Col, Row } from 'components/Grid';
import { Button } from 'components/deprecated/Button';
import { getUserMeTE } from 'api/users';
import { updateUserProfileTE, UserBody } from 'api/users/updateUser';
import { useQueryTE, useMutationTE, queryKeys } from 'utils/react-query';
import { errorToReactLeft, pipe } from 'utils/fp';
import { alphabeticalSort } from 'utils/collections';
import { Group } from 'components/Group';
import { useAuthState } from 'modules/Auth/AuthContext';
import 'react-credit-cards/es/styles-compiled.css';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { Form, Formik } from 'formik';
import { string, object } from 'yup';
import { SelectField } from 'components/Formik/SelectField';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import nProgress from 'nprogress';
import { countryCollection, jobRoleCollection } from './constants';
import { formGroup } from './ContactInformation.styles';
type ContactInformationProps = {
  user: CF.API.Users.User;
  userOrOrgId: string;
};
type ContactInformationFields = {
  firstName: string;
  lastName: string;
  companyName?: string;
  country?: {
    value: string;
    name: string;
  };
  jobRole?: {
    value: string;
    name: string;
  };
  jobTitle?: string;
};
export const genericStringRules = string().min(1, 'Too Short!').max(240, 'Too Long!');
const ContactInfoSchema = object().shape({
  firstName: genericStringRules.required('Required'),
  lastName: genericStringRules.required('Required'),
  companyName: genericStringRules.required('Required'),
  country: object().required('Required'),
  jobRole: object().required('Required'),
  jobTitle: genericStringRules
});
export const ContactInformation_: React.FC<ContactInformationProps> = ({
  user,
  userOrOrgId
}) => {
  const countries = alphabeticalSort(countryCollection);
  const jobRoles = jobRoleCollection;
  const queryClient = useQueryClient();
  const updateContactInfoMutation = useMutationTE((body: UserBody) => updateUserProfileTE({
    userId: userOrOrgId,
    body
  })(), {
    onMutate: () => {
      nProgress.start();
    },
    onSettled: (): void => {
      nProgress.done();
      queryClient.invalidateQueries([queryKeys.Users, {
        userOrOrgId
      }]);
      queryClient.invalidateQueries([queryKeys.LoggedInUser]);
    },
    onSuccess: (): void => {
      cogoToast.success('Contact information updated');
    },
    onError: (): void => {
      cogoToast.error('Could not update the profile');
    }
  });
  return <section>
      <Group title="Contact Information">
        <Formik enableReinitialize initialValues={{
        firstName: user.first_name,
        lastName: user.last_name,
        companyName: user.company_name,
        country: countries.find(x => x.value === user.user_detail?.country),
        jobTitle: user.job_title,
        jobRole: jobRoles.find(x => x.value === user.job_role),
        email: user.primary_email
      }} validationSchema={ContactInfoSchema} onSubmit={(values: ContactInformationFields) => {
        updateContactInfoMutation.mutateTE({
          info: {
            first_name: values.firstName.trim(),
            last_name: values.lastName.trim(),
            // adding non null assertions because validation checks for required
            company_name: values.companyName!.trim(),
            country: values.country!.value,
            job_role: values.jobRole!.value,
            job_title: values.jobTitle!.trim()
          }
        })();
      }}>
          {({
          isSubmitting,
          isValid,
          dirty
        }) => <Form>
              <Row columnSpacing={2}>
                <Col xs={12} md={6}>
                  <InputField name="firstName" label="First Name" placeholder="Write your first name..." formGroupClassName={formGroup} required />
                </Col>
                <Col xs={12} md={6}>
                  <InputField name="lastName" label="Last Name" placeholder="Write your last name..." formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <Row columnSpacing={2}>
                <Col xs={12} md={6}>
                  <InputField name="companyName" label="Company" placeholder="Write your company name..." formGroupClassName={formGroup} required />
                </Col>
                <Col xs={12} md={6}>
                  <SelectField name="country" label="Country" placeholder="Select country" options={countries} formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <Row columnSpacing={2}>
                <Col xs={12} md={6}>
                  <InputField name="jobTitle" label="Job Title" placeholder="Write your job title..." formGroupClassName={formGroup} />
                </Col>
                <Col xs={12} md={6}>
                  <SelectField name="jobRole" label="Job Role" placeholder="Select job role" options={jobRoles} formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <ButtonGroup className={cx(flex.init, 'buttons')}>
                <Button kind="primary" type="submit" disabled={isSubmitting || !isValid || !dirty} isLoading={isSubmitting}>
                  Update Contact Information
                </Button>
              </ButtonGroup>
            </Form>}
        </Formik>
      </Group>
    </section>;
};
export const ContactInformation: React.FC = () => {
  const sessionToken = useAuthState().authData?.session_token;
  const {
    data
  } = useQueryTE([queryKeys.LoggedInUser, {
    sessionToken
  }], getUserMeTE(undefined, errorToReactLeft));
  return pipe(data, either.fold(reactLeftToJSX(), ({
    user
  }) => <ContactInformation_ user={user} userOrOrgId={user.id} />));
};