export const validLanguages = [
  {
    language: 'Arabic (ar)',
    code: 'ar',
  },
  {
    language: 'Bengali (bn)',
    code: 'bn',
  },
  {
    language: 'Danish (da)',
    code: 'da',
  },
  {
    language: 'German (de)',
    code: 'de',
  },
  {
    language: 'English (en)',
    code: 'en',
  },
  {
    language: 'Spanish (es)',
    code: 'es',
  },
  {
    language: 'Finnish (fi)',
    code: 'fi',
  },
  {
    language: 'French (fr)',
    code: 'fr',
  },
  {
    language: 'Hindi (hi)',
    code: 'hi',
  },
  {
    language: 'Hungarian (hu)',
    code: 'hu',
  },
  {
    language: 'Italian (it)',
    code: 'it',
  },
  {
    language: 'Japanese (ja)',
    code: 'ja',
  },
  {
    language: 'Korean (ko)',
    code: 'ko',
  },
  {
    language: 'Dutch (nl)',
    code: 'nl',
  },
  {
    language: 'Norwegian (no)',
    code: 'no',
  },
  {
    language: 'Punjabi (pa)',
    code: 'pa',
  },
  {
    language: 'Polish (pl)',
    code: 'pl',
  },
  {
    language: 'Portuguese (pt)',
    code: 'pt',
  },
  {
    language: 'Russian (ru)',
    code: 'ru',
  },
  {
    language: 'Swedish (sv)',
    code: 'sv',
  },
  {
    language: 'Turkish (tr)',
    code: 'tr',
  },
  {
    language: 'Chinese Simplified (zh)',
    code: 'zh',
  },
  {
    language: 'Chinese Traditional (zh-TW)',
    code: 'zh-TW',
  },
];

export const useLanguages = (): { value: string; name: string }[] => {
  return validLanguages.map((lang) => ({ value: lang.code, name: lang.language }));
};
