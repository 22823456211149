import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const patchTeamTE = <E = CF.API.ApiError>(
  {
    orgId,
    team,
  }: {
    orgId: string;
    team: Partial<CF.API.Organizations.Team>;
  },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.ListTeamsResponse> =>
  fetchTE<CF.API.Organizations.ListTeamsResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams`,
      method: 'PATCH',
      body: {
        teams: [team],
        action: 'overwrite',
      },
    },
    onRejected,
  );
