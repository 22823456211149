import React, { useCallback } from 'react';
import { omit } from 'rambda';
import { cx } from '@linaria/core';
import { IconQuestion, IconQuestionFill } from 'components/Icons';
import { usePopoverState, PopoverAction } from 'components/Popover';
import { HelpTooltip, TooltipText } from './HelpTooltip';
import { iconHelp, helpTooltipWrapper } from './LabelWithTooltip.styles';

export interface LabelWithTooltipProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  tooltipTitle?: string;
  tooltipText?: TooltipText;
  useIconFill?: boolean;
  iconSize?: number;
  iconColor?: string;
  children?: React.ReactNode;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  children,
  tooltipTitle,
  tooltipText,
  useIconFill = true,
  iconSize = 18,
  iconColor,
  wrapperProps = {},
  ...rest
}): JSX.Element => {
  const popover = usePopoverState();

  const onHelpTooltipClose = useCallback(() => {
    popover.hide();
  }, [popover]);

  const QuestionIcon = useIconFill ? IconQuestionFill : IconQuestion;

  return (
    <div className={cx(helpTooltipWrapper, wrapperProps.className)} {...omit(['className'], wrapperProps)}>
      <label {...rest}>{children}</label>
      {tooltipText && (
        <>
          <PopoverAction data-testid="labelWithTooltip-open" popover={popover}>
            <QuestionIcon size={iconSize} className={iconHelp} color={iconColor} />
          </PopoverAction>

          <HelpTooltip tooltipTitle={tooltipTitle} tooltipText={tooltipText} popover={popover} onCloseButton={onHelpTooltipClose} />
        </>
      )}
    </div>
  );
};
